import { apiAdn, getUIContext } from "./common";
import { AxiosPromise } from "axios";
import { Notification } from "types/Notification";

export function getUnreadNotification() {
  const context = getUIContext();

  return apiAdn.get(`/notifications/unread?${context}`);
}

export function getReadNotification(first: number, size: number) {
  const context = getUIContext();

  return apiAdn.get(`/notifications/read?${context}&first=${first}&size=${size}`);
}

export function validateNotification(id: string): AxiosPromise<Notification> {
  const context = getUIContext();

  return apiAdn.post(`/notifications/${id}/validate?${context}`);
}

export function validateAllNotification(): AxiosPromise<Notification[]> {
  const context = getUIContext();

  return apiAdn.post(`/notifications/validate-all?${context}`);
}
