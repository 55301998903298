import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactGridLayout, { WidthProvider, Layout } from "react-grid-layout";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

import { ReducerState } from "reducers";

import { PanelState } from "types/Dashboard";
import { ComplexComponent, TypeComplexComponent } from "enum";

import { closePanel, changeFocus, layoutChange } from "actions/dashboard";

import Panel from "./Panel";

import { EmptyDashboard } from "./emptyDashboard";
import PanelLight from "./PanelLight";

import { InformationProvider, InformationRefresh } from "containers/information/Information";
import { LoadableLoader } from "composants/Loader";
import { Fa } from "composants/Icon";
import { createSelector } from "reselect";
import { InformationTemplate } from "composants/genericDisplay/ServerTemplateComponent";
import { initRsqlCriteria } from "utils/query.utils";
import { RSQLFilterExpression, Operators } from "rsql-criteria-typescript";
import { find } from "api";
import { ErrorBoundary } from "composants/ErrorBoundary";

type AdminButtonProps = { panel: PanelState; tableName: string };
function AdminButton({ panel, tableName }: AdminButtonProps) {
  const [url, setUrl] = useState<string | "UNKNOWN">(getURL);

  function getURL() {
    let url;
    if (panel.panelType === TypeComplexComponent.DATATABLE) {
      url = `/admin/datatable/${panel.panelTableName}`;
    } else if (panel.panelType === TypeComplexComponent.INTERACTIVE_REPORT) {
      url = `/admin/interactivereport/${panel.interactiveReportId}`;
    } else if (panel.panelType === TypeComplexComponent.NAV_CARDS) {
      url = `/admin/navcard/${panel.panelId}`;
    } else if (panel.panelType === TypeComplexComponent.SAS) {
      url = `/admin/sas/${panel.panelId}`;
    } else if (panel.panelType === TypeComplexComponent.INDICATEUR) {
      url = `/admin/indicateur/${panel.panelId}`;
    } else if (panel.panelType === TypeComplexComponent.ARBO) {
      url = `/admin/arborescence/${panel.panelId}`;
    }

    return url || "UNKNOWN";
  }

  function onAccessToAdminDgl(panel: PanelState) {
    let url = "";
    const rsql = initRsqlCriteria();
    rsql.filters.and(new RSQLFilterExpression("syjPanelId.id", Operators.Equal, panel.panelId));
    find("syjPanelDgList", rsql.build()).then(res => {
      if (res.data.data.length > 0) {
        url = `/admin/dgl/${res.data.data[0].syjDgListId}`;
      } else {
        url = `/admin/dgl`;
      }
      setUrl(url);
    });
  }

  function onAccessToMiniExpert(tableName: string) {
    let url = "";
    const rsql = initRsqlCriteria();
    rsql.filters.and(new RSQLFilterExpression("sjtaName", Operators.Equal, tableName));
    find("syjTables", rsql.build()).then(res => {
      if (res.data.data.length > 0) {
        url = `/admin/miniexpert/${res.data.data[0].id}`;
      } else {
        url = `/admin/miniexpert`;
      }
      setUrl(url);
    });
  }

  useEffect(() => {
    if (panel.panelType === TypeComplexComponent.DGL) {
      onAccessToAdminDgl(panel);
    } else if (panel.panelType === TypeComplexComponent.MINI_EXPERT) {
      onAccessToMiniExpert(tableName);
    }
  }, [panel]);

  return (
    <>
      {url === "UNKNOWN" ? (
        <Link to={url} target="_blank">
          <span className="icon">
            <Fa icon="spinner" />
          </span>
        </Link>
      ) : (
        <Link to={url} target="_blank">
          <span className="icon">
            <Fa icon="cog" />
          </span>
        </Link>
      )}
    </>
  );
}
const ResponsiveReactGridLayout = WidthProvider(ReactGridLayout);

interface PanelGridReduxProps {
  focusId: string;
  panels: PanelState[];
}

interface PanelGridPropsFn {
  changeFocus(sjmoCode: string, selected: string): void;
  layoutChange(sjmoCode: string, layouts: Layout[]): void;
  closePanel(sjmoCode: string, sjpaId: string): void;
}

type PanelGridAllProps = PanelGridReduxProps &
  PanelGridPropsFn & {
    sjmoCode: string;
    tableName: string;
    currentMainEntityId: string | null;
    onAfterSaveDatatable(): void;
    onAfterDeleteDatatable(): void;
    onAfterProcessListeGeneric(): void;
    onOpenCreator?(creatorTableName: string): void;
  };

class PanelGrid extends Component<PanelGridAllProps> {
  panelTypeWithLeftHeader = [
    TypeComplexComponent.DATATABLE,
    TypeComplexComponent.MINI_EXPERT,
    TypeComplexComponent.INTERACTIVE_REPORT,
    TypeComplexComponent.NAV_CARDS,
    TypeComplexComponent.DGL,
    TypeComplexComponent.SAS,
    TypeComplexComponent.INDICATEUR,
    TypeComplexComponent.ARBO,
    TypeComplexComponent.PLANIF
  ];
  panelWithOverflow = ["INFORMATION", "ARBO", "MINI_EXPERT", "EDITOR", "PLANIF"];
  panelWithNoPadding = ["DT", "LISTE_GENERIQUE", "INTERACTIVE_REPORT"];

  onLayoutChange = (layouts: Layout[]) => {
    this.props.layoutChange(this.props.sjmoCode, layouts);
  };

  onPanelClose = (sjpaId: string) => {
    this.props.closePanel(this.props.sjmoCode, sjpaId);
  };

  onFocusChange = (sjmoCode: string, selected: string) => {
    this.props.changeFocus(sjmoCode, selected);
  };

  createPanels = (panels: PanelState[]) => {
    const listPanel = panels
      .filter(panel => panel.visible)
      .map(panel => {
        const SelectedComponent = ComplexComponent[panel.panelType];
        const layout = {
          i: `${this.props.focusId}_${panel.panelId}`,
          x: panel.posX,
          y: panel.posY,
          w: panel.width,
          h: panel.height
        };

        const buttonAdmin =
          this.panelTypeWithLeftHeader.indexOf(panel.panelType) !== -1 &&
          sessionStorage.getItem("superUser") === "true" ? (
            <AdminButton panel={panel} tableName={this.props.tableName} />
          ) : null;

        switch (panel.panelType) {
          case TypeComplexComponent.NAV_CARDS:
          case TypeComplexComponent.INDICATEUR:
            return (
              <section
                key={layout.i}
                data-grid={layout}
                style={{ padding: 2 }}
                aria-label={panel.panelLabel}
              >
                <PanelLight
                  onPanelClose={this.onPanelClose}
                  style={{ height: "inherit" }}
                  noPaddingContent={this.panelWithNoPadding.indexOf(panel.panelType) !== -1}
                  isOverflowScroll={this.panelWithOverflow.indexOf(panel.panelType) !== -1}
                  panelId={panel.panelId}
                  leftHeader={<>{buttonAdmin}</>}
                  mandatory={panel.mandatory}
                >
                  <ErrorBoundary>
                    {SelectedComponent ? (
                      <SelectedComponent
                        sjmoCode={this.props.sjmoCode}
                        ctrlkey={undefined}
                        sjpaId={panel.panelId}
                        currentMainEntityId={this.props.currentMainEntityId}
                      />
                    ) : null}
                  </ErrorBoundary>
                </PanelLight>
              </section>
            );

          case TypeComplexComponent.INFORMATION:
            return (
              <section
                key={layout.i}
                data-grid={layout}
                style={{ padding: 2 }}
                aria-label={panel.panelLabel}
              >
                {process.env.REACT_APP_FLAG_NEW_TEMPLATE === "true" &&
                panel.informationTemplateName ? (
                  <Panel
                    title={panel.panelLabel}
                    onPanelClose={this.onPanelClose}
                    style={{ height: "inherit" }}
                    noPaddingContent={this.panelWithNoPadding.indexOf(panel.panelType) !== -1}
                    isOverflowScroll={this.panelWithOverflow.indexOf(panel.panelType) !== -1}
                    panel={panel}
                    leftHeader={<>{buttonAdmin}</>}
                    onOpenCreator={this.props.onOpenCreator}
                  >
                    <ErrorBoundary>
                      <InformationTemplate
                        moduleCode={this.props.sjmoCode}
                        panelId={panel.panelId}
                        entityId={this.props.currentMainEntityId}
                        templateIdentifier={panel.informationTemplateName}
                      />
                    </ErrorBoundary>
                  </Panel>
                ) : (
                  <InformationProvider
                    currentMainEntityId={this.props.currentMainEntityId}
                    sjmoCode={this.props.sjmoCode}
                    sjpaId={panel.panelId}
                  >
                    <Panel
                      title={panel.panelLabel}
                      onPanelClose={this.onPanelClose}
                      style={{ height: "inherit" }}
                      noPaddingContent={this.panelWithNoPadding.indexOf(panel.panelType) !== -1}
                      isOverflowScroll={this.panelWithOverflow.indexOf(panel.panelType) !== -1}
                      panel={panel}
                      leftHeader={
                        <>
                          {buttonAdmin}
                          <InformationRefresh />
                        </>
                      }
                      onOpenCreator={this.props.onOpenCreator}
                    >
                      <ErrorBoundary>
                        <ComplexComponent.INFORMATION />
                      </ErrorBoundary>
                    </Panel>
                  </InformationProvider>
                )}
              </section>
            );

          default:
            return (
              <section
                key={layout.i}
                data-grid={layout}
                style={{ padding: 2 }}
                aria-label={panel.panelLabel}
              >
                <Panel
                  id={
                    panel.panelType === TypeComplexComponent.DATATABLE
                      ? `panel-${panel.panelCtrlKey}`
                      : undefined
                  }
                  title={panel.panelLabel}
                  onPanelClose={this.onPanelClose}
                  style={{ height: "inherit" }}
                  noPaddingContent={this.panelWithNoPadding.indexOf(panel.panelType) !== -1}
                  isOverflowScroll={this.panelWithOverflow.indexOf(panel.panelType) !== -1}
                  panel={panel}
                  leftHeader={<>{buttonAdmin}</>}
                  onOpenCreator={this.props.onOpenCreator}
                >
                  <ErrorBoundary>
                    {SelectedComponent ? (
                      <SelectedComponent
                        sjmoCode={this.props.sjmoCode}
                        ctrlkey={
                          panel.panelType === TypeComplexComponent.DATATABLE ||
                          panel.panelType === TypeComplexComponent.EDITOR
                            ? panel.panelCtrlKey
                            : undefined
                        }
                        sjpaId={panel.panelId}
                        tableName={
                          [TypeComplexComponent.DATATABLE, TypeComplexComponent.EDITOR].indexOf(
                            panel.panelType
                          ) !== -1
                            ? panel.panelTableName
                            : this.props.tableName
                        }
                        currentMainEntityId={this.props.currentMainEntityId}
                        onAfterUpload={
                          panel.panelType === TypeComplexComponent.SAS
                            ? this.props.onAfterSaveDatatable
                            : undefined
                        }
                        onAfterSaveDatatable={
                          panel.panelType === TypeComplexComponent.DATATABLE
                            ? this.props.onAfterSaveDatatable
                            : panel.panelType === TypeComplexComponent.INTERACTIVE_REPORT
                            ? this.props.onAfterProcessListeGeneric
                            : undefined
                        }
                        onAfterDeleteDatatable={
                          panel.panelType === TypeComplexComponent.DATATABLE
                            ? this.props.onAfterDeleteDatatable
                            : undefined
                        }
                        onAfterProcessListeGeneric={
                          panel.panelType === TypeComplexComponent.DATATABLE
                            ? this.props.onAfterProcessListeGeneric
                            : undefined
                        }
                        nodeType={
                          panel.panelType === TypeComplexComponent.DATATABLE
                            ? panel.nodeType
                            : undefined
                        }
                        processIdDrop={
                          panel.panelType === TypeComplexComponent.DATATABLE
                            ? panel.processIdDrop
                            : undefined
                        }
                        definitionId={panel.interactiveReportId}
                      />
                    ) : null}
                  </ErrorBoundary>
                </Panel>
              </section>
            );
        }
      });

    return listPanel;
  };

  render() {
    const { panels } = this.props;

    if (panels.filter(p => p.visible).length <= 0) {
      return (
        <div style={{ textAlign: "center", marginTop: "1em" }}>
          <div className="title is-5">
            <Trans i18nKey="commun_dashboard_vide">Votre dashboard est vide</Trans>
          </div>
          <EmptyDashboard />
          <div className="subtitle is-4">
            <Trans i18nKey="commun_ajouter_un_panel_pour_commencer">
              Ajouter un <strong>panel</strong> pour commencer
            </Trans>
          </div>
        </div>
      );
    }

    return (
      <React.Suspense fallback={<LoadableLoader />}>
        <ResponsiveReactGridLayout
          className="layout"
          cols={12}
          rowHeight={30}
          onLayoutChange={this.onLayoutChange}
          measureBeforeMount
          draggableHandle=".card-header-draggable"
        >
          {this.createPanels(panels)}
        </ResponsiveReactGridLayout>
      </React.Suspense>
    );
  }
}

const selectDashboardDefinition = (state: ReducerState, sjmoCode: string) =>
  state.dashboard.dashboardDefinitions[sjmoCode];

const selectSelectedFocus = (state: ReducerState, sjmoCode: string) =>
  state.dashboard.selected[sjmoCode];

const selectIndexActiveFocus = (state: ReducerState, sjmoCode: string, focusId: string) => {
  const def = state.dashboard.dashboardDefinitions[sjmoCode];
  return def ? def.findIndex(el => el.focusId === focusId) : null;
};

type PanelsAndFocusIdPropsSelector = { sjmoCode: string };
const selectPanelsAndFocus = createSelector(
  (state: ReducerState, props: PanelsAndFocusIdPropsSelector) =>
    selectDashboardDefinition(state, props.sjmoCode),
  (state: ReducerState, props: PanelsAndFocusIdPropsSelector) =>
    selectSelectedFocus(state, props.sjmoCode),
  (currentModuleDefinitions, selectedFocus) => {
    const currentFocus = currentModuleDefinitions.find(d => d.focusId === selectedFocus);
    if (currentFocus) {
      const orderedPanels = currentFocus.panels.slice();
      orderedPanels.sort((a, b) => (a.posY < b.posY || a.posX < b.posX ? -1 : 1));

      return { panels: orderedPanels, focusId: selectedFocus };
    }
    return { panels: [], focusId: selectedFocus };
  }
);

function mapStateToProps(state: ReducerState, { sjmoCode }: PanelGridAllProps) {
  // récupération de la définition correspondante
  const panelsAndFocusId = selectPanelsAndFocus(state, { sjmoCode });
  return {
    panels: panelsAndFocusId.panels,
    focusId: panelsAndFocusId.focusId
  };
}

export default connect<PanelGridReduxProps, PanelGridPropsFn>(mapStateToProps, {
  changeFocus,
  closePanel,
  layoutChange
})(PanelGrid);
