import React, { FC, SyntheticEvent } from "react";
import { useInfiniteQuery } from "react-query";
import { Input } from "@axin-org/comet";
import { useState } from "react";
import { convertValue } from "utils/entities.utils";
import {
  Operators,
  RSQLCriteria,
  RSQLFilterExpression,
  RSQLFilterList
} from "rsql-criteria-typescript";
import useDebounce from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { findView } from "api";
import InfiniteList from "./InfiniteList";
import { uuidv4 } from "utils/uuid.utils";
import { Pojo } from "types/Galaxy";
import { initRsqlCriteria, mapSortToRSQL } from "utils/query.utils";
import { PagedResource } from "types/Search";
import { useEffect } from "react";

export type SortDirection = "ASC" | "DESC";

const FETCH_SIZE = 50;
export const filterHeight = 40.6;

export interface InfiniterequestProps {
  sjmoCode?: string;
  tableName: string;
  filter?: RSQLCriteria;
  searchFields?: string[];
  searchString?: string;
  sortValues?: Record<string, SortDirection>;
  first: number;
}

/**
 * Fonction qui récupère les données pour une infinite query,
 * on spécifie toujours tous les paramètres et dans cet ordre
 * afin de d'avoir une clé réutilisable pour react-query
 *
 */
export async function fetchData({
  sjmoCode,
  tableName,
  filter,
  searchFields,
  searchString,
  sortValues,
  first
}: InfiniterequestProps) {
  const rsql = initRsqlCriteria();

  if (filter) {
    rsql.filters.and(filter.filters);
  }

  if (searchFields && searchString && searchFields.length > 0 && searchString.length > 0) {
    const rsqlList = new RSQLFilterList();
    for (let field of searchFields) {
      rsqlList.or(new RSQLFilterExpression(field, Operators.StartsWith, searchString));
    }
    rsql.filters.and(rsqlList);
  }

  mapSortToRSQL(rsql, sortValues);

  const { data } = await findView({
    sjmoCode,
    tableName: tableName,
    filter: rsql.build(),
    first: first,
    size: FETCH_SIZE
  });

  return data;
}

export const infiniteQueryOptions = {
  staleTime: 60000,
  keepPreviousData: true,
  getNextPageParam: (lastPage: any, allPages: any) => {
    const first = allPages.length * FETCH_SIZE;
    return lastPage ? (lastPage.meta.totalRecords > first ? first : false) : false;
  }
};

const ViewFilterableList: FC<{
  sjmoCode?: string;
  tableName: string;
  searchFields?: string[];
  filter?: RSQLCriteria;
  sortValues?: Record<string, SortDirection>;
  listHeight: number;
  forceRefresh?: boolean;
  renderCell(entity: Pojo): React.ReactNode;
  customFetchData?: (args: InfiniterequestProps) => Promise<PagedResource<Pojo>>;
}> = props => {
  const [t] = useTranslation();
  const [searchString, setSearchString] = useState<string>("");
  const loadMoreButtonRef = React.useRef<HTMLDivElement | null>(null);

  const debounceSearch = useDebounce(searchString, 200);

  // La clé pour le cache de reactQuery est la suivante :
  // tableName,
  // filtre string,
  // filtre via liste de criteria,
  // liste des champs pour la recherche manuelle
  // termes de la recherche manuelle
  // ordre de trie
  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    [
      props.tableName,
      props.filter ?? [],
      props.searchFields ? props.searchFields : [],
      debounceSearch,
      props.sortValues
    ],
    ({ pageParam = 0 }) => {
      const args = {
        sjmoCode: props.sjmoCode,
        tableName: props.tableName,
        filter: props.filter,
        searchFields: props.searchFields ? props.searchFields : [],
        searchString: debounceSearch,
        sortValues: props.sortValues,
        first: pageParam
      };
      return props.customFetchData ? props.customFetchData(args) : fetchData(args);
    },
    infiniteQueryOptions
  );

  useEffect(() => {
    refetch();
  }, [props.forceRefresh, refetch]);

  return (
    <div style={{ height: props.listHeight }}>
      {props.searchFields && props.searchFields.length > 0 && (
        <Input
          id={`filterableListInput_${props.tableName}_${uuidv4()}`}
          type="text"
          className="mb-7"
          value={searchString}
          onChange={(e: SyntheticEvent<any>) => {
            const val = convertValue(e);
            setSearchString(val);
          }}
          placeholder={t("commun_recherche")}
          autoComplete="off"
        />
      )}
      <InfiniteList
        data={data?.pages ?? undefined}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        loadMoreButtonRef={loadMoreButtonRef}
        fetchNextPage={fetchNextPage}
        renderCell={props.renderCell}
        listHeight={
          props.listHeight -
          (props.searchFields && props.searchFields.length ? filterHeight + 20 : 0)
        }
      />
    </div>
  );
};

export default ViewFilterableList;
