// copied and pasted in to make sure it becomes a local require
// and gets compiled to es5 by microbundle/buble too
class Store {
  private storeName: string;
  private _dbp: Promise<IDBDatabase>;
  constructor(dbName = "keyval-store", storeName = "keyval") {
    this.storeName = storeName;
    this._dbp = new Promise((resolve, reject) => {
      const openreq = indexedDB.open(dbName, 1);
      openreq.onerror = () => reject(openreq.error);
      openreq.onsuccess = () => resolve(openreq.result);
      // First time setup: create an empty object store
      openreq.onupgradeneeded = () => {
        openreq.result.createObjectStore(storeName);
      };
    });
  }
  _withIDBStore(
    type: IDBTransactionMode,
    callback: (objectStore: IDBObjectStore) => void
  ): Promise<void> {
    return this._dbp.then(
      db =>
        new Promise((resolve, reject) => {
          const transaction = db.transaction(this.storeName, type);
          transaction.oncomplete = () => resolve();
          transaction.onabort = transaction.onerror = () => reject(transaction.error);
          callback(transaction.objectStore(this.storeName));
        })
    );
  }
}
let store: Store;
function getDefaultStore() {
  if (!store) store = new Store();
  return store;
}

function get(key: string, store: Store = getDefaultStore()) {
  let req: IDBRequest<any | undefined>;
  return store
    ._withIDBStore("readonly", store => {
      req = store.get(key);
    })
    .then(() => req.result);
}

function set(key: string, value: any, store = getDefaultStore()) {
  return store._withIDBStore("readwrite", store => {
    store.put(value, key);
  });
}
function del(key: string, store = getDefaultStore()) {
  return store._withIDBStore("readwrite", store => {
    store.delete(key);
  });
}
function clear(store = getDefaultStore()) {
  return store._withIDBStore("readwrite", store => {
    store.clear();
  });
}
function keys(store = getDefaultStore()) {
  const keys: string[] = [];
  return store
    ._withIDBStore("readonly", store => {
      // This would be store.getAllKeys(), but it isn't supported by Edge or Safari.
      // And openKeyCursor isn't supported by Safari.
      (store.openKeyCursor || store.openCursor).call(store).onsuccess = function() {
        if (!this.result) return;
        keys.push(this.result.key);
        this.result.continue();
      };
    })
    .then(() => keys);
}

export { Store, get, set, del, clear, keys };
