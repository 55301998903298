import { ComponentState } from "types/Component";
import { FilterBar } from "types/Search";
import { SatelliteLine } from "containers/satellites/SatellitesData";

import produce from "immer";

import { ActionTypeData } from "reducers/Action";
import { Action } from "redux";

export const LOCAL_STORAGE_GRID_PADDING = "grid-padding";
export type GridPaddingType = "small" | "large";

export type DatatableSort = "ASC" | "DESC" | undefined;

export function gridPadding(grid: GridPaddingType): number {
  return grid === "small" ? 50 : 130;
}

export function gridPaddingComponentSize(grid: GridPaddingType) {
  return grid === "small" ? "small" : undefined;
}

export interface DatatableState {
  columns: ComponentState[];
  rowExpand: Record<string, boolean>;
  rowExpandData: Record<string, any>;
  filterOpen: boolean;
  filterValues: Record<string, any>;
  sortValues: Record<string, DatatableSort>;
  first3vals: SatelliteLine[];
  satelliteContextId?: string;
  gridPadding: GridPaddingType;
  breakDialogOpen: boolean;
  currentRowClick: number | null;
}

const initialState: DatatableState = {
  columns: [],
  rowExpand: {},
  rowExpandData: {},
  filterOpen: false,
  filterValues: {},
  sortValues: {},
  first3vals: [],
  gridPadding: (localStorage.getItem(LOCAL_STORAGE_GRID_PADDING) as GridPaddingType) || "small",
  breakDialogOpen: false,
  currentRowClick: null
};

const INIT_TABLE = "INIT_TABLE";
type ActionInit = Action<"INIT_TABLE">;

const RESIZE_CHANGE = "RESIZE_CHANGE";
type ActionResize = ActionTypeData<
  "RESIZE_CHANGE",
  {
    indexCol: number;
    columnWidthName: string;
    newWidth: number;
  }
>;

const FILTER_CHANGE = "FILTER_CHANGE";
type ActionFilterChange = ActionTypeData<
  "FILTER_CHANGE",
  {
    col: string;
    value: any;
  }
>;

const CLEAR_FILTER = "CLEAR_FILTER";
type ActionFilterClear = ActionTypeData<
  "CLEAR_FILTER",
  {
    col: string;
  }
>;

const SORT_CHANGE = "SORT_CHANGE";
type ActionSortChange = ActionTypeData<
  "SORT_CHANGE",
  {
    col: string;
    sort: DatatableSort;
  }
>;
const CLEAR_SORT = "CLEAR_SORT";
type ActionClearSort = Action<"CLEAR_SORT">;

const CLEAR_SORT_AND_FILTER = "CLEAR_SORT_AND_FILTER";
type ActionClearSortAndFilter = Action<"CLEAR_SORT_AND_FILTER">;

const TOGGLE_EXPAND_ROW = "TOGGLE_EXPAND_ROW";
type ActionToggleExpandRow = ActionTypeData<"TOGGLE_EXPAND_ROW", { id: string }>;

const ROW_EXPAND_DATA_SUCCESS = "ROW_EXPAND_DATA_SUCCESS";
type ActionRowExpandDataSuccess = ActionTypeData<
  "ROW_EXPAND_DATA_SUCCESS",
  { id: string; result: string }
>;

const ROW_EXPAND_DATA_ERROR = "ROW_EXPAND_DATA_ERROR";
type ActionRowExpandDataError = ActionTypeData<"ROW_EXPAND_DATA_ERROR", { id: string }>;

const INIT_GRID_PADDING = "INIT_GRID_PADDING";
type ActionInitGridPadding = ActionTypeData<"INIT_GRID_PADDING", GridPaddingType>;

const CHANGE_GRID_PADDING = "CHANGE_GRID_PADDING";
type ActionChangeGridPadding = ActionTypeData<"CHANGE_GRID_PADDING", GridPaddingType>;

const TOGGLE_SEARCH = "TOGGLE_SEARCH";
type ActionToggleSearch = Action<"TOGGLE_SEARCH">;

const SET_VISIBILITY_SEARCH = "SET_VISIBILITY_SEARCH";
type ActionVisibilitySearch = ActionTypeData<"SET_VISIBILITY_SEARCH", boolean>;

const UPDATE_COLUMNS = "UPDATE_COLUMNS";
type ActionUpdateColumns = ActionTypeData<"UPDATE_COLUMNS", ComponentState[]>;

const TOGGLE_BREAK_DIALOG = "TOGGLE_BREAK_DIALOG";
type ActionToggleBreakDialog = Action<"TOGGLE_BREAK_DIALOG">;

const CHANGE_BREAK_COLUMN_AND_TOGGLE_BREAK_DIALOG = "CHANGE_BREAK_COLUMN_AND_TOGGLE_BREAK_DIALOG";
type ActionChangeBreakColumnAndToggleBreakDialog = ActionTypeData<
  "CHANGE_BREAK_COLUMN_AND_TOGGLE_BREAK_DIALOG",
  string[]
>;

const SET_ROW_CLICK = "SET_ROW_CLICK";
type ActionSetRowClick = ActionTypeData<"SET_ROW_CLICK", number>;

export const DatatableActions = {
  INIT_TABLE,
  RESIZE_CHANGE,
  FILTER_CHANGE,
  CLEAR_FILTER,
  SORT_CHANGE,
  CLEAR_SORT,
  CLEAR_SORT_AND_FILTER,
  TOGGLE_EXPAND_ROW,
  ROW_EXPAND_DATA_SUCCESS,
  ROW_EXPAND_DATA_ERROR,
  INIT_GRID_PADDING,
  CHANGE_GRID_PADDING,
  TOGGLE_SEARCH,
  SET_VISIBILITY_SEARCH,
  // UPDATE_FILTER_BAR,
  // UPDATE_FILTER_BAR_DEFAULT,
  UPDATE_COLUMNS,
  TOGGLE_BREAK_DIALOG,
  CHANGE_BREAK_COLUMN_AND_TOGGLE_BREAK_DIALOG,
  SET_ROW_CLICK
};

export type DatatableActionAll =
  | ActionInit
  | ActionResize
  | ActionFilterChange
  | ActionFilterClear
  | ActionToggleExpandRow
  | ActionSortChange
  | ActionRowExpandDataSuccess
  | ActionRowExpandDataError
  | ActionInitGridPadding
  | ActionChangeGridPadding
  | ActionClearSort
  | ActionClearSortAndFilter
  | ActionUpdateColumns
  | ActionToggleSearch
  | ActionVisibilitySearch
  | ActionToggleBreakDialog
  | ActionSetRowClick;

export function reducerState(
  state: Readonly<DatatableState> = initialState,
  action: DatatableActionAll
): DatatableState {
  switch (action.type) {
    case RESIZE_CHANGE:
      return resizeChange(state, action);
    case FILTER_CHANGE:
      return filterChange(state, action);
    case CLEAR_FILTER:
      return clearFilter(state, action);
    case SORT_CHANGE:
      const { col, sort } = action.payload;
      const shouldSortBeClear = state.sortValues[col] === sort;

      return {
        ...state,
        sortValues: {
          ...state.sortValues,
          [col]: shouldSortBeClear ? undefined : sort
        }
      };
    case CLEAR_SORT:
      return {
        ...state,
        sortValues: {}
      };
    case CLEAR_SORT_AND_FILTER:
      return {
        ...state,
        filterValues: {},
        sortValues: {}
      };
    case TOGGLE_EXPAND_ROW:
      return toggleExpandRow(state, action);
    case ROW_EXPAND_DATA_SUCCESS:
      return rowExpandSuccess(state, action);

    case ROW_EXPAND_DATA_ERROR:
      return rowExpandError(state, action);

    case INIT_GRID_PADDING:
    case CHANGE_GRID_PADDING:
      return {
        ...state,
        gridPadding: action.payload
      };

    case TOGGLE_SEARCH:
      return {
        ...state,
        filterOpen: !state.filterOpen
      };

    case SET_VISIBILITY_SEARCH:
      return {
        ...state,
        filterOpen: action.payload
      };

    case UPDATE_COLUMNS:
      return {
        ...state,
        columns: action.payload
      };

    case TOGGLE_BREAK_DIALOG:
      return toggleBreakColumn(state);

    case SET_ROW_CLICK:
      return setRowClick(state, action);
    default:
      return state;
  }
}

function resizeChange(state: DatatableState, action: ActionResize) {
  const { indexCol, columnWidthName, newWidth } = action.payload;
  return produce(state, draft => {
    draft.columns[indexCol][columnWidthName] = newWidth;
  });
}

function filterChange(state: DatatableState, action: ActionFilterChange) {
  const { col, value } = action.payload;
  return produce(state, draft => {
    if (value === null || value === "") {
      delete draft.filterValues[col];
    } else {
      draft.filterValues[col] = value;
    }
  });
}

function clearFilter(state: DatatableState, action: ActionFilterClear) {
  return produce(state, draft => {
    delete draft.filterValues[action.payload.col];
  });
}

function toggleExpandRow(state: DatatableState, action: ActionToggleExpandRow) {
  const { id } = action.payload;
  return produce(state, draft => {
    if (draft.rowExpand[id]) {
      draft.rowExpand[id] = false;
    } else {
      draft.rowExpand[id] = true;
    }
  });
}

function rowExpandSuccess(state: DatatableState, action: ActionRowExpandDataSuccess) {
  const { id, result } = action.payload;

  return produce(state, draft => {
    draft.rowExpandData[id] = result;
  });
}

function rowExpandError(state: DatatableState, action: ActionRowExpandDataError) {
  const { id } = action.payload;

  return produce(state, draft => {
    delete draft.rowExpandData[id];
  });
}

function toggleBreakColumn(state: DatatableState): DatatableState {
  return produce(state, draft => {
    draft.breakDialogOpen = !state.breakDialogOpen;
  });
}

function setRowClick(state: DatatableState, action: ActionSetRowClick) {
  return produce(state, draft => {
    draft.currentRowClick = action.payload;
  });
}
