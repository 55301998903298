import { combineReducers } from "redux";
import Action from "reducers/Action";
import immer from "immer";

import {
  EXTENSIONS,
  COMMENTAIRES,
  DOCUMENTS,
  CONFIGURATEURS,
  ARTICULATIONS,
  VALIDATIONS,
  MAILS
} from "constant/satellite";
import * as actionNames from "constant/satellite";
import { INIT_USER_SETTINGS } from "constant/userSettings";

/**
 * Interface correspondant au state du reducer global
 *
 * @export
 * @interface AllSatelliteState
 */
export interface AllSatelliteState {
  [EXTENSIONS]: OneSatelliteState;
  [COMMENTAIRES]: OneSatelliteState;
  [DOCUMENTS]: OneSatelliteState;
  [CONFIGURATEURS]: OneSatelliteState;
  [ARTICULATIONS]: OneSatelliteState;
  [VALIDATIONS]: OneSatelliteState;
  [MAILS]: OneSatelliteState;
  context: { tableName: string | undefined; id: string | undefined; sjmoCode: string | undefined };
}

const initialState = {
  count: 0,
  datas: [],
  visible: false
};

/**
 * Interface correspondant au state d'une donnée satellite particulière
 *
 * @interface OneSatelliteState
 */
export interface OneSatelliteState {
  count: number;
  datas: Array<any>;
  visible: boolean;
}

/**
 * Factory qui génère une fonction de reducer correspondant à une partie des données satellites
 * en fonction des noms d'action passé
 *
 * @param typeDatas
 * @param typeCount
 */
function createReducer(type: string, typeDatas: string, typeCount: string) {
  return function(state: OneSatelliteState = initialState, action: Action<any>): OneSatelliteState {
    if (action.type === typeDatas) {
      return immer(state, draft => {
        draft.datas = action.payload;
      });
    }
    if (action.type === typeCount) {
      return immer(state, draft => {
        draft.count = action.payload;
      });
    }
    if (action.type === actionNames.CLEAR_SATELLITES_ALL_COUNT) {
      return immer(state, draft => {
        draft.count = 0;
      });
    }
    // INIT_USER_SETTINGS récupère les valeurs permettant de savoir s'il faut afficher (ou pas)
    // Les indicateurs de données stellites
    if (action.type === INIT_USER_SETTINGS) {
      return immer(state, draft => {
        draft.visible = action.payload[type];
      });
    }
    return state;
  };
}

/**
 * Reducer qui gère l'objet contextuel de la barre des données satellites
 * @param state
 * @param action
 */
function satelliteContextReducer(
  state: { tableName: string | undefined; id: string | undefined; sjmoCode: string | undefined } = {
    tableName: undefined,
    id: undefined,
    sjmoCode: undefined
  },
  action: Action<{ tableName: string; id: string; sjmoCode: string }>
) {
  if (action.type === actionNames.UPDATE_CONTEXT) {
    return immer(state, draft => {
      draft.tableName = action.payload.tableName;
      draft.id = action.payload.id;
      draft.sjmoCode = action.payload.sjmoCode;
    });
  }
  if (action.type === actionNames.CLEAR_CONTEXT) {
    return immer(state, draft => {
      draft.tableName = undefined;
      draft.id = undefined;
      draft.sjmoCode = undefined;
    });
  }
  return state;
}

const satellitesReducer = combineReducers<AllSatelliteState>({
  context: satelliteContextReducer,
  [EXTENSIONS]: createReducer(
    EXTENSIONS,
    actionNames.FETCH_EXTENSIONS,
    actionNames.FETCH_EXTENSIONS_COUNT_SUCESS
  ),
  [COMMENTAIRES]: createReducer(
    COMMENTAIRES,
    actionNames.FETCH_COMMENTAIRES,
    actionNames.FETCH_COMMENTAIRES_COUNT_SUCESS
  ),
  [DOCUMENTS]: createReducer(
    DOCUMENTS,
    actionNames.FETCH_DOCUMENTS,
    actionNames.FETCH_DOCUMENTS_COUNT_SUCESS
  ),
  [CONFIGURATEURS]: createReducer(
    CONFIGURATEURS,
    actionNames.FETCH_CONFIGURATEURS,
    actionNames.FETCH_CONFIGURATEURS_COUNT_SUCESS
  ),
  [ARTICULATIONS]: createReducer(
    ARTICULATIONS,
    actionNames.FETCH_ARTICULATIONS,
    actionNames.FETCH_ARTICULATIONS_COUNT_SUCESS
  ),
  [VALIDATIONS]: createReducer(
    VALIDATIONS,
    actionNames.FETCH_VALIDATIONS,
    actionNames.FETCH_VALIDATIONS_COUNT_SUCESS
  ),
  mails: createReducer(MAILS, actionNames.FETCH_MAILS, actionNames.FETCH_MAILS_COUNT_SUCESS)
});

export default satellitesReducer;
