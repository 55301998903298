import React, { Component } from "react";

type ReactResult = JSX.Element | JSX.Element[] | undefined | null;

type RafComponentProps = {
  children(): ReactResult;
};
type RafComponentState = { isVisible: boolean };
export default class RafComponent extends Component<RafComponentProps, RafComponentState> {
  state: RafComponentState = {
    isVisible: false
  };

  private unmount = false;

  componentWillUnmount() {
    this.unmount = true;
  }

  update = () => {
    if (this.unmount) {
      return;
    }

    this.setState({ isVisible: true });
  };

  componentDidMount() {
    requestAnimationFrame(this.update);
  }
  render() {
    return this.state.isVisible ? this.props.children() : null;
  }
}

export function withRaf<P>(WrappedComponent: React.ComponentType<P>) {
  if (WrappedComponent === undefined || WrappedComponent === null) {
    throw new Error("cannot use withLabel with and undefined or null component");
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || "Component";
  const displayName = `withRaf(${wrappedComponentName})`;

  class WithRaf extends Component<P> {
    static displayName = displayName;

    renderWrapped = () => {
      return <WrappedComponent {...this.props} />;
    };

    render() {
      return <RafComponent>{this.renderWrapped}</RafComponent>;
    }
  }

  return WithRaf;
}
