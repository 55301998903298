import { ReducerState } from "reducers";
import { createSelector } from "reselect";
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";
import {
  InteractionReducerParameter,
  InteractionDefinitionState,
  InteractionReducerState,
  InteractionValuesByField
} from "reducers/modules/InteractionReducer";

export const selectContext = (state: ReducerState) => state.interactions;

type SelectContextByModuleAndCtrlKeyProps = {
  sjmoCode: string;
  tableName?: string;
  ctrlKey: string;
};

export const selectContextProperties = (
  state: ReducerState,
  props: SelectContextByModuleAndCtrlKeyProps
): any => {
  return state.interactions[props.sjmoCode];
};

export function selectListComponentParents(
  state: ReducerState,
  sjmoCode: string,
  key: string
): string[] {
  let parents: string[] = [];

  let currentParent = findMaster(state.interactions, sjmoCode, key);
  if (currentParent == null) return parents;

  parents.push(currentParent);
  while (currentParent != null) {
    currentParent = findMaster(state.interactions, sjmoCode, currentParent);
    if (currentParent != null) parents.push(currentParent);
  }

  return parents;
}

export function selectListComponentChildren(
  state: ReducerState,
  sjmoCode: string,
  key: string
): string[] {
  const moduleInteractions = state.interactions[sjmoCode];

  if (moduleInteractions[key] == null || moduleInteractions[key] === undefined) return [];

  const interactionsChildren = [...moduleInteractions[key]];

  let childrenKeys = new Set<string>();

  for (let i = 0; i < interactionsChildren.length; i++) {
    let child = interactionsChildren[i];
    childrenKeys.add(child.ctrlkey);
    if (moduleInteractions[child.ctrlkey] != null && moduleInteractions[child.ctrlkey]) {
      interactionsChildren.push(...moduleInteractions[child.ctrlkey]);
    }
  }

  return [...childrenKeys];
}

export function makeSelectContextAsDetailList() {
  return createSelector(
    (state: ReducerState, _: SelectContextByModuleAndCtrlKeyProps) => state.interactions,
    (_: ReducerState, props: SelectContextByModuleAndCtrlKeyProps) => props,
    (state, { sjmoCode, tableName, ctrlKey }) => {
      // on récupére la liste des détails que l'on souhaite exlure
      // des interactions avec moins de spécificité
      // car les interactions fonctionnent dans cet ordre :
      // - global < module.
      // - table < module
      // on récupère donc
      // - les modules en premier
      // - puis les tables en excluant les fields gérés par module, si on a passé un tableName spécifique
      // - sinon global en excluant les fields gérés par module.
      let interactionModule = getListInteractionValues(state, sjmoCode, ctrlKey);
      let ignoredDetails = interactionModule.map(el => el.fieldDetails);

      if (tableName) {
        let interactionTable = getListInteractionValues(state, tableName, ctrlKey, ignoredDetails);

        // si on trouve des interactions propres à la table on ne doit pas cumuler avec les intéractions globales
        // Les intéractions de la table écrase les intéractions globales.
        if (interactionTable.length > 0) {
          return [...interactionTable, ...interactionModule];
        }
      }

      let interactionGlobal = getListInteractionValues(state, "GLOBAL", ctrlKey, ignoredDetails);

      return [...interactionGlobal, ...interactionModule];
    }
  );
}

export function makeSelectContextAsDetail() {
  return createSelector(
    (state: ReducerState, _: SelectContextByModuleAndCtrlKeyProps) => state.interactions,
    (_: ReducerState, props: SelectContextByModuleAndCtrlKeyProps) => props,
    (state, { sjmoCode, tableName, ctrlKey }) => {
      // le composant est il un detail ?
      const inter1 = getModuleInteractions(state, sjmoCode, ctrlKey);
      const inter2 = getModuleInteractions(state, "GLOBAL", ctrlKey);

      let interByTable = null;

      if (tableName) {
        interByTable = getModuleInteractions(state, tableName, ctrlKey);
        // si on trouve des interactions propres à la table on ne doit pas cumuler avec les intéractions globales
        // Les intéractions de la table écrase les intéractions globales.

        if (!isEmpty(interByTable)) {
          return { ...interByTable, ...inter1 };
        }
      }

      return { ...inter2, ...interByTable, ...inter1 };
    }
  );
}

/**
 * Un composant demande s'il est detail d'un autre composant
 */
export const selectContextAsDetail = (
  stateInteraction: InteractionReducerState,
  { sjmoCode, tableName, ctrlKey }: SelectContextByModuleAndCtrlKeyProps
): InteractionReducerParameter => {
  // le composant est il un detail ?

  const inter1 = getModuleInteractions(stateInteraction, sjmoCode, ctrlKey);
  const inter2 = getModuleInteractions(stateInteraction, "GLOBAL", ctrlKey);

  let interByTable = null;

  if (tableName) {
    interByTable = getModuleInteractions(stateInteraction, tableName, ctrlKey);
    // si on trouve des interactions propres à la table on ne doit pas cumuler avec les intéractions globales
    // Les intéractions de la table écrase les intéractions globales.

    if (!isEmpty(interByTable)) {
      return { ...interByTable, ...inter1 };
    }
  }

  return { ...inter2, ...interByTable, ...inter1 };
};

export function makeSelectInteractionValues() {
  return createSelector(
    (state: ReducerState, _: SelectContextByModuleAndCtrlKeyProps) => state.interactions,
    (_: ReducerState, props: SelectContextByModuleAndCtrlKeyProps) => props,
    (interactions, { sjmoCode, tableName, ctrlKey }) => {
      // le composant est il un detail ?

      const inter1 = getInteractionValues(interactions, sjmoCode, ctrlKey);
      const inter2 = getInteractionValues(interactions, "GLOBAL", ctrlKey);

      let interByTable = null;

      if (tableName) {
        interByTable = getInteractionValues(interactions, tableName, ctrlKey);
      }

      return { ...inter2, ...interByTable, ...inter1 };
    }
  );
}

export const selectInteractionValues = (
  stateInteraction: InteractionReducerState,
  { sjmoCode, tableName, ctrlKey }: SelectContextByModuleAndCtrlKeyProps
): InteractionReducerParameter => {
  // le composant est il un detail ?

  const inter1 = getInteractionValues(stateInteraction, sjmoCode, ctrlKey);
  const inter2 = getInteractionValues(stateInteraction, "GLOBAL", ctrlKey);

  let interByTable = null;

  if (tableName) {
    interByTable = getInteractionValues(stateInteraction, tableName, ctrlKey);
  }

  return { ...inter2, ...interByTable, ...inter1 };
};

function getInteractionValues(
  stateInteraction: InteractionReducerState,
  sjmoCode: string,
  ctrlKey: string
) {
  const contextModule = get(stateInteraction, [sjmoCode], {});
  if (contextModule) {
    return Object.keys(contextModule)
      .map(key => {
        const tupleValues = contextModule[key]
          .map((child: InteractionDefinitionState) => {
            if (child.ctrlkey === ctrlKey) {
              // le composant est un detail d'un parent
              // on boucle sur les valeurs de contextualisation
              const keyValues = child.interactionsValues;
              return keyValues;
            }
            return null;
          })
          .filter((item: Record<string, any>) => item) // filtre les valeurs nulles
          .reduce((acc: any, current: any) => {
            return { ...acc, ...current };
          }, {});
        return tupleValues;
      })
      .reduce((acc: any, current: any) => {
        return { ...acc, ...current };
      }, {});
  }
  return {};
}

function getListInteractionValues(
  stateInteraction: InteractionReducerState,
  target: string,
  detail: string,
  ignoredDetails: string[] = []
) {
  const interactionModule = get(
    stateInteraction,
    [target],
    {} as {
      [ctrlkey: string]: InteractionDefinitionState[];
    }
  );
  const masters = Object.keys(interactionModule);

  let interactionValues: InteractionValuesByField[] = [];
  for (let master of masters) {
    if (interactionModule[master]) {
      const interaction = interactionModule[master].find(el => el.ctrlkey === detail);
      if (interaction) {
        // le composant est un detail d'un parent
        // on boucle sur les valeurs de contextualisation
        interactionValues = interactionValues.concat(
          interaction.interactionsValues
            .filter(el => !ignoredDetails.includes(el.fieldDetails))
            .map(el => ({ ...el, master } as InteractionValuesByField))
        );
      }
    }
  }

  return interactionValues;
}

function getModuleInteractions(
  stateInteraction: InteractionReducerState,
  sjmoCode: string,
  ctrlKey: string
) {
  const contextModule = get(stateInteraction, [sjmoCode], {});

  if (contextModule) {
    return Object.keys(contextModule)
      .map(key => {
        const tupleValues = contextModule[key]
          .map((child: InteractionDefinitionState) => {
            if (child.ctrlkey === ctrlKey) {
              // le composant est un detail d'un parent
              // on boucle sur les valeurs de contextualisation
              const keyValues = child.interactionsValues.reduce((acc, current) => {
                acc[current.fieldDetails] = current.valueParent;
                return acc;
              }, {});
              return keyValues;
            }
            return null;
          })
          .filter((item: Record<string, any>) => item) // filtre les valeurs nulles
          .reduce((acc: any, current: any) => {
            return { ...acc, ...current };
          }, {});
        return tupleValues;
      })
      .reduce((acc: any, current: any) => {
        return { ...acc, ...current };
      }, {});
  }
  return {};
}

export function makeGetMaster() {
  return createSelector(
    (state: ReducerState) => state.interactions,
    (_: ReducerState, props: SelectContextByModuleAndCtrlKeyProps) => props,
    (state, { sjmoCode, tableName, ctrlKey }) => {
      const inter1 = findMaster(state, sjmoCode, ctrlKey);
      if (inter1) {
        return inter1;
      }
      let interByTable = null;
      if (tableName) {
        interByTable = findMaster(state, tableName, ctrlKey);
        if (interByTable) {
          return interByTable;
        }
      }

      const inter2 = findMaster(state, "GLOBAL", ctrlKey);

      if (inter2) {
        return inter2;
      }

      return null;
    }
  );
}

export const getMaster = (
  stateInteraction: InteractionReducerState,
  { sjmoCode, tableName, ctrlKey }: SelectContextByModuleAndCtrlKeyProps
): string | null => {
  const inter1 = findMaster(stateInteraction, sjmoCode, ctrlKey);
  if (inter1) {
    return inter1;
  }
  let interByTable = null;
  if (tableName) {
    interByTable = findMaster(stateInteraction, tableName, ctrlKey);
    if (interByTable) {
      return interByTable;
    }
  }

  const inter2 = findMaster(stateInteraction, "GLOBAL", ctrlKey);

  if (inter2) {
    return inter2;
  }

  return null;
};

function findMaster(stateInteraction: InteractionReducerState, sjmoCode: string, ctrlKey: string) {
  const contextModule = get(stateInteraction, [sjmoCode], {});

  if (contextModule) {
    for (let key of Object.keys(contextModule)) {
      for (let child of contextModule[key]) {
        if (child.ctrlkey === ctrlKey) {
          // le composant est un detail d'un parent
          // on renvoit le maitre
          return key;
        }
      }
    }
  }
  return null;
}
