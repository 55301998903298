import React, { FC, SyntheticEvent, useState } from "react";

import { Button, ButtonProps } from "./Button";
import { IconName } from "@fortawesome/pro-solid-svg-icons";
import { Fa } from "composants/Icon";

export const ButtonWithLoader: FC<
  ButtonProps & { onPress?: (e: SyntheticEvent<any>) => Promise<any>; icon?: IconName }
> = ({ onPress, icon, children, ...props }) => {
  const [isPending, setPending] = useState(false);

  const buttonProps = { ...props };

  if (onPress) {
    buttonProps.onClick = e => {
      if (isPending) {
        return;
      }

      setPending(true);
      onPress && onPress(e).then(() => setPending(false));
    };
  }

  if (isPending) {
    buttonProps.disabled = true;
  }

  return (
    <Button {...buttonProps}>
      {icon ? (
        <span className="icon">
          <Fa icon={isPending ? "spinner-third" : icon} spin={isPending} />
        </span>
      ) : isPending ? (
        <Fa icon="spinner-third" spin />
      ) : null}
      {children}
    </Button>
  );
};
