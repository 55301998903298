import React, { Component } from "react";
import { SortableContainer, SortableHandle, SortableElement } from "react-sortable-hoc";
import classNames from "classnames";
import { Trans } from "react-i18next";
import { Fa } from "composants/Icon";

type PanierProps = {
  loading: boolean;
  children: JSX.Element | JSX.Element[];
  disabled: boolean;
  onClear(): void;
  onValidate(): void;
} & {
  innerRef?: any;
};

export const Panier = SortableContainer<PanierProps>(
  class InnerPanier extends Component<PanierProps> {
    render() {
      const { loading, children, disabled, innerRef, onClear, onValidate } = this.props;
      return (
        <div ref={innerRef} className="panel">
          <div className="panel-heading">
            <Trans i18nKey="lov:lov_votre_selection">Votre sélection</Trans>
            <button className="button is-small is-text is-pulled-right" onClick={onClear}>
              <Trans i18nKey="lov:lov_vider">vider</Trans>
            </button>
          </div>
          {children}
          <div className="panel-block">
            <button
              className={classNames("button is-link is-fullwidth", {
                "is-loading": loading
              })}
              onClick={onValidate}
              disabled={disabled}
            >
              <Trans i18nKey="commun_valider">Valider</Trans>
            </button>
          </div>
        </div>
      );
    }
  },
  { withRef: true }
);

interface DragHandleProps {
  title: string;
  className: string;
}
export const DragHandle = SortableHandle<DragHandleProps>(
  ({ title, className }: DragHandleProps) => <div className={className}>{title}</div>
);

interface PanierItemProps {
  title: string;
  removeRow(): void;
}
export const PanierItem = SortableElement<PanierItemProps>(
  ({ title, removeRow }: PanierItemProps) => {
    return (
      <div className="panel-block background-light-grey" style={{ zIndex: 41 }}>
        <div className="columns is-paddingless" style={{ width: "100%" }}>
          <DragHandle className="column is-11 has-text-weight-semibold" title={title} />
          <div className="column">
            <a onClick={removeRow}>
              <span className="icon is-small">
                <Fa icon="times" />
              </span>
            </a>
          </div>
        </div>
      </div>
    );
  },
  { withRef: false }
);
