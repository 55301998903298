import {
  CLOSE_GALAXIE,
  INIT_GALAXIE,
  INIT_GALAXIE_SUCCESS,
  INIT_GALAXIE_ERROR,
  INIT_GALAXIE_LOADING,
  SHOULD_NOT_UPDATE_GALAXY,
  SET_GALAXY_ACTIVE,
  CANCEL_CLOSING_DIRTY_GALAXY,
  FORCE_CLOSE_DIRTY_GALAXY,
  SAVE_CLOSE_GALAXY,
  RESET_GALAXY,
  DELETE_MAIN_ENTITY
} from "constant/galaxy";

/**
 * Permet d'initialiser la galaxie en fonction du code module
 * @param sjmoCode code module
 */
export function initGalaxy(sjmoCode: string, focusFromURL?: string) {
  return {
    type: INIT_GALAXIE,
    payload: { sjmoCode, focusFromURL }
  };
}

export function initGalaxieLoading(sjmoCode: string) {
  return {
    type: INIT_GALAXIE_LOADING,
    payload: sjmoCode
  };
}

export function initGalaxySuccess(sjmoCode: string) {
  return {
    type: INIT_GALAXIE_SUCCESS,
    payload: sjmoCode
  };
}

export function initGalaxyError(sjmoCode: string) {
  return {
    type: INIT_GALAXIE_ERROR,
    payload: sjmoCode
  };
}

/**
 * Permet de lancer l'action de fermeture de galaxie
 * @param toClose code de galaxie à fermé
 * @param active code de galaxie courrament ouverte
 */
export function closeGalaxy(toClose: string, active: string, isDirty: boolean) {
  return {
    type: CLOSE_GALAXIE,
    payload: { toClose, active, isDirty }
  };
}

export function shouldNotUpdateGalaxy(code: string) {
  return {
    type: SHOULD_NOT_UPDATE_GALAXY,
    payload: { sjmoCode: code }
  };
}

export function setActive(sjmoCode: string) {
  return { type: SET_GALAXY_ACTIVE, payload: sjmoCode };
}

export function cancelClosingDirtyGalaxy(sjmoCode: string) {
  return { type: CANCEL_CLOSING_DIRTY_GALAXY, payload: sjmoCode };
}

export function forceCloseDirtyGalaxy(toClose: string, active: string) {
  return { type: FORCE_CLOSE_DIRTY_GALAXY, payload: { toClose, active } };
}

export function saveAndCloseGalaxy(toClose: string, active: string) {
  return { type: SAVE_CLOSE_GALAXY, payload: { toClose, active } };
}

export function resetGalaxyMainEntity(sjmoCode: string, tableName: string, id: string): any {
  // IncludeStyle est nécéssaire pour le findOne
  return {
    type: RESET_GALAXY,
    payload: { sjmoCode, tableName, id, includeStyle: true }
  };
}

export function deleteMainEntity(
  sjmoCode: string,
  tableName: string,
  id: string,
  callback?: () => void
): any {
  return {
    type: DELETE_MAIN_ENTITY,
    payload: {
      sjmoCode,
      tableName,
      id,
      callback
    }
  };
}
