import Action from "../Action";
import { CREATOR_SAVE_SUCESS, CREATOR_SAVE_ERROR, CREATOR_SAVE_CLEAR } from "constant/adminCreator";

export interface AssociationDTO {
  nature: "GLOBAL" | "ROLE" | "USER";
  id: number;
}

export interface AdminCreatorReducerState {
  saveStatus?: "SUCCESS" | "ERROR";
}

const initialState = {};

export default function reducer(
  state: AdminCreatorReducerState = initialState,
  action: Action<any>
): AdminCreatorReducerState {
  switch (action.type) {
    case CREATOR_SAVE_SUCESS:
      return { saveStatus: "SUCCESS" };
    case CREATOR_SAVE_ERROR:
      return { saveStatus: "ERROR" };
    case CREATOR_SAVE_CLEAR:
      return {};

    default:
      return state;
  }
}
