import { call, put, takeLatest, select, fork, delay } from "redux-saga/effects";
import { Task } from "redux-saga";

import { AxiosResponse } from "axios";

import { t } from "utils/i18n";

/*************** ACTIONS *******************/
import Action from "reducers/Action";
import { addEntity, removeEntities, addAllEntities } from "actions/actionEntities";
import { addMessage } from "actions/messages";
/*************** API ***********************/
import { findOne, createMany, savePickListResultApi, deleteMany } from "api/entities";

/*************** TYPE **********************/
import { Pojo } from "types/Galaxy";
import { Message } from "types/Message";

/************** CONSTANTES *****************/
import {
  FIND_ONE,
  FIND_ONE_SUCCESS,
  SAVE_OR_UDPATE_ENTITY,
  SAVE_ALL_GALAXY_ENTITIES,
  SAVE_PICKLIST_RESULT,
  CREATE_ONE_ENTITY,
  DELETE_ONE_ENTITY
} from "constant/entities";

/************** SELECTORS *****************/
import { selectCurrentEntity, selectDirtyGalaxyEntities } from "selectors";

import history from "customHistory";
import { LOADER_TIME_TRIGGER } from "customGlobal";
import { startGalaxyLoader, stopGalaxyLoader } from "actions/loader";
import { RESET_GALAXY } from "constant/galaxy";

/**
 * Permet de récupérer les focus de galaxies
 * @param action action redux
 */
function* findOneEntity(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    id: string;
    includeStyle: boolean;
    labelContext?: string;
    labelDetailsContext?: string;
  }>
) {
  try {
    const response: AxiosResponse<Object> = yield call(findOne, {
      tableName: action.payload.tableName,
      id: action.payload.id,
      includeStyle: action.payload.includeStyle,
      labelContext: action.payload.labelContext,
      labelDetailsContext: action.payload.labelDetailsContext
    });

    yield put({
      type: FIND_ONE_SUCCESS,
      payload: {
        sjmoCode: action.payload.sjmoCode,
        value: response.data,
        tableName: action.payload.tableName,
        cle: action.payload.id
      }
    });
  } catch {
    console.error("error saga findOneEntity");
  }
}

/**
 * Fonction de sauvegarde unitaire d'entité en base
 * @param action
 */
function* saveOrUpdateEntity(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    id: string;
    urlAfterSave?: string;
    callback?: () => void;
  }>
) {
  let task: Task | undefined;
  try {
    // recherche du POJO concerné
    const sjmoCode = action.payload.sjmoCode;
    const tableName = action.payload.tableName;

    task = yield fork(showLoading, sjmoCode);

    const selectProperties = {
      sjmoCode: action.payload.sjmoCode,
      tableName: action.payload.tableName,
      id: action.payload.id
    };

    const ent: Pojo = yield select(selectCurrentEntity, selectProperties);

    const response: AxiosResponse<Pojo[]> = yield call(createMany, tableName, [ent], sjmoCode);

    const savedEntities = response.data;
    if (savedEntities && savedEntities.length > 0) {
      yield put(addEntity(sjmoCode, tableName, savedEntities[0].id, savedEntities[0], true));
    }

    if (action.payload.urlAfterSave) {
      history.push(action.payload.urlAfterSave);
    }

    if (task && task.isRunning()) {
      task.cancel();
    } else if (task) {
      // si le démarrage du loading est inférieur à 1s, on laisse le loader encore jusqu'à
      // 1s pour éviter de faire un flash à l'utilisateur
      const timeToDelay = 500 - (performance.now() - task.result());
      if (timeToDelay > 0) {
        yield delay(timeToDelay);
      }
    }

    const message: Message = {
      code: "",
      message: t("commun_modification_enregistrees"),
      type: "SUCCESS",
      target: "GLOBAL"
    };
    yield put(addMessage(message));
  } catch {
    console.error("erreur lors de la sauvegarde : entites.saga");
  } finally {
    if (task && task.isRunning()) {
      task.cancel();
    }

    yield put(stopGalaxyLoader(action.payload.sjmoCode));

    if (action.payload.callback) {
      yield call(action.payload.callback);
    }
  }
}

function* showLoading(sjmoCode: string) {
  yield delay(LOADER_TIME_TRIGGER());
  yield put(startGalaxyLoader(sjmoCode));
  return performance.now();
}

/**
 * Fonction de sauvegarde unitaire d'entité en base
 * @param action
 */
function* saveAllDirtyGalaxyEntitiesRedux(
  action: Action<{ sjmoCode: string; urlAfterSave?: string; callback?(): void }>
) {
  try {
    yield call(saveAllDirtyGalaxyEntities, action.payload.sjmoCode, action.payload.urlAfterSave);
  } catch (e) {
    console.error("Erreur lors de la sauvegarde", e);
    yield put(stopGalaxyLoader(action.payload.sjmoCode));
  } finally {
    if (action.payload.callback) {
      action.payload.callback();
    }
  }
}

export function* saveAllDirtyGalaxyEntities(sjmoCode: string, urlAfterSave?: string) {
  const task: Task = yield fork(showLoading, sjmoCode);
  const selectProperties = {
    sjmoCode: sjmoCode
  };
  const dirtyEntitiesList = yield select(selectDirtyGalaxyEntities, selectProperties);

  for (let i = 0; i < dirtyEntitiesList.length; i++) {
    const response: AxiosResponse<Pojo[]> = yield call(
      createMany,
      dirtyEntitiesList[i].tableName,
      [dirtyEntitiesList[i]],
      sjmoCode
    );

    const savedEntities = response.data;
    if (savedEntities && savedEntities.length > 0) {
      yield put(
        addEntity(
          sjmoCode,
          dirtyEntitiesList[i].tableName,
          savedEntities[0].id,
          savedEntities[0],
          true
        )
      );
    }

    if (urlAfterSave) {
      history.push(urlAfterSave);
    }
  }

  if (task.isRunning()) {
    task.cancel();
  } else {
    // si le démarrage du loading est inférieur à 1s, on laisse le loader encore jusqu'à
    // 1s pour éviter de faire un flash à l'utilisateur
    const timeToDelay = 500 - (performance.now() - task.result());
    if (timeToDelay > 0) {
      yield delay(timeToDelay);
    }
  }

  yield put(stopGalaxyLoader(sjmoCode));

  const message: Message = {
    code: "",
    message: t("commun_modification_enregistrees"),
    type: "SUCCESS",
    target: "GLOBAL"
  };
  yield put(addMessage(message));
}

export function* watchSavePickListResult(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    chosen: Pojo[];
    oldIdToDelete: string[];
  }>
) {
  const { sjmoCode, tableName, chosen, oldIdToDelete } = action.payload;
  try {
    const response: AxiosResponse<Pojo[]> = yield call(
      savePickListResultApi,
      sjmoCode,
      tableName,
      chosen,
      oldIdToDelete
    );

    yield put(removeEntities(sjmoCode, tableName, oldIdToDelete));
    yield put(addAllEntities(sjmoCode, tableName, response.data, true));
  } catch (e) {
    console.log(e);
  }
}

export function* watchCreateOneEntity(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    pojo: any;
  }>
) {
  try {
    const { sjmoCode, tableName, pojo } = action.payload;
    const response = yield call(createMany, tableName, [pojo], sjmoCode);
    if (response.data && response.data.length > 0) {
      const entity: Pojo = response.data[0];
      yield put(addEntity(sjmoCode, tableName, entity.id, entity));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* watchDeleteOneEntity(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    id: string | number;
  }>
) {
  try {
    const { sjmoCode, tableName, id } = action.payload;
    yield call(deleteMany, tableName, [id], sjmoCode);
    yield put(removeEntities(sjmoCode, tableName, [id]));
  } catch (e) {
    console.log(e);
  }
}

// export d'une liste qui est utilisée dans l'index des saga
export default [
  takeLatest(FIND_ONE, findOneEntity),
  takeLatest(SAVE_OR_UDPATE_ENTITY, saveOrUpdateEntity),
  takeLatest(SAVE_ALL_GALAXY_ENTITIES, saveAllDirtyGalaxyEntitiesRedux),
  takeLatest(RESET_GALAXY, findOneEntity),
  takeLatest(SAVE_PICKLIST_RESULT, watchSavePickListResult),
  takeLatest(CREATE_ONE_ENTITY, watchCreateOneEntity),
  takeLatest(DELETE_ONE_ENTITY, watchDeleteOneEntity)
];
