import React, { FC } from "react";
import classNames from "classnames";
import { PanelState } from "types/Dashboard";

import { Menu } from "composants/DropDown/Menu";
import { Fa } from "composants/Icon";
import { getIconByPanelType } from "enum";

interface PanelListProps {
  className?: string;
  panels: PanelState[];
  title: string;
  onPanelAddedToFocus(sjpaId: string): void;
}

const PanelList: FC<PanelListProps> = ({ className, panels, title, onPanelAddedToFocus }) => {
  return (
    <Menu autoclose>
      <Menu.Button
        className={classNames("button", className)}
        aria-haspopup="true"
        aria-controls="dropdown-panel"
      >
        <span className="icon">
          <Fa icon={["fad", "th"]} />
        </span>
        <span>{title}</span>
        <span className="icon">
          <Fa icon="angle-down" />
        </span>
      </Menu.Button>
      <Menu.Overlay>
        {panels.map(panel => {
          const iconName = getIconByPanelType(panel.panelType);
          return (
            <Menu.Item
              key={panel.panelId}
              as="a"
              onClick={() => {
                onPanelAddedToFocus(panel.panelId);
              }}
              autoClose
            >
              {iconName && (
                <span className="icon is-normal mr-8">
                  <Fa fixedWidth icon={["fas", iconName]} size="lg" />
                </span>
              )}
              <span>{panel.panelLabel}</span>
            </Menu.Item>
          );
        })}
      </Menu.Overlay>
    </Menu>
  );
};

export default PanelList;
