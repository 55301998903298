export const INIT_ADMIN_GALAXY = "INIT_ADMIN_GALAXY";
export const INIT_ADMIN_GALAXY_SUCCESS = "INIT_ADMIN_GALAXY_SUCCESS";

export const SAVE_ALL_ADMIN = "SAVE_ALL_ADMIN";

export const SET_DIRTY = "SET_DIRTY";

export const CREATE_NEW_PANEL = "CREATE_NEW_PANEL";
export const CREATE_NEW_GALAXY_FOCUS = "CREATE_NEW_GALAXY_FOCUS";

export const SET_CURRENT_GALAXY_PROPERTIES_ID = "SET_CURRENT_GALAXY_PROPERTIES_ID";

export const CREATE_NEW_GALAXY = "CREATE_NEW_GALAXY";
export const CREATE_OR_UPDATE_FOCUS_GALAXY = "CREATE_OR_UPDATE_FOCUS_GALAXY";
export const DELETE_FOCUS_GALAXY = "DELETE_FOCUS_GALAXY";

export const DELETE_PANEL = "DELETE_PANEL";
export const GET_ASSOCIATION_FOCUS = "GET_ASSOCIATION_FOCUS";
export const REFRESH_GALAXY_FOCUS_LIST = "REFRESH_GALAXY_FOCUS_LISTE";
