import React, { useRef, FC, useContext, useMemo } from "react";
import { get, set } from "lodash-es";

const DatatableDirtyContext = React.createContext<{
  getDirty(code: string, key?: string): boolean;
  setDirty(code: string, key: string, val: boolean): void;
}>({
  getDirty() {
    return false;
  },
  setDirty() {}
});

export const DirtyProvider: FC = props => {
  const isDirtyRef = useRef<Record<string, Record<string, boolean>>>({});

  function getDirty(code: string, key?: string) {
    if (key) return get(isDirtyRef.current, [code, key], false);

    const dirtyValues = get(isDirtyRef.current, [code], {});

    const keys = Object.keys(dirtyValues);

    for (let key of keys) {
      if (dirtyValues[key] === true) return true;
    }

    return false;
  }

  function setDirty(code: string, key: string, val: boolean) {
    set(isDirtyRef.current, [code, key], val);
  }

  const context = useMemo(() => ({ getDirty, setDirty }), []);

  return (
    <DatatableDirtyContext.Provider value={context}>
      {props.children}
    </DatatableDirtyContext.Provider>
  );
};

export function useDirtyGalaxy() {
  return useContext(DatatableDirtyContext);
}
