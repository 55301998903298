import { AxiosPromise } from "axios";
import { api, apiAdn, getUIContext } from "./common";
import { FocusState } from "types/Focus";
import { ComponentState, FilterBarDefinition } from "types/Component";

export {
  findAll,
  createMany,
  deleteMany,
  preRecord,
  whenValidateItem,
  duplicate
} from "./entities";

/**
 * Récupère les focus d'une table
 * @param tableName nom de la table
 * @param ctrlKey clé de controle
 */
export function getFocus(
  sjmoCode: string,
  tableName: string,
  ctrlKey: string
): AxiosPromise<FocusState[]> {
  const params = getUIContext({ sjmoCode });
  params.append("tableName", tableName);
  params.append("ctrlkey", ctrlKey);

  return apiAdn.get(`/datatable/focus?${params}`);
}

/**
 * Récupère les définitions d'une table
 * @param sjmoCode code module
 * @param focus focus de datatable
 */
export function getColumn(sjmoCode: string, focus: string): AxiosPromise<ComponentState[]> {
  const params = getUIContext({ sjmoCode });
  params.append("sjdtId", focus ? focus.toString() : "");

  return apiAdn.get(`/datatable/columns?${params}`);
}

/**
 * Méthhode qui permet de charger les données de rowExpand
 * @param sjmoCode code module
 * @param focusId id du focus
 * @param contextTableName nom de la table de context
 * @param contextId id de la table de context
 */
export function getRowExpand(
  sjmoCode: string,
  focusId: string,
  contextTableName: string,
  contextId: string
): AxiosPromise<string> {
  const params = getUIContext({ sjmoCode });
  params.append("contextTableName", contextTableName);
  params.append("contextId", contextId.toString());

  return api.get(`/datatable/${focusId}/expand?${params}`);
}

export function saveFocusPersoDatatable(
  datatableId: string,
  tableName: string,
  ctrlKey: string,
  sjmoCode: string,
  columns: ComponentState[],
  breakRows: string[]
): AxiosPromise<void> {
  const params = getUIContext({ sjmoCode });

  if (sessionStorage.getItem("superUser") === "true") {
    return apiAdn.post(`/datatable/focus?${params}`, {
      datatableId,
      tableName,
      ctrlKey,
      sjmoCode,
      columns,
      breakRows
    });
  }

  return apiAdn.post(`/datatable/focus/perso?${params}`, {
    datatableId,
    tableName,
    ctrlKey,
    sjmoCode,
    columns,
    breakRows
  });
}

/**
 * Permet de supprimer un focus perso en passant directement l'id du focus
 * @param datatableId id de table
 * @param sjmoCode code module
 */
export function razFocusPersoDatatable(datatableId: string, sjmoCode: string) {
  const params = getUIContext({ sjmoCode });
  params.append("datatableId", datatableId.toString());
  return apiAdn.delete(`/datatable/focus/perso?${params}`);
}

export function callExportPdf(
  sjmoCode: string,
  tableName: string,
  filter: string,
  columns: ComponentState[]
) {
  const params = getUIContext({ sjmoCode });

  return api.post(`export/pdf?${params}&${filter}`, { tableName, columns });
}

export function callExportXls(
  sjmoCode: string,
  tableName: string,
  filter: string,
  columns: ComponentState[]
): AxiosPromise<string> {
  const params = getUIContext({ sjmoCode });

  return api.post(`export/xls?${params}&${filter}`, { tableName, columns });
}

export function getDatatableFilters(
  sjmoCode: string,
  ctrlKey: string
): AxiosPromise<FilterBarDefinition[]> {
  const params = getUIContext({ sjmoCode });
  params.append("ctrlKey", ctrlKey);
  return apiAdn.get(`/datatable/filters?${params}`);
}
