import { ReducerState } from "reducers";

export const selectPanelIdsBySjmoCode = (
  state: ReducerState,
  props: { sjmoCode: string }
): { panelId: string; treeId: string }[] => {
  const treeState = state.tree[props.sjmoCode];
  if (treeState) {
    return Object.keys(treeState).map(panelId => {
      return { panelId, treeId: treeState[panelId].selectedTree };
    });
  }
  return [];
};
