import React, {
  useState,
  FC,
  useEffect,
  CSSProperties,
  useCallback,
  useRef,
  SyntheticEvent,
  useMemo
} from "react";
import {
  Editor as DraftEditor,
  EditorState,
  RichUtils,
  Modifier,
  DraftEditorCommand,
  getVisibleSelectionRect,
  DraftHandleValue
} from "draft-js";
import classNames from "classnames";
import { GithubPicker as ColorPicker } from "react-color";

import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";

import "./draft.css";
import { ContextMenuEvent } from "composants/common";
import { Menu } from "composants/DropDown/Menu";
import { Fa } from "composants/Icon";
import { IconName } from "@fortawesome/pro-solid-svg-icons";
import { Portal } from "composants/Portal";
import { PluginObject } from "./plugins/pluginTypes";
import autoListPlugin from "./plugins/autoList";
import blockBreakoutPlugin from "./plugins/blockBreakout";
import draftDefaultPlugin from "./plugins/defaultPlugins";

export interface EditorProps {
  toolbar?: "TOP" | "BOTTOM" | "HOVER";
  mode?: "FULL" | "TEXT_OVERRIDE";
  className?: string;
  style?: CSSProperties;
  name?: string;
  value: string;
  disabled?: boolean;
  readonly?: boolean;
  onContextMenu?: ContextMenuEvent;
  plugins?: PluginObject[];
  onFocus?(e: SyntheticEvent): void;
  onBlur?(e: SyntheticEvent): void;
  onValueChange?(name: string | undefined, html: string): void;
}

// export interface TextEditorProps extends ColorProps ⛔, SizeProps ⛔ {
//   wviState?: string; // ⛔
// }

// prettier-ignore
export const COLORS = [
  '#B80000', '#DB3E00', '#FCCB00', '#008B02',
  '#006B76', '#1273DE', '#004DCF', '#5300EB',
  '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5',
  '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB',  
];

const styleMap = {};
const styleMapConvert = {};

COLORS.map((c, i) => {
  // font color
  styleMap[`color-${c.replace("#", "")}`] = { color: c };
  styleMapConvert[`color-${c.replace("#", "")}`] = {
    style: {
      color: c
    }
  };

  // background color
  styleMap[`background-${c.replace("#", "")}`] = { backgroundColor: c };
  styleMapConvert[`background-${c.replace("#", "")}`] = {
    style: {
      "background-color": c
    }
  };
});

const OPTIONS_CONVERT_HTML = {
  inlineStyles: styleMapConvert
};

function rgbToHex(r: string, g: string, b: string) {
  return (
    "#" +
    [r, g, b]
      .map(x => {
        const hex = parseInt(x).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
}

const RGB_REGEX = new RegExp("rgb\\((\\d+),\\s{0,1}(\\d+),\\s{0,1}(\\d+)\\)");
function getHex(rgb: string) {
  const result = RGB_REGEX.exec(rgb);

  if (result) {
    return rgbToHex(result[1], result[2], result[3]);
  }
  return null;
}

const defaultPlugins: PluginObject[] = [autoListPlugin(), blockBreakoutPlugin()];

function convertFromHTML(html: string) {
  return stateFromHTML(html, {
    customInlineFn: (element, inlineCreators) => {
      const style = (element as any).style as CSSStyleDeclaration;
      if (style) {
        if (style.color) {
          const hex = getHex(style.color);
          if (hex) {
            return inlineCreators.Style("color-" + hex.substr(1).toUpperCase());
          }
        }

        if (style.backgroundColor) {
          const hex = getHex(style.backgroundColor);
          if (hex) {
            return inlineCreators.Style("background-" + hex.substr(1).toUpperCase());
          }
        }
      }

      return null;
    }
  });
}

function calculatePositionHoverMenu(
  rect: ClientRect,
  toolbarRect: ClientRect,
  toolbarDomInstance: HTMLDivElement
) {
  let collisions = {
    top: rect.top - toolbarRect.height < 0,
    right: document.documentElement.clientWidth < rect.left + toolbarRect.width,
    bottom: window.innerHeight < rect.top + toolbarRect.height,
    left: rect.left - toolbarRect.width < 0
  };

  const directionRight = collisions.right && !collisions.left;
  const directionUp = collisions.bottom && !collisions.top;

  // The toolbar shouldn't be positioned directly on top of the selected text,
  // but rather with a small offset so the caret doesn't overlap with the text.
  const extraTopOffset = -5;
  const position: CSSProperties = {};

  position.top = directionUp
    ? rect.top - toolbarRect.height + window.scrollY
    : rect.top + window.pageYOffset - toolbarDomInstance.offsetHeight + extraTopOffset;
  position.left = directionRight
    ? rect.right - toolbarRect.width + window.scrollX
    : rect.left +
      toolbarRect.width / 2 +
      (window.pageXOffset - toolbarDomInstance.offsetWidth / 2) +
      rect.width / 2;

  return position;
}

const isValidChange = (html: string, value: string) => {
  return (value == null && html !== "<p><br></p>") || (value != null && html !== value);
};

const Editor: FC<EditorProps> = ({
  plugins: userPlugins = defaultPlugins,
  toolbar = "TOP",
  mode = "TEXT_OVERRIDE",
  className,
  name,
  style,
  disabled,
  readonly,
  value,
  onValueChange: onChangeParent,
  onFocus,
  onBlur,
  onContextMenu
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const draftEditorRef = useRef<DraftEditor | null>(null);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState(() => {
    if (value !== null && value !== undefined) {
      const state = convertFromHTML(value);
      return EditorState.createWithContent(state);
    } else {
      return EditorState.createEmpty();
    }
  });

  const plugins = useMemo(() => {
    return [...userPlugins, draftDefaultPlugin()];
  }, [userPlugins]);

  const [overlayPosition, setOverlayPosition] = useState<CSSProperties | undefined>();

  const oldValue = useRef(value);
  useEffect(() => {
    const htmlState = stateToHTML(state.getCurrentContent());
    if (
      value !== oldValue.current &&
      value !== htmlState &&
      value !== null &&
      value !== undefined
    ) {
      const state = convertFromHTML(value);
      setState(EditorState.createWithContent(state));
    } else if (
      oldValue.current !== null &&
      oldValue !== undefined &&
      htmlState !== null &&
      htmlState !== undefined &&
      (value === undefined || value === null)
    ) {
      setState(EditorState.createEmpty());
    }
    oldValue.current = value;
  }, [state, value]);

  const notifyParentChange = useCallback(
    (state: EditorState) => {
      const html = stateToHTML(state.getCurrentContent(), OPTIONS_CONVERT_HTML);
      if (onChangeParent && isValidChange(html, value)) {
        onChangeParent(name, html);
      }
    },
    [name, onChangeParent, value]
  );

  const onSelectionChanged = useCallback(
    function onSelectionChanged() {
      if (toolbar !== "HOVER") {
        return;
      }
      // need to wait a tick for window.getSelection() to be accurate
      // when focusing editor with already present selection
      setTimeout(() => {
        if (!toolbarRef.current) return;

        // The editor root should be two levels above the node from
        // `getEditorRef`. In case this changes in the future, we
        // attempt to find the node dynamically by traversing upwards.
        if (!draftEditorRef.current) return;

        const selectionRect = getVisibleSelectionRect(window);
        if (!selectionRect) return;

        const rect = selectionRect;

        const toolbarRect = toolbarRef.current.getBoundingClientRect();

        const position = calculatePositionHoverMenu(rect, toolbarRect, toolbarRef.current);
        setOverlayPosition(position);
      }, 300);
    },
    [toolbar]
  );

  const onChange = useCallback(
    function onChange(editorState: EditorState | null) {
      if (editorState) {
        setState(editorState);
        notifyParentChange(editorState);
        onSelectionChanged();
      }
    },
    [notifyParentChange, onSelectionChanged]
  );

  const pluginContext = useMemo(() => {
    return { setEditorState: onChange };
  }, [onChange]);

  let keyCommandPlugins = useMemo(() => {
    let functions: Required<PluginObject>["handleKeyCommand"][] = [];

    for (let plugin of plugins) {
      if (plugin.handleKeyCommand) {
        functions.push(plugin.handleKeyCommand);
      }
    }
    return functions;
  }, [plugins]);

  const handleReturnsPlugins = useMemo(() => {
    let functions: Required<PluginObject>["handleReturn"][] = [];

    for (let plugin of plugins) {
      if (plugin.handleReturn) {
        functions.push(plugin.handleReturn);
      }
    }
    return functions;
  }, [plugins]);

  const keyBindingFnPlugins = useMemo(() => {
    let functions: Required<PluginObject>["keyBindingFn"][] = [];

    for (let plugin of plugins) {
      if (plugin.keyBindingFn) {
        functions.push(plugin.keyBindingFn);
      }
    }
    return functions;
  }, [plugins]);

  function handleKeyCommand(command: DraftEditorCommand, editorState: EditorState) {
    for (let pluginCommand of keyCommandPlugins) {
      let result = pluginCommand(command, editorState, pluginContext);

      if (result === "handled") {
        return "handled";
      }
    }

    return "not-handled";
  }

  function handleReturn(e: React.KeyboardEvent<{}>, editorState: EditorState): DraftHandleValue {
    for (let pluginHandleReturn of handleReturnsPlugins) {
      let result = pluginHandleReturn(e, editorState, pluginContext);
      if (result === "handled") {
        return "handled";
      }
    }
    return "not-handled";
  }

  function keyBindingFn(e: React.KeyboardEvent<{}>): DraftEditorCommand | null {
    for (let pluginKeyBindingFn of keyBindingFnPlugins) {
      let result = pluginKeyBindingFn(e);

      if (result !== null) {
        return result as any;
      }
    }

    return null;
  }

  function changeHighlightColor(type: "background-" | "color-", color: string) {
    const safeColor = color.substr(1).toUpperCase();
    const inlineStyle = type + safeColor;

    const selection = state.getSelection();
    const currentStyles = state.getCurrentInlineStyle();

    const hasStyle = currentStyles.some(val => val === inlineStyle);

    if (hasStyle) {
      const newContent = Modifier.removeInlineStyle(
        state.getCurrentContent(),
        selection,
        inlineStyle
      );
      onChange(EditorState.push(state, newContent, "change-inline-style"));
    } else {
      let nextEditorState = state;
      let nextEditorContent = state.getCurrentContent();
      currentStyles.forEach(val => {
        if (val && val.startsWith(type)) {
          nextEditorContent = Modifier.removeInlineStyle(nextEditorContent, selection, val);
        }
      });
      nextEditorState = EditorState.push(nextEditorState, nextEditorContent, "change-inline-style");
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, inlineStyle);
      onChange(nextEditorState);
    }
  }

  function onSelectionColorBackground(color: string) {
    changeHighlightColor("background-", color);
  }

  function onSelectionColorFont(color: string) {
    changeHighlightColor("color-", color);
  }

  function handleInlineStyles(style: string) {
    const newState = RichUtils.toggleInlineStyle(state, style);
    if (newState) {
      onChange(newState);
    }
  }

  function handleModifyBlock(type: string) {
    const newState = RichUtils.toggleBlockType(state, type);
    if (newState) {
      onChange(newState);
    }
  }

  const selection = state.getSelection();
  const blockType = state
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const currentInlineStyles = state.getCurrentInlineStyle();

  function getCurrentColor(type: string) {
    const currentStyle = currentInlineStyles.find(
      style => (style && style.startsWith(type)) || false
    );

    if (currentStyle) {
      return "#" + currentStyle.substr(type.length);
    }
    return undefined;
  }

  const toolbarComponent =
    toolbar !== "HOVER" ? (
      <>
        <ToolbarButton
          icon="bold"
          isActive={currentInlineStyles.has("BOLD")}
          onClick={() => handleInlineStyles("BOLD")}
        />
        <ToolbarButton
          icon="italic"
          isActive={currentInlineStyles.has("ITALIC")}
          onClick={() => handleInlineStyles("ITALIC")}
        />
        <ToolbarButton
          icon="underline"
          isActive={currentInlineStyles.has("UNDERLINE")}
          onClick={() => handleInlineStyles("UNDERLINE")}
        />
        {mode === "FULL" ? (
          <>
            <ToolbarHeading changeBlockType={handleModifyBlock} />
            <ToolbarButton
              icon="list"
              isActive={blockType === "unordered-list-item"}
              onClick={() => handleModifyBlock("unordered-list-item")}
            />
            <ToolbarButton
              icon="list-ol"
              isActive={blockType === "ordered-list-item"}
              onClick={() => handleModifyBlock("ordered-list-item")}
            />
            <EditorColorPicker
              icon="highlighter"
              color={getCurrentColor("background-")}
              colors={COLORS}
              onSelection={onSelectionColorBackground}
            />
            <EditorColorPicker
              icon="font"
              color={getCurrentColor("color-")}
              colors={COLORS}
              onSelection={onSelectionColorFont}
            />
          </>
        ) : null}
      </>
    ) : null;

  return (
    <>
      {toolbar === "TOP" && (
        <div className="mt-7" style={{ borderTop: "1px solid #dedede", padding: "0.4em" }}>
          {toolbarComponent}
        </div>
      )}
      <div
        ref={wrapperRef}
        className={classNames("editor-draft", className)}
        style={style}
        onContextMenu={onContextMenu}
        onClick={() => {
          draftEditorRef.current && draftEditorRef.current.focus();
        }}
      >
        <div className="content" style={{ width: "inherit", height: "inherit" }}>
          <DraftEditor
            ref={draftEditorRef}
            editorState={state}
            handleKeyCommand={handleKeyCommand}
            handleReturn={handleReturn}
            keyBindingFn={keyBindingFn}
            readOnly={readonly || disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            customStyleMap={styleMap}
          />
        </div>
      </div>
      {toolbar === "BOTTOM" && (
        <div className="mb-7" style={{ borderBottom: "1px solid #dedede", padding: "0.4em" }}>
          {toolbarComponent}
        </div>
      )}
      {toolbar === "HOVER" && (
        <Portal>
          <HoverMenu ref={toolbarRef} position={overlayPosition} editorState={state}>
            <ToolbarButton
              className="is-text"
              icon="bold"
              isActive={currentInlineStyles.has("BOLD")}
              onClick={() => handleInlineStyles("BOLD")}
            />
            <ToolbarButton
              className="is-text"
              icon="italic"
              isActive={currentInlineStyles.has("ITALIC")}
              onClick={() => handleInlineStyles("ITALIC")}
            />
            <ToolbarButton
              className="is-text"
              icon="underline"
              isActive={currentInlineStyles.has("UNDERLINE")}
              onClick={() => handleInlineStyles("UNDERLINE")}
            />
          </HoverMenu>
        </Portal>
      )}
    </>
  );
};

const ToolbarButton: FC<{
  className?: string;
  icon: IconName;
  isActive: boolean;
  onClick(): void;
}> = props => {
  return (
    <button
      className={classNames("button mr-8", props.isActive && "is-link", props.className)}
      onClick={props.onClick}
    >
      <span className={classNames("icon", !props.isActive && "has-text-link")}>
        <Fa icon={["fad", props.icon]} />
      </span>
    </button>
  );
};

const ToolbarHeading: FC<{ changeBlockType(type: string): void }> = ({ changeBlockType }) => {
  return (
    <Menu autoclose>
      <Menu.Button className="button mr-8">
        <span className="icon has-text-link">
          <Fa icon={["fad", "heading"]} />
        </span>
      </Menu.Button>
      <Menu.Overlay>
        <Menu.WithContext>
          {({ toggleIsActive }) => {
            function onSelectBlock(type: string) {
              changeBlockType(type);
              toggleIsActive();
            }
            return (
              <>
                <Menu.Item
                  as="a"
                  className="cursor-pointer"
                  onClick={() => onSelectBlock("header-one")}
                >
                  <span className="icon has-text-link">
                    <Fa icon={["fad", "h1"]} />
                  </span>
                </Menu.Item>
                <Menu.Item as="a" onClick={() => onSelectBlock("header-two")}>
                  <span className="icon has-text-link">
                    <Fa icon={["fad", "h2"]} />
                  </span>
                </Menu.Item>
                <Menu.Item as="a" onClick={() => onSelectBlock("header-three")}>
                  <span className="icon has-text-link">
                    <Fa icon={["fad", "h3"]} />
                  </span>
                </Menu.Item>
                <Menu.Item as="a" onClick={() => onSelectBlock("header-four")}>
                  <span className="icon has-text-link">
                    <Fa icon={["fad", "h4"]} />
                  </span>
                </Menu.Item>
              </>
            );
          }}
        </Menu.WithContext>
      </Menu.Overlay>
    </Menu>
  );
};

const EditorColorPicker: FC<{
  className?: string;
  icon: IconName;
  color: string | undefined;
  colors: string[];
  onSelection(color: string): void;
}> = ({ className, icon, color, colors, onSelection }) => {
  return (
    <Menu autoclose>
      <Menu.Button className={classNames("button mr-8", className)}>
        <span className="icon">
          <Fa icon={["fad", icon]} color={color || "#7D3A96"} />
        </span>
      </Menu.Button>
      <Menu.Overlay>
        <Menu.WithContext>
          {({ toggleIsActive }) => (
            <ColorPicker
              color={color}
              colors={colors}
              triangle="top-left"
              onChangeComplete={color => {
                onSelection(color.hex);
                toggleIsActive();
              }}
            />
          )}
        </Menu.WithContext>
      </Menu.Overlay>
    </Menu>
  );
};

type HoverMenuProps = {
  editorState: EditorState;
  position?: CSSProperties;
  children?: React.ReactNode;
};

const HoverMenu = React.forwardRef<HTMLDivElement, HoverMenuProps>(
  ({ editorState, position, children }, ref) => {
    const [isVisible, setIsVisible] = useState<boolean>();
    const selection = editorState.getSelection();
    const isCollapsed = selection.isCollapsed();
    const hasFocus = selection.getHasFocus();
    useEffect(() => {
      const isVisibleSelection = !isCollapsed && hasFocus;

      let timeout: NodeJS.Timeout | undefined;
      if (isVisible !== isVisibleSelection) {
        timeout = setTimeout(() => {
          setIsVisible(isVisibleSelection);
        }, 100);
      }

      return () => {
        timeout && clearTimeout(timeout);
      };
    }, [isCollapsed, hasFocus, isVisible]);

    function getStyleHover(): CSSProperties {
      const style: CSSProperties = { ...position };

      if (isVisible) {
        style.opacity = 1;
        style.transform = "translate(-50%) scale(1)";
        style.transition = "transform 0.15s cubic-bezier(.3,1.2,.2,1)";
      } else {
        style.opacity = 0;
        style.transform = "translate(-50%) scale(0)";
      }

      return style;
    }

    return (
      // <Portal>
      <div ref={ref} className="editor-draft-toolbar-hover" style={getStyleHover()}>
        {children}
      </div>
      // </Portal>
    );
  }
);

export default Editor;
