import React, { useEffect } from "react";

export function usePreventWheelOnFocus(ref: React.MutableRefObject<any>) {
  useEffect(() => {
    let input = ref.current;

    function onWheel(e: WheelEvent) {
      e.preventDefault();
    }

    function onFocus(e: FocusEvent) {
      input && input.addEventListener("wheel", onWheel);
    }

    function onBlur(e: FocusEvent) {
      input && input.removeEventListener("wheel", onWheel);
    }

    if (input) {
      input.addEventListener("focus", onFocus);
      input.addEventListener("blur", onBlur);
    }

    return () => {
      if (input) {
        input.removeEventListener("wheel", onWheel);
        input.removeEventListener("focus", onFocus);
        input.removeEventListener("blur", onBlur);
      }
    };
  }, [ref]);
}
