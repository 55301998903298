import React, { Component, CSSProperties } from "react";
import AutoComplete from "../autocomplete/AutoComplete";
import { CommentaireType, Langue, Commentaire } from "./TabCommentaire";
import { t } from "utils/i18n";
import TextEditor from "../RichTextEditor/TextEditor";
import { GSBuilder } from "utils/query.utils";

interface EditeurCommentaireProps {
  selectedComment?: Commentaire;
  sjmoCode: string;
  currentText: string;
  currentType: string;
  currentLang: string;
  forceUpdate?: boolean;
  style?: CSSProperties;
  onChangeText(text?: string): void;
  onChangeType(type?: string): void;
  onChangeLang(lang?: string): void;
  save(): void;
  editing(): void;
}

/**
 * Composant qui gère l'édition d'un commentaire
 *
 * @class EditeurCommentaire
 * @extends {Component<EditeurCommentaireProps>}
 */
class EditeurCommentaire extends Component<EditeurCommentaireProps> {
  state: any = {
    copy: this.props.currentText
  };
  render() {
    return (
      <>
        <TextEditor
          className="mb-6"
          value={this.props.currentText}
          onValueChange={(name: string, html: string | undefined) => {
            this.props.onChangeText(html);
          }}
          style={this.props.style}
        />

        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <AutoComplete
                id="types"
                value={this.props.currentType}
                onItemChange={(pojo: CommentaireType | null) => {
                  this.props.onChangeType(pojo != null ? pojo.id : undefined);
                }}
                sjmoCode={this.props.sjmoCode}
                joinTableName="commentaireType"
                joinListFields="cotyLibelle"
                additionalClause={GSBuilder.Comparison("cotyLibelle", "OPER_LIKE_ANYWHERE", null)}
              />
            </div>
            <div className="level-item">
              <AutoComplete
                id="langs"
                value={this.props.currentLang}
                onItemChange={(pojo: Langue | null) => {
                  this.props.onChangeLang(pojo != null ? pojo.id : undefined);
                }}
                sjmoCode={this.props.sjmoCode}
                joinTableName="langue"
                joinListFields="langLibelle"
                additionalClause={GSBuilder.Comparison("langLibelle", "OPER_LIKE_ANYWHERE", null)}
              />
            </div>
          </div>
        </nav>
        <nav className="level">
          <div className="level-left">
            {this.props.children}
            <div className="level-item">
              <button className="button is-success" onClick={() => this.props.save()}>
                {t("commun_valider")}
              </button>
            </div>
            <div className="level-item">
              <button className="button" onClick={() => this.props.editing()}>
                {t("commun_annuler")}
              </button>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default EditeurCommentaire;
