import produce from "immer";

import Action from "reducers/Action";

import {
  SET_GALAXY_ACTIVE,
  CLOSE_GALAXIE_SUCCESS,
  CLOSING_DIRTY_GALAXY,
  CANCEL_CLOSING_DIRTY_GALAXY
} from "constant/galaxy";
import { UPDATE_CONTEXT, CLEAR_CONTEXT } from "constant/satellite";
import { GalaxyActive } from "types/Galaxy";

/**
 * type du state du reducer qui stocke les informations de la galaxie
 */
export type GalaxyActiveState = Record<string, GalaxyActive>;

/**
 * Reducer de gestions des galaxies ouvertes / fermées
 *
 * @param {GalaxyInformationState} state state redux
 * @param {Action<GalaxyInformation>} action action redux
 * @returns {GalaxyInformationState} nouveau state
 */
export default function reducer(
  state: GalaxyActiveState = {},
  action: Action<string> | Action<{ tableName: string; id: string; sjmoCode: string }>
): GalaxyActiveState {
  switch (action.type) {
    case SET_GALAXY_ACTIVE:
      // voir si on peut pas faire mieux ici
      if (action.payload === "HOME") {
        return state;
      }

      return produce(state, draft => {
        if (!draft[action.payload as string]) {
          draft[action.payload as string] = { active: true };
        } else {
          draft[action.payload as string].active = true;
        }
      });

    case CLOSE_GALAXIE_SUCCESS:
      return produce(state, draft => {
        draft[action.payload as string].active = false;
        draft[action.payload as string].closingDirty = undefined;
      });

    case UPDATE_CONTEXT:
      return produce(state, draft => {
        if (
          typeof action.payload !== "string" &&
          action.payload.sjmoCode &&
          draft[action.payload.sjmoCode]
        ) {
          draft[action.payload.sjmoCode].lastId = action.payload.id;
        }
      });

    case CLEAR_CONTEXT:
      return produce(state, draft => {
        if (
          typeof action.payload !== "string" &&
          action.payload &&
          action.payload.sjmoCode &&
          draft[action.payload.sjmoCode]
        ) {
          draft[action.payload.sjmoCode].lastId = undefined;
        }
      });

    case CLOSING_DIRTY_GALAXY:
      return produce(state, draft => {
        draft[action.payload as string].closingDirty = true;
      });
    case CANCEL_CLOSING_DIRTY_GALAXY:
      return produce(state, draft => {
        draft[action.payload as string].closingDirty = undefined;
      });
    default:
      return state;
  }
}
