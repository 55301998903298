import React, { FC } from "react";
import { PagedResource } from "types/Search";
import { Loader } from "@axin-org/comet";
import { tw } from "twind";
import { Trans } from "react-i18next";
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "react-query";
import { Pojo } from "types/Galaxy";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import Scrollbars from "react-custom-scrollbars";

/**
 * WARNING : pour que la liste infinie fonctionne sur toute les tailles d'écrans.
 * Il faut que la balise "loadMoreButtonRef" qui déclenche l'action, ne soit pas le dernier élément dans ce composant.
 * Il faut qu'il y est un composant en dessous de taille non-null pour être sûr qu'il pparaisse.
 * D'où la création de la <div style={{height:1}}/>
 *
 * @param props
 */
const InfiniteList: FC<{
  data: PagedResource<Pojo>[] | undefined;
  isFetchingNextPage: boolean | undefined;
  hasNextPage: boolean | undefined;
  loadMoreButtonRef: React.MutableRefObject<HTMLDivElement | null>;
  listHeight: number;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<PagedResource<Pojo>, unknown>>;
  renderCell(entity: Pojo): React.ReactNode;
}> = props => {
  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    loadMoreButtonRef,
    fetchNextPage,
    renderCell
  } = props;

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage
  });

  return (
    <div style={{ height: props.listHeight, overflow: "auto" }}>
      {data &&
        data.map((page, i) => (
          <React.Fragment key={i}>{page?.data.map(renderCell)}</React.Fragment>
        ))}
      {data && data[0].data.length === 0 && (
        <div className={tw`flex w-full justify-center items-center pt-3`}>
          <div>
            <Trans i18nKey="commun_aucune_donnees">Aucune données trouvées</Trans>
          </div>
        </div>
      )}
      <div ref={loadMoreButtonRef} style={{ height: "1px" }} />
      {isFetchingNextPage && (
        <div className={tw`flex w-full justify-center items-center pt-3`}>
          <Loader />
        </div>
      )}
      <div style={{ height: 1 }} />
    </div>
  );
};

export default InfiniteList;
