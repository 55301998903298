import {
  GroupByFunctionParameter,
  AggregateProperties,
  FilterProperties,
  SortProperties,
  ComputedColumnProperties
} from "./InteractiveReport";
import { HighlightProperties } from "composants/datatable/dialog/HighlightDialog";
import { RSQLOperator, GS } from "utils/query.utils";

export enum ComponentReadOnly {
  DEFAULT = "DEFAULT",
  READ_ONLY = "READ_ONLY",
  READ_ONLY_INSERT = "READ_ONLY_INSERT",
  READ_ONLY_UPDATE = "READ_ONLY_UPDATE"
}

/**
 * Interface récupéré par service
 * définissant un composant (I,CH,SD,GS,CA)
 */
export interface ComponentState {
  id: string;
  column: string;
  label: string;
  tooltip?: string;
  typeCompo: string;
  resourceKey?: string;
  labelSize: number;
  contentSize: number;
  position: number;
  readOnly: ComponentReadOnly;
  disabled: ComponentReadOnly;
  mandatory: boolean;
  mask: string;
  compoVisible: boolean;
  authorizeVisibility?: boolean;
  // gensearch
  joinTableName: string;
  joinListFields: string;
  joinDisplayedFields: string;
  joinListFiltre: string;

  // clause additionnelle de recherche (principalement pour les gensearch)
  additionalClause: GS;
  sortClause?: GensearchSortClause[];
  sysDomaineChoices?: Item[];

  // valeur par défaut
  defaultValue?: any;

  wvi: boolean;
  options?: Record<string, any>;
  isNumber?: boolean;
  wviMessage?: string;
  // lov
  hasLov?: boolean;
  isInsertable?: boolean;
  oel?: string;
  oelContenuSource?: "D" | "F";
}

export interface Item {
  value: any;
  label: string;
}

export interface ItemGrp {
  label: string;
  items: Item[];
}

export interface GensearchClause {
  field: string;
  operator: RSQLOperator;
  value: any;
  bracket?: "OPEN" | "CLOSE";
  conjunctionWithNext?: "AND" | "OR";
}

export interface GensearchSortClause {
  sortField: string;
  sortOrder: "ASCENDING" | "DESCENDING" | "UNSORTED";
}

export interface GenericListDefinition {
  columns: GenericListComponent[];
  filters: FilterBarDefinition[];
}

export interface GenericListComponent extends ComponentState {
  originalField: string;
  width: number;
  isId: boolean;
}

/**
 * Définition de la LOV.
 */
export interface LovDefinition {
  id: number;
  mapping: LovMapping[];
  filters: FilterBarDefinition[];
}

/**
 * Mapping des valeurs
 */
export interface LovMapping {
  columnTarget: string;
  columnOrig: string;
  wvi: boolean;
}

/**
 * Paramètre pour l'ouverture de la LOV.
 */
export interface LovParam {
  column: string;
  tableName: string;
  label: string;
  syjLovId: string | null;
}

export interface KanbanLane {
  syjKanbanId: number;
  code: string;
  position: number;
  label: string;
  jsonMapping: Record<string, any>;
  template: string;
  tableName: string;
  filter: string;
  sort: string;
  view: boolean;
  job: boolean;
  advancedProcessMove: boolean;
  advancedProcessReorder: boolean;
  advancedProcessLane: boolean;
  processIdMove: string;
  processIdReorder: string;
  processIdLane: string;
  filters: FilterBarDefinition[];
  defaultDtFilter: string;
}

export interface KanbanDetail {
  code: string;
  kanbaLaneCode: string;
  jsonMapping: Record<string, any>;
  template: string;
}

export interface KanbanDefinition {
  kanban: string;
  canMove: boolean;
  canReorder: boolean;
  canValidateGlobally: boolean;
  token: string;
  lanes: KanbanLane[];
  details: KanbanDetail[];
}

export interface FilterBarDefinition {
  filterBarId: string;
  label: string;
  privilegie: boolean;
  startDate?: Date;
  endDate?: Date;
}

export interface Line {
  infos: string[];
  origin?: any;
}

export interface InteractiveReportConfiguration {
  typeFocus: "user" | "global";
  id?: number | string;
  label: string;
  includes?: string[];
  includesSize?: Record<string, number>;
  groupBy: {
    groupBy: string[];
    functions: GroupByFunctionParameter[];
  };
  aggregates: AggregateProperties[];
  filters: FilterProperties[];
  sortBy: SortProperties[];
  breakRows: string[];
  computedColumns: ComputedColumnProperties[];
  highlights: HighlightProperties[];
  isGlobal?: boolean;
  isUserFocus?: boolean;
}

export interface InteractiveReport {
  code: string;
  tableName: string;
  columns: ComponentState[];
  configurations: InteractiveReportConfiguration[];
}
