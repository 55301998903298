import { CSSProperties, useEffect, useState, useLayoutEffect } from "react";
import throttle from "lodash-es/throttle";

function useWindowSize() {
  const [sizes, setSizes] = useState(() => ({
    width: window.innerWidth,
    height: window.innerHeight
  }));

  useEffect(() => {
    const resize = throttle(() => {
      setSizes({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }, 50);

    window.addEventListener("resize", resize);
    return function cleanup() {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return sizes;
}

export function useOverlayPosition(
  buttonRef: React.RefObject<HTMLElement>,
  menuContentRef: React.RefObject<HTMLElement>,
  isActive: boolean,
  isInsideModal: boolean
) {
  const [stylesMenu, setStylesMenu] = useState<CSSProperties>({});

  const sizes = useWindowSize();

  function calculatePositions() {
    if (buttonRef.current && menuContentRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const menuRect = menuContentRef.current.getBoundingClientRect();

      const styles = getStylesPortal(buttonRect, menuRect, isInsideModal);
      setStylesMenu(styles);
    }
  }

  useLayoutEffect(() => {
    if (isActive) {
      calculatePositions();
    } else {
      setStylesMenu({});
    }
  }, [buttonRef, menuContentRef, isActive]);

  useLayoutEffect(() => {
    calculatePositions();
  }, [sizes.width, sizes.height]);

  return stylesMenu;
}

/**
 * Permet de positionner correctement le menu dans la page en fonction de la position du bouton avec
 * gestion automatique de la collision si jamais le menu sort de la page.
 *
 * voir @reach-ui/menu-button pour plus de détail sur l'implémentation d'un menu plus correct avec les
 * demandes de W3C pour l'accessibilité.
 *
 * @param buttonRect bounding client rect du bouton
 * @param menuRect bounding client rect du menu
 */
export function getStylesPortal(
  buttonRect: ClientRect,
  menuRect: ClientRect,
  isInsideModal: boolean
) {
  let haventMeasuredButtonYet = !buttonRect;
  if (haventMeasuredButtonYet) {
    return { opacity: 0 };
  }

  let haventMeasuredMenuYet = !menuRect;

  let styles: CSSProperties = {
    left: `${buttonRect.left + window.scrollX}px`,
    top: `${buttonRect.top + buttonRect.height + window.scrollY}px`
  };

  if (haventMeasuredMenuYet) {
    return {
      ...styles,
      opacity: 0
    };
  }

  if (buttonRect.width < 500) {
    styles.minWidth = buttonRect.width;
  }

  let collisions = {
    top: buttonRect.top - menuRect.height < 0,
    right: document.documentElement.clientWidth < buttonRect.left + menuRect.width,
    bottom: window.innerHeight < buttonRect.top + menuRect.height,
    left: buttonRect.left - menuRect.width < 0
  };

  const directionRight = collisions.right && !collisions.left;
  const directionUp = collisions.bottom && !collisions.top;

  return {
    ...styles,
    left: directionRight
      ? `${buttonRect.right - menuRect.width + (isInsideModal ? 0 : window.scrollX)}px`
      : `${buttonRect.left + (isInsideModal ? 0 : window.scrollX)}px`,
    top: directionUp
      ? `${buttonRect.top - menuRect.height + (isInsideModal ? 0 : window.scrollY)}px`
      : `${buttonRect.top + buttonRect.height + (isInsideModal ? 0 : window.scrollY)}px`
  };
}
