import React, { FC } from "react";
import { useGetComponentState } from "composants/GetComponent";
import { SchedulerDefinition } from "./Scheduler";
import { Task } from "./FullCalendarContainer";
import AutoCompleteAndLabel from "composants/autocomplete/AutoCompleteAndLabel";
import { Button } from "composants/button";
import { Fa } from "composants/Icon";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import ProcessusMenu from "composants/processus/ProcessusMenu";
import { Trans, useTranslation } from "react-i18next";
import { SchedulerDndList } from "./SchedulerDndList";

interface SchedulerMenuTabsGlobalProps {
  sjmoCode: string;
  definition?: SchedulerDefinition;
  mainEntityId: string;
  isMenuOpen: boolean;
  pins: Task[];
  isVisible: boolean;
  setMainEntityId(id: string): void;
  setMenuOpen(open: boolean): void;
  refreshAfterProcess(): void;
  cancelPInTask(task: Task): void;
}

function print() {
  const node = document.getElementsByClassName("fc-view fc-resourceTimeline-view fc-timeline")[0];
  const nw = window.open() as Window;
  nw.document
    .write(`<link href='https://unpkg.com/@fullcalendar/core@4.3.1/main.min.css' rel='stylesheet' />
    <link href='https://unpkg.com/@fullcalendar/daygrid@4.3.0/main.min.css' rel='stylesheet' />
    <link href='https://unpkg.com/@fullcalendar/timeline@4.3.0/main.min.css' rel='stylesheet' />
    <link href='https://unpkg.com/@fullcalendar/resource-timeline@4.3.0/main.min.css' rel='stylesheet' />
    <link href='https://unpkg.com/@fullcalendar/timegrid@4.3.0/main.min.css' rel='stylesheet' /><style type="text/css" media="print"> @page { size: landscape; } </style>
    ${node.innerHTML}`);
  nw.focus();
  setTimeout(() => {
    nw.print();
    nw.close();
  }, 0);
}

export const SchedulerMenuTabsGlobal: FC<SchedulerMenuTabsGlobalProps> = props => {
  const { t } = useTranslation();
  // On ne charge la MGS que dans le cas ou la définition n'est pas en mode mainEntity = user connecté
  const mgsState = useGetComponentState(
    props.sjmoCode,
    props.definition && !props.definition.userIsMainEntity
      ? props.definition.mainEntityTable
      : null,
    props.definition && !props.definition.userIsMainEntity
      ? [props.definition.mgsColumnName]
      : undefined
  );

  return (
    <div style={{ display: props.isVisible ? "block" : "none" }}>
      {mgsState.state === "OK" && (
        <AutoCompleteAndLabel
          label={mgsState.computedColumnsState.columns[0].label}
          id={props.definition ? props.definition.mgsColumnName : "schedulerMainGenSeach"}
          sjmoCode={props.sjmoCode}
          value={props.mainEntityId}
          onItemChange={pojo => props.setMainEntityId(pojo ? (pojo.id as string) : "")}
          joinTableName={mgsState.computedColumnsState.columns[0].joinTableName}
          joinListFields={mgsState.computedColumnsState.columns[0].joinListFields}
          additionalClause={mgsState.computedColumnsState.columns[0].additionalClause}
        />
      )}
      <nav className="level mb-8">
        <div className="level-left">
          <span className="level-item">
            <Button
              onClick={() => props.setMenuOpen(!props.isMenuOpen)}
              title={t("commun_fermer_menu")}
            >
              <span className="icon">
                <Fa icon="bars" fixedWidth />
              </span>
            </Button>
          </span>
          <span className="level-item">
            <Button onClick={() => print()} title={t("commun_imprimer")}>
              <span className="icon">
                <Fa icon="print" fixedWidth />
              </span>
            </Button>
          </span>
          <span className="level-item">
            <ProcessusProvider
              sjmoCode={props.sjmoCode}
              tableName={props.definition ? props.definition.mainEntityTable : ""}
              selected={props.mainEntityId}
              onAfterSaveProcess={() => {
                props.refreshAfterProcess();
              }}
            >
              <ProcessusMenu isAnchor isRight={true} icon="cogs" />
            </ProcessusProvider>
          </span>
        </div>
      </nav>
      <div>
        <Trans i18nKey="commun_taches_misent_de_coté">Tâches misent de coté</Trans>
      </div>
      {props.pins.length > 0 && (
        <SchedulerDndList
          events={props.pins}
          disabled={false}
          cancelPinTask={props.cancelPInTask}
        />
      )}
    </div>
  );
};
