import { createSelector } from "reselect";
import { ReducerState } from "reducers";
import {
  ReducerDatatableState,
  DatatableState,
  initialStateDatatable
} from "reducers/modules/DatatableReducer";
import { Pojo } from "types/Galaxy";
import { get } from "lodash-es";
import { DATATABLE_PRE_RECORD_ENTITY_CACHE } from "customGlobal";

type SelectPropsForDatatable = { sjmoCode: string; ctrlKey: string };

export const selectKeysBySjmoCode = (
  state: ReducerState,
  props: { sjmoCode: string }
): string[] => {
  const dtState = state.datatable[props.sjmoCode];
  if (dtState) {
    return Object.keys(dtState);
  }
  return [];
};

export const selectCurrentDefinition = (
  state: ReducerState,
  props: SelectPropsForDatatable
): DatatableState => {
  return state.datatable[props.sjmoCode] && state.datatable[props.sjmoCode][props.ctrlKey]
    ? state.datatable[props.sjmoCode][props.ctrlKey]
    : initialStateDatatable;
};

export const isLoadedDefinition = (
  state: ReducerState,
  props: SelectPropsForDatatable
): boolean => {
  return state.datatable[props.sjmoCode] && state.datatable[props.sjmoCode][props.ctrlKey]
    ? true
    : false;
};

export const selectCurrentOelColumns = createSelector(
  selectCurrentDefinition,
  currentDefinition => {
    if (currentDefinition.columns) {
      return currentDefinition.columns
        .filter(column => column.typeCompo === "OEL")
        .map(column => column.column);
    } else {
      return [];
    }
  }
);

export const selectCurrentFocus = createSelector(
  selectCurrentDefinition,
  currentDefinition => {
    return currentDefinition.focus.find(f => f.focusId === currentDefinition.selectedFocus) || null;
  }
);

export const selectDatatableDefaultSort = createSelector(
  selectCurrentFocus,
  currentFocus => {
    return get(currentFocus, "sort", null);
  }
);

export const selectIsDatatableInit = (state: ReducerState, props: SelectPropsForDatatable) =>
  state.datatable[props.sjmoCode] && state.datatable[props.sjmoCode][props.ctrlKey] ? true : false;

export const selectDatatableFilter = createSelector(
  selectCurrentDefinition,
  current => current.filter
);

export const selectDatatableFilterBar = createSelector(
  selectCurrentDefinition,
  current => current.filterBar
);

export const selectDatatableSize = createSelector(
  selectCurrentDefinition,
  current => current.size
);

type SelectEntitiesFromTable = { sjmoCode: string; tableName: string };

export const selectEntitiesFromTable = (
  state: ReducerState,
  { sjmoCode, tableName }: SelectEntitiesFromTable
): Record<string, Pojo> =>
  state.entities[sjmoCode] ? state.entities[sjmoCode][tableName] || {} : {};

export const selectNewEntitiesStart = createSelector(
  selectCurrentDefinition,
  definition => definition.newEntitiesStart
);

export const selectNewEntitiesEnd = createSelector(
  selectCurrentDefinition,
  def => def.newEntitiesLast
);

export const selectDatatableEntities = createSelector(
  selectCurrentDefinition,
  selectNewEntitiesStart,
  selectNewEntitiesEnd,
  selectEntitiesFromTable,
  (currentDefinition, newEntitiesStart, newEntitiesEnd, entitiesFromTable) => {
    let map = {};
    let indexNewEntities = 0;
    for (; indexNewEntities < newEntitiesStart.length; indexNewEntities++) {
      map[indexNewEntities] = newEntitiesStart[indexNewEntities];
    }

    const indexes = Object.keys(currentDefinition.entities);

    let validMapIndex = -1;
    for (let index of indexes) {
      validMapIndex = indexNewEntities + parseInt(index, 10);
      const entities = currentDefinition.entities;
      if (entities && entities[index] === "LOADING_POJO") {
        map[validMapIndex] = entities[index];
      } else if (entitiesFromTable && entitiesFromTable[entities[index]]) {
        map[validMapIndex] = entitiesFromTable[entities[index]];
      }
    }

    // gestion des entités ajoutés à la fin
    let indexEnd = Object.keys(map).length;

    for (let index = 0; index < newEntitiesEnd.length; index++) {
      map[indexEnd + index] = newEntitiesEnd[index];
    }

    return map;
  }
);

export const selectIsEntityModified = createSelector(
  selectDatatableEntities,
  entities => {
    const keys = Object.keys(entities);

    for (let key of keys) {
      // si l'entité est présente et modifié, alors on est en dirty
      if (entities[key] && entities[key] !== "LOADING_POJO" && entities[key].modifie) {
        return true;
      }
    }

    // pas de dirty, on a passé toutes les entités sans avoir de modifie
    return false;
  }
);

export const selectDatatableExistingEntities = createSelector(
  selectCurrentDefinition,
  selectEntitiesFromTable,
  (currentDefinition, entitiesFromTable) => {
    let map = {};
    const indexes = Object.keys(currentDefinition.entities);

    let validMapIndex = -1;
    for (let index of indexes) {
      validMapIndex = parseInt(index, 10);
      const entities = currentDefinition.entities;
      if (entities && entities[index] === "LOADING_POJO") {
        map[validMapIndex] = entities[index];
      } else if (entitiesFromTable && entitiesFromTable[entities[index]]) {
        map[validMapIndex] = entitiesFromTable[entities[index]];
      }
    }

    return map;
  }
);

export const selectDatatablePreRecordEntity = (
  state: ReducerState,
  props: { sjmoCode: string; ctrlKey: string }
) => {
  return (
    state.datatable[props.sjmoCode] &&
    state.datatable[props.sjmoCode][props.ctrlKey] &&
    state.datatable[props.sjmoCode][props.ctrlKey].preRecordEntity
  );
};

export const selectDatatablePreRecordEntityLastFetch = (
  state: ReducerState,
  props: { sjmoCode: string; ctrlKey: string }
) => {
  return (
    state.datatable[props.sjmoCode] &&
    state.datatable[props.sjmoCode][props.ctrlKey] &&
    state.datatable[props.sjmoCode][props.ctrlKey].preRecordEntityLastFetch
  );
};

export const selectDatatableShouldUpdateCache = createSelector(
  selectDatatablePreRecordEntity,
  selectDatatablePreRecordEntityLastFetch,
  (preRecordEntity, lastFetch) => {
    if (preRecordEntity === null) {
      return true;
    }

    if (lastFetch === null) {
      return true;
    }

    const ellapsedTime = Date.now() - lastFetch;

    if (ellapsedTime > DATATABLE_PRE_RECORD_ENTITY_CACHE()) {
      return true;
    }

    return false;
  }
);

export const selectDatatableTotalRecords = createSelector(
  selectCurrentDefinition,
  def => def.totalRecords + def.newEntitiesStart.length + def.newEntitiesLast.length
);
