import { APP_REDUCER_ACTIONS_TYPE } from "reducers/modules/AppReducer";
import { AppGalaxyInfo } from "types/Galaxy";
import { getAppState, getAppMostUsed } from "api";
import { Dispatch } from "redux";
import { ProcessusDefinition } from "types/Processus";

export function galaxyMenuFavorisSuccess(menus: AppGalaxyInfo[]) {
  return {
    type: APP_REDUCER_ACTIONS_TYPE.UPDATE_FAVORIS,
    payload: menus
  };
}

export function setDefaultEditionDefinition(sjmoCode: string, definition: ProcessusDefinition) {
  return {
    type: APP_REDUCER_ACTIONS_TYPE.SET_DEFAULT_EDITION_DEFINITION,
    payload: { sjmoCode, definition }
  };
}

export function fetchApplicationState(): any {
  return (dispatch: Dispatch) => {
    return Promise.all([
      getAppMostUsed()
        .then(res => res.data)
        .then(appState => {
          dispatch({
            type: APP_REDUCER_ACTIONS_TYPE.INIT_APP_STATE_MOST_USED,
            payload: appState
          });
        }),
      getAppState()
        .then(res => res.data)
        .then(appState => {
          dispatch({
            type: APP_REDUCER_ACTIONS_TYPE.INIT_APP_STATE,
            payload: appState
          });
        })
    ]);
  };
}
