import { isKeyHotkey } from "is-hotkey";
import { useLayoutEffect, useState } from "react";

export function useShortcut(hotkeys: string, ref: React.RefObject<HTMLElement>) {
  const [isValid, setValidHotkey] = useState<boolean>(false);

  useLayoutEffect(() => {
    const hotkey = isKeyHotkey(hotkeys);

    const currentRef = ref.current;

    function keydown(e: KeyboardEvent) {
      const isHotKey = hotkey(e);
      setValidHotkey(isHotKey);
    }

    if (currentRef) {
      currentRef.addEventListener("keydown", keydown);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("keydown", keydown);
      }
    };
  }, [hotkeys, ref]);

  return isValid;
}
