import immer from "immer";
import Action from "../Action";
import {
  INIT_ADMIN_DATATABLE_SUCCESS,
  CLEAR_ADMIN_DATATABLE,
  ADMIN_DATATABLE_SAVE_NEW_SUCCESS,
  ADMIN_DATATABLE_CLEAR_SAVE_STATE,
  ADMIN_DATATABLE_CLEAR_CTRLKEYS,
  ADMIN_DATATABLE_ADD_ONE_CTRLKEY
} from "constant/adminDatatable";
import { DatatableAndUsage } from "types/admin";

export interface AdminDatatableReducerState {
  ctrlKeys: DatatableAndUsage[];
  saveState?: "SUCCESS";
  focusToNavigate?: string;
}

const initialState = {
  ctrlKeys: []
};

export default function reducer(
  state: AdminDatatableReducerState = initialState,
  action: Action<any>
): AdminDatatableReducerState {
  switch (action.type) {
    case INIT_ADMIN_DATATABLE_SUCCESS:
      return initCtrlKey(state, action.payload);
    case CLEAR_ADMIN_DATATABLE:
      return initialState;
    case ADMIN_DATATABLE_SAVE_NEW_SUCCESS:
      return setSaveState(state, action.payload);
    case ADMIN_DATATABLE_CLEAR_SAVE_STATE:
      return setSaveState(state, {});
    case ADMIN_DATATABLE_CLEAR_CTRLKEYS:
      return initialState;
    case ADMIN_DATATABLE_ADD_ONE_CTRLKEY:
      return addOneCtrlKey(state, action.payload);
    default:
      return state;
  }
}

function initCtrlKey(state: AdminDatatableReducerState, ctrlKeys: DatatableAndUsage[]) {
  return immer(state, draft => {
    draft.ctrlKeys = ctrlKeys;
  });
}

function setSaveState(
  state: AdminDatatableReducerState,
  params: {
    saveState?: "SUCCESS";
    focusToNavigate?: string;
  }
) {
  return immer(state, draft => {
    draft.saveState = params.saveState;
    draft.focusToNavigate = params.focusToNavigate;
  });
}

function addOneCtrlKey(state: AdminDatatableReducerState, params: { ctrlKey: string }) {
  return immer(state, draft => {
    draft.ctrlKeys.push({ ctrlKey: params.ctrlKey, usages: [] });
  });
}
