import immer from "immer";
import Action from "../Action";

import { FocusState } from "types/Focus";
import {
  FETCH_ADMIN_MINI_EXPERT_FOCUS_SUCCESS,
  CLEAR_FOCUS_ADMIN_MINI_EXPERT,
  ADMIN_MINI_EXPERT_SAVE_PICKLIST,
  ADMIN_MINI_EXPERT_SAVE_SUCESS,
  CLEAR_ADMIN_MINI_EXPERT_SAVE_STATUS
} from "constant/adminMiniExpert";

// type alias pour l'action dans le reducer de miniExpert
type MiniExpertAction = Action<any>;

// State redux du dashboard
export interface AdminMiniExpertState {
  focuses: FocusState[];
  saveStatus?: "SUCCESS" | "ERROR";
}

const initialState = { focuses: [] };

export default function reducer(
  state: AdminMiniExpertState = initialState,
  action: MiniExpertAction
): AdminMiniExpertState {
  switch (action.type) {
    case FETCH_ADMIN_MINI_EXPERT_FOCUS_SUCCESS:
      return addFocuses(state, action);
    case CLEAR_FOCUS_ADMIN_MINI_EXPERT:
      return initialState;
    case ADMIN_MINI_EXPERT_SAVE_SUCESS:
      return saveStatus(state, "SUCCESS");
    case CLEAR_ADMIN_MINI_EXPERT_SAVE_STATUS:
      return saveStatus(state, undefined);
    default:
      return state;
  }
}

/**
 * Action de sélection d'une syjTables qui met à jour les focus de mini expert correspondants
 */
function addFocuses(state: AdminMiniExpertState, action: MiniExpertAction): AdminMiniExpertState {
  const focuses: FocusState[] = action.payload.focuses;
  return immer(state, draft => {
    draft.focuses = focuses;
  });
}

function saveStatus(
  state: AdminMiniExpertState,
  newStatus: "SUCCESS" | "ERROR" | undefined
): AdminMiniExpertState {
  return immer(state, draft => {
    draft.saveStatus = newStatus;
  });
}
