import {
  GET_EXPERT_TREE_FOCUS,
  TREE_DATA_CHANGE,
  TREE_EXPAND_COLLAPSE_ALL,
  TREE_ON_MOVE_NODE,
  TREE_ON_MOVE_NODE_EXPERT,
  TREE_ON_INSERT_NODE_EXPERT,
  TREE_ON_DELETE_NODE_EXPERT,
  INIT_TREE_FOCUSES,
  GET_FLAT_TREE_DATA,
  ON_TREE_FOCUS_CHANGE,
  TREE_ON_DELETE_NODE,
  TREE_INFO_LOADED,
  SHOULD_UPDATE_TREE
} from "constant/tree";
import { RoughTreeData } from "types/Tree";

export function initTreeFocuses(
  sjmoCode: string,
  panelId: number,
  mainEntityId: number | string | null,
  mainEntityType: string
) {
  return {
    type: INIT_TREE_FOCUSES,
    payload: {
      sjmoCode,
      panelId,
      mainEntityId,
      mainEntityType
    }
  };
}

export function getTreeData(
  sjmoCode: string,
  panelId: number,
  treeId: string,
  mainEntityId: number | string | null,
  mainEntityType: string
) {
  return {
    type: GET_FLAT_TREE_DATA,
    payload: {
      sjmoCode,
      panelId,
      treeId,
      mainEntityId,
      mainEntityType
    }
  };
}

export function onDeleteNode(
  sjmoCode: string,
  panelId: number,
  treeId: string,
  mainEntityId: number | null,
  mainEntityType: string,
  current: RoughTreeData,
  callback: () => void
) {
  return {
    type: TREE_ON_DELETE_NODE,
    payload: {
      sjmoCode,
      panelId,
      treeId,
      mainEntityId,
      mainEntityType,
      current,
      callback
    }
  };
}

export function onMoveNode(
  sjmoCode: string,
  panelId: number,
  treeId: string,
  mainEntityId: string | null,
  mainEntityType: string,
  current: RoughTreeData,
  nextParent: RoughTreeData,
  nextIndex: number,
  callback: () => void
) {
  return {
    type: TREE_ON_MOVE_NODE,
    payload: {
      sjmoCode,
      panelId,
      treeId,
      mainEntityId,
      mainEntityType,
      current,
      nextParent,
      nextIndex,
      callback
    }
  };
}

export function getExpertTreeFocus(sjmoCode: string) {
  return {
    type: GET_EXPERT_TREE_FOCUS,
    payload: {
      sjmoCode
    }
  };
}

export function treeDataChange(treeData: RoughTreeData[], sjmoCode: string, panelId: number) {
  return {
    type: TREE_DATA_CHANGE,
    payload: { treeData, sjmoCode, panelId }
  };
}

export function onFocusChange(
  sjmoCode: string,
  panelId: number,
  treeId: string,
  mainEntityId: number,
  mainEntityType: string
) {
  return {
    type: ON_TREE_FOCUS_CHANGE,
    payload: { sjmoCode, panelId, treeId, mainEntityId, mainEntityType }
  };
}

export function expandCollapseAll(
  treeData: RoughTreeData[],
  sjmoCode: string,
  panelId: number,
  expanded: boolean
) {
  return { type: TREE_EXPAND_COLLAPSE_ALL, payload: { treeData, sjmoCode, panelId, expanded } };
}

export function onMoveNodeExpert(
  current: RoughTreeData,
  nextParent: RoughTreeData,
  nextIndex: number,
  sjmoCode: string
) {
  return {
    type: TREE_ON_MOVE_NODE_EXPERT,
    payload: { current, nextParent, nextIndex, sjmoCode }
  };
}

export function onInsertNodeExpert(
  current: RoughTreeData,
  nextParent: RoughTreeData,
  nextIndex: number,
  sjmoCode: string
) {
  return {
    type: TREE_ON_INSERT_NODE_EXPERT,
    payload: { current, nextParent, nextIndex, sjmoCode }
  };
}

export function onDeleteNodeExpert(current: RoughTreeData, sjmoCode: string) {
  return {
    type: TREE_ON_DELETE_NODE_EXPERT,
    payload: { current, sjmoCode }
  };
}

/**
 * Envoie l'information au reducer que le composant informarion du title est chargé
 * Cela déclenche le recalcul de la hauteur de la node
 * @export
 * @param {string} sjmoCode
 * @param {number} panelId
 * @returns
 */
export function infoLoaded(sjmoCode: string, panelId: number) {
  return {
    type: TREE_INFO_LOADED,
    payload: { sjmoCode, panelId }
  };
}

export function shouldUpdateTree(sjmoCode: string, panelId: number) {
  return {
    type: SHOULD_UPDATE_TREE,
    payload: { sjmoCode, panelId }
  };
}
