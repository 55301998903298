import React, { Component, SFC, CSSProperties } from "react";
import { Trans } from "react-i18next";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Spring } from "react-spring/renderprops";
import get from "lodash-es/get";

import { Pojo } from "types/Galaxy";

import { findOne, preRecord } from "api";

import { Row, Col } from "composants/Layout";
import { Control } from "composants/form";
import { Field } from "composants/form/Form";
import { convertValue, convertToMap } from "utils/entities.utils";

import Calendar from "composants/calendar/Calendar";
import { executeKanbanLaunch, executeProcessLaunch } from "api/kanban";
import { getUserLang, getNumberFormat } from "utils/network.utils";
import { formatDate } from "utils/i18n";
import { CardWrapper } from "composants/kanban/Kanban";
import { RefLink } from "./common";
import { Select } from "composants/select/Select";
import AutoComplete from "composants/autocomplete/AutoComplete";
import { getSigle } from "utils/label.utils";
import { ComponentState } from "types/Component";
import { getColumnDefinition } from "api/column";
import customHistory from "customHistory";
import { Fa } from "composants/Icon";

interface CardFactProps {
  entity: Pojo;
  baseUrl: string;
  galaxyUrl: string;
  laneId: string;
  title: string;
  subtitle: string;
  subtitle2: string;
  subtitle3: string;
  price: number;
  sigle: string;
  baseUrlLinkRef?: string;
  linkRef?: string | string[];
  linkRefLabel?: string;
  borderLeftColor: string;
  createdAt: string;
  editAt?: string;
  validatedAt?: string;
  createdAtLabel?: string;
  editAtLabel?: string;
  validatedAtLabel?: string;
  kanbanId: string;
  modalTemplate?: string;
  onClickAdvanced(): void;
  onSelectCard(entity: Pojo, laneId: string): void;
}

export const KanbanCardGcoFact: SFC<CardFactProps> = ({
  entity,
  baseUrl,
  galaxyUrl,
  laneId,
  title,
  subtitle,
  subtitle2,
  subtitle3,
  price,
  sigle,
  createdAt,
  editAt,
  validatedAt,
  createdAtLabel,
  editAtLabel,
  validatedAtLabel,
  baseUrlLinkRef,
  linkRef,
  linkRefLabel,
  borderLeftColor,
  onClickAdvanced,
  onSelectCard,
  kanbanId,
  modalTemplate
}) => {
  const style: CSSProperties | undefined = borderLeftColor
    ? {
        borderLeft: `5px solid ${borderLeftColor}`
      }
    : undefined;

  const numberFormatter = getNumberFormat();

  return (
    <CardWrapper
      id={entity.id}
      entity={entity}
      laneId={laneId}
      className="box box-link"
      style={style}
      role="button"
      onSelectCard={e => {
        if (e.ctrlKey) {
          onSelectCard && onSelectCard(entity, laneId);
        }
      }}
      onOpen={() => {
        if (modalTemplate) {
          customHistory.push(
            `${baseUrl}/${entity.id}?kanbanId=${kanbanId}&template=${modalTemplate}&entityId=${entity.id}`
          );
        }
      }}
    >
      <div className="columns is-narrow">
        <div className="column">
          <div>
            <Link
              to={galaxyUrl ? `${galaxyUrl}/${entity.id}` : "#"}
              className="underline-link is-capitalized is-size-4 has-text-weight-semibold"
            >
              {title}
            </Link>
          </div>
          {subtitle2 && <div className="is-size-6 has-text-weight-semibold">{subtitle2}</div>}
          {price !== null ? (
            <div>
              <span
                className="has-text-grey-dark word-break-none"
                style={{ fontSize: 20, fontWeight: 300, marginRight: ".4em" }}
              >
                {price && typeof price === "number" ? numberFormatter.format(price) : price}
              </span>
              <span
                className="has-text-grey-dark"
                style={{ fontSize: 20, fontWeight: 400, marginRight: ".2em" }}
              >
                {getSigle(sigle)}
              </span>
              <span
                className="has-text-grey-dark word-break-none"
                style={{ fontSize: 18, fontWeight: 400, marginRight: ".2em" }}
              >
                <Trans i18nKey="commun_ht">HT</Trans>
              </span>
            </div>
          ) : null}
        </div>
        <div className="column has-text-right">
          <div className="title is-6">{subtitle}</div>
          <div className="subtitle is-7">{subtitle3 && <span>{subtitle3}</span>}</div>
        </div>
      </div>

      {baseUrlLinkRef && linkRef ? (
        <RefLink baseUrlLinkRef={baseUrlLinkRef} linkRef={linkRef} linkRefLabel={linkRefLabel} />
      ) : null}

      <div className="has-text-grey">
        <div>
          <span className="icon">
            <Fa icon="calendar" fixedWidth />
          </span>
          <Trans i18nKey={createdAtLabel}>créée le </Trans>
          <strong className="ml-8">{formatDate(createdAt)}</strong>
        </div>
        {editAt && (
          <div>
            <span className="icon">
              <Fa icon="calendar-plus" fixedWidth />
            </span>
            <Trans i18nKey={editAtLabel}>edité le </Trans>
            <strong className="ml-8">{formatDate(editAt)}</strong>
          </div>
        )}
        {validatedAt && (
          <div>
            <span className="icon">
              <Fa icon="calendar-check" fixedWidth />
            </span>
            <Trans i18nKey={validatedAtLabel}>validé le</Trans>
            <strong className="ml-8">{formatDate(validatedAt)}</strong>
          </div>
        )}
      </div>
    </CardWrapper>
  );
};

interface KanbanModalFactState {
  parent: Pojo | null;
  configurationOpen: boolean;
  loadingValidation: boolean;

  configurationComponent: {
    trcoComptabilite: ComponentState | null;
    ventilationComptableId: ComponentState | null;
    journalId: ComponentState | null;
    typeTiersId: ComponentState | null;
    societeId: ComponentState | null;
    trcoDtComptable: ComponentState | null;
    trcoModeTransfert: ComponentState | null;
  };

  createdCompta: Pojo | null;
  transfertCompta: Partial<Pojo> | null;
}

export class KanbanModalGcoFact extends Component<
  { sjmoCode: string; id: string; kanbanDefinitionId: string; processId: string },
  KanbanModalFactState
> {
  state: KanbanModalFactState = {
    parent: null,
    configurationOpen: false,
    loadingValidation: false,
    configurationComponent: {
      trcoComptabilite: null,
      ventilationComptableId: null,
      journalId: null,
      typeTiersId: null,
      societeId: null,
      trcoDtComptable: null,
      trcoModeTransfert: null
    },
    //
    createdCompta: null,
    transfertCompta: null
  };

  componentDidMount() {
    findOne({ tableName: "factureClient", id: this.props.id, includeJoinParent: true }).then(res =>
      this.setState({ parent: res.data })
    );
    getColumnDefinition(this.props.sjmoCode, "transfertComptable", [
      "trcoComptabilite",
      "ventilationComptableId",
      "journalId",
      "typeTiersId",
      "societeId",
      "trcoDtComptable",
      "trcoModeTransfert"
    ])
      .then(res => {
        const definitions = convertToMap(res.data, el => el.column);
        this.setState(state => ({
          ...state,
          configurationComponent: definitions as any
        }));
      })
      .catch(() => console.error("cannot resolve definition of columns"));

    preRecord({ sjmoCode: this.props.sjmoCode, tableName: "transfertComptable" })
      .then(res => {
        this.setState({
          transfertCompta: res.data
        });
      })
      .catch(() => console.error("cannot initialize transfertComptable"));
  }

  onChange = (field: string, value: any) => {
    this.setState({
      ...this.state,
      transfertCompta: {
        ...this.state.transfertCompta,
        [field]: value
      }
    });
  };

  validateConfiguration = () => {
    this.setState({ loadingValidation: true }, () => {
      const { transfertCompta, parent: factureClient } = this.state;
      if (transfertCompta === null || factureClient === null) {
        return;
      }

      if (this.props.kanbanDefinitionId) {
        executeKanbanLaunch(this.props.sjmoCode, this.props.kanbanDefinitionId, transfertCompta, {
          factureClientId: factureClient.id
        })
          .then(res =>
            this.setState({
              createdCompta: res.data && res.data.length > 0 ? res.data[0] : null
            })
          )
          .catch(() => console.error("error during validation of kanban"))
          .then(() => this.setState({ loadingValidation: false }));
      } else if (this.props.processId) {
        executeProcessLaunch(this.props.sjmoCode, this.props.processId, transfertCompta, {
          factureClientId: factureClient.id
        })
          .then(res =>
            this.setState({
              createdCompta: res.data && res.data.length > 0 ? res.data[0] : null
            })
          )
          .catch(() => console.error("error during validation of kanban"))
          .then(() => this.setState({ loadingValidation: false }));
      }
    });
  };

  render() {
    const { parent: entity, transfertCompta } = this.state;

    if (entity === null) {
      return null;
    }

    const {
      trcoComptabilite,
      ventilationComptableId,
      journalId,
      typeTiersId,
      societeId,
      trcoDtComptable,
      trcoModeTransfert
    } = this.state.configurationComponent;

    const isDefinitionLoaded =
      transfertCompta !== null &&
      trcoComptabilite !== null &&
      ventilationComptableId !== null &&
      journalId !== null &&
      typeTiersId !== null &&
      societeId !== null &&
      trcoDtComptable !== null &&
      trcoModeTransfert !== null;

    const { drvTotalAPayer: total, drvTotalTtc: totalTTC, drvTotalHt: totalHT } = entity;

    const currencyFormatter = new Intl.NumberFormat(getUserLang(), {
      style: "currency",
      currency: entity.deviseId.id
    });

    return (
      <>
        <div style={{ padding: "0 0 4em 0" }}>
          <Row>
            <Col span={4}>
              <div
                className="is-size-1 has-text-black-ter has-text-weight-light"
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <div>
                  <Link
                    to={`/page/OGCO008/${entity.id}`}
                    title="naviguer vers le bl"
                    className="underline-link"
                  >
                    {entity.id}
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}
            >
              <div className="is-size-3 has-text-black-ter has-text-weight-light">
                <strong>
                  <Link
                    to={`/page/OGCO001/${entity.clientId.id}`}
                    title="naviguer vers le client"
                    className="underline-link"
                  >
                    {get(entity, "clientId.clntNom")}
                  </Link>
                </strong>
                {" ("}
                {get(entity, "clientId.id")}
                {")"}
              </div>
              <div className="is-size-3 has-text-black-ter has-text-weight-light">
                {get(entity, "societeId.sociLibelle")}
              </div>
              <div>
                <button
                  onClick={() => this.setState({ configurationOpen: true })}
                  className="button is-link"
                >
                  Passer en compta &rarr;
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="is-5">
          <Col className="is-narrow">
            <div className="box">
              <h3 className="subtitle is-4 underline">
                <Trans>Autres</Trans>
              </h3>
              <div className="is-size-6 has-text-black-ter has-text-weight-light">
                par <strong>{get(entity, "personnelId.persNom")}</strong> le{" "}
                <strong>{format(entity.faclDtSaisie, "DD/MM/YYYY")}</strong>
              </div>

              <div className="is-size-6 has-text-black-ter has-text-weight-light">
                {entity.faclReferenceClient ? (
                  <>
                    référence <strong>{entity.faclReferenceClient}</strong>
                  </>
                ) : (
                  "aucune référence"
                )}
              </div>

              <div className="is-size-6 has-text-black-ter has-text-weight-light">
                service : <strong>{get(entity, "serviceId.servLibelle")}</strong>
              </div>
            </div>
          </Col>
          <Col className="is-narrow px-5">
            <div className="box">
              <h3 className="subtitle is-4 underline">
                <Trans>Adresse</Trans>
              </h3>
              <div className="has-text-weight-bold is-size-4">{entity.clientAdresseId.cladNom}</div>
              <div className="has-text-weigh-light is-size-5">
                {entity.clientAdresseId.cladAdresse1}
              </div>
              <div className="has-text-weigh-light is-size-6">
                {entity.clientAdresseId.cladCp} {entity.clientAdresseId.cladLocalite}
              </div>
            </div>
          </Col>
          <Col className="is-narrow px-5">
            <div className="box">
              <h3 className="subtitle is-4 underline">
                <Trans>Prix</Trans>
              </h3>
              <div className="has-text-weight-bold is-size-6">
                <div
                  style={{ display: "inline-flex", justifyContent: "space-between", width: 250 }}
                >
                  <div>
                    <Trans>HT</Trans>
                  </div>
                  <div>
                    <strong>{currencyFormatter.format(totalHT)}</strong>
                  </div>
                </div>
              </div>
              <div className="has-text-weight-bold is-size-5">
                <div
                  style={{ display: "inline-flex", justifyContent: "space-between", width: 250 }}
                >
                  <div>
                    <Trans>TTC</Trans>
                  </div>
                  <div>
                    <strong>{currencyFormatter.format(totalTTC)}</strong>
                  </div>
                </div>
              </div>
              <div className="has-text-weight-bold is-size-4">
                <div
                  style={{ display: "inline-flex", justifyContent: "space-between", width: 250 }}
                >
                  <div>
                    <Trans>TOTAL</Trans>
                  </div>
                  <div>
                    <strong>{currencyFormatter.format(total)}</strong>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div>
          {this.state.configurationOpen && isDefinitionLoaded ? (
            <Spring from={{ right: -400, opacity: 0 }} to={{ right: 0, opacity: 1 }}>
              {styles => {
                return (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        right: 610,
                        opacity: (styles as any).opacity,
                        top: 5
                      }}
                    >
                      <button
                        className="delete"
                        onClick={() => this.setState({ configurationOpen: false })}
                        aria-label="close"
                      />
                    </div>
                    <div className="octal-overlay" style={{ ...styles, width: 600 }}>
                      <h3 className="pt-5 px-5 subtitle is-3">Configuration</h3>
                      <div
                        className="px-5 py-7 my-7 -ml-px"
                        style={{ borderLeft: "2px solid hsl(217, 71%, 53%)" }}
                      >
                        <Field label="Comptabilité">
                          <Control>
                            <Select
                              id={trcoComptabilite!.column}
                              value={transfertCompta![trcoComptabilite!.column]}
                              sysDomaineChoices={trcoComptabilite!.sysDomaineChoices || []}
                              required={trcoComptabilite!.mandatory}
                              onChange={e =>
                                this.onChange(trcoComptabilite!.column, convertValue(e))
                              }
                            />
                          </Control>
                        </Field>
                        <Field label="N°">
                          <Control>
                            <AutoComplete
                              id={ventilationComptableId!.column}
                              sjmoCode={this.props.sjmoCode}
                              value={transfertCompta![ventilationComptableId!.column]}
                              additionalClause={ventilationComptableId!.additionalClause}
                              joinTableName={ventilationComptableId!.joinTableName}
                              joinListFields={ventilationComptableId!.joinListFields}
                              onItemChange={item =>
                                this.onChange(ventilationComptableId!.column, item ? item.id : null)
                              }
                            />
                          </Control>
                        </Field>
                        <Field label="Journal">
                          <Control>
                            <AutoComplete
                              id={journalId!.column}
                              sjmoCode={this.props.sjmoCode}
                              value={transfertCompta![journalId!.column]}
                              additionalClause={journalId!.additionalClause}
                              joinTableName={journalId!.joinTableName}
                              joinListFields={journalId!.joinListFields}
                              onItemChange={item =>
                                this.onChange(journalId!.column, item ? item.id : null)
                              }
                            />
                          </Control>
                        </Field>
                        <Field label="Code">
                          <Control>
                            <AutoComplete
                              id={typeTiersId!.column}
                              sjmoCode={this.props.sjmoCode}
                              value={transfertCompta![typeTiersId!.column]}
                              additionalClause={typeTiersId!.additionalClause}
                              joinTableName={typeTiersId!.joinTableName}
                              joinListFields={typeTiersId!.joinListFields}
                              onItemChange={item =>
                                this.onChange(typeTiersId!.column, item ? item.id : null)
                              }
                            />
                          </Control>
                        </Field>
                        <Field label="Société">
                          <Control>
                            <AutoComplete
                              id={societeId!.column}
                              sjmoCode={this.props.sjmoCode}
                              value={transfertCompta![societeId!.column]}
                              additionalClause={societeId!.additionalClause}
                              joinTableName={societeId!.joinTableName}
                              onItemChange={item =>
                                this.onChange(societeId!.column, item ? item.id : null)
                              }
                            />
                          </Control>
                        </Field>
                        <Field label="Date comptable">
                          <Control>
                            <Calendar
                              id={trcoDtComptable!.column}
                              value={transfertCompta![trcoDtComptable!.column]}
                              onChange={e =>
                                this.onChange(trcoDtComptable!.column, convertValue(e))
                              }
                            />
                          </Control>
                        </Field>
                        <Field label="Mode">
                          <Control>
                            <Select
                              id={trcoModeTransfert!.column}
                              value={transfertCompta![trcoModeTransfert!.column]}
                              sysDomaineChoices={trcoModeTransfert!.sysDomaineChoices || []}
                              required={trcoModeTransfert!.mandatory}
                              onChange={e =>
                                this.onChange(trcoModeTransfert!.column, convertValue(e))
                              }
                            />
                          </Control>
                        </Field>
                      </div>

                      <div className="pb-5 px-5">
                        <button
                          className={classNames("button is-link is-large is-fullwidth", {
                            "is-loading": this.state.loadingValidation
                          })}
                          onClick={this.validateConfiguration}
                        >
                          <Trans i18nKey="commun_valider">Valider</Trans>
                        </button>
                        {this.state.createdCompta && (
                          <Link
                            to={`/page/OFIN001/${this.state.createdCompta.id}`}
                            className="button is-link is-outlined is-large is-fullwidth mt-7"
                          >
                            <Trans i18nKey="commun_naviguer_facl">Ouvrir la galaxie</Trans> &rarr;
                          </Link>
                        )}
                      </div>
                    </div>
                  </>
                );
              }}
            </Spring>
          ) : null}
        </div>
      </>
    );
  }
}
