import React, { FC, useState, useEffect, useCallback, useMemo } from "react";

import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ReducerState } from "reducers";
import produce from "immer";
import { Fa } from "composants/Icon";
import { useTranslation } from "react-i18next";

import Scheduler, {
  MapLookProps,
  SchedulerDefinition,
  getDurationFromDates,
} from "../containers/scheduler/Scheduler";
import { Row, Col } from "composants/Layout";
import { ComponentState } from "types/Component";
import { contextualize } from "actions/contextTreeModule";
import { fetchInteractionsGalaxy } from "api/interaction";
import { initInteractionSucces } from "actions/interactions";
import { getSchedulerDefinition } from "api/scheduler";
import { schedulerView } from "types/Galaxy";
import { SchedulerMenuTabsGlobal } from "containers/scheduler/SchedulerMenuTabGlobal";
import { SchedulerMenuTabHighlight } from "containers/scheduler/SchedulerMenuTabHighLight";
import SchedulerMenuTabSuggest from "containers/scheduler/SchedulerMenuTabSuggest";
import { Task } from "containers/scheduler/FullCalendarContainer";
import { setActive } from "actions/galaxy.action";
import { updateContext } from "actions/satellites";

interface SchedulerPageProps {
  sjmoCode: string;
  type: schedulerView;
}

type Tabs = "GLOBAL" | "HIGHLIGHT" | "SUGGEST";

type SchedulerPageAllProps = SchedulerPageProps;

export const SchedulerPage: FC<SchedulerPageAllProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const match = useParams<{ id: string }>();
  const { mainEntityId, type } = useMemo(() => {
    return { mainEntityId: match.id, type: props.type };
  }, [match.id, props.type]);

  const [currentTab, setCurrentTab] = useState<Tabs>("GLOBAL");
  const [pins, setPins] = useState<Task[]>([]);
  const [taskToUnpin, setTaskToUnpin] = useState<Task | null>(null);
  const [definition, setDefinition] = useState<undefined | SchedulerDefinition>(undefined);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(true);

  // Highlight
  const [hightLightMaps, setHightLightMaps] = useState<MapLookProps[]>([]);
  const [taskColumns, setTaskColumns] = useState<ComponentState[]>([]);

  // Changer la valeur de reloadSuggest va recharger les suggestions
  const [reloadSuggest, setReloadSuggest] = useState<boolean>(true);
  const [disableSuggest, setDisableSuggest] = useState<boolean>(false);

  // Force le refresh des taches, utilisé après un processus
  const [reloadTask, setReloadTask] = useState<boolean>(true);

  const userId = useSelector<ReducerState, string | undefined>((state) =>
    state.userSettings ? state.userSettings.id : undefined
  );

  useEffect(() => {
    dispatch(setActive(props.sjmoCode));
  }, [dispatch, props.sjmoCode]);

  // Si la définition est en mode mainntity = user on set le user en mainEntity
  useEffect(() => {
    if (userId && definition && definition.userIsMainEntity && mainEntityId !== userId) {
      history.push(`/page/${props.sjmoCode}/${userId}`);
    }
  }, [definition, dispatch, history, mainEntityId, props.sjmoCode, userId]);

  const setMainEntity = useCallback(
    (id: string) => {
      history.push(`/page/${props.sjmoCode}/${id}`);
      if (definition) {
        dispatch(
          updateContext({ tableName: definition.mainEntityTable, sjmoCode: props.sjmoCode, id })
        );
      }
    },
    [definition, dispatch, history, props.sjmoCode]
  );

  // Définition et intéractions
  useEffect(() => {
    async function fetchAll() {
      try {
        const [definitionResponse, interactionsResponse] = await Promise.all([
          getSchedulerDefinition(props.sjmoCode),
          fetchInteractionsGalaxy(props.sjmoCode),
        ]);

        dispatch(initInteractionSucces(props.sjmoCode, interactionsResponse.data));

        let definition = definitionResponse.data;
        // Si la définition n'a pas de mainEntityTable alors
        // - La table principale est personnel
        // - le user connecté est l'entité principale
        if (!definition.mainEntityTable) {
          definition.mainEntityTable = "personnel";
          definition.mgsColumnName = "persInitiale";
          definition.userIsMainEntity = true;
          dispatch(
            contextualize(props.sjmoCode, definition.mainEntityTable, "tPersMainGenSearch", {
              id: userId,
            } as any)
          );
          setMainEntity(userId as string);
        }
        setDefinition(definition);
      } catch {}
    }
    if (props.sjmoCode && userId) {
      fetchAll();
    }
    // On sort le setMainEntity du useEffect car un refresh de la fonction ne doit pas déclencher l'action.
    // De plus autrement cela créer une boucle infinie
  }, [dispatch, props.sjmoCode, userId]);

  // Enlève la card d'une tache dans la liste des taches misent de coté.
  const removePin = useCallback(
    (id: string) => {
      setPins(
        produce(pins, (draft) => {
          const indexToRemove = draft.findIndex((pin) => pin.id === id);
          draft.splice(indexToRemove, 1);
          return draft;
        })
      );
    },
    [pins]
  );

  // Enlève la card d'une tache dans la liste des taches misent de coté.
  // Et replace la tache d'où elle vient
  const cancelPinTask = useCallback(
    (task: Task) => {
      removePin(task.id as string);
      setTaskToUnpin(task);
    },
    [removePin]
  );

  const addPin = useCallback(
    (task) => {
      const duration = getDurationFromDates(task.start, task.end);
      const toBePinned = { ...task, duration, extendedProps: { ...task.extendedProps, duration } };
      setPins([...pins, toBePinned]);
    },
    [pins]
  );

  const refreshSuggest = useCallback(() => {
    setReloadSuggest(!reloadSuggest);
  }, [reloadSuggest]);

  const refreshAfterProcess = useCallback(() => {
    setReloadSuggest(!reloadSuggest);
    setReloadTask(!reloadTask);
  }, [reloadSuggest, reloadTask]);

  return (
    <>
      <Row style={{ paddingTop: "1em" }}>
        {isMenuOpen && (
          <Col>
            <div className="card">
              <div className="card-content" style={{ padding: "1em", minHeight: 600 }}>
                <div className="tabs is-centered">
                  <ul>
                    <li className={"GLOBAL" === currentTab ? "is-active" : ""}>
                      <a onClick={() => setCurrentTab("GLOBAL")} title={t("commun_menu_global")}>
                        <Fa icon="globe" fixedWidth />
                      </a>
                    </li>
                    <li className={"HIGHLIGHT" === currentTab ? "is-active" : ""}>
                      <a
                        onClick={() => setCurrentTab("HIGHLIGHT")}
                        title={t("commun_menu_highlight")}
                      >
                        <Fa icon="highlighter" fixedWidth />
                      </a>
                    </li>
                    {definition?.suggestSource && (
                      <li className={"SUGGEST" === currentTab ? "is-active" : ""}>
                        <a
                          onClick={() => setCurrentTab("SUGGEST")}
                          title={t("commun_menu_suggestion")}
                        >
                          <Fa icon="laptop-medical" fixedWidth />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>

                <SchedulerMenuTabsGlobal
                  sjmoCode={props.sjmoCode}
                  definition={definition}
                  isMenuOpen={isMenuOpen}
                  mainEntityId={mainEntityId}
                  pins={pins}
                  setMainEntityId={setMainEntity}
                  setMenuOpen={setMenuOpen}
                  isVisible={"GLOBAL" === currentTab}
                  refreshAfterProcess={refreshAfterProcess}
                  cancelPInTask={cancelPinTask}
                />

                <SchedulerMenuTabHighlight
                  sjmoCode={props.sjmoCode}
                  definition={definition}
                  taskColumns={taskColumns}
                  setHighlightMap={setHightLightMaps}
                  isVisible={"HIGHLIGHT" === currentTab}
                />
                {definition?.suggestSource && (
                  <SchedulerMenuTabSuggest
                    sjmoCode={props.sjmoCode}
                    definition={definition}
                    isVisible={"SUGGEST" === currentTab}
                    reloadSuggest={reloadSuggest}
                    disableSuggest={disableSuggest}
                  />
                )}
              </div>
            </div>
          </Col>
        )}
        <Col span={isMenuOpen ? 10 : 12}>
          <div className="card">
            <div className="card-content" style={{ padding: "1em" }}>
              {definition && (
                <Scheduler
                  sjmoCode={props.sjmoCode}
                  mainEntityId={mainEntityId}
                  definition={definition}
                  view={type}
                  menuOpen={isMenuOpen}
                  pinnedTasks={pins}
                  addPin={addPin}
                  removePin={removePin}
                  highLights={hightLightMaps}
                  setTaskColumns={setTaskColumns}
                  setMenuOpen={setMenuOpen}
                  refreshSuggest={refreshSuggest}
                  reloadTask={reloadTask}
                  refreshAfterProcess={refreshAfterProcess}
                  setDisableSuggest={setDisableSuggest}
                  taskToUnpin={taskToUnpin}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
