import React, { FC, useMemo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Pojo } from "types/Galaxy";
import { ReducerState } from "reducers";
import { selectDirtyGalaxyEntities } from "selectors";
import { findAll } from "api";
import { GSBuilder, initRsqlCriteria } from "utils/query.utils";

export interface ProcessusContextProps {
  sjmoCode: string;
  tableName: string;
  navigationUrl?: string;
  selected?: Pojo[];
  isDirty: boolean;
  onAfterSaveProcess?(): void;
}

export const ProcessusContext = React.createContext<ProcessusContextProps | null>(null);

type SelectedProps = string | Pojo | string[] | Pojo[];

export const ProcessusProvider: FC<{
  sjmoCode: string;
  tableName: string;
  selected?: SelectedProps;
  navigationUrl?: string;
  onAfterSaveProcess?(): void;
}> = ({ children, selected, ...props }) => {
  const [fetchedEntities, setFetchedEntities] = useState<Pojo[]>([]);
  const selectedEntities = useMemo(() => {
    if (selected == null || selected === undefined) return undefined;
    else if (Array.isArray(selected)) return selected;
    else return [selected];
  }, [selected]);

  const useInternalEntities = useMemo(() => {
    return selectedEntities?.every(it => typeof it === "string") ?? false;
  }, [selectedEntities]);

  useEffect(() => {
    if (!useInternalEntities) return;
    if (selectedEntities === undefined || selectedEntities.length === 0) return;

    const filter = GSBuilder.toFilter(GSBuilder.Comparison("id", "OPER_IN", selectedEntities));
    const rsql = initRsqlCriteria();
    rsql.filters.and(filter);
    findAll({
      tableName: props.tableName,
      filter: rsql.build(),
      size: false
    })
      .then(res => {
        setFetchedEntities(res.data.data);
      })
      .catch(() => {
        setFetchedEntities([]);
      });
  }, [props.tableName, selectedEntities, useInternalEntities]);

  const selector = useCallback((state: ReducerState) => hasDirtyEntities(state, props.sjmoCode), [
    props.sjmoCode
  ]);

  const isDirty = useSelector(selector);

  const contextValue = useMemo(
    () => ({
      ...props,
      isDirty,
      selected: useInternalEntities ? fetchedEntities : (selectedEntities as Pojo[])
    }),
    [fetchedEntities, isDirty, props, selectedEntities, useInternalEntities]
  );
  return <ProcessusContext.Provider value={contextValue}>{children}</ProcessusContext.Provider>;
};

function hasDirtyEntities(state: ReducerState, sjmoCode: string) {
  const dirtyEntities = selectDirtyGalaxyEntities(state, { sjmoCode });
  return dirtyEntities.length > 0;
}
