import {
  INIT_INTERACTION_GLOBAL,
  INIT_INTERACTION_BY_TABLE,
  INIT_INTERACTION_SUCCESS
} from "constant/context";
import { InteractionDefinitionState } from "reducers/modules/InteractionReducer";

export function initGlobalInteraction() {
  return {
    type: INIT_INTERACTION_GLOBAL,
    payload: { type: "GLOBAL" }
  };
}

/**
 * Pour les cas particuliers où un meme detail a plusieurs maitre en global de part le jeu des FK sui ont le meme nom
 */
export function initInteractionByTable() {
  return {
    type: INIT_INTERACTION_BY_TABLE,
    payload: { type: "TABLE" }
  };
}

export function initInteractionSucces(type: string, definition: InteractionDefinitionState[]) {
  return { type: INIT_INTERACTION_SUCCESS, payload: { type, definition } };
}
