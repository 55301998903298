import { KanbanCardGcoDevis, KanbanModalGcoDevis } from "./KanbanGco/KanbanGco";
import { KanbanModalGcoCde } from "./KanbanGco/KanbanCde";
import { KanbanModalGcoBl, KanbanCardGcoBl } from "./KanbanGco/KanbanBl";
import { KanbanCardGcoFact, KanbanModalGcoFact } from "./KanbanGco/KanbanFact";

export const CardTemplate = {
  CARD_GCO: KanbanCardGcoDevis,
  CARD_GCO_BL: KanbanCardGcoBl,
  CARD_GCO_FACT: KanbanCardGcoFact
};

export const ModalTemplate = {
  MODAL_GCO_DEVIS: KanbanModalGcoDevis,
  MODAL_GCO_CDE: KanbanModalGcoCde,
  MODAL_GCO_BL: KanbanModalGcoBl,
  MODAL_GCO_FACT: KanbanModalGcoFact,
  DEFAULT: () => "pas de détail"
};
