export const GET_EXPERT_TREE_FOCUS = "GET_EXPERT_TREE_FOCUS";
export const INIT_TREE_FOCUSES = "GET_TREE_FOCUSES";
export const GET_FLAT_TREE_DATA = "GET_FLAT_TREE_DATA";
export const TREE_DATA_CHANGE = "TREE_DATA_CHANGE";
export const TREE_EXPAND_COLLAPSE_ALL = "TREE_EXPAND_COLLAPSE_ALL";
export const TREE_ON_MOVE_NODE = "TREE_ON_MOVE_NODE";
export const TREE_ON_MOVE_NODE_EXPERT = "TREE_ON_MOVE_NODE_EXPERT";
export const TREE_ON_INSERT_NODE_EXPERT = "TREE_ON_INSERT_NODE_EXPERT";
export const TREE_ON_DELETE_NODE_EXPERT = "TREE_ON_DELETE_NODE_EXPERT";
export const ON_TREE_FOCUS_CHANGE = "ON_TREE_FOCUS_CHANGE";
export const TREE_ON_DELETE_NODE = "TREE_ON_DELETE_NODE";

export const GET_EXPERT_TREE_FOCUS_SUCCESS = "GET_EXPERT_TREE_FOCUS_SUCCESS";
export const INIT_TREE_FOCUS_SUCESS = "INIT_TREE_FOCUS_SUCESS";
export const GET_FLAT_TREE_DATA_SUCCESS = "GET_FLAT_TREE_DATA_SUCCESS";
export const TREE_ON_MOVE_NODE_SUCCESS = "TREE_ON_MOVE_NODE_SUCCESS";
export const TREE_ON_CRUD_NODE_EXPERT_SUCESS = "TREE_ON_CRUD_NODE_EXPERT_SUCESS";
export const ON_TREE_FOCUS_CHANGE_SUCCESS = "ON_TREE_FOCUS_CHANGE_SUCCESS";
export const TREE_ON_CRUD_NODE_SUCESS = "TREE_ON_CRUD_NODE_SUCESS";
export const TREE_ON_CRUD_NODE_ERROR = "TREE_ON_CRUD_NODE_ERROR";

export const TREE_ON_CRUD_NODE_EXPERT_ERROR = "TREE_ON_CRUD_NODE_EXPERT_ERROR";

export const TREE_INFO_LOADED = "TREE_INFO_LOADED";
export const SHOULD_UPDATE_TREE = "SHOULD_UPDATE_TREE";

// Définition des constantes liées à l'administration des experts
// Ces éléments peuvent être en constante car :
// Il ne changeront jamais
// Il n'existe aucune table de pramétrage pour les définir
export const ADMIN_EXPERT_TREE_MODULE = "ADMIN_EXPERT";
export const ADMIN_EXPERT_TREE_PANEL_ID = 0;
// Ces trois constantes doivent être égales à celles présentes dans AdminExpertController
export const ADMIN_EXPERT_TREE_CONTAINER = "CONTAINER";
export const ADMIN_EXPERT_TREE_COMPONENT = "COMPONENT";
export const ADMIN_EXPERT_TREE_DATATABLE = "DATATABLE";
export const ADMIN_EXPERT_TREE_IS_HEADER = "IS_HEADER";
