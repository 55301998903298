import {
  SHOW_CONTEXT_MENU,
  HIDE_CONTEXT_MENU,
  LAUNCH_PROCESS_FROM_RIGHT_CLICK
} from "constant/contextMenu";
import { Pojo } from "types/Galaxy";

export function showContextMenu(
  xpos: number,
  ypos: number,
  sjmoCode: string,
  contextTableName: string,
  columnName: string | null,
  contextId: string | null,
  value: string,
  genericEntity: Pojo | null,
  urlCplt?: string,
  forceNewTab: boolean = false
) {
  return {
    type: SHOW_CONTEXT_MENU,
    payload: {
      xpos,
      ypos,
      sjmoCode,
      columnName,
      contextTableName,
      contextId,
      value,
      urlCplt,
      genericEntity,
      forceNewTab
    }
  };
}

export function hideContextMenu() {
  return {
    type: HIDE_CONTEXT_MENU,
    payload: {}
  };
}
/**
 * Méthode permettant de lancer un processus depuis un clic droit.
 *
 * @export
 * @param {number} sjipId id du processus
 * @param {string} [navigationUrl] ne doit être utilisé que dans le cas d'une navigation vers autre chose qu'une galaxie
 * @returns
 */
export function launchProcessFromRightClick(sjipId: string, navigationUrl?: string) {
  return {
    type: LAUNCH_PROCESS_FROM_RIGHT_CLICK,
    payload: { sjipId, navigationUrl }
  };
}
