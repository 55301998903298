// Il n'existe aucune table de pramétrage pour les définir
export const ADMIN_MINI_EXPERT_TREE_MODULE = "ADMIN_MINI_EXPERT_TREE_MODULE";
export const ADMIN_MINI_EXPERT_TREE_PANEL_ID = 0;

export const FETCH_ADMIN_MINI_EXPERT_FOCUS = "FETCH_ADMIN_MINI_EXPERT_FOCUS";
export const FETCH_ADMIN_MINI_EXPERT_FOCUS_SUCCESS = "FETCH_ADMIN_MINI_EXPERT_FOCUS_SUCCESS";
export const CHANGE_FOCUS_ADMIN_MINI_EXPERT = "CHANGE_FOCUS_ADMIN_MINI_EXPERT";
export const CLEAR_FOCUS_ADMIN_MINI_EXPERT = "CLEAR_FOCUS_ADMIN_MINI_EXPERT";
export const INIT_ADMIN_MINI_EXPERT = "INIT_ADMIN_MINI_EXPERT";

export const INIT_ADMIN_MINI_EXPERT_TREE = "INIT_ADMIN_MINI_EXPERT_TREE";
export const GET_MINI_EXPERT_TREE_FOCUS_SUCCESS = "GET_MINI_EXPERT_TREE_FOCUS_SUCCESS";

export const TREE_ON_MOVE_NODE_MINI_EXPERT = "TREE_ON_MOVE_NODE_MINI_EXPERT";
export const TREE_ON_INSERT_NODE_MINI_EXPERT = "TREE_ON_INSERT_NODE_MINI_EXPERT";
export const TREE_ON_DELETE_NODE_MINI_EXPERT = "TREE_ON_DELETE_NODE_MINI_EXPERT";

export const TREE_ON_CRUD_NODE_MINI_EXPERT_SUCESS = "TREE_ON_CRUD_NODE_MINI_EXPERT_SUCESS";
export const TREE_ON_CRUD_NODE_MINI_EXPERT_ERROR = "TREE_ON_CRUD_NODE_MINI_EXPERT_ERROR";

export const ADMIN_MINI_EXPERT_SAVE_PICKLIST = "ADMIN_MINI_EXPERT_SAVE_PICKLIST";
export const ADMIN_MINI_EXPERT_SAVE_SUCESS = "ADMIN_MINI_EXPERT_SAVE_SUCESS";
export const ADMIN_MINI_EXPERT_SAVE_ERROR = "ADMIN_MINI_EXPERT_SAVE_ERROR";
export const CLEAR_ADMIN_MINI_EXPERT_SAVE_STATUS = "CLEAR_ADMIN_MINI_EXPERT_SAVE_STATUS";
export const ADMIN_EXPERT_MINI_DELETE_FOCUS = "ADMIN_EXPERT_MINI_DELETE_FOCUS";
