import Editor, { EditorProps } from "./Editor";

/**
 * html et marckdown sont deux valeurs valides
 */
export const TEXT_FORMAT = "html";

export type TextEditorProps = EditorProps;
const TextEditor = Editor;

export default TextEditor;

export { DatatableEditor } from "./DatatableEditor";
