import immer from "immer";
import Action from "reducers/Action";
import produce from "immer";
import { Pojo } from "types/Galaxy";
import { ComponentState } from "types/Component";

import {
  ADD_PROCESSUS_DEFINTION_SUCCESS,
  CHANGE_PROCESSUS_NEW_ENTITY_VALUE,
  LAUNCH_ADVANCED_PROCESS_SUCCESS,
  ADD_NEW_ENTITY,
  ON_APPLY_TO_ALL_CHANGE,
  SET_PROCESSUS_CONTEXT,
  SET_PROCESSUS_ID,
  START_EDITION_IN_PROGRESS,
  SET_EDITION_SUCCESS,
  SET_EDITION_INDEX,
  DISPLAY_EDITION_DOCUMENT,
  CLEAR_PROCESSUS_REDUCER,
  SET_MODE_EDITION,
  SET_PROCESSUS_MODULE,
  SET_PROCESSUS_TABLE_NAME,
  SET_PROCESSUS_CONTEXT_IDS,
  SET_PROCESSUS_URL_CPLT,
  SET_PROCESS_IN_PROGRESS,
  SET_PROCESSUS_FOR_ALL,
  PREPARE_PROCESSUS_CONTEXT,
  HIDE_EDITION_DOCUMENT,
  INIT_ADVANCED_PROCESS,
  RESET_PROCESSUS
} from "constant/processus";
import { EditionState } from "types/Processus";

// State redux du processus
export interface ProcessusState {
  sjipId: string;
  groups: ProcessusComponentGroupState[];
  entities: Partial<Pojo>[];
  advancedParams: Partial<Pojo>[];
  modifiedAdvancedParams: Set<string>;
  contextTableName: string | null; // nom de la table des éléments qui contextualisent le traitement
  // (peut etre le nom de la table jointe si le champ est une FK)
  contextIds: string[] | null; // id de l'élément contextuel du traitement
  // (peut etre l'id de la FK si le champ est une FK)
  indexContextualEntity: number;
  sjmoCode: string;
  urlCplt: string | null;
  processInProgress: boolean;
  displayNotification: boolean;
  editions: EditionState | null;
  currentEditionIndex: number;
  displayModalEdition: boolean;
  mode: string | null;
  forAll: boolean;
}

export interface ProcessusComponentGroupState {
  compos: ProcessusComponentState[];
}

export interface ProcessusComponentState extends ComponentState {
  defaultValue: Object;
  ctrlValid: string;
}

// type alias pour l'action dans le reducer de dashboard
type ProcessusAction = Action<any>;

const initialState: ProcessusState = {
  groups: [],
  entities: [],
  advancedParams: [],
  sjipId: "-1",
  contextTableName: null,
  contextIds: null,
  indexContextualEntity: 0,
  sjmoCode: "",
  urlCplt: "",
  processInProgress: false,
  displayNotification: false,
  editions: null,
  currentEditionIndex: 0,
  displayModalEdition: false,
  mode: null,
  forAll: false,
  modifiedAdvancedParams: new Set<string>()
};

export default function reducer(
  state: ProcessusState = initialState,
  action: ProcessusAction
): ProcessusState {
  switch (action.type) {
    case RESET_PROCESSUS:
      return initialState;

    case INIT_ADVANCED_PROCESS:
      return initAdvancedProcess(state, action);

    case ADD_PROCESSUS_DEFINTION_SUCCESS:
      return addDefinition(state, action);

    case CHANGE_PROCESSUS_NEW_ENTITY_VALUE:
      return changeProcessusNewEntityValue(state, action);

    case LAUNCH_ADVANCED_PROCESS_SUCCESS:
      return handleAdvancedProcessSuccess(state, action);

    case ADD_NEW_ENTITY:
      return addNewEntity(state, action);

    case ON_APPLY_TO_ALL_CHANGE:
      return onForAllChange(state, action);

    case SET_PROCESSUS_CONTEXT:
      return setProcessusContext(state, action);

    case SET_PROCESSUS_ID:
      return setProcessusId(state, action);

    case START_EDITION_IN_PROGRESS:
      return immer(state, draft => {
        draft.processInProgress = true;
        draft.displayNotification = true;
      });

    case SET_EDITION_SUCCESS:
      return setEditionSuccess(state, action);

    case SET_EDITION_INDEX:
      return immer(state, draft => {
        draft.currentEditionIndex = action.payload.index;
      });

    case DISPLAY_EDITION_DOCUMENT:
      return displayEdition(state, action);

    case HIDE_EDITION_DOCUMENT:
      return immer(state, draft => {
        draft.displayModalEdition = false;
      });
    case CLEAR_PROCESSUS_REDUCER:
      return initialState;

    case SET_MODE_EDITION:
      return immer(state, draft => {
        draft.mode = action.payload.mode;
      });

    case SET_PROCESSUS_MODULE:
      return immer(state, draft => {
        draft.sjmoCode = action.payload.sjmoCode;
      });

    case SET_PROCESSUS_TABLE_NAME:
      return immer(state, draft => {
        draft.contextTableName = action.payload.tableName;
      });

    case SET_PROCESSUS_CONTEXT_IDS:
      return immer(state, draft => {
        draft.contextIds = action.payload.contextIds;
      });

    case SET_PROCESSUS_URL_CPLT:
      return immer(state, draft => {
        draft.urlCplt = action.payload.urlCplt;
      });

    case SET_PROCESS_IN_PROGRESS:
      return immer(state, draft => {
        draft.processInProgress = action.payload;
      });

    case SET_PROCESSUS_FOR_ALL:
      return immer(state, draft => {
        draft.forAll = action.payload.forAll;
      });

    case PREPARE_PROCESSUS_CONTEXT:
      return prepareProcessusContext(state, action);

    default:
      return state;
  }
}

function initAdvancedProcess(state: ProcessusState, action: ProcessusAction): ProcessusState {
  return immer(state, draft => {
    draft.entities = action.payload.entities;
    draft.groups = action.payload.definition;
    draft.advancedParams = action.payload.paramsAdd;
    draft.forAll = action.payload.forAll;
    draft.indexContextualEntity = 0;
  });
}

function setEditionSuccess(state: ProcessusState, action: ProcessusAction): ProcessusState {
  const editions = action.payload.editions;
  return immer(state, draft => {
    draft.editions = editions;
    draft.currentEditionIndex = 0;
    draft.processInProgress = false;
  });
}
function setProcessusId(state: ProcessusState, action: ProcessusAction): ProcessusState {
  const sjipId = action.payload.sjipId;
  return immer(state, draft => {
    draft.sjipId = sjipId;
  });
}

/**
 * set le context du processus au moment de l'ouverture du menu des processus sur un clic droit
 * et remet à null l'id du processus qui ne sera décidé qu'au moment du clic sur un item du menu
 * @param state
 * @param action
 */
function setProcessusContext(state: ProcessusState, action: ProcessusAction): ProcessusState {
  const contextTableName = action.payload.contextTableName;
  const contextIds = action.payload.contextIds;
  const sjmoCode = action.payload.sjmoCode;
  const urlCplt = action.payload.urlCplt;
  const sjipId = action.payload.sjipId;
  return immer(state, draft => {
    draft.contextTableName = contextTableName;
    draft.contextIds = contextIds;
    draft.sjipId = sjipId;
    draft.sjmoCode = sjmoCode;
    draft.entities = [];
    draft.advancedParams = [];
    draft.urlCplt = urlCplt;
    draft.forAll = false;
  });
}

function onForAllChange(state: ProcessusState, action: ProcessusAction): ProcessusState {
  const forAll = action.payload.forAll;
  return immer(state, draft => {
    draft.forAll = forAll;
  });
}
/**
 * Fonction qui remplace les apram avancés en cours de saisie suite à un wvi
 * @param state
 * @param action
 */
function addNewEntity(state: ProcessusState, action: ProcessusAction): ProcessusState {
  const avancedParams = action.payload.avancedParams;
  return immer(state, draft => {
    draft.advancedParams[draft.indexContextualEntity] = avancedParams;
  });
}

function handleAdvancedProcessSuccess(
  state: ProcessusState,
  action: ProcessusAction
): ProcessusState {
  return immer(state, draft => {
    draft.indexContextualEntity = draft.indexContextualEntity + 1;
  });
}

function addDefinition(state: ProcessusState, action: ProcessusAction): ProcessusState {
  const definition: ProcessusComponentGroupState[] = action.payload.groups;
  return immer(state, draft => {
    draft.groups = definition;
    for (let def of draft.groups) {
      def.compos.forEach(k => {
        draft.advancedParams[draft.indexContextualEntity][k.column] = k.defaultValue;
      });
      const visibleCompo = def.compos.filter(compo => compo.compoVisible);
      def.compos = visibleCompo;
    }
  });
}

/**
 * Fonction qui change une valeur sur la nouvelle entité en cours de saisie
 * @param state
 * @param action
 */
function changeProcessusNewEntityValue(
  state: ProcessusState,
  action: Action<{
    field: string;
    value: any;
  }>
): ProcessusState {
  const field = action.payload.field;
  const value = action.payload.value;

  return produce(state, draft => {
    draft.advancedParams[draft.indexContextualEntity][field] = value;
    draft.modifiedAdvancedParams.add(field);
  });
}

function prepareProcessusContext(
  state: ProcessusState,
  action: Action<{
    sjipId: string;
    sjmoCode: string;
    tableName: string;
    contextIds: string[];
    forAll: boolean;
  }>
) {
  const { sjipId, sjmoCode, tableName, contextIds, forAll } = action.payload;
  return produce(state, draft => {
    draft.sjipId = sjipId;
    draft.sjmoCode = sjmoCode;
    draft.contextTableName = tableName;
    draft.contextIds = contextIds;
    draft.forAll = forAll;
  });
}

function displayEdition(state: ProcessusState, action: ProcessusAction): ProcessusState {
  const editions = action.payload;

  return immer(state, draft => {
    if (editions) {
      draft.editions = editions;
      draft.currentEditionIndex = 0;
      draft.processInProgress = false;
    }
    draft.currentEditionIndex = 0;
    draft.displayModalEdition = true;
  });
}
