import { Machine } from "xstate";

export const lovMachine = Machine({
  initial: "start",
  states: {
    start: {
      on: {
        START: "definitions"
      }
    },
    definitions: {
      entry: "fetchDefinition",
      on: {
        DEFINITION_SUCCESS: {
          target: "columns",
          actions: ["setLovDefinition"]
        }
      }
    },
    columns: {
      entry: "fetchColumns",
      on: {
        COLUMNS_SUCCESS: {
          target: "data",
          actions: ["setColumns"]
        }
      }
    },
    data: {
      entry: "fetchData",
      on: {
        DATA_SUCCESS: {
          target: "wait",
          actions: ["setData"]
        }
      }
    },
    wait: {
      on: {
        SEARCH_DATA: "data"
      }
    }
  }
});
