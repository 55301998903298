import React, { FC, useCallback } from "react";
import { SchedulerDefinition, mapTaskProps } from "./Scheduler";
import { Trans } from "react-i18next";
import { useSelector, shallowEqual } from "react-redux";
import { ReducerState } from "reducers";
import {
  Operators,
  RSQLCriteria,
  RSQLFilterExpression,
  RSQLFilterList,
} from "rsql-criteria-typescript";
import { selectContextAsDetail } from "selectors/context.selector";
import { initRsqlCriteria, mapSortToRSQL, mapToRSQL } from "utils/query.utils";
import { SchedulerDndCell } from "./SchedulerDndList";
import ViewFilterableList, {
  InfiniterequestProps,
} from "containers/listegenerique/ViewFilterableList";
import { Pojo } from "types/Galaxy";
import { getSchedulerPaginatedTask } from "api/scheduler";

const FETCH_SIZE = 50;

interface SchedulerMenuTabSuggestProps {
  sjmoCode: string;
  definition?: SchedulerDefinition;
  isVisible: boolean;
  // Recharger les suggestions à chaque fois que cette valeur change
  reloadSuggest: boolean;
  disableSuggest: boolean;
}

export async function fetchData({
  sjmoCode,
  tableName,
  filter,
  searchFields,
  searchString,
  sortValues,
  first,
}: InfiniterequestProps) {
  const rsql = initRsqlCriteria();

  if (filter) {
    rsql.filters.and(filter.filters);
  }

  if (searchFields && searchString && searchFields.length > 0 && searchString.length > 0) {
    const rsqlList = new RSQLFilterList();
    for (let field of searchFields) {
      rsqlList.or(new RSQLFilterExpression(field, Operators.Contains, searchString));
    }
    rsql.filters.and(rsqlList);
  }

  // TODO ajouter sort dans requête sur vue en optionnel
  mapSortToRSQL(rsql, sortValues);

  const { data } = await getSchedulerPaginatedTask({
    sjmoCode: sjmoCode as string,
    tableName,
    filter: rsql.build(),
    first: first,
    size: FETCH_SIZE,
  });

  return data;
}

function mapResultToSuggest(pojo: Pojo, definition: SchedulerDefinition) {
  // ATTENTION il faut ajouter "create = false" dans toutes les taches qui peuvent être drop sur le composant fullCalendar
  // Sinon la tache est visuellement doublée
  // On tag aussi la tache comme étant une suggestion
  let readySuggest = mapTaskProps(
    [pojo as any],
    definition.editTime,
    definition.editResource,
    definition.taskLook,
    []
  );

  const suggest = readySuggest[0];
  suggest.create = false;
  suggest.isSuggest = true;
  // Si il n'y a pas de duration on met une rdurée d'ne heure pour avoir dans tout les cas une date de fin valide
  suggest.duration = suggest.duration ? suggest.duration : { hour: 1 };

  return suggest;
}

const SchedulerMenuTabSuggest: FC<SchedulerMenuTabSuggestProps> = (props) => {
  const suggestInteractions = useSelector<ReducerState, RSQLCriteria | undefined>((state) => {
    let rsql = undefined;
    if (props.definition) {
      const interactions = selectContextAsDetail(state.interactions, {
        sjmoCode: props.sjmoCode,
        ctrlKey: "schedulerSuggest:" + props.definition.schedulerCode,
      });
      if (Object.keys(interactions).length > 0) {
        const rsqlInteractions = mapToRSQL(interactions);
        rsql = rsqlInteractions;
      }
    }
    return rsql;
  }, shallowEqual);

  const buildChildrenList = useCallback(
    (entity: Pojo) => {
      const task = mapResultToSuggest(entity, props.definition as SchedulerDefinition);
      return <SchedulerDndCell key={task.id} task={task} disabled={props.disableSuggest} />;
    },
    [props.definition, props.disableSuggest]
  );

  return props.definition && props.definition.insertableSuggest ? (
    <div style={{ display: props.isVisible ? "block" : "none" }}>
      <div className="mt-5 mb-8">
        <Trans i18nKey="commun_taches_suggerees">Tâches sugerées</Trans>
      </div>
      <ViewFilterableList
        tableName={props.definition.suggestSource}
        filter={suggestInteractions}
        searchFields={["title"]}
        renderCell={buildChildrenList}
        listHeight={500}
        customFetchData={fetchData}
        forceRefresh={props.reloadSuggest}
      />
    </div>
  ) : null;
};

function areEqual(
  prevProps: SchedulerMenuTabSuggestProps,
  nextProps: SchedulerMenuTabSuggestProps
) {
  return (
    prevProps.definition === nextProps.definition &&
    prevProps.disableSuggest === nextProps.disableSuggest &&
    prevProps.isVisible === nextProps.isVisible &&
    prevProps.reloadSuggest === nextProps.reloadSuggest &&
    prevProps.sjmoCode === nextProps.sjmoCode
  );
}

export default React.memo(SchedulerMenuTabSuggest, areEqual);
