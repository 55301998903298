import React, { Component, SFC } from "react";
import Input from "../input/Input";
import { FieldHorizontal } from "../form/Form";
import classNames from "classnames";
import {
  ComposantAllProps,
  ColorProps,
  SizeProps,
  getColorsClasses,
  getSizeClasses
} from "composants/common";

import { format } from "date-fns";

export interface CalendarProps extends ComposantAllProps {
  defaultAppearance?: boolean;
}

const Calendar: SFC<CalendarProps> = ({ value, defaultAppearance, ...restProps }) => {
  const finalValue = format(value, "YYYY-MM-DD");
  return (
    <Input
      {...restProps}
      data-initial-appearance={defaultAppearance}
      type="date"
      value={value ? finalValue : ""}
      data-value={value ? finalValue : ""}
    />
  );
};

export default Calendar;
