import React, { FC, useEffect, useState, useRef, useMemo } from "react";

import ResizeObserver from "resize-observer-polyfill";

const ObserveParent: FC<{
  observerId?: any;
  children(params: {
    ref: React.MutableRefObject<any>;
    width: number;
    height: number;
  }): React.ReactElement;
}> = ({ observerId, children }) => {
  const ref = useRef<Element | null>(null);
  const [sizes, setSizes] = useState({ width: 0, height: 0 });

  useEffect(() => {
    let valid = true;
    const element = observerId ? document.getElementById(observerId) : ref.current;

    const observer = new ResizeObserver(entries => {
      if (!Array.isArray(entries)) return;

      if (!entries.length) return;

      const entry = entries[0];

      valid && setSizes({ width: entry.contentRect.width, height: entry.contentRect.height });
    });

    if (element) {
      let elementToObserve: Element | null = element;

      if (!observerId) {
        elementToObserve = element.parentElement;
      }

      if (elementToObserve) {
        observer.observe(elementToObserve);
      }
    }
    return () => {
      valid = false;
      element && observer.unobserve(element);
    };
  }, [observerId]);

  const params = useMemo(() => ({ ref, ...sizes }), [sizes]);

  return children(params);
};

export default ObserveParent;
