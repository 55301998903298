import React, { Component } from "react";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";

import { CollapseComponent, Menu as MenuComponent } from "./Menu";

import { Menu } from "types/Menu";
import * as pathToRegexp from "path-to-regexp";
import Scrollbars from "react-custom-scrollbars";
import { track } from "tracking";

interface MenuNavigationExpertProps {
  menu: Menu[];
}

type MenuNavigationExpertPropsAll = MenuNavigationExpertProps &
  RouteComponentProps<{ code: string; id: string; expertId: any }>;

class MenuNavigationExpert extends Component<MenuNavigationExpertPropsAll> {
  render() {
    const defaultOpen = this.props.match.params.expertId;
    let defaultParentOpen: string | null = null;
    this.props.menu.forEach(top => {
      if (top.children.find(sub => sub.code === defaultOpen) !== undefined) {
        defaultParentOpen = top.code;
      }
    });

    const defaulMenuOpen = { [this.props.match.params.expertId]: true };
    if (defaultParentOpen != null) {
      defaulMenuOpen[defaultParentOpen] = true;
    }

    return (
      <Scrollbars autoHide>
        <CollapseComponent
          defaultOpen={defaulMenuOpen}
          render={({ open, onClickMenu }) => {
            return (
              <aside className="menu">
                <ul className="menu-list">
                  <MenuComponent
                    open={open}
                    menu={this.props.menu}
                    classNames={{ ulClassName: "menu-list" }}
                    onClickMenu={key => {
                      onClickMenu(key);
                      track("menu::click");
                    }}
                    render={(item, onClickMenuItem) => {
                      const toPath = pathToRegexp.compile(this.props.match.path);
                      const linkUrl = toPath({ ...this.props.match.params, expertId: item.code });
                      if (onClickMenuItem && item.children.length > 0) {
                        return (
                          <>
                            <NavLink
                              className="has-text-weight-semibold"
                              activeClassName="is-active"
                              onClick={onClickMenuItem}
                              style={{ display: "inline-block", width: "100%" }}
                              to={linkUrl}
                            >
                              {item.label}
                            </NavLink>
                          </>
                        );
                      } else {
                        return (
                          <NavLink
                            className="subtitle is-6"
                            activeClassName="is-active"
                            to={linkUrl}
                            onClick={() => {
                              track("menu::click::item");
                            }}
                          >
                            {item.label}
                          </NavLink>
                        );
                      }
                    }}
                  />
                </ul>
              </aside>
            );
          }}
        />
      </Scrollbars>
    );
  }
}

export default withRouter(MenuNavigationExpert);
