import React, { SFC, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps, matchPath } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Pojo, AppGalaxyInfo } from "types/Galaxy";
import { ReducerState } from "reducers";

import { findMap } from "utils/entities.utils";
import { selectCurrentEntity, selectGalaxyInformation } from "selectors";

interface LabelGalaxyProps extends RouteComponentProps<any> {
  galaxies: Record<string, AppGalaxyInfo>;
}

interface LabelGalaxyReduxProps {
  currentEntity?: Pojo;
  code?: string;
}

type LabelGalaxyAllProps = LabelGalaxyProps & LabelGalaxyReduxProps;

const LabelGalaxy: SFC<LabelGalaxyAllProps> = ({ galaxies, code, currentEntity }) => {
  // return galaxies[match.params.code] ? <h3>{galaxies[match.params.code].label}</h3> : null;
  if (code) {
    const currentGalaxy = findMap(galaxies, galaxy => galaxy.code === code);

    const entityString = currentEntity ? currentEntity.label : "";

    return currentGalaxy !== null ? (
      <Fragment>
        <h3 className="title is-5 has-text-white">{currentGalaxy.label}</h3>
        <Helmet {...currentEntity && { titleTemplate: "%s" }}>
          <title>{currentGalaxy.label + " : " + entityString}</title>
        </Helmet>
      </Fragment>
    ) : null;
  } else {
    return null;
  }
};

const mapStateToProps = (
  state: ReducerState,
  { location }: LabelGalaxyAllProps
): LabelGalaxyReduxProps => {
  const match = matchPath<{ code: string; id: string }>(location.pathname, {
    path: "/page/:code/:id?"
  });

  const code = match ? match.params.code : undefined;
  const id = match ? match.params.id : undefined;

  if (code) {
    const galaxyInfo = selectGalaxyInformation(state, code);

    const entity =
      galaxyInfo && id
        ? selectCurrentEntity(state, { sjmoCode: code, tableName: galaxyInfo.tableName, id })
        : undefined;

    return {
      code,
      currentEntity: entity
    };
  }
  return {};
};

export default withRouter(connect<LabelGalaxyReduxProps>(mapStateToProps)(LabelGalaxy));
