import React, { FC, useState, useEffect, useMemo, useReducer, useCallback, useRef } from "react";

import debounce from "lodash-es/debounce";
import classNames from "classnames";

import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timeline/main.css";
import "@fullcalendar/resource-timeline/main.css";
import "@fullcalendar/timegrid/main.css";

import {
  format,
  addHours,
  addYears,
  addMonths,
  addWeeks,
  addDays,
  addMinutes,
  addSeconds,
  addMilliseconds,
  getYear,
  getMonth,
  getHours,
  getMinutes,
  getSeconds,
  getMilliseconds,
  subYears,
  subMonths,
  subWeeks,
  subDays,
  subHours,
  subMinutes,
  subSeconds,
  subMilliseconds,
  parse,
  isValid,
  getDate
} from "date-fns";
import { ReducerState } from "reducers";
import { useSelector, shallowEqual } from "react-redux";
import { RSQLCriteria } from "rsql-criteria-typescript";
import { forEach, keys } from "lodash-es";
import produce from "immer";

import {
  getSchedulerTasks,
  getSchedulerResources,
  addSchedulerTask,
  changeSchedulerTask,
  deleteSchedulerTask,
  getSchedulerMarkedAarea
} from "api/scheduler";
import { selectContextAsDetail } from "selectors/context.selector";
import { mapToRSQL } from "utils/query.utils";
import { schedulerView } from "types/Galaxy";
import { isHighlighted, HighlightProperties } from "composants/datatable/dialog/HighlightDialog";
import { ActionTypeData } from "reducers/Action";
import { ComponentState } from "types/Component";
import SchedulerTaskDetail from "./modalsAndOverlay/SchedulerTaskDetail";
import { uuidv4 } from "utils/uuid.utils";
import FullCalendarContainer, { Task, Resource } from "./FullCalendarContainer";
import toaster from "composants/toaster/toaster";
import { NavigationDefinition } from "constant/navigation";
import { SchedulerCreatorList } from "./modalsAndOverlay/SchedulerCreatorList";
import { NotificationGroup } from "types/Notification";
import { DurationObjectInput } from "./FullCalendarContainer";
import { DateClickArg } from "@fullcalendar/interaction";
import { AxiosError } from "axios";
import { Message } from "types/Message";

export type SchedulerTimeSpan = "DAY" | "WEEK" | "MONTH";

export interface LegendProps {
  className: string;
  title: string;
}

export interface CreatorLink {
  title: string;
  color: string;
  navigation: NavigationDefinition;
}

export interface SchedulerDefinition {
  schedulerCode: string;
  taskSource: string;
  resourceSource: string;
  suggestSource: string;
  mainEntityTable: string;
  userIsMainEntity: boolean;
  mgsColumnName: string;
  timeSpan: SchedulerTimeSpan;
  businessHours?: BusinessHours[];
  actionClassName: string;
  taskLook: MapLookProps[];
  editTime: boolean;
  editResource: boolean;
  insertableFree: boolean;
  insertableSuggest: boolean;
  deletable: boolean;
  template: "SIMPLE" | "PROGRESS";
  legend?: LegendProps[];
  creators: CreatorLink[];
}

export interface MapLookProps {
  field: string;
  operator: string;
  value: any;
  className?: string;
  style?: React.CSSProperties;
}

export type TimeSpan = "DAY" | "WEEK" | "MONTH";

export interface PaginationState {
  start?: Date;
  end?: Date;
  timeSpan: TimeSpan;
  duration: DurationObjectInput;
}

/**
 * Constante servant à mapper chaque composante d'une duration avec la fonction date-fns qui lui correspond.
 * Les durations sont crées de deux façons :
 *    - Calculées en base pour les suggestions.
 *    - Calculées coté front lors du pin d'une tache.
 *
 * La première méthode doit pouvoir données une durées arbitraires en semaine si l'administrateur le veut.
 * La seconde ne fait que calculer de manière éffective la différence entre deux dates.
 */
const DURATION_ADD = {
  year: addYears,
  month: addMonths,
  week: addWeeks,
  day: addDays,
  hour: addHours,
  minute: addMinutes,
  second: addSeconds,
  millisecond: addMilliseconds
};

const DURATION_SUB = {
  year: subYears,
  month: subMonths,
  week: subWeeks,
  day: subDays,
  hour: subHours,
  minute: subMinutes,
  second: subSeconds,
  millisecond: subMilliseconds
};

/**
 * Constante servant à mapper chaque composante d'une duration avec la fonction date-fns qui lui correspond.
 * Cette comstante est utiliser pour créer une duration en faisant la différence entre deux dates.
 *
 * Ce qui explique qu'il n'y ai pas week dans cette mappe.
 */
const DURATION_GET = {
  year: getYear,
  month: getMonth,
  day: getDate,
  hour: getHours,
  minute: getMinutes,
  second: getSeconds,
  millisecond: getMilliseconds
};

/**
 * Ajoute une durée spécifique à une date.
 * Cette fonction est uilisée pour calculé une date de fin à partir d'une date de début et d'une durée.
 *
 * Gère le cas ou la durée contient des valeures négatives (cf getDurationFromDates).
 *
 * @param {Date} date
 * @param {DurationObjectInput} duration
 * @returns
 */
function addDurationToDate(date: Date, duration: DurationObjectInput) {
  let current = new Date(date);
  Object.keys(duration).forEach(prop => {
    if (duration[prop] > 0) {
      current = DURATION_ADD[prop](current, duration[prop]);
    } else if (duration[prop] < 0) {
      current = DURATION_SUB[prop](current, -duration[prop]);
    }
  });

  return current;
}

/**
 * Calcul une durée à partir d'une date de début et d'une date de fin.
 * Les membres dans la durée calculée peuvent être négatif, la méthode inverse gère ce cas.
 *
 * Celà est utile pour gérer les cas du genre :
 *   - start : 03 mars 2019 15:00
 *   - end : 04 mars 2019 11:00
 *
 * @param {Date} dateStart
 * @param {Date} dateEnd
 * @returns {DurationObjectInput}
 */
export function getDurationFromDates(dateStart: Date, dateEnd: Date): DurationObjectInput {
  let duration: DurationObjectInput = {};
  Object.keys(DURATION_GET).forEach(prop => {
    const start = DURATION_GET[prop](dateStart);
    const end = DURATION_GET[prop](dateEnd);
    duration[prop] = end - start;
  });
  return duration;
}

function calcLook(task: Task, look: MapLookProps[]) {
  let classNames: string = "";
  let style: React.CSSProperties | undefined = {};
  forEach(look, (l, index) => {
    // On définit le typeCompo par rapport au type de la value
    let type = typeof l.value;
    let typeCompo;
    if (type === "number") {
      typeCompo = "NUMBER";
    } else {
      const v = parse(l.value);
      if (isValid(v)) {
        typeCompo = "DATE";
      } else {
        typeCompo = "TEXT";
      }
    }

    // Seul les trois premiers champs comptes, le reste on met des valeurs par défaut
    const mappedLook: HighlightProperties = {
      operator: l.operator,
      column: l.field,
      term: typeCompo === "DATE" ? parse(l.value) : l.value,
      id: "",
      backgroundColor: "",
      color: "",
      isActive: true,
      label: "",
      sequence: index,
      typeHighlight: "column"
    };

    if (isHighlighted(typeCompo, task.extendedProps, mappedLook)) {
      classNames += l.className;
      style = l.style;
    }
  });
  return { className: classNames.length > 0 ? classNames : task.originalClassName, style };
}

/**
 * Fonction qui mappe les propriétés provenant de la définition du scheduler dans les events.
 * Les propiétés permettant d'interdire l'édition ne marche pas directement sur FullCalendar on doit donc les mapper dans chaque évènements
 * à partir de la définition du scheduler.
 *
 * @param {Task[]} tasks
 * @param {boolean} editTime
 * @param {boolean} editResource
 * @returns {Task[]}
 */
export function mapTaskProps(
  tasks: Task[],
  editTime: boolean,
  editResource: boolean,
  definitionLook: MapLookProps[],
  hightLightLook: MapLookProps[]
): Task[] {
  for (let t of tasks) {
    if (t.display) {
      break;
    }

    // mapping des propriété d'édition depuis la définition
    t.resourceEditable = editResource;
    t.editable = editTime;

    // Gestion du style sur la tache par priorité hightlight > tache > definition
    let look: { className?: string; style?: React.CSSProperties } | null = null;
    if (hightLightLook && hightLightLook.length > 0) {
      look = calcLook(t, hightLightLook);
    } else if (!t.originalClassName && definitionLook) {
      look = calcLook(t, definitionLook);
    } else {
      t.className = t.originalClassName;
    }
    if (look !== null) {
      t.className = look.className;
      t.backgroundColor = look.style ? look.style.backgroundColor : undefined;
      t.textColor = look.style ? look.style.color : undefined;
    } else {
      t.backgroundColor = undefined;
      t.textColor = undefined;
    }

    // On force la disparition de la bordure bleu moche par défaut, lorsque aucune bordure n'est définie.
    if (!t.className || (!t.className.includes("border") && !t.className.includes("b-0"))) {
      t.className = classNames("b-0", t.className);
    }
  }
  return tasks;
}

const defaultEvent: Task = {
  key: "0",
  title: "",
  editable: true,
  resourceEditable: true,
  start: undefined,
  end: undefined
};

/**
 * L'eventApi ne contient pas toutes les propriétées de la tache originelle
 * Dans le cas d'un move on récupère  la tache originelle et on l'enrichie avec les infos provenant de l'eventApi.
 *
 * @param {*} eventApi
 * @returns {Task}
 */
function mapEventApiToTask(eventApi: any, tasks: Task[], resourceAvailable: boolean): Task {
  const actualTask = eventApi.event ? eventApi.event : eventApi;

  // Selection de l'ancienne tache
  let oldTask: Task | undefined = tasks.find(t => t.id === actualTask.id);

  if (oldTask) {
    // Création d'une copie de l'ancienne tache quand elle existe
    oldTask = { ...oldTask };
  } else {
    const dataEvent: string = eventApi.draggedEl.attributes["data-event"].nodeValue;
    oldTask = JSON.parse(dataEvent);
  }

  if (oldTask) {
    // Enrichissement de la tache avec les valeur provenant de l'event
    oldTask.start =
      actualTask.start && actualTask.start != null ? format(actualTask.start) : oldTask.start;
    // Si la date de fin est égale à la date de début (ce qui ne devrait jamais arriver mais avec les données qu'on a...), la librairie envoi null dans "actualTask.end"
    // On remplace la date de fin donc avec la date début (à défaut de mieux).
    oldTask.end =
      actualTask.end && actualTask.end != null
        ? format(actualTask.end)
        : actualTask.start && actualTask.start != null
        ? format(actualTask.start)
        : oldTask.end;
    if (resourceAvailable) {
      oldTask.resourceId = actualTask.resource
        ? actualTask.resource.id
        : actualTask.getResources()[0].id;
    }

    return oldTask;
  } else {
    return {
      key: uuidv4(),
      id: actualTask.id,
      title: actualTask.title,
      resourceId:
        resourceAvailable && actualTask.resource
          ? actualTask.resource.id
          : actualTask.getResources()[0].id,
      start: format(actualTask.start),
      end: format(actualTask.end),
      editable: true,
      resourceEditable: true,
      className: actualTask.classNames ? actualTask.classNames.join(" ") : undefined,
      extendedProps: actualTask.extendedProps
    };
  }
}

function mergeAndPrepareTaskToDisplay(tasks: Task[], markedAreas: Task[], pinnedTasks: Task[]) {
  // il ne faut pas afficher dans le scheduler les taches qui ont été misent de coté (pin)
  const tasksToAdd = tasks.filter(t => {
    let contains = false;
    for (const p of pinnedTasks) {
      if (p.id === t.id) {
        contains = true;
      }
    }
    return !contains;
  });

  tasksToAdd.forEach(t => {
    // Sauvegarde des styles d'origines qui proviennent de la base
    // Sert à revenir en arrière après une mise en valeur
    t.originalClassName = t.className;
  });

  // Passage des markedAreas en background afin qu'elles s'affichent correctement
  markedAreas.forEach(task => {
    task.display = "background";
  });
  return [...tasksToAdd, ...markedAreas];
}

export interface BusinessHours {
  daysOfWeek: number[]; // days of week. an array of zero-based day of week integers (0=Sunday, 7=saturday)
  startTime: string; // a start time : "HH:mm"
  endTime: string; // an end time : "HH:mm"
}

interface SchedulerProps {
  sjmoCode: string;
  mainEntityId: string | null;
  definition: SchedulerDefinition;
  view: schedulerView;
  highLights: MapLookProps[];
  menuOpen?: boolean;
  reloadTask: boolean;
  pinnedTasks: Task[];
  taskToUnpin?: Task | null;
  addPin(task: Task): void;
  removePin(id: string): void;
  setTaskColumns?(columns: ComponentState[]): void;
  setMenuOpen?(open: boolean): void;
  refreshSuggest(): void;
  refreshAfterProcess(): void;
  setDisableSuggest(disabled: boolean): void;
}

type AllActionTask =
  | ActionTypeData<
      "CHANGE_HIGHLIGHT",
      { highlights: MapLookProps[]; definition: SchedulerDefinition }
    >
  | ActionTypeData<
      "CHANGE_TASKS",
      { tasks: Task[]; highlights: MapLookProps[]; definition: SchedulerDefinition }
    >
  | ActionTypeData<"REMOVE_TASK", { task: Task }>
  | ActionTypeData<"ADD_TASK", { task: Task }>;

function reducerTask(state: Task[], action: AllActionTask) {
  switch (action.type) {
    case "CHANGE_HIGHLIGHT":
      return produce(state, draft => {
        mapTaskProps(
          draft,
          action.payload.definition.editTime,
          action.payload.definition.editResource,
          action.payload.definition.taskLook,
          action.payload.highlights
        );
      });

    case "CHANGE_TASKS":
      return mapTaskProps(
        action.payload.tasks,
        action.payload.definition.editTime,
        action.payload.definition.editResource,
        action.payload.definition.taskLook,
        action.payload.highlights
      );

    case "REMOVE_TASK":
      return produce(state, draft => {
        const index = draft.findIndex(task => task.id === action.payload.task.id);
        draft.splice(index, 1);
        return draft;
      });

    case "ADD_TASK":
      return produce(state, draft => {
        draft.push(action.payload.task);
        return draft;
      });
    default:
      return state;
  }
}

const Scheduler: FC<SchedulerProps> = props => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    timeSpan: props.definition.timeSpan,
    duration: {
      day: props.definition.timeSpan === "DAY" ? 1 : props.definition.timeSpan === "WEEK" ? 7 : 31
    }
  });
  const [tasks, dispatchInterne] = useReducer(reducerTask, []);
  const [reloadTask, setReloadTask] = useState(false);

  // Overlay de détail, créators et fenêtre d'édition
  const [currentTask, setCurrentTask] = useState<Task>(defaultEvent);
  const [overlay, setOverlay] = useState<{
    initial?: "initialValue";
    targetRect?: ClientRect; // la target de l'event qui déclanche l'ouverture
    isOpen: boolean; // l'overlay est-il actullement ouvert
    isClosing: boolean; // gestion du cas particulier où l'utilisateur clique sur une date pour fermer l'overlay
  }>({ initial: "initialValue", isOpen: false, isClosing: false });
  const [isOpenCreator, setIsOpenCreator] = useState(false);

  // Interractions pour gérer les taches
  const selector = useCallback(
    state => {
      let rsql = undefined;
      const interactions = selectContextAsDetail(state.interactions, {
        sjmoCode: props.sjmoCode,
        ctrlKey: "schedulerTask:" + props.definition.schedulerCode
      });
      if (Object.keys(interactions).length > 0) {
        const rsqlInteractions = mapToRSQL(interactions);
        rsql = rsqlInteractions;
      }
      return rsql;
    },
    [props.definition.schedulerCode, props.sjmoCode]
  );
  const taskInteractions = useSelector<ReducerState, RSQLCriteria | undefined>(selector);

  // Interractions pour gérer les ressources
  const resourceInteractions = useSelector<ReducerState, RSQLCriteria | undefined>(state => {
    let rsql = undefined;
    const interactions = selectContextAsDetail(state.interactions, {
      sjmoCode: props.sjmoCode,
      ctrlKey: "schedulerResource:" + props.definition.schedulerCode
    });
    if (Object.keys(interactions).length > 0) {
      const rsqlInteractions = mapToRSQL(interactions);
      rsql = rsqlInteractions;
    }
    return rsql;
  }, shallowEqual);

  const lang = useSelector<ReducerState, string>(state => state.userSettings.lang, shallowEqual);

  const crudContext = useMemo(() => {
    return {
      sjmoCode: props.sjmoCode,
      taskSource: props.definition.taskSource,
      logicClassName: props.definition.actionClassName,
      interactionFilter: taskInteractions
    };
  }, [
    props.sjmoCode,
    props.definition.taskSource,
    props.definition.actionClassName,
    taskInteractions
  ]);

  /**
   * Déclenche la récupération des données en base.
   * pagination est en JSON.stringify(pagination)
   * car fullcalendar déclenche le changement de date (cf onDateChange dans fullcalendarContainer) trop de fois
   * De même les interactions sont en JSON.stringify(interractions), car redux les déclenchent en boucle
   */
  useEffect(() => {
    const fetchAllTask = debounce(
      async () => {
        if (pagination.start && pagination.end) {
          try {
            const start = addDurationToDate(pagination.start, { week: -1 });
            const end = addDurationToDate(pagination.end, { week: 1 });
            const [taskResponse, markedAreaResponse] = await Promise.all([
              getSchedulerTasks({
                sjmoCode: props.sjmoCode,
                source: props.definition.taskSource,
                start,
                end,
                interactionFilter: taskInteractions
              }),
              getSchedulerMarkedAarea({
                sjmoCode: props.sjmoCode,
                source: props.definition.actionClassName,
                timeSpan: pagination.timeSpan,
                start,
                end,
                interactionFilter: taskInteractions
              })
            ]);

            // il ne faut pas afficher dans le scheduler les taches qui ont été misent de coté (pin)
            const tasksToAdd = taskResponse.data.filter(t => {
              let contains = false;
              for (const p of props.pinnedTasks) {
                if (p.id === t.id) {
                  contains = true;
                }
              }
              return !contains;
            });

            tasksToAdd.forEach(t => {
              // Sauvegarde des styles d'origines qui proviennent de la base
              // Sert à revenir en arrière après une mise en valeur
              t.originalClassName = t.className;
            });

            markedAreaResponse.data.forEach(task => {
              task.display = "background";
            });
            const allTasks = mergeAndPrepareTaskToDisplay(
              tasksToAdd,
              markedAreaResponse.data,
              props.pinnedTasks
            );

            dispatchInterne({
              type: "CHANGE_TASKS",
              payload: {
                tasks: allTasks,
                definition: props.definition,
                highlights: props.highLights
              }
            });

            if (props.setTaskColumns && taskResponse.data.length > 0) {
              const columnNames: string[] = keys(taskResponse.data[0].extendedProps);
              const columns: ComponentState[] = columnNames.map((name, index) => {
                return {
                  column: name,
                  label: name,
                  position: index
                } as ComponentState;
              });
              props.setTaskColumns(columns);
            }
            props.setDisableSuggest(false);
          } catch {
            props.setDisableSuggest(false);
          }
        }
      },
      300,
      { maxWait: 1000 }
    );
    if (props.sjmoCode && props.definition.taskSource && pagination.start && pagination.end) {
      fetchAllTask();
    }
  }, [
    props.sjmoCode,
    props.definition.taskSource,
    props.reloadTask,
    reloadTask,
    props,
    pagination.start,
    pagination.end,
    pagination.timeSpan,
    taskInteractions,
    props.pinnedTasks
  ]);

  /**
   * On utilise le JSON.strigify car les isntances des interactions changent sans arrêt
   * sans pour autant changer de valeur.
   */
  useEffect(() => {
    if (props.sjmoCode && props.definition.resourceSource) {
      getSchedulerResources({
        sjmoCode: props.sjmoCode,
        source: props.definition.resourceSource,
        interactionFilter: resourceInteractions
      })
        .then(resourceResponse => setResources(resourceResponse.data))
        .catch(e => console.log(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sjmoCode, props.definition.resourceSource, JSON.stringify(resourceInteractions)]);

  useEffect(() => {
    dispatchInterne({
      type: "CHANGE_HIGHLIGHT",
      payload: { definition: props.definition, highlights: props.highLights }
    });
  }, [props.definition, props.highLights]);

  const { addPin } = props;
  const pinTask = useCallback(
    (taskToPin: Task) => {
      // Cette propriétée est obligatoire pour éviter que fullCalendar de dupplique visuellement la tache lorrs de l'ajout.
      taskToPin.create = false;

      // Calcul de la duration à partir des date de la tache.
      taskToPin.duration = getDurationFromDates(
        parse(taskToPin.start as string),
        parse(taskToPin.end as string)
      );
      addPin(taskToPin);

      dispatchInterne({ type: "REMOVE_TASK", payload: { task: taskToPin } });
    },
    [addPin]
  );

  const {
    view,
    definition,
    highLights,
    removePin,
    refreshSuggest,
    mainEntityId,
    setDisableSuggest,
    pinnedTasks
  } = props;
  const addTaskFromDrop = useCallback(
    (e: any) => {
      setDisableSuggest(true);
      let task: Task = mapEventApiToTask(e, tasks, definition.resourceSource ? true : false);
      task.resourceId = view === "TIMELINE" ? (e as any).resource.id : mainEntityId;
      const dateStart = new Date(e.dateStr as string);
      task.start = format(dateStart);
      // Gestion de la date de fin
      // Si il n'y a pas de duration on met une rdurée d'ne heure pour avoir dans tout les cas une date de fin valide
      task.duration = task.duration ? task.duration : { hour: 1 };
      const dateEnd = addDurationToDate(dateStart, task.duration);
      task.end = format(dateEnd);

      if (task.isSuggest !== true) {
        changeSchedulerTask(task, pagination.start as Date, pagination.end as Date, crudContext)
          .then(taskResponse => {
            const tasksToRefresh = taskResponse.data.tasks.filter(t => {
              let contains = false;
              for (const p of pinnedTasks) {
                // la tache qui est pin qui vient d'être drop n'a pas ecnore disparue de la liste pin
                if (p.id === t.id && t.id !== task.id) {
                  contains = true;
                }
              }
              return !contains;
            });

            tasksToRefresh.forEach(t => {
              // Sauvegarde des styles d'origines qui proviennent de la base
              // Sert à revenir en arrière après une mise en valeur
              t.originalClassName = t.className;
            });

            setCurrentTask(defaultEvent);
            dispatchInterne({
              type: "CHANGE_TASKS",
              payload: {
                tasks: tasksToRefresh,
                definition: definition,
                highlights: highLights
              }
            });
            removePin(task.id as string);
            if (taskResponse.data.message != null && taskResponse.data.message.message) {
              toaster.notify({
                id: uuidv4(),
                group: NotificationGroup.DEFAULT,
                title: taskResponse.data.message.message,
                priority: "NORMAL",
                intent: taskResponse.data.message.type,
                createdAt: format(Date.now())
              });
            }
            setDisableSuggest(false);
          })
          .catch(e => console.log(e));
      } else {
        addSchedulerTask(task, pagination.start as Date, pagination.end as Date, crudContext)
          .then(taskResponse => {
            const tasksToRefresh = taskResponse.data.tasks.filter(t => {
              let contains = false;
              for (const p of pinnedTasks) {
                if (p.id === t.id) {
                  contains = true;
                }
              }
              return !contains;
            });

            tasksToRefresh.forEach(t => {
              // Sauvegarde des styles d'origines qui proviennent de la base
              // Sert à revenir en arrière après une mise en valeur
              t.originalClassName = t.className;
            });

            dispatchInterne({
              type: "CHANGE_TASKS",
              payload: {
                tasks: tasksToRefresh,
                definition: definition,
                highlights: highLights
              }
            });
            refreshSuggest();
            if (taskResponse.data.message != null && taskResponse.data.message.message) {
              toaster.notify({
                id: uuidv4(),
                group: NotificationGroup.DEFAULT,
                title: taskResponse.data.message.message,
                priority: "NORMAL",
                intent: taskResponse.data.message.type,
                createdAt: format(Date.now())
              });
            }
            setDisableSuggest(false);
          })
          .catch((eResponse: AxiosError) => {
            refreshSuggest();
            if (
              eResponse.response?.data.message != null &&
              eResponse.response?.data.message.message
            ) {
              toaster.notify({
                id: uuidv4(),
                group: NotificationGroup.DEFAULT,
                title: eResponse.response?.data.message.message,
                priority: "NORMAL",
                intent: eResponse.response?.data.message.type,
                createdAt: format(Date.now())
              });
            }
            setDisableSuggest(false);
          });
      }
    },
    [
      crudContext,
      definition,
      highLights,
      mainEntityId,
      pagination.end,
      pagination.start,
      pinnedTasks,
      refreshSuggest,
      removePin,
      setDisableSuggest,
      tasks,
      view
    ]
  );

  // Replace la task sur le scheduler à l'emplacement d'où elle vient
  const unpinTask = useCallback((taskToUnpin: Task) => {
    dispatchInterne({ type: "ADD_TASK", payload: { task: taskToUnpin } });
  }, []);
  useEffect(() => {
    if (props.taskToUnpin) unpinTask(props.taskToUnpin);
  }, [props.taskToUnpin, unpinTask]);

  function deleteTask() {
    props.setDisableSuggest(true);
    deleteSchedulerTask(currentTask, pagination.start as Date, pagination.end as Date, crudContext)
      .then(taskResponse => {
        const tasksToRefresh = taskResponse.data.tasks.filter(t => {
          let contains = false;
          for (const p of props.pinnedTasks) {
            if (p.id === t.id) {
              contains = true;
            }
          }
          return !contains;
        });

        tasksToRefresh.forEach(t => {
          // Sauvegarde des styles d'origines qui proviennent de la base
          // Sert à revenir en arrière après une mise en valeur
          t.originalClassName = t.className;
        });

        setOverlay({ isOpen: false, isClosing: true });
        setCurrentTask(defaultEvent);
        dispatchInterne({
          type: "CHANGE_TASKS",
          payload: {
            tasks: tasksToRefresh,
            definition: props.definition,
            highlights: props.highLights
          }
        });
        if (taskResponse.data.message != null && taskResponse.data.message.message) {
          toaster.notify({
            id: uuidv4(),
            group: NotificationGroup.DEFAULT,
            title: taskResponse.data.message.message,
            priority: "NORMAL",
            intent: taskResponse.data.message.type,
            createdAt: format(Date.now())
          });
        }
        props.setDisableSuggest(false);
      })
      .catch(e => console.log(e));
  }

  const onEventClick = useCallback(
    (e: any) => {
      const task: Task = mapEventApiToTask(e, tasks, definition.resourceSource ? true : false);
      if (e.jsEvent.button === 0 && e.jsEvent.ctrlKey === true) {
        pinTask(task);
      } else if (!task.display) {
        const targetRect = e.jsEvent.target.getBoundingClientRect();
        setCurrentTask(task);
        setOverlay({
          targetRect,
          isOpen: true,
          isClosing: false
        });
      }
    },
    [definition.resourceSource, pinTask, tasks]
  );

  const clickCnt = useRef(0);
  const oneClickTimer = useRef<NodeJS.Timeout | null>(null);

  const openCreators = useCallback((e: DateClickArg) => {
    clickCnt.current++;
    if (clickCnt.current === 1) {
      oneClickTimer.current = setTimeout(function() {
        clickCnt.current = 0;
      }, 400);
      setOverlay({ isOpen: false, isClosing: false });
    } else if (clickCnt.current === 2) {
      oneClickTimer.current && clearTimeout(oneClickTimer.current);
      clickCnt.current = 0;

      const task: Task = {
        start: format(e.date),
        resourceId: e.resource?.id
      } as Task;
      setCurrentTask(task);
      return setIsOpenCreator(true);
    }
  }, []);

  const refreshTask = useCallback(
    (tasks: Task[], message: Message) => {
      const tasksToRefresh = tasks.filter(t => {
        let contains = false;
        for (const p of props.pinnedTasks) {
          if (p.id === t.id) {
            contains = true;
          }
        }
        return !contains;
      });

      tasksToRefresh.forEach(t => {
        // Sauvegarde des styles d'origines qui proviennent de la base
        // Sert à revenir en arrière après une mise en valeur
        t.originalClassName = t.className;
      });

      dispatchInterne({
        type: "CHANGE_TASKS",
        payload: {
          tasks: tasksToRefresh,
          definition: props.definition,
          highlights: props.highLights
        }
      });
      if (message != null && message.message) {
        toaster.notify({
          id: uuidv4(),
          group: NotificationGroup.DEFAULT,
          title: message.message,
          priority: "NORMAL",
          intent: message.type,
          createdAt: format(Date.now())
        });
      }
    },
    [props.definition, props.highLights, props.pinnedTasks]
  );

  const eventChange = useCallback(
    async e => {
      const modifiedEvent = mapEventApiToTask(e, tasks, definition.resourceSource ? true : false);

      try {
        if (pagination.start && pagination.end) {
          const start = addDurationToDate(pagination.start, { week: -1 });
          const end = addDurationToDate(pagination.end, { week: 1 });
          const [taskResponse, markedAreaResponse] = await Promise.all([
            changeSchedulerTask(
              modifiedEvent,
              pagination.start as Date,
              pagination.end as Date,
              crudContext
            ),
            getSchedulerMarkedAarea({
              sjmoCode: props.sjmoCode,
              source: props.definition.actionClassName,
              timeSpan: pagination.timeSpan,
              start,
              end,
              interactionFilter: taskInteractions
            })
          ]);

          const taskToDisplay = mergeAndPrepareTaskToDisplay(
            taskResponse.data.tasks,
            markedAreaResponse.data,
            props.pinnedTasks
          );

          refreshTask(taskToDisplay, taskResponse.data.message);
        }
      } catch (eResponse) {
        refreshTask(eResponse.response?.data.tasks, eResponse.response?.data.message);
      }
    },
    [
      crudContext,
      definition.resourceSource,
      pagination.end,
      pagination.start,
      pagination.timeSpan,
      props.definition.actionClassName,
      props.pinnedTasks,
      props.sjmoCode,
      refreshTask,
      taskInteractions,
      tasks
    ]
  );
  const refresh = useCallback(() => setReloadTask(!reloadTask), [reloadTask]);

  return (
    <>
      <div style={{ height: "calc(100vh - 130px)" }}>
        <FullCalendarContainer
          locale={lang}
          dateClick={openCreators}
          eventClick={onEventClick}
          addTask={addTaskFromDrop}
          changeTask={eventChange}
          resources={resources}
          definition={props.definition}
          menuOpen={props.menuOpen}
          setMenuOpen={props.setMenuOpen}
          pagination={pagination}
          refresh={refresh}
          setPagination={setPagination}
          tasks={tasks}
          view={props.view}
          setDisableSuggest={props.setDisableSuggest}
        />
      </div>

      {overlay.isOpen && overlay.targetRect && (
        <SchedulerTaskDetail
          task={currentTask}
          targetRect={overlay.targetRect}
          sjmoCode={props.sjmoCode}
          addPin={() => {
            setOverlay({ isOpen: false, isClosing: true });
            pinTask(currentTask);
            setCurrentTask(defaultEvent);
          }}
          deleteTask={deleteTask}
          onClose={dateClick => {
            // isClosing signifie que l'overlay vient de se fermer
            // sert dans le cas où l'utilisateur clique sur les dates pour fermer l'overlay
            setOverlay({
              isOpen: false,
              isClosing: dateClick
            });
          }}
          refreshAfterProcess={props.refreshAfterProcess}
        />
      )}
      {isOpenCreator && (
        <SchedulerCreatorList
          origin={currentTask}
          mainEntityId={props.mainEntityId as string}
          creators={props.definition.creators}
          refreshCallback={refresh}
          onClose={() => {
            setIsOpenCreator(false);
          }}
        />
      )}
    </>
  );
};

function areEqual(prevProps: SchedulerProps, nextProps: SchedulerProps) {
  return (
    prevProps.definition === nextProps.definition &&
    prevProps.menuOpen === nextProps.menuOpen &&
    prevProps.view === nextProps.view &&
    prevProps.highLights === nextProps.highLights &&
    prevProps.mainEntityId === nextProps.mainEntityId &&
    prevProps.pinnedTasks === nextProps.pinnedTasks &&
    prevProps.reloadTask === nextProps.reloadTask &&
    prevProps.sjmoCode === nextProps.sjmoCode &&
    prevProps.addPin === nextProps.addPin &&
    prevProps.refreshAfterProcess === nextProps.refreshAfterProcess &&
    prevProps.refreshSuggest === nextProps.refreshSuggest &&
    prevProps.removePin === nextProps.removePin &&
    prevProps.setDisableSuggest === nextProps.setDisableSuggest &&
    prevProps.setMenuOpen === nextProps.setMenuOpen &&
    prevProps.setTaskColumns === nextProps.setTaskColumns
  );
}

export default React.memo(Scheduler, areEqual);
