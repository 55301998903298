import React, { FC, useState, useEffect, useMemo } from "react";
import { SchedulerDefinition, MapLookProps, LegendProps } from "./Scheduler";
import Select from "composants/select/Select";
import { Item, ComponentState } from "types/Component";
import { useTranslation } from "react-i18next";
import { getSchedulerHighlight } from "api/scheduler";
import { convertValue } from "utils/entities.utils";
import { Button } from "composants/button";
import { Fa } from "composants/Icon";
import HighlightDialog, { HighlightProperties } from "composants/datatable/dialog/HighlightDialog";
import produce from "immer";
import { Dot } from "composants/Dot";

interface SchedulerMenuTabHighlightProps {
  sjmoCode: string;
  definition?: SchedulerDefinition;
  taskColumns?: ComponentState[];
  isVisible: boolean;
  setHighlightMap(look: MapLookProps[]): void;
}

interface HighlightDefinition extends Item {
  look: null | MapLookProps[];
  temp: boolean; // fait la distinction entre les highlights qui proviennent du paramétrage et ceux créer manullement par l'utilisateur
}

const Legend: FC<{ legend?: LegendProps[] }> = props => {
  if (!props.legend) {
    return <div />;
  }
  const lines = props.legend.map((l, i) => {
    return (
      <li key={i}>
        <Dot color="#fff" size={32} innerClassName={l.className} className="mr-7" />
        <span>{l.title}</span>
      </li>
    );
  });

  return <ul className="mb-6">{lines}</ul>;
};

function getLookFromHighlight(highlights: HighlightDefinition[], currentHighlight: string) {
  const hightLightMaps = highlights.find(
    h => h.value === currentHighlight && h.value !== "default"
  );
  return hightLightMaps && hightLightMaps.look ? hightLightMaps.look : [];
}

export const SchedulerMenuTabHighlight: FC<SchedulerMenuTabHighlightProps> = props => {
  const { t } = useTranslation();
  const { setHighlightMap } = props;
  const defaultHightlight: HighlightDefinition = useMemo(
    () => ({
      label: t("commun_aucune_valorisation"),
      value: "default",
      temp: false,
      look: []
    }),
    [t]
  );
  const [highlights, setHighlights] = useState<HighlightDefinition[]>([]);
  const [currentHighlight, setCurrentHighlight] = useState<string>("default");
  const [isOpenHighlight, setOpenHighlight] = useState<boolean>(false);

  useEffect(() => {
    if (props.definition) {
      getSchedulerHighlight(props.sjmoCode, props.definition.schedulerCode)
        .then(response => {
          let hs: HighlightDefinition[] = [defaultHightlight];
          hs.push(
            ...response.data.map(h => {
              return {
                label: h.label,
                value: h.id,
                look: h.mapLook as MapLookProps[],
                temp: false
              };
            })
          );

          setHighlights(hs);
        })
        .catch(e => console.log(e));
    }
  }, [props.sjmoCode, props.definition, defaultHightlight]);

  /**
   * Cette méthode est appelée lorsquel'on valide la dialog.
   * Il peut s'agir soit d'un ajout soit d'une modification.
   *
   * @param {HighlightProperties} aggr
   */
  function onValidTemporalHighlighConfig(aggr: HighlightProperties) {
    const updated = {
      label: aggr.label,
      value: aggr.id,
      look: [
        {
          field: aggr.column,
          operator: aggr.operator,
          value: aggr.term,
          style: { backgroundColor: aggr.backgroundColor, color: aggr.color }
        }
      ],
      temp: true
    };
    const newState = produce(highlights, draft => {
      const currentIndex = highlights.findIndex(h => h.value === aggr.id);

      if (currentIndex === -1) {
        draft.push(updated);
      } else {
        draft[currentIndex] = updated;
      }

      return draft;
    });
    setHighlights(newState);
    setCurrentHighlight(aggr.id);
    setOpenHighlight(false);
  }

  function deleteTempHighlight(partialIndex: number) {
    // L'index renvoyer par la dialog ne prend en compte que les highlight manuels (temp===true)
    const id = highlights.filter(h => h.temp)[partialIndex].value;
    // Il faut donc calculer le "vrai index" de l'élément à supprimer.
    const index = highlights.findIndex(h => h.value === id);
    const newState = produce(highlights, draft => {
      draft.splice(index, 1);
      return draft;
    });

    if (currentHighlight === id) {
      setCurrentHighlight(defaultHightlight.value);
      props.setHighlightMap(getLookFromHighlight(highlights, defaultHightlight.value));
    }
    setHighlights(newState);
    setOpenHighlight(false);
  }

  function deleteAllTempHighlight() {
    const newState = produce(highlights, draft => {
      return draft.filter(h => !h.temp);
    });
    setHighlights(newState);
  }

  const highlightForDialog = highlights
    .filter(current => current.temp)
    .map(current => {
      // Un highlight temporare à toujours un et un seul critère.
      const lookProps: MapLookProps = current.look ? current.look[0] : ({} as MapLookProps);
      let h: HighlightProperties = {
        id: current.value,
        column: lookProps.field,
        operator: lookProps.operator,
        term: lookProps.value,
        label: current.label,
        isActive: currentHighlight === (current as any).id,
        backgroundColor: lookProps.style ? (lookProps.style.backgroundColor as string) : "",
        color: lookProps.style ? (lookProps.style.color as string) : "",
        sequence: 0,
        typeHighlight: "column"
      };

      return h;
    });

  useEffect(() => {
    setHighlightMap(getLookFromHighlight(highlights, currentHighlight));
  }, [currentHighlight, highlights, setHighlightMap]);

  return (
    <div style={{ display: props.isVisible ? "block" : "none" }}>
      <Legend legend={props.definition ? props.definition.legend : []} />
      <Select
        id="schedulerHighlightSelect"
        value={currentHighlight}
        sysDomaineChoices={highlights}
        onChange={e => {
          const value = convertValue(e);
          setCurrentHighlight(value);
        }}
      />
      <nav className="level mt-6">
        <div className="level-left">
          <span className="level-item">
            <Button
              onClick={() => setOpenHighlight(true)}
              disabled={!(props.taskColumns && props.taskColumns.length > 0)}
              title={t("commun_highlight_personalise")}
            >
              <span className="icon">
                <Fa icon="highlighter" fixedWidth />
              </span>
            </Button>
          </span>
        </div>
      </nav>
      {isOpenHighlight && props.taskColumns && (
        <HighlightDialog
          columns={props.taskColumns}
          highlights={highlightForDialog}
          onClose={() => setOpenHighlight(false)}
          onValidate={onValidTemporalHighlighConfig}
          onDelete={deleteTempHighlight}
          onClear={deleteAllTempHighlight}
        />
      )}
    </div>
  );
};
