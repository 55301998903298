import React, { Component } from "react";
import { Fa } from "composants/Icon";

interface CardProps {
  title: string;
  footer: JSX.Element | JSX.Element[];
}
// Composant représentant un bloc de composant pour les mini-expert, creator, traitement etc...

class Card extends Component<CardProps> {
  constructor(props: CardProps) {
    super(props);
  }

  render() {
    return (
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">{this.props.title}</p>
          <a href="#" className="card-header-icon" aria-label="more options">
            <span className="icon">
              <Fa icon="angle-down" />
            </span>
          </a>
        </header>
        <div className="card-content">
          <div className="">{this.props.children}</div>
        </div>
        <footer className="card-footer">{this.props.footer}</footer>
      </div>
    );
  }
}

export default Card;
