import React, { FC, useEffect, useState } from "react";
import { Fa } from "composants/Icon";
import { getVersion } from "api/userSettings";

export const Version: FC<{}> = () => {
  const [version, setVersion] = useState<string>("0");

  useEffect(() => {
    getVersion()
      .then(response => setVersion(response.data))
      .catch(e => {
        console.error("error during fetch of version", e);
      });
  }, []);
  return (
    <>
      <span className="icon">
        <Fa icon={["fal", "code-branch"]} fixedWidth />
      </span>
      <span>{version}</span>
    </>
  );
};
