import React, { Component, SyntheticEvent, KeyboardEvent, FC, useMemo } from "react";
import classNames from "classnames";

import { SimpleComponentAndLabel } from "enum";
import { ComponentGroupState } from "types/ComponentGroup";
import { ComponentState } from "types/Component";
import { Pojo } from "types/Galaxy";
import { AutocompleteProps } from "../autocomplete/AutoComplete";
import { Row, Col } from "../Layout";
import { ParentComponentProps } from "../common";
import { getReadonlyValue, getOptionsByType } from "utils/component.utils";
import { uuidv4 } from "utils/uuid.utils";

interface GroupAndBlockProps {
  sjmoCode: string;
  parent?: ParentComponentProps;
  entity: any;
  readonly?: boolean;
  tableName?: string;
  // Le code correspond à une  classe de couleur (voir colors dans common.ts)
  wviState: { [ctrlKey: string]: { code: string; message: string } };
  contextMenu: Function;
  onBlur: (e: SyntheticEvent<any>) => void;
  onChange: (e: SyntheticEvent<any>) => void;
  onItemChange?: (selectedItem: Pojo, name?: string) => void;
  onValueChange?: (name: string | undefined, val: any) => void;
  onKeyDown?: (e: KeyboardEvent<any>) => void;
  excludePropFromCompo?: (compo: Partial<ComponentState>) => Partial<ComponentState>;
}

interface GroupProps extends GroupAndBlockProps {
  id?: string;
  classNames?: string;
  group: ComponentGroupState;
  groupSize: number;
  title?: string | JSX.Element;
  actions?: JSX.Element | JSX.Element[];
  styleLabel?: object;
}

interface BlockComponentProps extends GroupAndBlockProps {
  prependId?: string;
  isHorizontal?: boolean;
  compos: ComponentState[];
  styleLabel?: object;
}

export const BlockComponent: FC<BlockComponentProps> = props => {
  const prependId = useMemo(() => {
    return props.prependId ?? uuidv4();
  }, [props.prependId]);

  return (
    <>
      {props.compos.map(compo => {
        const SelectedComponent = SimpleComponentAndLabel[compo.typeCompo];
        const {
          typeCompo,
          contentSize,
          mandatory,
          joinTableName,
          joinListFields,
          joinDisplayedFields,
          additionalClause,
          sortClause,
          wvi,
          readOnly,
          disabled,
          compoVisible,
          isNumber,
          defaultValue,
          hasLov,
          ...restProps
        } = compo;

        const wviState = props.wviState[compo.column];

        const style = props.entity._style ? props.entity._style[compo.column] : {};

        // on ajoute la méthode onItemChange spécifique de la GS
        const propsGS: Partial<AutocompleteProps> =
          typeCompo === "GS"
            ? {
                joinTableName,
                joinListFields,
                additionalClause,
                sortClause,
                onItemChange: props.onItemChange,
                controlProps: { expanded: true },
                sjmoCode: props.sjmoCode,
                parent: props.parent,
                tableName: props.tableName,
                styleInput: style,
                hasLov
              }
            : {};

        let propsToPass;
        if (props.excludePropFromCompo) {
          propsToPass = props.excludePropFromCompo(restProps);
        } else {
          propsToPass = restProps;
        }

        const propsToSelectedComponent = {
          style,
          ...propsToPass,
          ...propsGS
        };
        const combinedReadOnly =
          getReadonlyValue(readOnly, props.entity.version) || props.readonly || false;
        const combinedDisabled = getReadonlyValue(disabled, props.entity.version) || false;

        const options = getOptionsByType(compo);

        return (
          <SelectedComponent
            key={compo.column}
            name={compo.column}
            isHorizontal={props.isHorizontal}
            {...propsToSelectedComponent}
            {...options}
            onChange={props.onChange}
            onValueChange={
              typeCompo === "ED" || typeCompo === "CH" ? props.onValueChange : undefined
            }
            onBlur={props.onBlur}
            onContextMenu={props.contextMenu(compo.column, props.tableName)}
            onKeyDown={props.onKeyDown && props.onKeyDown}
            id={`${prependId}-${compo.column}`}
            value={props.entity[compo.column]}
            required={mandatory}
            wviState={wviState ? wviState.code : null}
            wviMessage={wviState ? wviState.message : null}
            readOnly={combinedReadOnly}
            disabled={combinedDisabled}
            styleLabel={props.styleLabel}
          />
        );
      })}
    </>
  );
};

// Composant représentant un bloc de composant pour les mini-expert, creator, traitement etc...

class GroupComponent extends Component<GroupProps, any> {
  render() {
    return (
      <div className={classNames("box", this.props.classNames)} id={this.props.id}>
        {(this.props.actions || this.props.title) && (
          <Row>
            <Col span={8}>
              <span className="is-size-4 is-bold">{this.props.title}</span>
            </Col>
            <Col span={4}>
              <div className="is-pulled-right">{this.props.actions}</div>
            </Col>
          </Row>
        )}
        <BlockComponent
          prependId={this.props.id}
          compos={this.props.group.compos}
          styleLabel={this.props.styleLabel}
          {...this.props}
        />
      </div>
    );
  }
}

export default GroupComponent;
