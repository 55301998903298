import { apiAdn, getUIContext, apiTemplateServer } from "./common";
import { CancelTokenSource } from "axios";
/**
 * Permet de récupérer le nom du package a appeler pour afficher les informations du composant Information
 * @param id id de focus de datatable
 */
export function getInformation(
  {
    sjmoCode,
    target,
    entityId,
    valueAdditionnelle
  }: {
    sjmoCode: string;
    target: string;
    entityId: string;
    valueAdditionnelle?: string;
  },
  cancel?: CancelTokenSource
) {
  const params = getUIContext({ sjmoCode });
  // const uiContext: URLSearchParams = getUIContext(sjmoCode);
  params.append("sjpaId", target);
  params.append("entityId", entityId.toString());
  if (valueAdditionnelle) {
    params.append("valueAdditionnelle", valueAdditionnelle);
  }

  return apiAdn.get(`/information/content?${params}`, {
    cancelToken: cancel && cancel.token
  });
}

export function getInformationTemplateServer(
  params: {
    moduleCode: string;
    target: string;
    entityId: string;
    context: Record<string, any>;
    action: string | undefined;
  },
  cancel?: CancelTokenSource
) {
  const contextParams = new URLSearchParams();
  contextParams.append("id", params.entityId);
  contextParams.append("moduleCode", params.moduleCode);
  if (params.action) {
    contextParams.append("action", params.action);
  }

  return apiTemplateServer.post(
    `/panel/${params.target}?${contextParams.toString()}`,
    params.context,
    {
      cancelToken: cancel && cancel.token
    }
  );
}

export function getListTemplates() {
  return apiTemplateServer.get<string[]>("/panels");
}
