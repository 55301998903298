import React, {
  CSSProperties,
  useState,
  useContext,
  useEffect,
  FC,
  useMemo,
  useCallback
} from "react";
import classNames from "classnames";

import { getProcessusDefinition } from "api/processus";

import { t } from "utils/i18n";
import { ProcessusDefinition } from "types/Processus";

import { SizeProps, ColorProps, getSizeClasses, getColorsClasses } from "../common";

import "./ProcessusMenu.css";

import ProcessusLink from "./ProcessusLink";
import { ProcessusContext } from "./ProcessusProvider";
import { Trans } from "react-i18next";
import { Menu } from "composants/DropDown/Menu";
import { Fa } from "composants/Icon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ProcessusMenuProps extends SizeProps, ColorProps {
  isAnchor?: boolean;
  icon?: IconProp;
  outlined?: boolean;
  isRight?: boolean;
  style?: CSSProperties;
  buttonClassName?: string;
  disabled?: boolean;
}

type ProcessusAllProps = ProcessusMenuProps;

const ProcessusMenu: FC<ProcessusAllProps> = props => {
  const context = useContext(ProcessusContext);

  const [definition, setDefinition] = useState<ProcessusDefinition[]>([]);
  const [isHovering, setIsHovering] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  const { traitements, navigations, editions } = useMemo(() => {
    return {
      traitements: definition.filter(it => it.type === "traitement"),
      navigations: definition.filter(it => it.type === "navigation"),
      editions: definition.filter(it => it.type === "edition")
    };
  }, [definition]);

  const findProcess = useCallback(
    function findProcess() {
      if (context != null) {
        const { sjmoCode, tableName } = context;
        getProcessusDefinition(sjmoCode, tableName)
          .then(res => {
            setDefinition(res.data);
            setIsFetched(true);
          })
          .catch(err => {
            console.error("erreur lors de la récupération de la définition du processus");
            setIsFetched(true);
          });
      }
    },
    [context]
  );

  useEffect(() => {
    if (!isHovering || isFetched) {
      return;
    }
    let timeOutFetch = setTimeout(() => {
      findProcess();
    }, 0);
    return () => {
      clearTimeout(timeOutFetch);
    };
  }, [findProcess, isFetched, isHovering]);

  function onMouseEnter() {
    setIsHovering(true);
  }

  function onMouseLeave() {
    setIsHovering(false);
  }

  const sizeClass = getSizeClasses(props.size ? props.size : "");
  const colorClasses = getColorsClasses(props.color ? props.color : "");

  const parentClass = classNames("button", sizeClass, colorClasses, props.buttonClassName, {
    "is-outlined": props.outlined
  });

  return (
    <Menu autoclose>
      <Menu.Button
        // as={props.isAnchor ? "a" : "button"}
        className={parentClass}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        title="Processus"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        disabled={props.disabled}
      >
        {props.icon && <Fa icon={props.icon} />}
        {props.children}
      </Menu.Button>
      <Menu.Overlay>
        {definition.length === 0 && isFetched && (
          <div className="p-7">
            <Trans i18nKey="commun_aucun_processus" />
          </div>
        )}
        <ProcessusMenuItem definition={traitements} type={"traitement"} />
        <ProcessusMenuItem definition={navigations} type={"navigation"} />
        <ProcessusMenuItem definition={editions} type={"edition"} />
      </Menu.Overlay>
    </Menu>
  );
};

const ProcessusMenuItem: FC<{
  definition: ProcessusDefinition[];
  type: ProcessusDefinition["type"];
  entityId?: string | string[] | null;
}> = ({ definition, type }) => {
  if (definition.length <= 0) {
    return null;
  }

  const title = getTraductionGroupDefinition(type);
  // si on ne connait pas le type, on affiche rien
  if (title == null) return null;

  return (
    <div>
      {title && <strong className="ml-7">{title}</strong>}
      {definition.map(process => (
        <ProcessusLink
          key={process.id}
          className="dropdown-item processmenu-hover-show-action"
          definition={process}
          canDelayProcess
        />
      ))}
    </div>
  );
};

function getTraductionGroupDefinition(type: ProcessusDefinition["type"]) {
  switch (type) {
    case "traitement":
      return t("commun_traitement");

    case "navigation":
      return t("commun_navigation_interne");

    // case ProcessusType.NAV_EXT:
    //   label = t("commun_navigation_externe");
    //   if (typeof props.entityId === "string") {
    //     navExtContext = props.entityId;
    //   } else if (typeof props.entityId === "object") {
    //     // Si on seletionne plusieurs objets ayant respectivement poour id "a", "b" et "c" on veut "(a,b,c)" dans l'url de navigation externe
    //     navExtContext = `(${props.entityId?.toString()})`;
    //   }
    //   break;

    case "edition":
      return t("commun_editions");

    default:
      // si l'affectation n'existe pas, on ne render rien
      return null;
  }
}

export default ProcessusMenu;
