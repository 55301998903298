import React, { Component, SFC } from "react";
import Input from "../input/Input";
import { FieldHorizontal } from "../form/Form";
import classNames from "classnames";
import {
  ComposantAllProps,
  ColorProps,
  SizeProps,
  getColorsClasses,
  getSizeClasses
} from "composants/common";
import { format } from "date-fns";

export interface CalendarHoursProps extends ComposantAllProps {
  defaultAppearance?: boolean;
}

export const CalendarHours: SFC<CalendarHoursProps> = ({
  value,
  defaultAppearance,
  ...restProps
}) => {
  const finalValue = format(value, "YYYY-MM-DDTHH:mm");
  return (
    <Input
      {...restProps}
      data-initial-appearance={defaultAppearance}
      type="datetime-local"
      value={value ? finalValue : ""}
      data-value={value ? finalValue : ""}
    />
  );
};

export default CalendarHours;
