import React, { FC, useMemo, useContext, useEffect, useReducer } from "react";
import {
  ServerComponent,
  ServerComponentAst,
  Grid,
  Columns,
  Column,
  Stack,
  ButtonGroup,
  Button,
  ButtonLink,
  Link,
  Avatar,
  Loader,
  Notice,
  Tooltip,
  Card,
  Badge,
  Input,
  Select,
  Checkbox,
  Switch,
  Textarea,
  Steps,
  Alert,
  Menu,
  gridPos
} from "@axin-org/comet";
import { apply, tw } from "twind";

import {
  ResponsiveContainer,
  LineChart,
  AreaChart,
  Area,
  Line,
  BarChart,
  Bar,
  RadialBarChart,
  RadialBar,
  ComposedChart,
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  PieChart,
  Pie,
  ScatterChart,
  Scatter,
  ReferenceLine,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  LabelList,
  Label,
  Text as ChartText,
  Cell,
  ResponsiveContainerProps
} from "recharts";

import { Link as RouterLink } from "react-router-dom";
import Axios, { CancelTokenSource } from "axios";

import { Fa } from "composants/Icon";
import { convertValue } from "utils/entities.utils";
import { getInformationTemplateServer } from "api/information";

import { GalaxieListenerCallbackContext } from "containers/galaxy/GalaxieContextListener";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import ProcessusLink from "composants/processus/ProcessusLink";
import { ProcessusButton } from "composants/processus/ProcessusButton";
import { GalaxieContext } from "containers/galaxy/Galaxie";
import useInteractions from "hooks/useInteractions";
import auth from "auth";
import { URL_DOWNLOAD_VIA_GED } from "customGlobal";
import { ProcessusDefinition } from "types/Processus";

import { getProcessusDefinitionByCompositeId } from "api/processus";
import { ActionTypeData } from "reducers/Action";
import { Action } from "redux";
import { useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import lodashTemplate from "lodash-es/template";

const HEADING_STYLES = {
  h1: apply`text-5xl`,
  h2: apply`text-4xl`,
  h3: apply`text-3xl`,
  h4: apply`text-2xl`,
  h5: apply`text-xl`,
  h6: apply`text-lg`
} as const;

function withResponsiveContainer<P extends object>(WrappedComponent: React.ComponentType<P>) {
  if (WrappedComponent === undefined || WrappedComponent === null) {
    throw new Error("cannot use withLabel with and undefined or null component");
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || "Component";
  const displayName = `ResponsiveContainer(${wrappedComponentName})`;

  const WithResponsiveContainer: FC<P & ResponsiveContainerProps> = props => {
    const {
      width,
      height,
      minWidth,
      minHeight,
      debounce = 100,
      aspect,
      children,
      ...restProps
    } = props as ResponsiveContainerProps;
    return (
      <ResponsiveContainer
        aspect={aspect}
        width={width}
        height={height}
        minWidth={minWidth}
        minHeight={minHeight}
        debounce={debounce}
      >
        <WrappedComponent {...(restProps as P)}>{children}</WrappedComponent>
      </ResponsiveContainer>
    );
  };
  WithResponsiveContainer.displayName = displayName;

  return WithResponsiveContainer;
}

const CustomButton: FC = (props: any) => {
  const action = useAction();

  function onClick() {
    action(props.action);
  }

  return <Button {...props} onClick={onClick} />;
};

const RechartCustomizedLabel: FC<{
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  value: number;
  index: number;
  colors?: string[];
  template: string;
}> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value,
  index,
  colors,
  template = "{{value}}"
}) => {
  const RADIAN = Math.PI / 180;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx ? cx + radius * Math.cos(-midAngle * RADIAN) : 0;
  const y = cy ? cy + radius * Math.sin(-midAngle * RADIAN) : 0;

  const compiled = useMemo(() => {
    return lodashTemplate(template, {
      interpolate: /{{([\s\S]+?)}}/g
    });
  }, [template]);

  return (
    <text
      x={x}
      y={y}
      fill={colors && colors.length >= index ? colors[index] : undefined}
      text-anchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {compiled({ cx, cy, midAngle, innerRadius, outerRadius, value, index, colors })}
    </text>
  );
};

const CustomInput: FC = (props: any) => {
  const { value, update } = useEditableValue(props.name, props.value ?? "");
  return <Input {...props} value={value} onChange={e => update(props.name, convertValue(e))} />;
};

const CustomSelect = (props: any) => {
  const { value, update } = useEditableValue(props.name, props.value ?? null);
  const action = useAction();

  function onChange(e: React.ChangeEvent<HTMLSelectElement>) {
    update(props.name, convertValue(e));
    action(props.action);
  }

  return <Select {...props} value={value} onChange={onChange} />;
};

const CustomMenuItem = ({ action, ...props }: any) => {
  const sendAction = useAction();

  function onSelect() {
    props.onSelect && props.onSelect();
    sendAction(action);
  }

  return <Menu.Item {...props} onSelect={onSelect} />;
};

interface ReducerCustomProcessusLinkState {
  definition: ProcessusDefinition | null;
  status: "initial" | "loaded" | "empty";
}
type ReducerCustomProcessusLinkAction =
  | ActionTypeData<"loaded", ProcessusDefinition>
  | Action<"empty">;

function reducerCustomProcessuslink(
  state: ReducerCustomProcessusLinkState = { definition: null, status: "initial" },
  action: ReducerCustomProcessusLinkAction
): ReducerCustomProcessusLinkState {
  switch (action.type) {
    case "loaded":
      return {
        definition: action.payload,
        status: "loaded"
      };

    case "empty":
      return {
        definition: null,
        status: "empty"
      };
    default:
      return state;
  }
}

const CustomProcessusLink: FC<{
  entityIds: string | string[];
  tableName: string;
  id: string;
  label: string;
} & React.ComponentProps<"a">> = ({ entityIds, tableName, id, label, ...props }) => {
  const galaxy = useContext(GalaxieContext);

  const [state, dispatch] = useReducer(reducerCustomProcessuslink, {
    definition: null,
    status: "initial"
  });

  useEffect(() => {
    getProcessusDefinitionByCompositeId(galaxy.sjmoCode ?? "defaultSjmoCode", id)
      .then(res => {
        if (res.data) {
          if (label) res.data.label = label;
          dispatch({ type: "loaded", payload: res.data });
        } else {
          dispatch({ type: "empty" });
        }
      })
      .catch(() => {
        console.error("error during fetch of processus definition");
        dispatch({ type: "empty" });
      });
  }, [galaxy.sjmoCode, id]); // label on en veut pas

  return (
    <ProcessusProvider
      sjmoCode={galaxy.sjmoCode || "DEFAULT"}
      tableName={tableName || galaxy.mainEntityTableName || "default"}
      selected={entityIds}
      onAfterSaveProcess={galaxy.refreshListenerAndCallback}
    >
      {state.status === "loaded" && state.definition && (
        <ProcessusButton
          definition={state.definition}
          children={props.children ?? state.definition.label}
        />
        // <ProcessusLink definition={state.definition} {...props} />
      )}
      {state.status === "empty" && (
        <button {...(props as any)}>
          erreur configuration processus <pre>{id}</pre>
        </button>
      )}
    </ProcessusProvider>
  );
};

CustomProcessusLink.defaultProps = {
  entityIds: [],
  style: { marginBottom: 5 }
};

const components = {
  Box: ({ as: Comp = "div", className, ...props }: any) => (
    <Comp className={tw(className)} {...props} />
  ),
  Grid,
  GridCol: ({ start, end, span, className, ...props }: any) => {
    return (
      <div
        className={tw(gridPos({ colStart: start, colEnd: end, colSpan: span, className }))}
        {...props}
      />
    );
  },
  Columns,
  Column,
  Stack,
  ButtonGroup,
  Button: CustomButton,
  button: CustomButton,
  ButtonLink: ({ children, to, ...props }: any) => (
    <ButtonLink {...props} asChild>
      <RouterLink to={to}>{children}</RouterLink>
    </ButtonLink>
  ),
  Link: ({ children, to, ...props }: any) => (
    <Link {...props} asChild>
      <RouterLink to={to}>{children}</RouterLink>
    </Link>
  ),
  Avatar,
  Image: (props: any) => <img {...props} className={tw(props.className)} />,
  ImageDoc: (props: any) => {
    const src =
      props.documentId === undefined || props.documentId === null || props.documentId === ""
        ? "https://bulma.io/images/placeholders/128x128.png"
        : `${URL_DOWNLOAD_VIA_GED()}/document/${props.documentId}?access_token=${auth.token}`;

    return <img {...props} className={tw(props.className)} src={src} />;
  },
  Loader,
  Notice,
  Tooltip,
  Heading: ({ as: Comp = "h1", className, ...props }: any) => {
    const selected = HEADING_STYLES[Comp];
    return <Comp className={tw(className, selected)} {...props} />;
  },
  Card,
  Badge,
  Steps: Steps,
  StepItem: Steps.Step,
  Menu: Menu,
  menu: Menu,
  MenuButton: (props: any) => (
    <Menu.Trigger asChild>
      <Button {...props} />
    </Menu.Trigger>
  ),
  MenuList: Menu.Content,
  MenuItem: CustomMenuItem,
  menuitem: CustomMenuItem,
  Icon: (props: any) => (
    <span>
      <Fa {...props} />
    </span>
  ),
  Input: CustomInput,
  input: CustomInput,
  Select: CustomSelect,
  select: CustomSelect,
  Checkbox: (props: any) => {
    const { value, update } = useEditableValue(props.name, props.value ?? null);
    return (
      <Checkbox {...props} checked={value} onChange={e => update(props.name, convertValue(e))} />
    );
  },
  Switch: (props: any) => {
    const { value, update } = useEditableValue(props.name, props.value ?? false);
    return (
      <Switch {...props} checked={value} onChange={e => update(props.name, convertValue(e))} />
    );
  },
  Textarea: (props: any) => {
    const { value, update } = useEditableValue(props.name, props.value ?? "");
    return (
      <Textarea {...props} value={value} onChange={e => update(props.name, convertValue(e))} />
    );
  },
  AutoRefresh: (props: any) => {
    const { action } = useContext(TemplateContext);
    useEffect(() => {
      const interval = setInterval(() => {
        action();
      }, props.ms);

      return () => clearInterval(interval);
    }, [props.ms, action]);
    return null;
  },
  State: (props: any) => {
    const { init } = useContext(TemplateContext);
    useEffect(() => {
      const keys = Object.keys(props);
      for (let key of keys) {
        init(key, props[key]);
      }
    }, [init, props]);

    return null;
  },
  RawHtml: ({ children }: any) => {
    return <div dangerouslySetInnerHTML={{ __html: children }} />;
  },
  ProcessusLink: CustomProcessusLink,
  Alert,
  // RECHART START --------------------------------------------------------------------------------
  RadialBarChart,
  ResponsiveRadialBarChart: withResponsiveContainer(RadialBarChart),
  ComposedChart,
  ResponsiveComposedChart: withResponsiveContainer(ComposedChart),
  RadarChart,
  ResponsiveRadarChart: withResponsiveContainer(RadarChart),
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  PieChart,
  ResponsivePieChart: withResponsiveContainer(PieChart),
  Pie,
  LineChart,
  ResponsiveLineChart: withResponsiveContainer(LineChart),
  Line,
  AreaChart,
  ResponsiveAreaChart: withResponsiveContainer(AreaChart),
  AreaElement: Area,
  BarChart,
  ResponsiveBarChart: withResponsiveContainer(BarChart),
  Bar,
  ScatterChart,
  ResponsiveScatterChart: withResponsiveContainer(ScatterChart),
  Scatter,
  ResponsiveContainer,
  RadialBar,
  ResponsiveRadialBar: withResponsiveContainer(RadialBar),
  ReferenceLine,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  ChartTooltip,
  ChartLegend: Legend,
  LabelList,
  Label,
  ChartText,
  Cell,
  // RECHART END ----------------------------------------------------------------------------------
  RechartCustomizedLabel // CUSTOM COMPONENT FOR RECHART
};

type TemplateContextType = {
  data: Record<string, any>;
  init: (name: string, value: any) => void;
  update: (name: string, value: any) => void;
  action: (name?: string) => void;
};

const TemplateContext = React.createContext<TemplateContextType>({
  data: {},
  init: () => {},
  update: () => {},
  action: () => {}
});

function useAction() {
  const context = useContext(TemplateContext);

  return context.action;
}

function useEditableValue(name: string, defaultValue: any = "") {
  const { data, init, update } = useContext(TemplateContext);

  const isInitialized = data[name] !== undefined;
  useEffect(() => {
    if (!isInitialized) {
      init(name, defaultValue);
    }
  }, [defaultValue, init, isInitialized, name]);

  return useMemo(() => {
    return { value: data[name], update };
  }, [data, name, update]);
}

async function fetchInformationTemplate(
  moduleCode: string,
  target: string,
  entityId: string | null,
  context: Record<string, any>,
  action: string | undefined,
  cancel?: CancelTokenSource
): Promise<ServerComponentAst | null> {
  if (entityId != null) {
    try {
      const res = await getInformationTemplateServer(
        { moduleCode, target, entityId, context, action },
        cancel
      );
      return res.data;
    } catch (e) {
      if (!Axios.isCancel(e)) {
        console.error(
          "error during fetch of information for ",
          moduleCode,
          "on the panel ",
          target
        );
        if (e.response.data.hasError) {
          return Promise.reject(e.response.data);
        }
        return Promise.reject({ title: null, message: null });
      }
      return null;
    }
  } else {
    return null;
  }
}

const TEMPLATE_CACHE_QUERY = { retry: 5, staleTime: 800 };

export const InformationTemplate: FC<{
  moduleCode: string;
  panelId: string;
  templateIdentifier: string;
  entityId: string | null;
}> = ({ moduleCode, panelId, templateIdentifier, entityId }) => {
  const interaction = useInteractions({ sjmoCode: moduleCode, ctrlKey: panelId });
  const [data, setData] = useState<Record<string, any>>({});

  const selectedId = useMemo(() => {
    return interaction?.id ?? entityId;
  }, [entityId, interaction]);

  const { data: ast, error, status, refetch } = useQuery(
    ["serverTemplate", moduleCode, templateIdentifier, selectedId],
    () => fetchInformationTemplate(moduleCode, templateIdentifier, selectedId, data, undefined),
    TEMPLATE_CACHE_QUERY
  );
  const queryClient = useQueryClient();

  const register = useContext(GalaxieListenerCallbackContext);
  useEffect(() => {
    const unregister = register(() => {
      refetch();
    });
    return () => unregister();
  }, [refetch, register]);

  const context = useMemo<TemplateContextType>(() => {
    return {
      state: ast,
      data: data,
      update: (name, value) => {
        setData(data => ({ ...data, [name]: value }));
      },
      init: (name, value) => {
        setData(data => ({ ...data, [name]: value }));
      },
      action: name => {
        if (name == null || name === undefined) return;

        const token = Axios.CancelToken.source();
        return queryClient.fetchQuery(
          ["serverTemplate", moduleCode, templateIdentifier, selectedId],
          () => {
            const promise = fetchInformationTemplate(
              moduleCode,
              templateIdentifier,
              selectedId,
              data,
              name,
              token
            );
            (promise as any).cancel = () => token.cancel();

            return promise;
          },
          TEMPLATE_CACHE_QUERY
        );
      }
    };
  }, [ast, data, moduleCode, queryClient, selectedId, templateIdentifier]);

  return (
    <TemplateContext.Provider value={context}>
      {ast && ast !== null && status !== "error" && (
        <ServerComponent components={components} ast={ast} />
      )}
      {status === "error" && error && (
        <Alert intent="warning" title={((error as any).error as string) ?? "Error widget"}>
          <Fa icon="engine-warning" />
          <p>{(error as any).message as string}</p>
          <Button variant="light" intent="warning" onClick={() => refetch()}>
            reload
          </Button>
        </Alert>
      )}
    </TemplateContext.Provider>
  );
};
