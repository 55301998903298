import React, { FC, useRef, useState, CSSProperties, useEffect, useCallback } from "react";
import { Task } from "../FullCalendarContainer";
import GenericMarkdownDisplay from "composants/genericDisplay/GenericMarkdownDisplay";
import { Fa } from "composants/Icon";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import ProcessusMenu from "composants/processus/ProcessusMenu";
import { getStylesPortal } from "hooks/useOverlayPosition";
import { Portal } from "composants/Portal";
import { Trans } from "react-i18next";
import { Menu } from "composants/DropDown/Menu";
import { format } from "date-fns";
import { Button, ButtonGroup, Column, Columns, Stack } from "@axin-org/comet";
import { tw } from "twind";
import customHistory from "customHistory";
import { openSatellite } from "utils/navigation.utils";
import { COMMENTAIRES } from "constant/satellite";
import { ON_CLOSE_MINIEXPERT_CALLBACK } from "constant/miniexpert";

interface SchedulerTaskDetailProps {
  task: Task;
  targetRect: ClientRect;
  sjmoCode: string;
  onClose(dateClick: boolean): void;
  addPin(): void;
  deleteTask(): void;
  refreshAfterProcess(): void;
}

const SchedulerTaskDetail: FC<SchedulerTaskDetailProps> = props => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [styles, setStyles] = useState<CSSProperties>({});
  const [isActive, setIsActive] = useState(false);

  const { onClose } = props;
  useEffect(() => {
    function onCloseEventListener(e: any) {
      const el = e.target && e.target.closest("#schedulerTaskDetail"); // Clique dans l'overlay
      const taskEl = e.target && e.target.closest(".fc-event"); // Clique dans une tache
      const dateEl = e.target && e.target.className === "fc-timeline-slots"; // Clique sur une date

      // On ferme l'overlay si l'utilisateur a cliqué en dehors de l'overlay
      // ET en dehors d'une tache
      if (isActive && (!el || el !== ref.current) && !taskEl) {
        // Si l'utilisateur à cliquer dans une tache on le spécifie à la fermeture pour traité ce cas précis
        onClose(dateEl);
      }
    }

    document.addEventListener("click", onCloseEventListener);

    setIsActive(true);
    return function cleanup() {
      document.removeEventListener("click", onCloseEventListener);
    };
  }, [isActive, onClose]);

  // Calcul des positions et tailles de l'overlay
  useEffect(() => {
    if (ref.current)
      setStyles(getStylesPortal(props.targetRect, ref.current.getBoundingClientRect(), false));
  }, [props.targetRect]);

  const toSatellite = useCallback(() => {
    const params = openSatellite({
      sjmoCode: props.sjmoCode,
      tableName: props.task.tableName as string,
      contextId: props.task.id,
      satelliteName: COMMENTAIRES
    });
    customHistory.push({
      search: params,
      state: {
        origin: customHistory.location.pathname
      }
    });
  }, [props.sjmoCode, props.task.id, props.task.tableName]);

  const openMiniExpert = () => {
    if (props.task.tableName && props.task.tableName) {
      const params = new URLSearchParams();
      params.append("miniExpertTableName", props.task.tableName);
      params.append("miniExpertSjmoCode", props.sjmoCode ? props.sjmoCode : "");
      params.append("miniExpertEntityId", props.task.id as string);

      (window as any)[ON_CLOSE_MINIEXPERT_CALLBACK] = () => props.refreshAfterProcess();

      return customHistory.push({
        search: params.toString(),
        state: {
          origin: customHistory.location.pathname
        }
      });
    }
  };

  return (
    <Portal>
      <div
        id="schedulerTaskDetail"
        ref={ref}
        className="box"
        style={{
          ...styles,
          position: "absolute",
          zIndex: 1
        }}
      >
        <Columns gap={3} style={{ minWidth: 250 }}>
          <Column className={tw("bg-gray-200 rounded-md p-2")}>
            <GenericMarkdownDisplay value={props.task.tooltip ? props.task.tooltip : ""} />
          </Column>
          <Column>
            <Stack>
              <GenericMarkdownDisplay value={props.task.title} />
              <div>
                <Trans i18nKey="commun_date_debut">Début</Trans> :
              </div>
              <div className={tw("mb-2 text-gray-600")}>
                {format(props.task.start as string, "DD/MM/YYYY HH:mm")}
              </div>
              <div>
                <Trans i18nKey="commun_date_fin">Fin</Trans> :
              </div>
              <div className={tw("mb-2 text-gray-600")}>
                {format(props.task.end as string, "DD/MM/YYYY HH:mm")}
              </div>
              <ButtonGroup>
                <Button onClick={props.addPin} variant="text" intent="primary" className="p-0">
                  <Fa icon="thumbtack" />
                </Button>

                <Button onClick={openMiniExpert} variant="text" intent="primary" className="p-0">
                  <Fa icon="pencil" />
                </Button>

                <Menu autoclose>
                  <Menu.Button className="button p-0 b-0 has-text-link">
                    <Fa icon="trash" />
                  </Menu.Button>
                  <Menu.Overlay>
                    <Menu.Item>
                      <Trans key="commun_sur_de_suprimer">
                        Êtes-vous sûr de vouloir supprimer ?
                      </Trans>
                      <button
                        className="button is-danger is-fullwidth is-small"
                        onClick={() => {
                          props.deleteTask();
                        }}
                      >
                        <Trans key="commun_oui">Oui</Trans>
                      </button>
                    </Menu.Item>
                  </Menu.Overlay>
                </Menu>
                <Button onClick={toSatellite} variant="text" intent="primary" className="p-0">
                  <Fa icon="satellite" />
                </Button>
                {props.task.tableName && (
                  <span className="level-item" aria-label="processus">
                    <ProcessusProvider
                      sjmoCode={props.sjmoCode}
                      tableName={props.task.tableName}
                      selected={props.task.id}
                      onAfterSaveProcess={() => {
                        props.refreshAfterProcess();
                      }}
                    >
                      <ProcessusMenu
                        isRight={true}
                        icon="cogs"
                        buttonClassName="p-0 b-0 has-text-link"
                      />
                    </ProcessusProvider>
                  </span>
                )}
              </ButtonGroup>
            </Stack>
          </Column>
        </Columns>
      </div>
    </Portal>
  );
};

export default SchedulerTaskDetail;
