import React, { FC, CSSProperties } from "react";
import classNames from "classnames";

import { ComposantAllProps, getColorsClasses, getSizeClasses } from "../common";
import { Item, ItemGrp } from "types/Component";
import { excludeFromProps } from "utils/component.utils";

export interface SelectProps extends ComposantAllProps {
  sysDomaineChoices: Item[] | ItemGrp[];
  multiple?: boolean;
  loading?: boolean;
  required?: boolean;
}

const Option: FC<Item> = ({ label, value }) => {
  return <option value={value}>{label}</option>;
};

const renderOptions = (sysDomaineChoices: Item[] = []) => {
  if (sysDomaineChoices === null) {
    return [];
  }

  if (sysDomaineChoices.length <= 0) {
    return [];
  }

  return sysDomaineChoices.map(item => <Option key={item.value} {...item} />);
};

const OptGroup: FC<{ groups: ItemGrp[] }> = ({ groups = [] }) => {
  return (
    <>
      {groups.map(({ label, items }, i) => {
        return (
          <optgroup key={i} label={label}>
            {renderOptions(items)}
          </optgroup>
        );
      })}
    </>
  );
};

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      id,
      name,
      value,
      onContextMenu,
      sysDomaineChoices = [],
      readOnly,
      disabled,
      size,
      wviState,
      className,
      tooltip,
      required,
      ...restProps
    },
    ref
  ) => {
    // La classe définissant la couleur dans un input est définie par le retour du wvi
    const colorClasses = getColorsClasses(wviState ? wviState : "");
    const sizeClasses = getSizeClasses(size ? size : "");
    const classes = classNames("select", className, colorClasses, sizeClasses, {
      "is-multiple": restProps.multiple,
      "is-loading": restProps.loading
    });

    let notNullValue = value ? value : "";
    const finalDisabled = readOnly || disabled;

    const propsToSpread = excludeFromProps(
      restProps as any,
      "joinDisplayedFields",
      "joinTableName",
      "joinListFields",
      "joinListFiltre"
    );
    return (
      <div className={classes}>
        <select
          {...propsToSpread}
          ref={ref}
          id={id}
          multiple={restProps.multiple}
          value={notNullValue}
          data-value={notNullValue}
          disabled={finalDisabled}
          onContextMenu={onContextMenu}
          title={tooltip}
          name={name}
        >
          <option />
          {sysDomaineChoices.length > 0 && (sysDomaineChoices[0] as ItemGrp).items !== undefined ? (
            <OptGroup groups={sysDomaineChoices as ItemGrp[]} />
          ) : (
            renderOptions(sysDomaineChoices as Item[])
          )}
        </select>
      </div>
    );
  }
);

export const InlineSelect: FC<SelectProps & { style?: CSSProperties }> = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  onContextMenu,
  sysDomaineChoices = [],
  readOnly,
  disabled,
  size,
  wviState,
  className,
  tooltip,
  required,
  style,
  ...restProps
}) => {
  // La classe définissant la couleur dans un input est définie par le retour du wvi
  const colorClasses = getColorsClasses(wviState ? wviState : "");
  const sizeClasses = getSizeClasses(size ? size : "");
  const classes = classNames("is-checkradio", className, colorClasses, sizeClasses, {
    "is-multiple": restProps.multiple,
    "is-loading": restProps.loading
  });

  const finalDisabled = readOnly || disabled;

  const propsToSpread = excludeFromProps(
    restProps as any,
    "joinDisplayedFields",
    "joinTableName",
    "joinListFields",
    "joinListFiltre"
  );
  return (
    <div id={id} className="control">
      {(sysDomaineChoices as Item[]).map(choice => {
        let radioChoiceId = id + "-" + choice.value;
        return (
          <React.Fragment key={choice.label}>
            <input
              className={classes}
              id={radioChoiceId}
              name={name}
              type="radio"
              value={choice.value}
              checked={choice.value === value}
              data-value={choice.value || ""}
              onChange={onChange}
              onBlur={onBlur}
              onContextMenu={onContextMenu}
              disabled={finalDisabled}
              {...propsToSpread}
            />
            <label htmlFor={radioChoiceId}>{choice.label}</label>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Select;
