import React, { FC, SyntheticEvent, useState, useCallback, useEffect } from "react";
import { Pojo } from "types/Galaxy";
import Modal from "composants/Modal/Modal";
import Picklist from "composants/pickList/Picklist";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox, Column, Columns } from "@axin-org/comet";

export interface SelectFieldsModalProps {
  title: string;
  defaultChosenFields: Pojo[];
  allFields: Pojo[];
  onValidate(chosen: Pojo[], useTrad: boolean): void;
  onClose(e: SyntheticEvent<HTMLElement>): void;
}

const SelectFieldsModal: FC<SelectFieldsModalProps> = props => {
  const [t] = useTranslation();
  const [noTrad, setNoTrad] = useState<boolean>(false);
  const [state, setstate] = useState<{ available: Pojo[]; chosen: Pojo[] }>({
    available: [],
    chosen: []
  });

  useEffect(() => {
    setstate({ available: props.allFields, chosen: props.defaultChosenFields });
  }, [props.allFields, props.defaultChosenFields]);

  const onValueChange = useCallback((values: { AVAILABLE: Pojo[]; CHOSEN: Pojo[] }) => {
    setstate({ available: values.AVAILABLE, chosen: values.CHOSEN });
  }, []);

  return (
    <Modal
      title={t(props.title)}
      onClose={props.onClose}
      onValidate={e => props.onValidate(state.chosen, !noTrad)}
      height="70vh"
    >
      <div style={{ height: "60vh" }}>
        <Picklist available={state.available} chosen={state.chosen} onValueChange={onValueChange} />
      </div>
      <Columns style={{ marginTop: "25px", position: "relative" }}>
        <Column>
          <Trans key="commun_nom_technique">Nom technique</Trans>
        </Column>
        <Column>
          <Checkbox
            checked={noTrad}
            onCheckedChange={val => val !== "indeterminate" && setNoTrad(val)}
            style={{ margin: "5px", border: "solid 1px" }}
          />
        </Column>
      </Columns>
    </Modal>
  );
};

export default SelectFieldsModal;
