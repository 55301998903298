import React, {
  SFC,
  useCallback,
  SyntheticEvent,
  useState,
  useMemo,
  MouseEvent,
  useEffect
} from "react";
import Modal from "composants/Modal/Modal";
import history from "customHistory";
import { useTranslation } from "react-i18next";
import GroupComponent from "composants/group/GroupComponent";
import { ProcessusComponentState } from "reducers/modules/processus/ProcessusReducer";
import { excludeFromProps } from "utils/component.utils";
import { Pojo } from "types/Galaxy";
import { Field } from "composants/form/Form";
import Control from "composants/form/Control";
import Checkbox from "composants/checkbox/Checkbox";
import LoaderContainer from "composants/LoaderContainer";
import { convertValue } from "utils/entities.utils";

import {
  validateParamAvance,
  initProcessusAdvanced,
  executeProcessusAdvanced
} from "api/processus";
import { getEntityLabels } from "api";
import { ComponentGroupState } from "types/ComponentGroup";
import formMachine, { FormMachineEvents, isFormLoadingState } from "machine/FormMachine";
import { interpret } from "xstate";
import { useMachine, asEffect } from "@xstate/react";
import {
  ProcessusDefinitionNature,
  ProcessusAdvancedFormInit,
  ProcessusResult
} from "types/Processus";
import {
  createCheckProcessusMachine,
  notifyAfterProcess,
  useRegisterProcessus,
  useStableProcessusId
} from "features/processus/processusManager";
import produce from "immer";
import toaster from "composants/toaster/toaster";

function contextMenu(ctrlKey: string) {
  return (event: MouseEvent<any>) => {
    event.preventDefault();
  };
}

interface ParamAvanceDialogProps {
  sjmoCode: string;
  type: ProcessusDefinitionNature;
  compositeId: string;
  navigationUrl?: string | null;
  selected: Pojo[] | undefined;
  delay?: string;
  label?: string;
  editionType?: "rapide" | "apercu";
  forcedForAll?: boolean;
  callback?: () => void;
}

function useEntityLabel(module: string, tableName: string, id: string | undefined) {
  const [label, setLabel] = useState("");

  useEffect(() => {
    if (tableName && id) {
      getEntityLabels(module, tableName, id)
        .then(response => setLabel(`${response.data.table} : ${response.data.entity}`))
        .catch(() => setLabel(""));
    } else {
      setLabel("");
    }
  }, [id, module, tableName]);

  return label;
}

const ParamAvanceDialog: SFC<ParamAvanceDialogProps> = props => {
  const [indexEntity, setIndexEntity] = useState(0);
  const [forAll, setForAll] = useState(false);
  const [formData, setFormData] = useState<Pojo[]>([]);
  const [definition, setDefinition] = useState<ProcessusAdvancedFormInit | null>(null);
  const [managerState, { register: registerProcessus }] = useRegisterProcessus();
  const [isLoading, setIsLoading] = useState(false);

  const stableID = useStableProcessusId(
    props.compositeId,
    props.selected,
    formData.slice(indexEntity)
  );
  const machineId = managerState.context.info[stableID];
  const currentProcessusMachine = managerState.context.processus[machineId ?? ""];
  const hasProcessusStateSpawned =
    currentProcessusMachine !== undefined &&
    !currentProcessusMachine.getSnapshot().matches("error") &&
    !currentProcessusMachine.getSnapshot().matches("done");

  // Si on met un délai, on ferme la fennêtre à l'enregistrement du processus et non après l'éxécution de celui-ci
  useEffect(() => {
    if (hasProcessusStateSpawned && props.delay) {
      setIsLoading(false);
      history.goBack();
    }
  }, [hasProcessusStateSpawned, props]);

  const [formState, send] = useMachine(formMachine, {
    actions: {
      "ext:modifyPojo": asEffect((context, event) => {
        if (context.entity == null || event.type !== "CHANGE") return;
        const field = event.field;

        setFormData(
          produce(formData, draft => {
            draft[indexEntity][field] = event.value;
          })
        );
      }),
      saveNotification: () => {}
    },
    services: {
      validate: (context, event: FormMachineEvents) => {
        return new Promise<Record<string, any> | void>((resolve, reject) => {
          if (context.entity != null && (event.type === "CHANGE" || event.type === "BLUR")) {
            const field = event.field;
            validateParamAvance(
              context.entity,
              props.compositeId,
              allComponentDefinitions[field].ctrlValid,
              field,
              success => {
                // on met la zone en vert, pas de message
                resolve({
                  entity: success.data,
                  field: field,
                  message: { message: "", type: "success" }
                });

                setFormData(
                  produce(formData, draft => {
                    draft[indexEntity] = success.data as Pojo;
                  })
                );
              },
              data => {
                if (data.entity) {
                  setFormData(
                    produce(formData, draft => {
                      draft[indexEntity] = data.entity;
                    })
                  );
                }

                reject({ field: field, ...data });
              }
            );
          } else {
            resolve();
          }
        });
      },
      save: (context, event: FormMachineEvents) => {
        if (!definition) return Promise.resolve(context.entity);

        let entities: Record<string, any>[];
        let paramsAdd: Pojo[];

        if (props.forcedForAll || forAll) {
          // on slice parce que l'utilisateur pourrait très bien avoir executé X entité avant de faire le forAll
          entities = definition.data.entities.slice(indexEntity);
          paramsAdd = formData.slice(indexEntity);
        } else {
          // on récupère qu'une seule valeur ici avec le slice
          entities = definition.data.entities.slice(indexEntity, indexEntity + 1);
          paramsAdd = formData.slice(indexEntity, indexEntity + 1);
        }

        if (props.delay && props.label) {
          // En cas de délai on enregistre le processus pour plus tard au lieu de l'éxécuter tout de suite
          return () => {
            registerProcessus(
              {
                module: props.sjmoCode,
                compositeID: props.compositeId,
                type: props.type,
                selected: props.selected ?? [],
                paramsAdd: paramsAdd,
                executeAt: props.delay,
                label: props.label as string,
                editionType: props.editionType
              },
              () => {
                props.callback && props.callback();
              }
            );
            return new Promise<void>(resolve => {
              resolve();
            }).then(() => context.entity);
          };
        }

        return executeProcessusAdvanced(
          props.sjmoCode,
          props.compositeId,
          {
            id: props.compositeId,
            entities,
            paramsAdd
          },
          { executeAt: props.delay, modeEdition: props.editionType }
        ).then(res => {
          const checkProcess = createCheckProcessusMachine(res.data);
          const checkService = interpret(checkProcess);

          return new Promise<void>(resolve => {
            checkService.onTransition(state => {
              if (state.matches("error")) {
                setIsLoading(false);
                resolve();
              } else if (state.matches("done")) {
                setIsLoading(false);
                const navigation: ProcessusResult = state.context.result;
                notifyAfterProcess(navigation);

                if (navigation.status === "SUCCESS") {
                  const nextIndex = indexEntity + 1;

                  if (!forAll && nextIndex < formData.length) {
                    if (navigation.message) {
                      toaster.info(navigation.message);
                    }

                    setIndexEntity(nextIndex);
                    send({
                      type: "LOAD_ENTITY",
                      sjmoCode: props.sjmoCode,
                      entity: formData[nextIndex]
                    });
                  } else {
                    props.callback?.();
                    history.goBack();
                  }
                }

                resolve();
              } else {
                setIsLoading(true);
              }
            });
            checkService.start();
          }).then(() => {
            checkService.stop();
            return context.entity;
          });
        });
      }
    }
  });

  useEffect(() => {
    initProcessusAdvanced(props.sjmoCode, props.compositeId, props.selected ?? [])
      .then(res => {
        setDefinition(res.data);
        setFormData(res.data.data.paramsAdd as Pojo[]);
        send({
          type: "LOAD_ENTITY",
          sjmoCode: props.sjmoCode,
          entity: res.data.data.paramsAdd[0] as Pojo
        });
        setForAll(res.data.forAll);
      })
      .catch(() => {});
  }, [props.compositeId, props.selected, props.sjmoCode, send]);

  const { t } = useTranslation();

  const allComponentDefinitions = useMemo<
    Record<string, { typeCompo: string; wvi: boolean; ctrlValid: string }>
  >(() => {
    const fields = definition?.definitions.flatMap(it => it).flatMap(group => group.compos);
    const definitionByField = {};

    if (!fields) return definitionByField;

    for (let field of fields) {
      definitionByField[field.column] = {
        wvi: field.wvi,
        typeCompo: field.typeCompo,
        ctrlValid: field.ctrlValid
      };
    }
    return definitionByField;
  }, [definition?.definitions]);

  const entityTableName = definition?.data.entities[indexEntity]?.tableName ?? "";
  const entityId = definition?.data.entities[indexEntity]?.id;
  const label = useEntityLabel(props.sjmoCode, entityTableName, entityId);

  const onLaunchAdvancedProcess = useCallback(() => {
    send({ type: "SAVE", sjmoCode: props.sjmoCode });
  }, [props.sjmoCode, send]);

  const onChangeForAll = useCallback((field: string, val: any) => {
    setForAll(val);
  }, []);

  const changeValue = useCallback(
    function changeValue(field: string, value: any) {
      send({
        type: "CHANGE",
        sjmoCode: props.sjmoCode,
        compo: allComponentDefinitions[field].typeCompo,
        wvi: allComponentDefinitions[field].wvi,
        field: field,
        value: value
      });
    },
    [allComponentDefinitions, props.sjmoCode, send]
  );

  const onChange = useCallback(
    (e: SyntheticEvent<any>) => {
      const field: string = e.currentTarget.name;
      const val = convertValue(e);
      changeValue(field, val);
    },
    [changeValue]
  );

  const onItemChange = useCallback(
    (selectedItem: Pojo | null, field?: string) => {
      if (!field) {
        return;
      }

      changeValue(field, selectedItem ? selectedItem.id : null);
    },
    [changeValue]
  );
  const onValueChange = useCallback(
    (field: string | undefined, val: any) => {
      if (!field) {
        return;
      }

      changeValue(field, val);
    },
    [changeValue]
  );

  const onBlur = useCallback(
    (event: SyntheticEvent<any>) => {
      const field: string = event.currentTarget.name;
      send({
        type: "BLUR",
        sjmoCode: props.sjmoCode,
        field,
        wvi: allComponentDefinitions[field]?.wvi ?? false
      });
    },
    [allComponentDefinitions, props.sjmoCode, send]
  );

  const currentProcessDefinition = definition?.definitions.map((wrapper, idxWrapper) => {
    return (
      <React.Fragment key={idxWrapper}>
        {wrapper
          .filter(group => group.compos.length > 0)
          .map((group, i) => {
            const castedGroup = (group as unknown) as ComponentGroupState;
            return (
              <GroupComponent
                key={i}
                id={`group-advanced-params-${i}`}
                group={castedGroup}
                onChange={onChange}
                onItemChange={onItemChange}
                onValueChange={onValueChange}
                onBlur={onBlur}
                contextMenu={contextMenu}
                entity={formState.context.entity ?? {}}
                groupSize={castedGroup.groupSize}
                wviState={formState.context.result}
                sjmoCode={props.sjmoCode}
                excludePropFromCompo={(compo: ProcessusComponentState) =>
                  excludeFromProps(compo, "defaultValue", "ctrlValid")
                }
                title={castedGroup.groupLabel}
                styleLabel={{ flexGrow: 2 }}
              />
            );
          })}
        <hr />
      </React.Fragment>
    );
  });

  return (
    <Modal
      show
      onClose={() => {
        history.goBack();
      }}
      onValidate={onLaunchAdvancedProcess}
      title={t("commun_parametres_du_traitement")}
    >
      <div>
        <div className="columns is-gapless">
          <div className="column is-3">
            <Field label={t("commun_appliquer_a_tous")} isHorizontal flexGrowBody={2}>
              <Control>
                <Checkbox
                  readOnly={props.type === "edition"}
                  // les paramètres sont tjrs appliqués à tous lors dune édition car les éditions
                  // en masse peuvent etre regroupées et cela n'a pas de sens quand on en selectionne
                  // plusieurs de les exécuter une par une
                  label={t("commun_appliquer_a_tous")}
                  value={props.forcedForAll || forAll}
                  id="forAll"
                  disabled={props.forcedForAll}
                  onValueChange={onChangeForAll}
                />
              </Control>
            </Field>
          </div>
          <div className="column is-7">
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">{`${label}`}</label>
              </div>
            </div>
          </div>
          <div className="column is-2">
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">
                  {indexEntity + 1 + "/" + (definition?.data.entities.length ?? 0)}
                </label>
              </div>
            </div>
          </div>
        </div>
        <LoaderContainer loading={isFormLoadingState(formState)}>
          {currentProcessDefinition}
        </LoaderContainer>
      </div>
    </Modal>
  );
};

export default ParamAvanceDialog;
