import React, {
  FC,
  useReducer,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
  MouseEvent,
  SyntheticEvent,
  KeyboardEvent
} from "react";
import { FocusState } from "types/Focus";
import { CreatorGroupState } from "types/Creator";
import { Pojo } from "types/Galaxy";
import produce from "immer";

import { ActionTypeData } from "reducers/Action";
import { Action } from "redux";
import { RouteComponentProps } from "react-router-dom";
import {
  getCreatorFocus,
  createMany,
  initCreatorFromContext,
  getCreatorGroups,
  preRecord
} from "api";
import FocusBar from "composants/focus/FocusBar";
import CreatorForm from "./CreatorForm";
import { ButtonWithLoader } from "composants/button/ButtonWithLoader";
import { Fa } from "composants/Icon";
import { useDispatch, useSelector } from "react-redux";
import { addAllEntities } from "actions";
import { useTranslation } from "react-i18next";
import { showContextMenu } from "actions/contextMenu";
import { getDimensionFromEvent, validateForm } from "utils/component.utils";
import { set } from "lodash-es";
import { convertValue, getCompos } from "utils/entities.utils";
import { resetGalaxyMainEntity } from "actions/galaxy.action";
import { selectGalaxyInformation } from "selectors";
import { ReducerState } from "reducers";
import { addMessage } from "actions/messages";
import { track } from "tracking";
import formMachine from "machine/FormMachine";
import { useMachine } from "@xstate/react";
import Modal from "composants/Modal/Modal";

// State d'un focus de creator
export interface CreatorFocusState extends FocusState {
  groups: CreatorGroupState[];
}

// State redux du mini-expert
export interface CreatorState {
  isInitialized: boolean;
  creatorDefinitions: CreatorFocusState[];
  selectedFocus: string | null;
  currentIndex: number;
  // les entités déjà créée dans le creator
  entities: { [indexBrique: number]: Pojo[] };
  loading: boolean;
}

const initialState: CreatorState = {
  isInitialized: false,
  creatorDefinitions: [],
  selectedFocus: null,
  entities: {},
  currentIndex: 0,
  loading: true
};

type CreatorAction =
  | ActionTypeData<
      "FETCH_FOCUS_CREATOR_SUCCESS",
      { focuses: FocusState[]; focusId: string; groups: CreatorGroupState[] }
    >
  | ActionTypeData<
      "FETCH_FOCUS_CREATOR_GROUP_SUCCESS",
      { focusId: string; groups: CreatorGroupState[] }
    >
  | Action<"UNSELECT_FOCUS">
  | Action<"START_CREATOR_LOADER">
  | Action<"STOP_CREATOR_LOADER">
  | Action<"STOP_CREATOR_LOADER">
  | ActionTypeData<"ADD_NEW_CREATOR_ENTITY", { entity: Partial<Pojo> }>
  | ActionTypeData<
      "CHANGE_CREATOR_NEW_ENTITY_VALUE",
      {
        field: string;
        value: any;
      }
    >
  | ActionTypeData<"SAVE_CREATOR_ENTITY_SUCCESS", { entity: Pojo[]; loop: boolean }>
  | Action<"CLEAN_CREATOR">;

function changeFocusCreatorGroups(
  state: CreatorState,
  focusId: string,
  groups: CreatorGroupState[]
) {
  // on récupère l'index
  const index = state.creatorDefinitions.findIndex(def => def.focusId === focusId);
  return produce(state, draft => {
    draft.creatorDefinitions[index].groups = groups;
    draft.selectedFocus = focusId;
    draft.currentIndex = 0;
    draft.entities = {};
  });
}

function reducer(state: CreatorState = initialState, action: CreatorAction) {
  switch (action.type) {
    case "FETCH_FOCUS_CREATOR_SUCCESS": {
      const focuses = action.payload.focuses;
      const afterFocusState = produce(state, draft => {
        draft.creatorDefinitions = focuses.map(focus => ({
          ...focus,
          groups: []
        }));

        const selectedFocus = focuses && focuses.filter(el => el.privilegie)[0];
        if (selectedFocus) {
          draft.selectedFocus = selectedFocus.focusId;
        }
        draft.isInitialized = true;
      });

      return changeFocusCreatorGroups(
        afterFocusState,
        action.payload.focusId,
        action.payload.groups
      );
    }
    case "FETCH_FOCUS_CREATOR_GROUP_SUCCESS": {
      // on récupère la valeurs des actions
      const focusId: string = action.payload.focusId;
      const groups: CreatorGroupState[] = action.payload.groups;
      return changeFocusCreatorGroups(state, focusId, groups);
    }

    case "UNSELECT_FOCUS":
      return produce(state, draft => {
        draft.selectedFocus = null;
      });

    case "START_CREATOR_LOADER":
      return produce(state, draft => {
        draft.loading = true;
      });

    case "STOP_CREATOR_LOADER":
      return produce(state, draft => {
        draft.loading = false;
      });

    case "SAVE_CREATOR_ENTITY_SUCCESS": {
      const entity = action.payload.entity;
      const loop = action.payload.loop;

      return produce(state, draft => {
        if (draft.entities[draft.currentIndex]) {
          draft.entities[draft.currentIndex] = [...draft.entities[draft.currentIndex], ...entity];
        } else {
          draft.entities[draft.currentIndex] = entity;
        }
        // si nous sommes dans une boucle il ne faut pas incrémenter l'index
        if (!loop) {
          draft.currentIndex = draft.currentIndex + 1;
        }
      });
    }

    case "CLEAN_CREATOR":
      return initialState;
    default:
      return state;
  }
}

interface CreatorProps {
  sjmoCode: string;
  galaxyMainEntityId?: string;
  // Le nom de la table dans le contexte d'ouverture du creator
  contextTableName?: string;
  // l'Id de l'entité contextuelle à l'ouverture du creator.
  contextId?: string;
  // nom de la table principale qui détermine la définition du creator
  mainTableName: string;
  // permet d'empécher la navigation si besoin
  navigateOnClose?: boolean | "EXIT";
  // permet de lancer le onClose de la modal
  onClose(): void;
  // valeurs contextuelles d'initialisation de la première entité à créer
  // ces valeurs sont mappées dans l'entité avant le pre-record
  contextualValues: { [key: string]: any } | null;
  // Id de focus préférentiel, selectionné si il existe
  focusId: string | null;
  title: JSX.Element | string;
}

type CreatorAllProps = RouteComponentProps<{ code: string; id: any }> & CreatorProps;

function selectGalaxyInformationTableName(state: ReducerState, sjmoCode: string) {
  const info = selectGalaxyInformation(state, sjmoCode);
  return info?.tableName ?? "";
}

const Creator: FC<CreatorAllProps> = props => {
  // on a besoin de redux pour le tableName de la galaxy
  const selector = useCallback(
    (state: ReducerState) => {
      if (props.sjmoCode) {
        return selectGalaxyInformationTableName(state, props.sjmoCode);
      } else return null;
    },

    [props.sjmoCode]
  );

  const galaxyMainEntityTableName = useSelector(selector);

  const [t] = useTranslation();
  const [focusState, dispatch] = useReducer(reducer, initialState);
  const [saveAfterWvi, setSaveAfterWvi] = useState(false);
  const loopRef = useRef(false);
  const changedFields = useRef<Record<string, boolean>>({});
  const reduxDispatch = useDispatch();

  const [state, send] = useMachine(formMachine);

  const currentFocusIndex = useMemo(() => {
    return focusState.creatorDefinitions.findIndex(el => el.focusId === focusState.selectedFocus);
  }, [focusState.creatorDefinitions, focusState.selectedFocus]);

  const currentFocus =
    currentFocusIndex != null ? focusState.creatorDefinitions[currentFocusIndex] : null;
  const focusId = currentFocus?.focusId ?? props.focusId;

  const initGroupCurrentFocus = useCallback(
    async function initGroupCurrentFocus(focusId: string) {
      const resFocus = await getCreatorGroups(props.sjmoCode, focusId);
      return {
        groups: resFocus.data as CreatorGroupState[],
        focusId
      };
    },
    [props.sjmoCode]
  );

  const initCreatorBlockFromContext = useCallback(
    function initCreatorBlockFromContext(
      sjmoCode: string,
      groupId: string,
      contextTable: string,
      contextId: string,
      contextualValues: Record<string, any> | null = null
    ) {
      if (currentFocus) {
        initCreatorFromContext(groupId, sjmoCode, contextTable, contextId, contextualValues)
          .then(res => {
            dispatch({ type: "STOP_CREATOR_LOADER" });
            send({ type: "LOAD_ENTITY", sjmoCode: props.sjmoCode, entity: res.data });
          })
          .catch(() => {
            console.error("erreur de l'initialisation de la brique dans le contexte ");
          });
      }
    },
    [currentFocus, props.sjmoCode, send]
  );

  const preRecordCreator = useCallback(
    function preRecordCreator() {
      // si dans l'initialisation du creator il y a déjà une entité contextuelle.
      if ((props.mainTableName && props.contextId) || props.contextualValues != null) {
        initCreatorBlockFromContext(
          props.sjmoCode,
          currentFocus?.groups?.[focusState.currentIndex]?.id ?? "",
          props.contextTableName || "",
          props.contextId || "",
          props.contextualValues
        );
      } else {
        const sjmoCode = props.sjmoCode;
        const mainTableName = props.mainTableName;
        preRecord({ sjmoCode, tableName: mainTableName, focusId })
          .then(res => {
            dispatch({ type: "STOP_CREATOR_LOADER" });
            send({ type: "LOAD_ENTITY", sjmoCode: props.sjmoCode, entity: res.data });
          })
          .catch(() => {
            console.error("erreur de l'initialisation de la brique dans le contexte ");
            dispatch({ type: "STOP_CREATOR_LOADER" });
          });
      }
    },
    [
      props.mainTableName,
      props.contextId,
      props.contextualValues,
      props.sjmoCode,
      props.contextTableName,
      initCreatorBlockFromContext,
      currentFocus?.groups,
      focusState.currentIndex,
      focusId,
      send
    ]
  );

  useEffect(() => {
    async function initCreator() {
      try {
        const res = await getCreatorFocus(props.mainTableName, props.sjmoCode, focusId);

        const predefinedFocus = res.data.find(el => el.privilegie);
        if (predefinedFocus) {
          const groupsPayload = await initGroupCurrentFocus(predefinedFocus.focusId);
          dispatch({
            type: "FETCH_FOCUS_CREATOR_SUCCESS",
            payload: { focuses: res.data, ...groupsPayload }
          });
        }
      } catch {
        console.error("error during the initialization of the creator definitions");
      }
    }

    // si le creator n'est pas initialisé, on lance la fonction juste au dessus
    if (!focusState.isInitialized) {
      initCreator();
    }
  }, [
    focusId,
    initGroupCurrentFocus,
    props.mainTableName,
    props.sjmoCode,
    focusState.isInitialized
  ]);

  useEffect(() => {
    if (focusState.isInitialized && focusState.currentIndex === 0) {
      preRecordCreator();
    }
  }, [currentFocusIndex, preRecordCreator, focusState.currentIndex, focusState.isInitialized]);

  const save = useCallback(
    function save() {
      return new Promise<void>(resolve => {
        // si un WVI est en cours on n'attend sa fin avant de sauver
        if (state.context.waitingSave) {
          resolve();
          setSaveAfterWvi(true);
          return;
        }

        if (currentFocus && state.context.entity) {
          createMany(
            currentFocus.groups[focusState.currentIndex].tableName,
            [state.context.entity] as Pojo[],
            props.sjmoCode
          )
            .then(res => {
              const savedEntities = res.data;
              let contextTableName = "";
              let contextId = "0";
              if (loopRef.current) {
                // on contextualise le prochain record avec la dernière entité sauvegardée dans la brique
                // de création précédente
                const previousIndex = focusState.currentIndex - 1;

                if (previousIndex < 0) {
                  return;
                }

                const previousEntity =
                  focusState.entities[previousIndex][focusState.entities[previousIndex].length - 1];
                contextId = previousEntity ? previousEntity.id : "-1";

                contextTableName = currentFocus.groups[previousIndex].tableName;
                // contextId = yield select(selectContextualEntityId, {});
                // contextTableName = yield select(selectContextualEntityTableName, {});
              } else {
                // sinon on contextualise avec l'entité fraichement sauvegardée.
                contextTableName = currentFocus.groups[focusState.currentIndex].tableName;
                contextId = savedEntities[0].id;
              }

              dispatch({
                type: "SAVE_CREATOR_ENTITY_SUCCESS",
                payload: { entity: savedEntities, loop: loopRef.current }
              });
              reduxDispatch(addAllEntities(props.sjmoCode, contextTableName, savedEntities, true));

              // suite à la sauvegarde de l'entité courante dans le creator,
              // on lance une initialisation de l'entité suivante selon contexte
              // si c'est pas la dernière sauvegarde on navigue en contextualisant
              // c'est la dernière sauvegarde si on n'est pas dans une boucle et que l'index courant
              // correspond au nombre de brique de création dans le focus courant
              const currentFocusSize = currentFocus.groups.length;
              const currentIndex = focusState.currentIndex;
              // on fait un test index - 1 maintenant car dispatch dans React n'est pas synchrone.
              // on a donc l'index qui est incrémenté **après** que l'on ait déjà la fenêtre.
              if (!loopRef.current && currentIndex === currentFocusSize - 1) {
                // la navigation vers la galaxie va lancer le willUnmount du Creator qui va s'occuper
                // de contextualiser et de clean
                props.onClose();
              } else {
                // ici, on doit initiliser le prochain groupe
                // on fait un index + 1 car react n'a pas encore appliqué les changements
                // sauf si on est sur une boucle.
                const nextIndex = loopRef.current === true ? currentIndex : currentIndex + 1;
                const groupId = currentFocus.groups[nextIndex].id;
                initCreatorBlockFromContext(props.sjmoCode, groupId, contextTableName, contextId);
              }
            })
            .catch(err => {
              console.error("error during the save in the creator");
            })
            .then(() => {
              // on enlève le saveAfterWVI après la tentative de sauvegarde, avec succès ou non
              setSaveAfterWvi(false);
              dispatch({ type: "STOP_CREATOR_LOADER" });
              resolve();
            });
        }
        // une fois que l'on a sauvé on remet le flag de sauvegarde après un wvi a false
        // et on vide les couleurs / texte associées au composant
        setSaveAfterWvi(true);

        // si on n'est pas dans une boucle on se déplace au groupe de composants suivant
        if (!loopRef.current) {
          scrollTo(focusState.currentIndex + 1);
        } else {
          scrollTo(focusState.currentIndex);
        }
      });
    },
    [
      currentFocus,
      initCreatorBlockFromContext,
      props,
      reduxDispatch,
      focusState.currentIndex,
      focusState.entities,
      state.context.entity
    ]
  );

  const validFormAndSave = useCallback(() => {
    if (!currentFocus || !state.context.entity) {
      return Promise.resolve();
    }
    return validateForm(
      state.context.entity,
      currentFocus.groups[focusState.currentIndex].compos,
      save,
      label => {
        reduxDispatch(
          addMessage({
            target: "GLOBAL",
            type: "DANGER",
            message: `${t("commun_champs_devraient_etre_remplis")} : ${label}`
          })
        );
        return Promise.resolve();
      }
    );
  }, [currentFocus, reduxDispatch, save, focusState.currentIndex, state.context.entity, t]);

  useEffect(() => {
    // nothing on mount
    return () => {
      // on peut faire un test de currentIndex === groups.length car après le save, on incremente la valeur de l'index dans le reducer
      if (currentFocus && focusState.currentIndex === currentFocus.groups.length) {
        // Si on a exit on essai de fermé l'onglet courant
        // Si c'est un onglet ouvert par l'utilisateur l'onglet ne se ferme pas
        // On se contente de fermé le créator en restant sur la page actuelle
        if (props.navigateOnClose === "EXIT") {
          window.close();
          props.history.push({ search: "" });
        } else if (
          props.navigateOnClose !== false &&
          focusState.entities[0] &&
          focusState.entities[0].length > 0
        ) {
          // navigation et contextualisation
          // as t-on crée une ou plusieurs entités principale ?
          // oui on contextualise
          let navigationUrl = `/page/${props.sjmoCode}`;
          if (focusState.entities[0].length === 1) {
            navigationUrl = `/page/${props.sjmoCode}/${encodeURIComponent(
              focusState.entities[0][0].id
            )}`;
          } else {
            // On ajoute ces nouvelles informations à l'URL
            let ids = "";
            focusState.entities[0].map((pojo: Pojo) => {
              return (ids += `${pojo.id},`);
            });
            ids = ids.substr(0, ids.length - 1);
            navigationUrl = `/page/${props.sjmoCode}/?suiviOpen=true&ids=${ids}`;
          }

          props.history.push(navigationUrl);
        } else if (
          props.navigateOnClose === false &&
          props.galaxyMainEntityId &&
          galaxyMainEntityTableName
        ) {
          reduxDispatch(
            resetGalaxyMainEntity(
              props.sjmoCode,
              galaxyMainEntityTableName,
              props.galaxyMainEntityId
            )
          );
        }
      }
    };
  }, [
    currentFocus,
    galaxyMainEntityTableName,
    props.galaxyMainEntityId,
    props.history,
    props.navigateOnClose,
    props.sjmoCode,
    reduxDispatch,
    focusState.currentIndex,
    focusState.entities
  ]);

  const onContextMenu = useCallback(
    (ctrlKey: string, tableName: string) => (event: MouseEvent<any>) => {
      if (event.ctrlKey) {
        return;
      }
      event.preventDefault();
      const dimension = getDimensionFromEvent(event);
      reduxDispatch(
        showContextMenu(
          dimension.x,
          dimension.y,
          props.sjmoCode,
          tableName,
          ctrlKey,
          null,
          (state.context.entity as Pojo)[ctrlKey],
          null, // generic entity pour liste générique uniquement
          "creator",
          true
        )
      );
    },
    [props.sjmoCode, reduxDispatch, state.context.entity]
  );

  const onChange = useCallback(
    function onChange(e: SyntheticEvent<any>) {
      const field: string = e.currentTarget.name;

      set(changedFields.current, [field], e.currentTarget.value !== e.currentTarget.dataset.value);

      const value = convertValue(e);
      const currentCompo = getCompos(currentFocus?.groups || []).find(
        compo => compo.column === field
      );
      send({
        type: "CHANGE",
        field,
        sjmoCode: props.sjmoCode,
        compo: currentCompo?.typeCompo ?? "I",
        value,
        wvi: currentCompo?.wvi ?? false
      });
    },
    [currentFocus?.groups, props.sjmoCode, send]
  );

  const onValueChange = useCallback(
    function onValueChange(field: string | undefined, value: string) {
      if (!field) return;

      set(changedFields.current, [field], (state.context.entity as Pojo)[field] !== value);

      const currentCompo = getCompos(currentFocus?.groups || []).find(
        compo => compo.column === field
      );
      send({
        type: "CHANGE",
        field,
        sjmoCode: props.sjmoCode,
        compo: currentCompo?.typeCompo ?? "I",
        value,
        wvi: currentCompo?.wvi ?? false
      });
    },
    [currentFocus?.groups, props.sjmoCode, send, state.context.entity]
  );

  const onKeyDown = useCallback(
    function onKeyDown(e: KeyboardEvent<any>) {
      if (e.ctrlKey && e.key === "Enter") {
        loopRef.current = currentFocus
          ? currentFocus.groups[focusState.currentIndex].isLoop
          : false;
        track("creator::save::shortcut", { withLoop: loopRef.current });
        validFormAndSave();
      }
    },
    [currentFocus, focusState.currentIndex, validFormAndSave]
  );

  const onItemChange = useCallback(
    function onItemChange(selectedItem: Pojo | null, field?: string) {
      if (!field) {
        return;
      }

      const value = selectedItem ? selectedItem.id : null;
      const currentCompo = getCompos(currentFocus?.groups || []).find(
        compo => compo.column === field
      );
      send({
        type: "CHANGE",
        field,
        sjmoCode: props.sjmoCode,
        compo: currentCompo?.typeCompo ?? "I",
        value,
        wvi: currentCompo?.wvi ?? false
      });
    },
    [currentFocus?.groups, props.sjmoCode, send]
  );

  const onBlur = useCallback(
    function onBlur(event: SyntheticEvent<any>) {
      const field: string = event.currentTarget.name;

      let currentCompo = getCompos(currentFocus?.groups || []).find(
        compo => compo.column === field
      );
      send({ type: "BLUR", field, sjmoCode: props.sjmoCode, wvi: currentCompo?.wvi ?? false });
    },
    [currentFocus?.groups, props.sjmoCode, send]
  );

  const onFocusChange = useCallback(
    function onFocusChange(focusId: string) {
      initGroupCurrentFocus(focusId)
        .then(payload => {
          dispatch({
            type: "FETCH_FOCUS_CREATOR_GROUP_SUCCESS",
            payload
          });
          // dispatch({ type: "FOCUS_CHANGE_CREATOR", payload: { selectedFocus: focusId } });
        })
        .then(() => {
          // il faut rappeler le pre-record lorsque l'on change de focus
          // car les focus peuvent pointer sur des types d'entité ou des contextualisations différentes
          preRecordCreator();
        });
    },
    [initGroupCurrentFocus, preRecordCreator]
  );

  const onClose = useCallback(() => {
    if (
      props.navigateOnClose !== false &&
      focusState.entities[0] &&
      focusState.entities[0].length > 0
    ) {
      // navigation et contextualisation
      // as t-on crée une ou plusieurs entités principale ?
      // oui on contextualise
      let navigationUrl = `/page/${props.sjmoCode}`;
      if (focusState.entities[0].length === 1) {
        navigationUrl = `/page/${props.sjmoCode}/${encodeURIComponent(
          focusState.entities[0][0].id
        )}`;
      } else {
        // On ajoute ces nouvelles informations à l'URL
        let ids = "";
        focusState.entities[0].map((pojo: Pojo) => {
          return (ids += `${pojo.id},`);
        });
        ids = ids.substr(0, ids.length - 1);
        navigationUrl = `/page/${props.sjmoCode}/?suiviOpen=true&ids=${ids}`;
      }

      props.history.push(navigationUrl);
    } else {
      // Fermeture qui exécute un callback définie via symbol dans la fennêtre.
      props.onClose();
    }
  }, [focusState.entities, props]);

  return (
    <Modal show onClose={onClose} title={props.title} hideFooter={true}>
      <FocusBar
        focuses={focusState.creatorDefinitions}
        selectedFocus={focusState.selectedFocus ? focusState.selectedFocus : ""}
        onFocusChange={onFocusChange}
        personnalisable={false}
      />
      {currentFocus ? (
        <CreatorForm
          groups={currentFocus.groups}
          currentIndex={focusState.currentIndex}
          currentEntity={state.context.entity || {}}
          createdEntities={focusState.entities}
          loading={focusState.loading}
          wviState={state.context.result}
          sjmoCode={props.sjmoCode}
          createActionsHeaderComponents={(groupIndex: number, readOnly: boolean) => {
            return (
              <>
                {currentFocus.groups[groupIndex].isLoop && (
                  <button
                    className="button is-outlined is-link"
                    style={{
                      marginRight: "5px"
                    }}
                    onClick={() => {
                      track("creator::save::loop");
                      loopRef.current = true;
                      validFormAndSave();
                    }}
                    disabled={readOnly}
                    title={t("commun_enregistrer_boucle")}
                  >
                    <span className="icon">
                      <Fa icon="redo" />
                    </span>
                  </button>
                )}
                <ButtonWithLoader
                  icon="save"
                  className="button is-outlined is-link"
                  style={{ marginRight: 5 }}
                  onPress={() => {
                    loopRef.current = false;
                    track("creator::save::unique");
                    return validFormAndSave();
                  }}
                  disabled={readOnly}
                  title={t("commun_enregistrer_suivant")}
                />
              </>
            );
          }}
          contextMenu={onContextMenu}
          onChange={onChange}
          onValueChange={onValueChange}
          onKeyDown={onKeyDown}
          onItemChange={onItemChange}
          onBlur={onBlur}
        />
      ) : null}
    </Modal>
  );
};

export default Creator;

function scrollTo(hash: string | number) {
  window.location.hash = "#" + hash;
}
