import { ReducerState } from "reducers";
import get from "lodash-es/get";
import { Pojo } from "types/Galaxy";
import { createSelector } from "reselect";
import { DatatableState } from "reducers/modules/DatatableReducer";
import { EntitesState } from "reducers/modules/EntitiesReducer";

type SelectCurrentEntityProps = { sjmoCode: string; tableName: string; id: string };
type SelectAnyEntityByIdProps = {
  sjmoCode: string;
  tableName: string;
  ctrlKey: string;
  id: string;
};
type SelectDirtyEntitiesProps = { sjmoCode: string };
/**
 * Selectionne l'entité courante dans le state des entities
 * @param state state redux
 * @param tableName nom de la table
 * @param id id de l'entité
 */
export const selectCurrentEntity = (
  state: ReducerState,
  { sjmoCode, tableName, id }: SelectCurrentEntityProps
) => {
  return get(state, ["entities", sjmoCode, tableName, id]);
};

/**
 * Permet de récupérer une entité de datatable (en cours d'ajout) en fonction de son
 * uuid.
 *
 * @param state state redux
 * @param param1 paramètre de contextualisation du selector
 */
export const selectDatatableEntity = (
  state: ReducerState,
  { sjmoCode, ctrlKey, id }: { sjmoCode: string; ctrlKey: string; id: number | string }
) => {
  const dtEntitiesStart: Array<Pojo> = get(
    state,
    ["datatable", sjmoCode, ctrlKey, "newEntitiesStart"],
    []
  );
  const dtEntitesEnd: Array<Pojo> = get(
    state,
    ["datatable", sjmoCode, ctrlKey, "newEntitiesLast"],
    []
  );

  return [...dtEntitiesStart, ...dtEntitesEnd].find(ent => ent.uuid === id) as Pojo;
};

/**
 * Permet de récupérer une entité dans notre state, peu importe l'emplacement de celle ci
 *
 * @param state state redux
 * @param param1 paramètre de contextualisation du selector
 */
export const selectAnyEntityById = (
  state: ReducerState,
  { sjmoCode, tableName, ctrlKey, id }: SelectAnyEntityByIdProps
): { pojo: Pojo; from: "CREATOR" | "DATATABLE" | "ENTITIES" } | null => {
  let entity: Pojo | null;

  // on récupère d'abord dans le reducer d'entities

  entity = selectCurrentEntity(state, { sjmoCode, tableName, id });

  if (entity) {
    return { pojo: entity, from: "ENTITIES" };
  }

  // on vérifie ensuite dans les datatables

  entity = selectDatatableEntity(state, { sjmoCode, ctrlKey, id });

  if (entity) {
    return { pojo: entity, from: "DATATABLE" };
  }

  // si on a toujours rien, c'est que le paramètre passé ne correspond à aucune entité.
  return null;
};

/**
 * Sélectionne les galaxies actives dans la page
 * Renvois :
 *  - le sjmoCode
 *  - un lien de navigation vers cette galaxie contextualisée
 *  - est-ce que l'utilisateur essai de fermer la galaxie alors qu'elle est dirty
 * @param state redux
 */
export const selectActiveGalaxies = ({ galaxies: { active }, app: { galaxies } }: ReducerState) => {
  return Object.keys(active)
    .filter(galaxy => active[galaxy] && active[galaxy].active === true)
    .map(activeGalaxy => {
      const menu = galaxies[activeGalaxy];
      const link: string = menu && menu.link ? menu.link : `/page/${activeGalaxy}`;
      const id = encodeURIComponent(active[activeGalaxy].lastId ?? "");

      return {
        sjmoCode: activeGalaxy,
        link: active[activeGalaxy].lastId ? `${link}/${id}` : link,
        closingDirty: active[activeGalaxy].closingDirty
      };
    });
};

export const selectGalaxyEntites = (
  state: ReducerState,
  { sjmoCode }: SelectDirtyEntitiesProps
) => {
  return get(state, ["entities", sjmoCode]);
};

/**
 * Selectionne toutes les entitées qui ont été modifiées mais pas enregistrées au sein d'une galaxie
 */
export const selectDirtyGalaxyEntities = (
  state: ReducerState,
  { sjmoCode }: SelectDirtyEntitiesProps
) => {
  // Récupération de toutes les entités dans le réducer entities de la galaxie voulue
  const selectedEntities = selectGalaxyEntites(state, { sjmoCode });

  if (!selectedEntities) {
    return [];
  }

  const existingEntities = Object.keys(selectedEntities)
    .map((tableName: string) => {
      const modifies = Object.keys(selectedEntities[tableName])
        .map(key => selectedEntities[tableName][key])
        .filter(pojo => pojo.modifie);
      return modifies;
    })
    .reduce((acc, cur) => acc.concat(cur), []);

  // Récupération des entities crées non sauvegardées dans les table de la galaxie voulue
  const newEntitiesFromTables: Array<Pojo> = [];

  const tables = Object.keys(get(state, ["datatable", sjmoCode], []));

  for (let ctrlKey of tables) {
    const table = get(state, ["datatable", sjmoCode, ctrlKey]) as DatatableState;
    newEntitiesFromTables.push(...table.newEntitiesStart, ...table.newEntitiesLast);
  }

  existingEntities.push(...newEntitiesFromTables);

  return existingEntities;
};

export const selectDashboardDefinition = (state: ReducerState, sjmoCode: string) =>
  state.dashboard.dashboardDefinitions[sjmoCode];

export const selectDashboardSelectedFocus = (state: ReducerState, sjmoCode: string) =>
  state.dashboard.selected[sjmoCode];

export const selectDashboardSelectedFocusCode = (state: ReducerState, sjmoCode: string) => {
  const selectedFocusId = state.dashboard.selected[sjmoCode];
  const focuses = state.dashboard.dashboardDefinitions[sjmoCode];
  if (focuses) {
    const selected = focuses.find(focus => focus.focusId === selectedFocusId);
    return selected ? selected.focusCode : null;
  } else return null;
};

export const selectGalaxyInformation = (state: ReducerState, sjmoCode: string) =>
  state.app.galaxies[sjmoCode] && state.app.galaxies[sjmoCode].informations;

/**
 * Permet de savoir, en passant le code module,
 * si la galaxie a déjà été initialisée
 */
export const selectIsGalaxyFetched = ({ galaxyState }: ReducerState, sjmoCode: string) => {
  return galaxyState[sjmoCode] === "OK";
};

export const selectPartialEntities = createSelector(
  (
    state: EntitesState,
    props: {
      sjmoCode: string;
      joinTableName?: string;
    }
  ) => get(state, [props.sjmoCode, props.joinTableName || ""], {}),
  entities => {
    return entities;
  }
);
