import Action from "reducers/Action";
import produce, { Patch } from "immer";
import get from "lodash-es/get";
// import { transformToTree } from "utils/jsonTree.utils";
import {
  INIT_INTERACTION_SUCCESS,
  CONTEXTUALIZE,
  INIT_INTERACTION_BY_TABLE_SUCCESS
} from "constant/context";
import { Pojo } from "types/Galaxy";
import { CLOSE_GALAXIE_SUCCESS } from "constant/galaxy";

export type InteractionReducerParameter = Record<string, any>;

export interface InteractionReducerState {
  [type: string]: { [ctrlkey: string]: Array<InteractionDefinitionState> };
}

export interface InteractionDefinitionState {
  ctrlkey: string;
  interactionsValues: Array<InteractionValuesState>;
  tableName?: string;
  parent?: string;
}

export interface InteractionValuesState {
  fieldMaster: string | null;
  valueParent: any;
  fieldDetails: string;
}

export interface InteractionValuesByField extends InteractionValuesState {
  master: string;
}

// type alias pour l'action dans le reducer de dashboard
type InteractionAction = Action<any>;

const initialState: InteractionReducerState = {};

export default function reducer(
  state: InteractionReducerState = initialState,
  action: InteractionAction
) {
  switch (action.type) {
    case INIT_INTERACTION_SUCCESS: {
      return initContext(state, action);
    }

    case INIT_INTERACTION_BY_TABLE_SUCCESS: {
      return initContextByTable(state, action);
    }

    case CONTEXTUALIZE: {
      return contextualizeDetails(state, action);
    }

    case CLOSE_GALAXIE_SUCCESS: {
      return closeGalaxie(state, action);
    }
    default:
      return state;
  }
}

function contextualizeDetails(
  state: InteractionReducerState,
  action: Action<{
    sjmoCode: string;
    tableName: string;
    ctrlkey: string;
    entity: Partial<Pojo> | null;
  }>
): InteractionReducerState {
  // const sjmoCode = action.payload.sjmoCode;
  const { ctrlkey, entity, tableName } = action.payload;

  const keys = ["GLOBAL", tableName, action.payload.sjmoCode];
  let changes: Patch[] = [];
  const newState = produce(
    state,
    draft => {
      for (let sjmoCode of keys) {
        const current: InteractionDefinitionState[] | null = get(draft, [sjmoCode, ctrlkey], null);
        // le composant est il le maitre d'autres composants détails ?
        if (current !== null) {
          updateInteractionChildren(draft[sjmoCode], current, entity);
        }
      }

      return draft;
    },
    changesToAdd => {
      changes.push(...changesToAdd);
    }
  );

  return newState;
}

function updateInteractionChildren(
  parent: Record<string, InteractionDefinitionState[]>,
  current: InteractionDefinitionState[],
  entity: Partial<Pojo> | null
) {
  for (let i = 0; i < current.length; i++) {
    const detail = current[i];
    // si l'entité est null, on vérifie si notre détail n'est pas également master pour un autre composant
    // si c'est le cas, on passe directement les valeurs de contextualisation à nulle pour les détails
    // de notre détails courant.
    if (entity == null && parent[detail.ctrlkey]) {
      updateInteractionChildren(parent, parent[detail.ctrlkey], null);
    }

    const values = detail.interactionsValues;
    for (let j = 0; j < values.length; j++) {
      const val = values[j];
      if (entity && val.fieldMaster) {
        const newVal = entity[val.fieldMaster];
        if (newVal) {
          detail.interactionsValues[j].valueParent = newVal.toString();
        }
      } else if (!val.fieldMaster) {
        // on ne change pas la value car elle n'est pas dépendante d'une valeur du parent mais en dure
        // (ex: artiBloque = 'N')
      } else {
        detail.interactionsValues[j].valueParent = null;
      }
    }
  }
}

function initContext(
  state: InteractionReducerState,
  action: Action<{
    type: string;
    definition: Array<InteractionDefinitionState>;
  }>
): InteractionReducerState {
  const definition = action.payload.definition;
  const type = action.payload.type;

  return addInteractions(state, type, definition);
}

function initContextByTable(
  state: InteractionReducerState,
  action: Action<{
    definition: Array<InteractionDefinitionState>;
  }>
): InteractionReducerState {
  const definition = action.payload.definition;

  return produce(state, draft => {
    definition.map((el: InteractionDefinitionState) => {
      if (el.tableName !== undefined) {
        if (!draft[el.tableName]) {
          draft[el.tableName] = {};
        }
        if (el.parent) {
          // il existe un parent
          if (
            draft[el.tableName][el.parent] &&
            draft[el.tableName][el.parent].findIndex(current => current.ctrlkey === el.ctrlkey) ===
              -1
          ) {
            return draft[el.tableName][el.parent].push({
              ctrlkey: el.ctrlkey,
              interactionsValues: el.interactionsValues
            });
          } else {
            return (draft[el.tableName][el.parent] = [
              { ctrlkey: el.ctrlkey, interactionsValues: el.interactionsValues }
            ]);
          }
        }
      }
      return draft;
    });
  });
}

function addInteractions(
  state: InteractionReducerState,
  type: string,
  definition: InteractionDefinitionState[]
) {
  return produce(state, draft => {
    // on reset sur les ADD_INTERACTION_SUCCESS
    draft[type] = {};

    for (let el of definition) {
      if (el.parent) {
        // il existe un parent
        if (!draft[type][el.parent]) {
          draft[type][el.parent] = [];
        }

        const interaction: InteractionDefinitionState = {
          ctrlkey: el.ctrlkey,
          interactionsValues: el.interactionsValues
        };
        draft[type][el.parent].push(interaction);
      }
    }
  });
}

function closeGalaxie(
  state: InteractionReducerState,
  action: Action<string>
): InteractionReducerState {
  const sjmoCode = action.payload;
  return produce(state, draft => {
    const currentModule = draft[sjmoCode];
    if (!currentModule) {
      return;
    }

    // on boucle sur les clé de notre module
    for (let key of Object.keys(currentModule)) {
      // si jamais on des interactions pour cette clé, on boucle.
      for (let parent of currentModule[key]) {
        // pour chaque parent, on passe le valueParent à null.
        for (let interaction of parent.interactionsValues) {
          // on ne change pas la value si elle n'a pas de field master car elle n'est pas dépendante d'une valeur du parent mais
          // d'une valeur en dur.
          // (ex: artiBloque = 'N')
          if (interaction.fieldMaster) {
            interaction.valueParent = null;
          }
        }
      }
    }
  });
}
