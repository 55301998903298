import React, { FC } from "react";
import { CreatorLink } from "../Scheduler";
import { getNavigationUrlFromMap } from "utils/navigation.utils";
import ModalViewver from "composants/Modal/ModalViewer";
import { Task } from "../FullCalendarContainer";
import { Trans } from "react-i18next";
import produce from "immer";
import customHistory from "customHistory";
import { NavigationDefinition } from "constant/navigation";
import { ON_CLOSE_CREATOR_CALLBACK } from "constant/creator";

interface SchedulerCreatorListProps {
  origin: Task;
  mainEntityId: string;
  creators: CreatorLink[];
  refreshCallback(): void;
  onClose(): void;
}

export const SchedulerCreatorList: FC<SchedulerCreatorListProps> = props => {
  function openCreator(navigation: NavigationDefinition) {
    const url = getNavigationUrlFromMap(navigation);
    customHistory.push({
      search: url.substring(url.indexOf("?"))
    });
    props.onClose();
    (window as any)[ON_CLOSE_CREATOR_CALLBACK] = () => props.refreshCallback();
  }

  return (
    <ModalViewver onClose={props.onClose}>
      <div
        className="box has-background-white-ter p-8"
        style={{ maxWidth: "40rem", margin: "0 auto" }}
      >
        {props.creators.length > 0 &&
          props.creators.map((creator, index) => {
            const navigation = produce(creator.navigation, draft => {
              draft.id = props.mainEntityId;

              // Mapping des valeurs dynamiques
              if (draft.subContext && draft.subContext.contextualValues) {
                for (const key in draft.subContext.contextualValues) {
                  if (
                    draft.subContext.contextualValues[key] === "resourceId" &&
                    props.origin.resourceId
                  ) {
                    draft.subContext.contextualValues[key] = props.origin.resourceId;
                  } else if (
                    draft.subContext.contextualValues[key] === "startDate" &&
                    props.origin.start
                  ) {
                    draft.subContext.contextualValues[key] = encodeURIComponent(props.origin.start);
                  }
                }
                if (draft.subContext.type === "creator") {
                  draft.subContext.navigateOnClose = "false";
                }
              }
            });

            return (
              <a key={index} onClick={() => openCreator(navigation)}>
                <div
                  className={`has-background-white has-text-black has-border-${creator.color} b-l-4 b-t-0 b-r-0 b-b-0 border-solid p-5 mt-8 mb-8`}
                  style={{ borderRadius: 2 }}
                >
                  {creator.title}
                </div>
              </a>
            );
          })}
        {props.creators.length === 0 && (
          <div className="p-5">
            <Trans i18nKey="commun_aucun_focus_creation_parametre">
              Aucun focus de création de Paramétré
            </Trans>
          </div>
        )}
      </div>
    </ModalViewver>
  );
};
