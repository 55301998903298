import React, { SFC, useMemo, useRef, useEffect } from "react";
import classNames from "classnames";
import MaskedInput from "react-text-mask";
import emailMask from "text-mask-addons/dist/emailMask";

import { ComposantAllProps, getColorsClasses, getSizeClasses } from "../common";
import { uuidv4 } from "utils/uuid.utils";
import { usePreventWheelOnFocus } from "hooks/usePreventWheelOnFocus";

export interface InputAllProps extends ComposantAllProps {
  tabIndex?: number;
  autoComplete?: string;
  htmlType?: string;
  placeholder?: string;
  "data-custom-mask-decimal-limit"?: number;
  "data-is-number"?: boolean;
  maxLength?: number;
}

export const Input = React.forwardRef<HTMLInputElement, InputAllProps>(
  (
    { id, type = "text", value, onContextMenu, wviState, size, className, tooltip, ...restProps },
    ref
  ) => {
    // Les navigateurs propose tout seul de l'autocomplete sur les fields
    // Pour ignorer ça on devrait mettre [autocomplete = "off"]
    // Mais les navigateurs ignore cette dernière depuis peu
    // Alors on truande le système de la manière suivante
    const autocompleteKiller = useMemo(() => {
      return uuidv4();
    }, []);
    // La classe définissant la couleur dans un input est définie par le retour du wvi
    const colorClasses = getColorsClasses(wviState ? wviState : "");
    const sizeClasses = getSizeClasses(size ? size : "");
    const classes = classNames("input", className, colorClasses, sizeClasses);

    const notNullValue = value !== null && value !== undefined ? value : "";

    return (
      <input
        {...restProps}
        ref={ref}
        id={id}
        className={classes}
        type={type}
        value={notNullValue}
        data-value={notNullValue}
        onContextMenu={onContextMenu}
        title={tooltip}
        autoComplete={autocompleteKiller}
      />
    );
  }
);

export const InputNumber = React.forwardRef<HTMLInputElement, InputAllProps>((props, ref) => {
  const innerRef = useRef<HTMLInputElement | null>(null);
  usePreventWheelOnFocus(innerRef);

  // on synchronise les ref entre interne & externe.
  useEffect(() => {
    if (ref == null) return;

    if (typeof ref === "function") {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  });

  return <Input ref={innerRef} {...props} type="number" data-right-align="true" />;
});

export type InputMaskAllProps = InputAllProps & { mask?: string | string[] };

export const InputMask = React.forwardRef<
  HTMLInputElement,
  InputAllProps & { mask?: string | string[] }
>(
  (
    { id, type, mask, value, onContextMenu, wviState, size, className, tooltip, ...restProps },
    ref
  ) => {
    let calculatedMask = type === "email" && mask === undefined ? emailMask : mask;

    // La classe définissant la couleur dans un input est définie par le retour du wvi
    const colorClasses = getColorsClasses(wviState ? wviState : "");
    const sizeClasses = getSizeClasses(size ? size : "");
    const classes = classNames("input", className, colorClasses, sizeClasses);

    return (
      <MaskedInput
        {...(restProps as any)}
        mask={calculatedMask}
        type="text"
        id={id}
        aria-label={id}
        className={classes}
        value={value}
        data-value={value}
        onContextMenu={onContextMenu}
        ref={ref}
        title={tooltip}
      />
    );
  }
);

export default Input;
