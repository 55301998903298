import React, { Component, SyntheticEvent, MouseEvent, KeyboardEvent } from "react";
import { CreatorGroupState } from "types/Creator";
import { Pojo } from "types/Galaxy";
import { get } from "lodash-es";
import GroupComponent from "composants/group/GroupComponent";
import { memoizeLoading } from "composants/LoaderContainer";
import classNames from "classnames";
import { FocusScope } from "react-aria";
import { DataInteractionContext } from "hooks/useInteractions";

function mockUpFonction() {
  // empty
}

interface CreatorFormProps {
  groups: CreatorGroupState[];
  currentIndex: number;
  currentEntity: Partial<Pojo>;
  createdEntities: Record<string, Pojo[]>;
  loading: boolean;
  wviState: { [ctrlKey: string]: { code: string; message: string } } | {};
  sjmoCode: string;
  createActionsHeaderComponents(groupIndex: number, readOnly: boolean): JSX.Element;
  onChange(e: SyntheticEvent<any>): void;
  onValueChange(field: string | undefined, value: string): void;
  contextMenu(ctrlKey: string, tableName: string): (event: MouseEvent<any>) => void;
  onItemChange(selectedItem: Pojo | null, field?: string): void;
  onBlur(event: SyntheticEvent<any>): void;
  onKeyDown?(e: KeyboardEvent<any>): void;
}

interface CreatorFormState {}

class CreatorForm extends Component<CreatorFormProps, CreatorFormState> {
  static defaultProps = {
    onChange: mockUpFonction,
    onValueChange: mockUpFonction,
    onItemChange: mockUpFonction,
    onBlur: mockUpFonction,
    contextMenu: mockUpFonction
  };

  render() {
    const currentFocusDefinition = this.props.groups.map((currentGroup, index) => {
      // toutes les briques de création qui ne sont pas la brique courante
      // sont en read only
      const readOnly = this.props.currentIndex !== index;

      // entité vide par défaut
      let entity = {};
      // on initialise les briques de creator avec les entités correspondantes
      if (!readOnly) {
        // la brique en cours de création on met la new entity en cours de création
        entity = this.props.currentEntity;
      } else {
        // on est sur des briques sur lesquelles soit une entité à déjà été créée,
        // soit aucune entité n'a été créée.
        const created = this.props.createdEntities[index];
        if (created) {
          // une ou plusieurs entités ont déjà été créées
          // on prend la dernière
          entity = created[created.length - 1];
        }
      }

      // on récupère le nombre d'entité déjà crée pour
      const nbCreatedEntities = get(this.props.createdEntities, [index, "length"], 0);
      const group = (
        <DataInteractionContext.Provider value={entity as Pojo}>
          <GroupComponent
            id={index.toString()}
            title={
              currentGroup.groupLabel +
              (currentGroup.isLoop && nbCreatedEntities ? " - " + nbCreatedEntities.toString() : "")
            }
            classNames={classNames("octal-container", memoizeLoading(this.props.loading))}
            group={currentGroup}
            onChange={this.props.onChange}
            onValueChange={!readOnly ? this.props.onValueChange : () => {}}
            onItemChange={!readOnly ? this.props.onItemChange : () => {}}
            onBlur={!readOnly ? this.props.onBlur : () => {}}
            contextMenu={this.props.contextMenu}
            onKeyDown={this.props.onKeyDown}
            readonly={readOnly}
            entity={entity}
            groupSize={this.props.groups[index].groupSize}
            wviState={this.props.wviState}
            sjmoCode={this.props.sjmoCode}
            actions={this.props.createActionsHeaderComponents(index, readOnly)}
            parent={{ ctrlKey: "creator", tableName: currentGroup.tableName }}
            tableName={currentGroup.tableName}
          />
        </DataInteractionContext.Provider>
      );

      // On applique le focus scope que sur le block courant
      return (
        <div key={index}>
          {readOnly && <>{group}</>}
          {!readOnly && (
            <FocusScope contain autoFocus>
              {group}
            </FocusScope>
          )}
        </div>
      );
    });

    return <>{currentFocusDefinition}</>;
  }
}

export default CreatorForm;
