import React, { FC, useState, useEffect } from "react";
import SysDomaineAndLabel from "composants/select/SysDomaineAndLabel";
import { Trans } from "react-i18next";
import { convertValue } from "utils/entities.utils";
import { findAll, updateEntity } from "api";
import { Item } from "types/Component";

export const APP_FONT_SIZE = "appFontSize";

type FontProperty = { value: string; id: string };

export function useVariableFontProperties(): [
  FontProperty | null,
  (fontProperty: FontProperty | null) => void
] {
  const [property, setProperty] = useState<FontProperty | null>(null);

  useEffect(() => {
    if (property != null) {
      const root = document.documentElement;
      root.style.setProperty("--axin-size", `${property.value}px`);
      localStorage.setItem("appFontSize", property.value);
    }
  }, [property]);

  useEffect(() => {
    findAll({ tableName: "syjApplication", filter: "q=sjapDomaine==APP_FONT_SIZE" })
      .then(response => {
        if (response.data.data.length > 0) {
          const sjap = response.data.data[0];
          setProperty({ value: sjap.sjapA1, id: sjap.id });
        }
      })
      .catch(e => console.log(e));
  }, []);

  return [property, setProperty];
}

const SelectSize: FC<{
  fontProperty: FontProperty | null;
  setFontProperty(val: FontProperty): void;
}> = ({ fontProperty, setFontProperty }) => {
  const [sysDomaineChoices, setSysDomaineChoices] = useState<Item[]>([]);

  useEffect(() => {
    if (fontProperty != null) {
      const root = document.documentElement;
      root.style.setProperty("--axin-size", `${fontProperty.value}px`);
      localStorage.setItem("appFontSize", fontProperty.value);
    }
  }, [fontProperty]);

  useEffect(() => {
    findAll({
      tableName: "sysDomaineVal",
      filter: "q=sysDomaineId.id==PL_APP_FONT_SIZE"
    }).then(response => {
      const choices = response.data.data.map(pojo => {
        return { value: pojo.sydvVal, label: pojo.label };
      });
      setSysDomaineChoices(choices);
    });
  }, []);

  return (
    <SysDomaineAndLabel
      id="changeFontSize"
      required
      sysDomaineChoices={sysDomaineChoices}
      value={fontProperty?.value}
      label={<Trans i18nKey="commun_taille">Taille</Trans>}
      onChange={e => {
        const val = convertValue(e);

        if (fontProperty != null) {
          setFontProperty({ ...fontProperty, value: val });
          updateEntity("HOME", "syjApplication", fontProperty.id, "sjapA1", val);
        }
      }}
    />
  );
};

export default SelectSize;
