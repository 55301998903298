import { Pojo } from "types/Galaxy";

export const INIT_DRAGGABLE_GROUP_LIST = "INIT_DRAGGABLE_GROUP_LIST";
export const INIT_DGLIST_DEFINITION_SUCCESS = "INIT_DGLIST_DEFINITION_SUCCESS";
export const INIT_DGLIST_DATA_SUCCESS = "INIT_DGLIST_DATA_SUCCESS";

export const GET_FILTERABLE_LIST_DATA = "GET_FILTERABLE_LIST_DATA";
export const GET_FILTERABLE_LIST_DATA_SUCCESS = "GET_FILTERABLE_LIST_DATA_SUCCESS";
export const DGLIST_TEMPORARY_LOADING = "DGLIST_TEMPORARY_LOADING";
export const DG_LIST_CLEAR_DATA = "DG_LIST_CLEAR_DATA";

export type DraggableListItem =
  | Pojo
  | {
      items: Pojo[];
      label: string;
      tooltip?: string;
    };
