import { INIT_USER_SETTINGS } from "constant/userSettings";
import { UserSettingProps } from "types/UserSettings";

/**
 * Action qui récupère les propriété utile du user.
 *
 * @export
 * @returns
 */
export function initUserSettings(params: UserSettingProps) {
  return {
    type: INIT_USER_SETTINGS,
    payload: params
  };
}
