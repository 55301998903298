import { AxiosPromise } from "axios";
import { api, apiAdn, apiUtils, getUIContext } from "./common";
import { UserSettingProps } from "types/UserSettings";

/**
 * appel au webservice pour récupéré les setting du user courant
 *
 * @export
 * @param {string} id
 * @returns
 */
export function fetchUserSettings(): AxiosPromise<UserSettingProps> {
  return apiAdn.get("/userSettings");
}

export function fetchLangs(): AxiosPromise<{ value: string; label: string; baseValue: string }[]> {
  return apiAdn.get("/userSettings/langue");
}

export function updateSelectedLang(lang: string) {
  const params = getUIContext();
  return Promise.all([
    apiAdn.put(`userSettings/langue?${params}`, { langueCode: lang }),
    api.put(`userSettings/langue/cache?${params}`),
    apiUtils.put(`userSettings/langue/cache?${params}`)
  ]);
}

export function getVersion(): AxiosPromise<string> {
  return apiAdn.get("/userSettings/version");
}

export function getEnvironment(): AxiosPromise<string> {
  return apiAdn.get("/userSettings/environment");
}
