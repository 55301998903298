import React, { FC, useRef, useEffect } from "react";
import { useLockBodyScroll } from "./useLockBodyScroll";
import { useShortcut } from "hooks/useHotkey";

const ModalViewver: FC<{ onClose(): void }> = ({ children, onClose }) => {
  useLockBodyScroll();

  const modalRef = useRef<HTMLElement | null>(document.body);
  const isEscapeShortcut = useShortcut("Escape", modalRef);

  useEffect(() => {
    isEscapeShortcut && onClose();
  }, [isEscapeShortcut, onClose]);

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose} />
      <div className="modal-content" style={{ overflow: "hidden" }}>
        {children}
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={onClose} />
    </div>
  );
};

export default ModalViewver;
