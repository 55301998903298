import React, { Component, SFC, CSSProperties, SyntheticEvent } from "react";
import { Trans } from "react-i18next";
import { format } from "date-fns";
import { CardWrapper } from "composants/kanban/Kanban";
import { Link } from "react-router-dom";
import produce from "immer";
import classNames from "classnames";
import { Spring } from "react-spring/renderprops";
import get from "lodash-es/get";

import { Pojo } from "types/Galaxy";

import { find, findOne, updateEntity, updateEntities } from "api";

import { Row, Col } from "composants/Layout";
import { Dot } from "composants/Dot";
import AutoComplete from "composants/autocomplete/AutoComplete";
import { Input } from "composants/input/Input";
import { Control } from "composants/form";
import { Field } from "composants/form/Form";
import { convertValue } from "utils/entities.utils";
import { TagDropdown } from "composants/DropDown/TagDropdown";

import { getSigle } from "utils/label.utils";
import Calendar from "composants/calendar/Calendar";
import { executeKanbanLaunch, executeProcessLaunch } from "api/kanban";
import { KanbanDatatable } from "./KanbanDatatable";
import { getUserLang, getNumberFormat } from "utils/network.utils";

import { RefLink, calculateLineTotalHT } from "./common";
import customHistory from "customHistory";
import { Fa } from "composants/Icon";
import { GSBuilder } from "utils/query.utils";

export const KanbanCardGcoDevis: SFC<{
  /** entité principale */
  entity: Pojo;
  /** url de base pour la navigation */
  baseUrl: string;
  /** url de la galaxie */
  galaxyUrl: string;
  /** id de la lane */
  laneId: string;
  /** titre */
  title: string;
  /**  sous-titre affiché au même niveau */
  subtitle: string;
  /** sous-titre 2 affiché en dessus du titre */
  subtitle2?: string;
  /** sous titre 3 affiché en dessous de sous-titre */
  subtitle3?: string;
  /** sigle à afficher : € par défaut */
  sigle?: string;
  /** prix */
  price?: string | number | null;
  /** affiche un tag */
  tagColor: string;
  /** texte du tag */
  tagText: string;
  /** url pour la navigation vers l'object liée à l'entité principale */
  baseUrlLinkRef?: string;
  /** id ou liste d'id de l'entité */
  linkRef?: string | string[];
  /** label pour aller avec */
  linkRefLabel?: string;
  /** crée à */
  createdAt: string;
  /** validée à */
  validatedAt?: string;
  /** bordure de couleur */
  borderLeftColor?: string;
  /** Type de l'élément, doit être une resourcekey d'un sysdomaine */
  type?: string;
  titleTooltip: string;
  subtitleTooltip: string;
  subtitle2Tooltip: string;
  subtitle3Tooltip: string;
  createdAtTooltip: string;
  validatedAtTooltip: string;
  typeTooltip: string;
  kanbanId?: string;
  modalTemplate: string;
  onClickAdvanced(): void;
  onSelectCard(entity: Pojo, laneId: string): void;
}> = ({
  entity,
  baseUrl,
  galaxyUrl,
  laneId,
  title,
  subtitle,
  subtitle2,
  sigle = "€",
  price = 0,
  tagText,
  tagColor,
  createdAt,
  validatedAt,
  baseUrlLinkRef,
  linkRef,
  linkRefLabel,
  borderLeftColor = "#ccc",
  onSelectCard,
  type,
  subtitle3,
  titleTooltip,
  subtitleTooltip,
  subtitle2Tooltip,
  subtitle3Tooltip,
  createdAtTooltip,
  validatedAtTooltip,
  typeTooltip,
  kanbanId,
  modalTemplate
}) => {
  const createdAtStr = format(createdAt, "DD/MM/YYYY");
  const validatedAtStr = validatedAt ? format(validatedAt, "DD/MM/YYYY") : null;

  const style: CSSProperties | undefined = borderLeftColor
    ? { borderLeft: `5px solid ${getColorByDevisStatut(borderLeftColor)}` }
    : undefined;

  const numberFormatter = getNumberFormat();

  const typeTrad = type ? type.split("_")[0] + ":" + type : "";

  const tagTable = tagText
    ? tagText.split(",", 4).map((tag, index) => (
        <span key={`kanban_card_tags_${index}`} className={classNames("tag is-small ", tagColor)}>
          <Trans i18nKey={tag} />
        </span>
      ))
    : [];
  if (tagText && tagText.split(",").length > 4) {
    const hiddenTag = tagText
      .split(",")
      .filter((tag, index) => index > 3)
      .toString();

    tagTable.push(
      <span className={classNames("tag is-small", tagColor)} title={hiddenTag}>
        ...
      </span>
    );
  }

  return (
    <CardWrapper
      id={entity.id}
      entity={entity}
      laneId={laneId}
      className="box box-link"
      style={style}
      role="button"
      onSelectCard={e => {
        if (e.ctrlKey) {
          onSelectCard && onSelectCard(entity, laneId);
        }
      }}
      onOpen={() => {
        if (modalTemplate) {
          customHistory.push(
            `${baseUrl}/${entity.id}?kanbanId=${kanbanId}&template=${modalTemplate}&entityId=${entity.id}`
          );
        }
      }}
    >
      <div className="columns is-gapless">
        <div className="column is-7">
          <div>
            <span title={titleTooltip}>
              <Link
                to={galaxyUrl ? `${galaxyUrl}/${entity.id}` : "#"}
                className="underline-link is-uppercase is-size-4 has-text-weight-semibold"
              >
                {title}
              </Link>
            </span>
            {type && type !== null && (
              <span className="ml-8" title={typeTooltip}>
                (<Trans i18nKey={typeTrad} />)
              </span>
            )}
          </div>
          {subtitle2 && (
            <div className="is-size-6 has-text-weight-semibold" title={subtitle2Tooltip}>
              {subtitle2}
            </div>
          )}
          <div>
            {price !== null ? (
              <div>
                <span
                  className="has-text-grey-dark word-break-none"
                  style={{
                    fontSize: 20,
                    fontWeight: 300,
                    marginRight: ".4em",
                    fontVariantNumeric: "tabular-nums"
                  }}
                >
                  {price && typeof price === "number" ? numberFormatter.format(price) : price}
                </span>
                <span
                  className="has-text-grey-dark"
                  style={{ fontSize: 20, fontWeight: 400, marginRight: ".2em" }}
                >
                  {getSigle(sigle)}
                </span>
                <span
                  className="has-text-grey-dark word-break-none"
                  style={{ fontSize: 18, fontWeight: 400, marginRight: ".2em" }}
                >
                  <Trans i18nKey="commun_ht">HT</Trans>
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="column has-text-right">
          <div className="title is-6" title={subtitleTooltip}>
            {subtitle}
          </div>
          <div className="subtitle is-7">
            {subtitle3 && <span title={subtitle3Tooltip}>{subtitle3}</span>}
          </div>
        </div>
      </div>

      {tagText && tagColor && <div className="tags">{tagTable}</div>}
      {baseUrlLinkRef && linkRef ? (
        <RefLink baseUrlLinkRef={baseUrlLinkRef} linkRef={linkRef} linkRefLabel={linkRefLabel} />
      ) : null}

      <div className="columns has-text-grey">
        <div className="column" title={createdAtTooltip}>
          <span className="icon">
            <Fa icon="calendar" fixedWidth />
          </span>
          <span>{createdAtStr}</span>
        </div>
        {validatedAt && (
          <div className="column" title={validatedAtTooltip}>
            <span className="icon">
              <Fa icon="calendar-check" fixedWidth />
            </span>
            <span>{validatedAtStr}</span>
          </div>
        )}
      </div>
    </CardWrapper>
  );
};

function getLabelByDevisStatut(status: string) {
  switch (status) {
    case "A":
      return "Accepté";
    case "C":
      return "Créé";
    case "E":
      return "Attente";
    case "P":
      return "Partiel";
    case "R":
      return "Refusé";

    default:
      return status;
  }
}

export function getColorByDevisStatut(status: string) {
  switch (status) {
    case "A":
      return "hsl(204, 86%, 53%)";
    case "E":
      return "hsl(48, 100%, 67%)";
    case "P":
      return "hsla(30, 100%, 67%)";
    case "R":
      return "hsl(348, 100%, 61%)";
    case "C":
    default:
      return undefined;
  }
}

interface KanbanModalDevisState {
  parent: Pojo | null;
  details: Pojo[];
  createdCde: Pojo[];
  configurationOpen: boolean;
  loadingValidation: boolean;
  cdeClientId: string | null;
  drvDtCommande: string;
  drvReference: string;
  drvAvecCommentaire: boolean;
  drvAvecComplement: boolean;
  editerCde: boolean;
}

export class KanbanModalGcoDevis extends Component<
  {
    /** code du module */
    sjmoCode: string;
    /** id de l'entité */
    id: string;
    /** id du kanban */
    kanbanDefinitionId: string;
    processId?: string;
  },
  KanbanModalDevisState
> {
  state: KanbanModalDevisState = {
    parent: null,
    details: [],
    createdCde: [],
    configurationOpen: false,
    loadingValidation: false,
    cdeClientId: null,
    drvDtCommande: format(new Date()),
    drvReference: "",
    drvAvecCommentaire: true,
    drvAvecComplement: true,
    editerCde: false
  };

  private headerList = [
    "ligne",
    "article",
    "statut",
    "désignation",
    "qté",
    "UV",
    "PV maj up",
    "total HT"
  ];

  private datatableHeaderComponent = {
    "total HT": (key: string) => (
      <th key={key} className="text-right">
        {key}
      </th>
    ),
    statut: () => (
      <th key="statut">
        <TagDropdown
          className="is-medium"
          label="statut"
          renderMenu={params => {
            return (
              <>
                <a className="dropdown-item" onClick={() => this.acceptAll().then(params.onClose)}>
                  accepté
                </a>
                <a className="dropdown-item" onClick={() => this.waitAll().then(params.onClose)}>
                  en attente
                </a>
                <a className="dropdown-item" onClick={() => this.refuseAll().then(params.onClose)}>
                  refusé
                </a>
              </>
            );
          }}
        />
      </th>
    ),
    default: (key: string) => <th key={key}>{key}</th>
  };

  componentDidMount() {
    findOne({ tableName: "devisClient", id: this.props.id, includeJoinParent: true }).then(res =>
      this.setState({ parent: res.data })
    );

    find(
      "ligneDevisClient",
      "q=" +
        encodeURIComponent("devisClientId.id == " + this.props.id) +
        "&order=" +
        encodeURI("id asc")
    ).then(res => this.setState({ details: res.data.data }));
  }

  updateAllLdclStatut = (ids: string[], value: string) => {
    return updateEntities(this.props.sjmoCode, "ligneDevisClient", ids, "ldclStatut", value).then(
      all => {
        const newState = produce(this.state, draft => {
          for (let pojo of all) {
            let index = draft.details.findIndex(d => d.id === pojo.id);
            if (index !== -1) {
              draft.details[index] = pojo;
            }
          }
        });

        this.setState(newState);
      }
    );
  };

  acceptAll = () => {
    const ids = this.state.details.map(d => d.id);
    return this.updateAllLdclStatut(ids, "A");
  };

  waitAll = () => {
    const ids = this.state.details.map(d => d.id);
    return this.updateAllLdclStatut(ids, "E");
  };

  refuseAll = () => {
    const ids = this.state.details.map(d => d.id);
    return this.updateAllLdclStatut(ids, "R");
  };

  updateLdclStatut = (id: string, value: string) => {
    return updateEntity(this.props.sjmoCode, "ligneDevisClient", id, "ldclStatut", value).then(
      res => {
        const index = this.state.details.findIndex(d => d.id === res.data.id);
        if (index !== -1) {
          const newState = produce(this.state, draft => {
            draft.details[index] = res.data;
          });
          this.setState(newState);
        }
      }
    );
  };

  accept = (id: string) => this.updateLdclStatut(id, "A");
  wait = (id: string) => this.updateLdclStatut(id, "E");
  refuse = (id: string) => this.updateLdclStatut(id, "R");

  validateConfiguration = () => {
    const {
      cdeClientId,
      drvDtCommande,
      drvReference,
      drvAvecCommentaire,
      drvAvecComplement
    } = this.state;

    this.setState({ loadingValidation: true }, () => {
      if (this.state.parent === null) {
        return;
      }

      if (this.props.kanbanDefinitionId) {
        executeKanbanLaunch(this.props.sjmoCode, this.props.kanbanDefinitionId, {
          devisClientId: this.state.parent.id,
          cdeClientId,
          drvDtCommande,
          drvReference,
          drvAvecCommentaire,
          drvAvecComplement
        })
          .then(res =>
            this.setState({
              createdCde: [...this.state.createdCde, ...res.data]
            })
          )
          .catch(() => console.error("error during validation of kanban"))
          .then(() => this.setState({ loadingValidation: false }));
      } else if (this.props.processId) {
        executeProcessLaunch(this.props.sjmoCode, this.props.processId, {
          devisClientId: this.state.parent.id,
          cdeClientId,
          drvDtCommande,
          drvReference,
          drvAvecCommentaire,
          drvAvecComplement
        })
          .then(res =>
            this.setState({
              createdCde: [...this.state.createdCde, ...res.data]
            })
          )
          .catch(() => console.error("error during validation of kanban"))
          .then(() => this.setState({ loadingValidation: false }));
      }
    });
  };

  render() {
    const { parent: entity } = this.state;

    if (entity === null) {
      return null;
    }

    const { drvTotalAPayer: total, drvTotalTtc: totalTTC, drvTotalHt: totalHT } = entity;

    const currencyFormatter = new Intl.NumberFormat(getUserLang(), {
      style: "currency",
      currency: entity.deviseId.id
    });

    const numberFormatter = new Intl.NumberFormat(getUserLang(), {
      maximumFractionDigits: 2
    });

    return (
      <>
        <div style={{ padding: "0 0 2em 0" }}>
          <Row>
            <Col span={4}>
              <div
                className="is-size-1 has-text-black-ter has-text-weight-light"
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Dot
                  size="3rem"
                  color={getColorByDevisStatut(entity.dvclStatut) || "#ccc"}
                  title={getLabelByDevisStatut(entity.dvclStatut)}
                />
                <div>
                  <Link
                    to={`/page/OGCO020/${entity.id}`}
                    title="naviguer vers le devis"
                    className="underline-link"
                  >
                    {entity.id}
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}
            >
              <div className="is-size-3 has-text-black-ter has-text-weight-light">
                <strong>
                  <Link
                    to={`/page/OGCO001/${entity.clientId.id}`}
                    title="naviguer vers le client"
                    className="underline-link"
                  >
                    {get(entity, "clientId.clntNom")}
                  </Link>
                </strong>
                {" ("}
                {get(entity, "clientId.id")}
                {")"}
              </div>
              <div className="is-size-3 has-text-black-ter has-text-weight-light">
                {get(entity, "societeId.sociLibelle")}
              </div>
              <div>
                <button
                  onClick={() => this.setState({ configurationOpen: true })}
                  className="button is-link"
                >
                  Passer en commande &rarr;
                </button>
              </div>
            </Col>
          </Row>
          <div className="is-size-6 has-text-black-ter has-text-weight-light">
            par <strong>{get(entity, "personnelIdDevis.persNom")}</strong> le{" "}
            <strong>{format(entity.dvclDtSaisie, "DD/MM/YYYY")}</strong>
          </div>

          <div className="is-size-6 has-text-black-ter has-text-weight-light">
            {entity.dvclReferenceClient ? (
              <>
                référence <strong>{entity.dvclReferenceClient}</strong>
              </>
            ) : (
              "aucune référence"
            )}
          </div>

          <div className="is-size-6 has-text-black-ter has-text-weight-light">
            service : <strong>{entity.serviceId.servLibelle}</strong>
          </div>
        </div>
        <div>
          <KanbanDatatable
            header={this.headerList}
            renderHeader={key =>
              this.datatableHeaderComponent[key] === undefined
                ? this.datatableHeaderComponent["default"](key)
                : this.datatableHeaderComponent[key](key)
            }
            totalHT={currencyFormatter.format(totalHT)}
            totalTTC={currencyFormatter.format(totalTTC)}
            total={currencyFormatter.format(total)}
          >
            {this.state.details.map(line => {
              return (
                <tr key={line.id}>
                  <th>{line.ldclLigne}</th>
                  <td>{line.articleId}</td>
                  <th>
                    <TagDropdown
                      className={classNames({
                        "has-background-warning-light": line.ldclStatut === "E",
                        "has-background-success-light": line.ldclStatut === "A",
                        "has-background-danger-light": line.ldclStatut === "R"
                      })}
                      label={getLabelByDevisStatut(line.ldclStatut)}
                      renderMenu={params => {
                        return (
                          <>
                            <a
                              className="dropdown-item"
                              onClick={() => this.accept(line.id).then(params.onClose)}
                            >
                              accepté
                            </a>
                            <a
                              className="dropdown-item"
                              onClick={() => this.wait(line.id).then(params.onClose)}
                            >
                              en attente
                            </a>
                            <a
                              className="dropdown-item"
                              onClick={() => this.refuse(line.id).then(params.onClose)}
                            >
                              refusé
                            </a>
                          </>
                        );
                      }}
                    />
                  </th>
                  <td>{line.ldclDesignation}</td>
                  <td>{line.ldclQteCommandeeUv}</td>
                  <td>{line.uniteIdUv}</td>
                  <td>{numberFormatter.format(line.ldclPrixUnitaireUpMaj)}</td>
                  <td className="text-right">
                    <strong>
                      {currencyFormatter.format(
                        calculateLineTotalHT(
                          line.ldclQteCommandeeUv,
                          line.ldclPrixUnitaireUvMaj,
                          line.ldclTauxRemise
                        )
                      )}
                    </strong>
                  </td>
                </tr>
              );
            })}
          </KanbanDatatable>

          {this.state.configurationOpen ? (
            <Spring from={{ right: -400, opacity: 0 }} to={{ right: 0, opacity: 1 }}>
              {styles => {
                return (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        right: 610,
                        opacity: (styles as any).opacity,
                        top: 5
                      }}
                    >
                      <button
                        className="delete"
                        onClick={() => this.setState({ configurationOpen: false })}
                        aria-label="close"
                      />
                    </div>
                    <div className="octal-overlay" style={{ ...styles, width: 600 }}>
                      <h3 className="pt-5 px-5 subtitle is-3">Configuration</h3>
                      <div
                        className="px-5 py-7 my-7 -ml-px"
                        style={{ borderLeft: "2px solid hsl(217, 71%, 53%)" }}
                      >
                        <Field
                          label="Choisir votre commande"
                          help={
                            <>
                              <span className="icon">
                                <Fa icon="info" />
                              </span>
                              laisser vide pour créer une nouvelle commande
                            </>
                          }
                          helpState="info"
                        >
                          <AutoComplete
                            sjmoCode={this.props.sjmoCode}
                            controlProps={{ expanded: true }}
                            id="NumeroCdeClient"
                            value={this.state.cdeClientId}
                            onItemChange={item =>
                              this.setState({ cdeClientId: item ? item.id : null })
                            }
                            additionalClause={GSBuilder.AND(
                              GSBuilder.Comparison("id", "OPER_LIKE_START", ""),
                              GSBuilder.Comparison(
                                "clientIdCde.id",
                                "OPER_EQ",
                                get(this.state.parent, ["clientId", "id"], null)
                              )
                            )}
                            joinListFields="id;clientIdCde.clntNom"
                            joinTableName="cdeClient"
                          />
                        </Field>
                        <Field label="comandée le">
                          <Calendar
                            id="CommandeLe"
                            value={this.state.drvDtCommande}
                            onChange={(e: SyntheticEvent<any>) =>
                              this.setState({ drvDtCommande: convertValue(e) })
                            }
                          />
                        </Field>
                        <Field label="avec la référence">
                          <Input
                            id="Reference"
                            value={this.state.drvReference}
                            onChange={(e: SyntheticEvent<any>) =>
                              this.setState({ drvReference: convertValue(e) })
                            }
                          />
                        </Field>
                      </div>
                      <div className="px-5 my-6">
                        <Field label="Option complémentaire">
                          <Control>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={this.state.drvAvecCommentaire}
                                onChange={(e: SyntheticEvent<any>) =>
                                  this.setState({ drvAvecCommentaire: convertValue(e) })
                                }
                              />{" "}
                              <Trans key="static_avec_commentaires">Avec les commentaires</Trans>
                            </label>
                          </Control>
                        </Field>

                        <Field>
                          <Control>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={this.state.drvAvecComplement}
                                onChange={(e: SyntheticEvent<any>) =>
                                  this.setState({ drvAvecComplement: convertValue(e) })
                                }
                              />{" "}
                              <Trans i18nKey="static_avec_complements">Avec les compléments</Trans>
                            </label>
                          </Control>
                        </Field>

                        <Field>
                          <Control>
                            <label className="checkbox" {...{ disabled: true }}>
                              <input
                                type="checkbox"
                                checked={this.state.editerCde}
                                onChange={(e: SyntheticEvent<any>) =>
                                  this.setState({ editerCde: convertValue(e) })
                                }
                                disabled
                              />{" "}
                              <Trans i18nKey="static_editer_commande">Editer votre commande</Trans>
                            </label>
                          </Control>
                        </Field>
                      </div>
                      <div className="pb-5 px-5">
                        <button
                          className={classNames("button is-link is-large is-fullwidth", {
                            "is-loading": this.state.loadingValidation
                          })}
                          onClick={this.validateConfiguration}
                        >
                          <Trans i18nKey="commun_valider">Valider</Trans>
                        </button>
                        <NavigationCde createdCde={this.state.createdCde} />
                      </div>
                    </div>
                  </>
                );
              }}
            </Spring>
          ) : null}
        </div>
      </>
    );
  }
}

const NavigationCde: SFC<{ createdCde: Pojo[] }> = ({ createdCde }) => {
  const idNotNull = createdCde.filter(cde => cde.id !== null).length > 0;

  const ids =
    createdCde.length > 1
      ? "ids=" + createdCde.map(cde => cde.id).join(",") + "&suiviOpen=true"
      : "";

  return idNotNull && createdCde.length > 0 ? (
    <Link
      to={`/page/OGCO006/${createdCde[0].id}?${ids}`}
      className="button is-link is-outlined is-large is-fullwidth mt-7"
    >
      <Trans i18nKey="commun_naviguer_cde_detail" values={{ nbCde: createdCde.length }} /> &rarr;
    </Link>
  ) : null;
};
