import React from "react";

/**
 * Appelle l'action onIntersect lorsque target et root sont visibles sur l'éran
 * @param param0 
 */
export default function useIntersectionObserver({
  root,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = "0px",
  enabled = true
}: {
  root?: React.RefObject<any>;
  target: React.RefObject<any>;
  onIntersect: any;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;
}) {
  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      entries => entries.forEach(entry => entry.isIntersecting && onIntersect()),
      {
        root: root && root.current,
        rootMargin,
        threshold
      }
    );

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [enabled, root, rootMargin, threshold, target, onIntersect]);
}
