import React, { SFC } from "react";
import classNames from "classnames";

import { getColorsClasses, getSizeClasses, ComposantAllProps } from "../common";

interface TextAreaAllProps extends ComposantAllProps {
  tabIndex?: number;
  autoComplete?: string;
  htmlType?: string;
  placeholder?: string;
  innerRef?(el: HTMLTextAreaElement): void;
}

const TextArea: SFC<TextAreaAllProps> = ({
  id,
  type,
  value,
  onContextMenu,
  innerRef,
  wviState,
  size,
  className,
  tooltip,
  ...restProps
}) => {
  // La classe définissant la couleur dans un input est définie par le retour du wvi
  const colorClasses = getColorsClasses(wviState ? wviState : "");
  const sizeClasses = getSizeClasses(size ? size : "");
  const classes = classNames("textarea", className, colorClasses, sizeClasses);

  const notNullValue = value !== null && value !== undefined ? value : "";

  return (
    <textarea
      {...restProps}
      id={id}
      ref={innerRef}
      aria-label={id}
      className={classes}
      data-right-align={type === "number"}
      value={notNullValue}
      onContextMenu={onContextMenu}
      title={tooltip}
    />
  );
};

export default TextArea;
