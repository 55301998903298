import { Dispatch } from "redux";

import { Pojo } from "types/Galaxy";

import { whenValidateItem } from "api";

import { addMultipleRowDatatableSuccess } from "./datatable.action";

import { LovMapping } from "types/Component";
import { chainPromise } from "utils/network.utils";
import { uuidv4 } from "utils/uuid.utils";
import { addMessage } from "./messages";
import { getDatatableKey } from "containers/datatable/Datatable";

export type AddSelectionFromLovParams = {
  entityToClone: Partial<Pojo> | null;
  sjmoCode: string;
  tableOrig: string;
  target: string;
  isDatatable: boolean;
  mappings: LovMapping[];
  selectionToAdd: Pojo[];
  replaceUuid: boolean;
  dispatchToEntity(pojo: Pojo): void;
};
export function addSelectionFromLov({
  entityToClone = {},
  sjmoCode,
  tableOrig,
  target,
  isDatatable,
  mappings,
  selectionToAdd,
  replaceUuid,
  dispatchToEntity
}: AddSelectionFromLovParams): any {
  return (dispatch: Dispatch<any>) => {
    if (!selectionToAdd || selectionToAdd.length === 0) {
      return Promise.reject(false);
    }
    if (isDatatable) {
      const listOfFinalEntity = [...selectionToAdd]
        // dans le cas d'une datatable, on reverse la liste pour garder l'ordre lors de l'insertion
        .reverse()
        .map(entity => {
          // on copie l'entité de référence passé lors de l'ouverture
          const pojo = { ...entityToClone } as Pojo;
          if (replaceUuid) {
            pojo.uuid = uuidv4();
          }
          // on map les valeurs paramétré
          for (const mapper of mappings) {
            pojo[mapper.columnTarget] = entity[mapper.columnOrig];
          }
          return pojo;
        })
        .map(entity => {
          // on créée une liste de fonction qui lance les wvi.
          const chainsOfPromise = mappings
            .filter(mapper => mapper.wvi)
            .map(mapper => (params: Pojo) =>
              whenValidateItem(sjmoCode, tableOrig, mapper.columnTarget, params).then(
                res => res.data.entity
              )
            );
          // on chaine les promesses.
          return chainPromise(entity, chainsOfPromise);
        });
      // on fait un promise.All pour attendre la fin de chacun des appels
      // puis on boucles sur les résultats et on dispatch les entités dans redux
      return Promise.all(listOfFinalEntity)
        .then(entities => {
          let dtKey = getDatatableKey(sjmoCode, target, tableOrig);
          let customEvent = new CustomEvent(`${dtKey}--lov`, { detail: { pojos: entities } });
          window.dispatchEvent(customEvent);
        })
        .catch(res => {
          if (res.response.data && res.response.data.message) {
            dispatch(addMessage(res.response.data.message));
            throw false;
          }
        });
    } else {
      // on fait une copie avant de modifier l'entité
      const entity = { ...entityToClone } as Pojo;
      if (replaceUuid) {
        entity.uuid = uuidv4();
      }

      // on ne peut avoir qu'une seule sélection dans le cas où l'on est pas
      // dans une datatable
      const lovSelection = selectionToAdd[0];

      for (let mapper of mappings) {
        entity[mapper.columnTarget] = lovSelection[mapper.columnOrig];
      }

      // on créée une liste de fonction qui lance les wvi.
      const chainsOfPromise = mappings
        // on ne garde que celle qui contiennent un wvi
        .filter(mapper => mapper.wvi)
        .map(mapper => (params: Pojo) =>
          whenValidateItem(sjmoCode, tableOrig, mapper.columnTarget, params).then(
            res => res.data.entity
          )
        );

      // on chaine les promesses.
      return chainPromise(entity, chainsOfPromise)
        .then(finalEntity => {
          // la méthode ici est déjà préparé pour appeler le dispatch de redux
          // on ajoute manuellement modifie = true car, comme on force le reset de l'entité
          // il faut manuellement mettre le modifie car le reducer ne va pas laisser modifie par
          // défaut ou setter à modifie si le changemen de la lov est le premier changement opéré
          // sur l'entité
          dispatchToEntity({ ...finalEntity, modifie: true });
        })
        .catch(res => {
          if (res.response.data && res.response.data.message) {
            dispatch(addMessage(res.response.data.message));
            throw false;
          }
        });
    }
  };
}
