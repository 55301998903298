import Action from "reducers/Action";
import { AxiosResponse, AxiosError } from "axios";
import { takeEvery, takeLatest, put, call, select } from "redux-saga/effects";
import {
  GET_EXPERT_TREE_FOCUS,
  GET_EXPERT_TREE_FOCUS_SUCCESS,
  TREE_ON_MOVE_NODE_EXPERT,
  TREE_ON_CRUD_NODE_EXPERT_SUCESS,
  TREE_ON_CRUD_NODE_EXPERT_ERROR,
  ADMIN_EXPERT_TREE_PANEL_ID,
  ADMIN_EXPERT_TREE_MODULE,
  TREE_ON_INSERT_NODE_EXPERT,
  TREE_ON_DELETE_NODE_EXPERT,
  INIT_TREE_FOCUSES,
  INIT_TREE_FOCUS_SUCESS,
  GET_FLAT_TREE_DATA_SUCCESS,
  GET_FLAT_TREE_DATA,
  ON_TREE_FOCUS_CHANGE,
  ON_TREE_FOCUS_CHANGE_SUCCESS,
  TREE_ON_DELETE_NODE,
  TREE_ON_CRUD_NODE_SUCESS,
  TREE_ON_CRUD_NODE_ERROR,
  TREE_ON_MOVE_NODE
} from "constant/tree";
import { FlatTreeNode, RoughTreeData } from "types/Tree";
import {
  getExpertTreeFocus,
  onMoveNodeExpert,
  onInsertNodeExpert,
  onDeleteNodeExpert,
  getTreeFocuses,
  getTreeDataApi,
  onDeleteNode,
  onMoveNode
} from "api/tree";
import { t } from "utils/i18n";
import { Message } from "types/Message";
import { addMessage } from "actions/messages";
import { TreeFocus } from "reducers/modules/TreeReducer";
import { RESET_GALAXY } from "constant/galaxy";
import { getTreeData } from "actions/actionTree";
import { selectPanelIdsBySjmoCode } from "selectors/tree.selector";
import { ReducerState } from "reducers";
import { contextualize } from "actions/contextTreeModule";
import { getFlatDataFromTree } from "composants/tree/Utils";

function* initTreeFocuses(
  action: Action<{
    sjmoCode: string;
    panelId: number;
    mainEntityId: number | null;
    mainEntityType: string;
  }>
) {
  const { sjmoCode, panelId, mainEntityId, mainEntityType } = action.payload;
  try {
    // Les focus reviennent du server ordonnés
    // Le focus sélectionné est donc le premier
    const focuses: AxiosResponse<TreeFocus[]> = yield call(getTreeFocuses, sjmoCode, panelId);

    // Si la requete ne renvoit rien on a rien à afficher
    if (focuses.data.length === 0) {
      return;
    }

    const treeId = focuses.data[0].focusId;
    yield put({
      type: INIT_TREE_FOCUS_SUCESS,
      payload: {
        sjmoCode,
        panelId,
        treeFocuses: focuses.data,
        selectedTree: treeId
      }
    });

    // si mainEntityId est null on en charge pas l'arbre
    if (mainEntityId === null) {
      return;
    }

    const flatTreeData: AxiosResponse<{ treeData: FlatTreeNode[] }> = yield call(
      getTreeDataApi,
      treeId,
      mainEntityId
    );

    yield put({
      type: GET_FLAT_TREE_DATA_SUCCESS,
      payload: {
        sjmoCode,
        panelId,
        treeId,
        rootId: mainEntityId,
        rootType: mainEntityType,
        flatTreeData: flatTreeData.data.treeData
      }
    });
  } catch (e) {
    const er = e as AxiosError;
    if (!er.response) {
      return;
    }

    const message: Message = {
      code: er.response.data.code,
      message: t(er.response.data.message),
      type: er.response.data.type,
      target: er.response.data.target
    };
    yield put(addMessage(message));
  }
}

function* doOnGetFlatTreeData(
  action: Action<{
    sjmoCode: string;
    panelId: number;
    treeId: string;
    mainEntityId: number | null;
    mainEntityType: string;
  }>
) {
  try {
    const { sjmoCode, panelId, treeId, mainEntityId, mainEntityType } = action.payload;

    let flatTreeData: FlatTreeNode[] = [];
    // si mainEntityId est null on en charge pas l'arbre
    if (mainEntityId !== null) {
      const treeDataResponse: AxiosResponse<{ treeData: FlatTreeNode[] }> = yield call(
        getTreeDataApi,
        treeId,
        mainEntityId
      );
      flatTreeData = treeDataResponse.data.treeData;
    }

    const oldTreeDatas = yield select(
      (state: ReducerState) => state.tree[sjmoCode][panelId].treeDatas[treeId]
    );
    const old = getFlatDataFromTree({
      treeData: oldTreeDatas,
      ignoreCollapsed: false
    });
    for (const node of flatTreeData) {
      const exist = old.find(old_node => old_node.key === node.key);
      if (exist) {
        node.expanded = exist.expanded;
      }
    }

    yield put({
      type: GET_FLAT_TREE_DATA_SUCCESS,
      payload: {
        sjmoCode,
        panelId,
        treeId,
        rootId: mainEntityId,
        rootType: mainEntityType,
        flatTreeData: flatTreeData
      }
    });
  } catch (e) {
    const er = e as AxiosError;
    if (!er.response) {
      return;
    }

    const message: Message = {
      code: er.response.data.code,
      message: t(er.response.data.message),
      type: er.response.data.type,
      target: er.response.data.target
    };
    yield put(addMessage(message));
  }
}

function* doOnFocusChange(
  action: Action<{
    sjmoCode: string;
    panelId: number;
    treeId: string;
    mainEntityId: number | null;
    mainEntityType: string;
  }>
) {
  const { sjmoCode, panelId, treeId, mainEntityId, mainEntityType } = action.payload;
  yield put({ type: ON_TREE_FOCUS_CHANGE_SUCCESS, payload: { sjmoCode, panelId, treeId } });

  // si mainEntityId est null on en charge pas l'arbre
  if (mainEntityId === null) {
    return;
  }

  if (mainEntityId && mainEntityType) {
    yield call(doOnGetFlatTreeData, action);
  }
}

function* initExpertTreeFocus(action: Action<{ sjmoCode: string }>) {
  try {
    const focus: AxiosResponse<{ treeData: FlatTreeNode[]; treeId: number }> = yield call(
      getExpertTreeFocus,
      action.payload.sjmoCode
    );

    yield put({ type: GET_EXPERT_TREE_FOCUS_SUCCESS, payload: focus.data });
  } catch (e) {
    const er = e as AxiosError;
    if (!er.response) {
      return;
    }

    // gestion erreur tree et erreur par défaut
    const code = er.response.data.message.code
      ? er.response.data.message.code
      : er.response.data.code;
    const texte = er.response.data.message.message
      ? er.response.data.message.message
      : er.response.data.message;
    const type = er.response.data.message.type
      ? er.response.data.message.type
      : er.response.data.type;
    const target = er.response.data.message.target
      ? er.response.data.message.target
      : er.response.data.target;

    const message: Message = {
      code: code,
      message: t(texte),
      type: type,
      target: target
    };
    yield put(addMessage(message));
  }
}

function* doOnDeleteNode(
  action: Action<{
    sjmoCode: string;
    panelId: number;
    treeId: number;
    mainEntityId: string | null;
    mainEntityType: string;
    current: RoughTreeData;
    callback: () => void;
  }>
) {
  const { sjmoCode, panelId } = action.payload;
  const args = { ...action.payload, nextParent: null, nextIndex: null };
  yield call(onActionApi, args, TREE_ON_CRUD_NODE_SUCESS, TREE_ON_CRUD_NODE_ERROR, onDeleteNode);

  const ctrlkey = yield select((state: ReducerState) => {
    const treeState = state.tree[sjmoCode][panelId];
    if (treeState) {
      const def = treeState.treeFocuses[treeState.selectedTree];
      return def ? def.focusCode : null;
    } else {
      return null;
    }
  });

  if (ctrlkey !== null) {
    yield put(contextualize(sjmoCode, "", ctrlkey, null));
  }
}

function* doOnMoveNode(
  action: Action<{
    sjmoCode: string;
    panelId: number;
    treeId: number;
    mainEntityId: string | null;
    mainEntityType: string;
    current: RoughTreeData;
    nextParent: RoughTreeData | null;
    nextIndex: number | null;
    callback: () => void;
  }>
) {
  yield call(
    onActionApi,
    action.payload,
    TREE_ON_CRUD_NODE_SUCESS,
    TREE_ON_CRUD_NODE_ERROR,
    onMoveNode
  );
}

function* onActionApi(
  args: {
    sjmoCode: string;
    panelId: number;
    treeId: number;
    mainEntityId: string | number | null;
    mainEntityType: string;
    current: RoughTreeData;
    nextParent: RoughTreeData | null;
    nextIndex: number | null;
    callback?: () => void;
  },
  success: string,
  error: string,
  apiFunction: any
) {
  try {
    const result = yield call(apiFunction, args);
    // Message OK
    const message: Message = {
      code: result.data.message.code,
      message: t(result.data.message.message),
      type: result.data.message.type,
      target: result.data.message.target
    };

    yield put(addMessage(message));

    const oldTreeDatas = yield select(
      (state: ReducerState) => state.tree[args.sjmoCode][args.panelId].treeDatas[args.treeId]
    );
    const old = getFlatDataFromTree({
      treeData: oldTreeDatas,
      ignoreCollapsed: false
    });
    for (const node of result.data.treeData) {
      const exist = old.find(old_node => old_node.key === node.key);
      if (exist) {
        node.expanded = exist.expanded;
      }
    }

    yield put({
      type: success,
      payload: {
        flatTreeData: result.data.treeData,
        sjmoCode: args.sjmoCode,
        panelId: args.panelId,
        rootId: args.mainEntityId,
        rootType: args.mainEntityType
      }
    });
    if (args.callback) {
      args.callback();
    }
  } catch (e) {
    const er = e as AxiosError;
    if (!er.response) {
      return;
    }
    const m = er.response.data.message ? er.response.data.message : er.response.data;
    yield put(addMessage(m));

    const oldTreeDatas = yield select(
      (state: ReducerState) => state.tree[args.sjmoCode][args.panelId].treeDatas[args.treeId]
    );
    const old = getFlatDataFromTree({
      treeData: oldTreeDatas,
      ignoreCollapsed: false
    });
    for (const node of er.response.data.treeData) {
      const exist = old.find(old_node => old_node.key === node.key);
      if (exist) {
        node.expanded = exist.expanded;
      }
    }

    yield put({
      type: error,
      payload: {
        flatTreeData: er.response.data.treeData,
        sjmoCode: args.sjmoCode,
        panelId: args.panelId,
        rootId: args.mainEntityId,
        rootType: args.mainEntityType
      }
    });

    if (args.callback) {
      args.callback();
    }
  }
}

function* doOnMoveNodeExpert(
  action: Action<{
    current: RoughTreeData;
    nextParent: RoughTreeData;
    nextIndex: number;
    sjmoCode: string;
  }>
) {
  yield call(
    onExpertActionApi,
    action,
    TREE_ON_CRUD_NODE_EXPERT_SUCESS,
    TREE_ON_CRUD_NODE_EXPERT_ERROR,
    onMoveNodeExpert
  );
}

function* doOnInsertNodeExpert(
  action: Action<{
    current: RoughTreeData;
    nextParent: RoughTreeData;
    nextIndex: number;
    sjmoCode: string;
  }>
) {
  yield call(
    onExpertActionApi,
    action,
    TREE_ON_CRUD_NODE_EXPERT_SUCESS,
    TREE_ON_CRUD_NODE_EXPERT_ERROR,
    onInsertNodeExpert
  );
}

function* doOnDeleteNodeExpert(
  action: Action<{
    current: RoughTreeData;
    sjmoCode: string;
  }>
) {
  yield call(
    onExpertActionApi,
    action as any,
    TREE_ON_CRUD_NODE_EXPERT_SUCESS,
    TREE_ON_CRUD_NODE_EXPERT_SUCESS,
    onDeleteNodeExpert
  );
}

/**
 * Fonction généric pour gérer les action CRUD sur l'arborescence
 * des Experts
 *
 * A voir s'il est possible de gérer les arborescence classiques ici aussi lors de leur développement
 *
 * @param {Action<{
 *     current: RoughTreeData;
 *     nextParent: RoughTreeData;
 *     nextIndex: number;
 *     sjmoCode: string;
 *   }>} action
 * @param {string} success
 * @param {string} error
 * @param {*} apiFunction
 * @returns
 */
function* onExpertActionApi(
  action: Action<{
    current: RoughTreeData;
    nextParent: RoughTreeData;
    nextIndex: number;
    sjmoCode: string;
  }>,
  success: string,
  error: string,
  apiFunction: any
) {
  try {
    const result = yield call(apiFunction, action.payload);
    // Message OK
    const message: Message = {
      code: result.data.message.code,
      message: t(result.data.message.message),
      type: result.data.message.type,
      target: result.data.message.target
    };

    yield put(addMessage(message));

    // Dans le cas de admin expert sjmoCode est toujours EXPERT sur le reducer state
    // Et panelId est toujours 0 sur le reducer state
    yield put({
      type: success,
      payload: {
        flatTreeData: result.data.treeData,
        sjmoCode: ADMIN_EXPERT_TREE_MODULE,
        panelId: ADMIN_EXPERT_TREE_PANEL_ID
      }
    });
  } catch (e) {
    const er = e as AxiosError;
    if (!er.response) {
      return;
    }

    const message: Message = {
      code: er.response.data.message.code,
      message: t(er.response.data.message.message),
      type: er.response.data.message.type,
      target: er.response.data.message.target
    };
    yield put(addMessage(message));

    yield put({
      type: error,
      payload: {
        flatTreeData: er.response.data.treeData,
        sjmoCode: ADMIN_EXPERT_TREE_MODULE,
        panelId: ADMIN_EXPERT_TREE_PANEL_ID
      }
    });
  }
}

function* updateAllBySjmoCode(action: Action<{ sjmoCode: string; tableName: string; id: string }>) {
  const { sjmoCode, tableName, id } = action.payload;

  const keys: { panelId: number; treeId: string }[] = yield select(selectPanelIdsBySjmoCode, {
    sjmoCode
  });

  for (let key of keys) {
    yield put(getTreeData(sjmoCode, key.panelId, key.treeId, id, tableName));
  }
}

export default [
  takeEvery(INIT_TREE_FOCUSES, initTreeFocuses),
  takeEvery(GET_EXPERT_TREE_FOCUS, initExpertTreeFocus),
  takeEvery(TREE_ON_MOVE_NODE_EXPERT, doOnMoveNodeExpert),
  takeEvery(TREE_ON_INSERT_NODE_EXPERT, doOnInsertNodeExpert),
  takeEvery(TREE_ON_DELETE_NODE_EXPERT, doOnDeleteNodeExpert),
  takeEvery(GET_FLAT_TREE_DATA, doOnGetFlatTreeData),
  takeEvery(ON_TREE_FOCUS_CHANGE, doOnFocusChange),
  takeEvery(TREE_ON_DELETE_NODE, doOnDeleteNode),
  takeEvery(TREE_ON_MOVE_NODE, doOnMoveNode),
  takeLatest(RESET_GALAXY, updateAllBySjmoCode)
];
