import React, { Component } from "react";

interface ErrorBoundaryState {
  error?: any;
  errorInfo?: any;
}

export class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  state: ErrorBoundaryState = {};
  componentDidCatch(error: any, info: any) {
    // FIXME: ici, au lieu d'un console.error, on devrait faire un appel réseau vers sentry
    console.error("boundary::", error, "::", info);
    this.setState({
      error,
      errorInfo: info
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="message is-danger">
          <div className="message-body">
            <p>{this.state.error && this.state.error.toString()}</p>
            <br />
            <details>
              <pre>{this.state.errorInfo?.componentStack}</pre>
            </details>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
