import React, { FC, useEffect, useState, useMemo } from "react";
import { ComponentState } from "types/Component";
import memoize from "memoizee";
import { getColumnDefinition } from "api/column";
import Loader from "./Loader";
import { LOADER_TIME_TRIGGER } from "customGlobal";

const findDefinition = memoize(
  (sjmoCode: string, tableName: string, columns?: string[]) => {
    return getColumnDefinition(sjmoCode, tableName, columns);
  },
  {
    maxAge: 1000 * 60 * 5, // 5 minutes
    primitive: true,
    promise: true
  }
);

export function useGetComponentState(
  sjmoCode: string | null,
  tableName: string | null,
  columns?: string[]
) {
  const [columnsState, setColumnsState] = useState<ComponentState[]>([]);
  const [state, setState] = useState<"INITIAL" | "LOADING" | "ERROR" | "OK">("INITIAL");

  useEffect(() => {
    if (!sjmoCode || !tableName) {
      setState("ERROR");
      return;
    }

    let timer = setTimeout(() => {
      setState("LOADING");
    }, LOADER_TIME_TRIGGER() / 2);

    findDefinition(sjmoCode, tableName, columns)
      .then(res => {
        setColumnsState(res.data);
        setState("OK");
      })
      .catch(() => {
        setColumnsState([]);
        setState("ERROR");
      })
      .then(() => {
        clearTimeout(timer);
      });

    return () => clearTimeout(timer);
  }, [sjmoCode, tableName, columns ? columns.join(",") : undefined]);

  const computedColumnsState = useMemo(() => {
    return { columns: columnsState };
  }, [columnsState]);

  return { computedColumnsState, state };
}

interface GetComponentProps {
  sjmoCode: string;
  tableName: string;
  columns?: string[];
  // loader?: React.ReactNode;
  children(params: { columns: ComponentState[] }): any;
}

export const GetComponent: FC<GetComponentProps> = ({ sjmoCode, tableName, columns, children }) => {
  const { computedColumnsState, state } = useGetComponentState(sjmoCode, tableName, columns);

  switch (state) {
    case "INITIAL":
      return null;
    case "ERROR":
      return <div>error during the fetch of components for {tableName}</div>;
    case "LOADING":
      return <Loader />;
    case "OK":
      return children(computedColumnsState);
    default:
      return null;
  }
};
