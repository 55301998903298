import React, { Component, HTMLAttributes } from "react";
import classNames from "classnames";
import memoize from "memoizee";

export const memoizeLoading = memoize((loading: boolean) => ({ "is-loading": loading }), {
  max: 2
});

export default class LoaderContainer extends Component<
  { loading: boolean } & HTMLAttributes<HTMLDivElement>
> {
  render() {
    const { loading, className, ...restProps } = this.props;
    return (
      <div
        className={classNames("octal-container", memoizeLoading(loading), className)}
        {...restProps}
      />
    );
  }
}
