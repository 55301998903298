import React, { Component, SyntheticEvent } from "react";

import { SysDomaineAndLabel } from "../select/SysDomaineAndLabel";
import i18n, { t } from "utils/i18n";
import { fetchLangs, updateSelectedLang } from "api";
import { convertValue } from "utils/entities.utils";
import { setupCookieLang } from "utils/network.utils";
import { Trans } from "react-i18next";

interface SelectLangProps {
  selectedLang: string;
}

interface SelectLangState {
  langs: { value: string; label: string; baseValue: string }[];
}

class SelectLang extends Component<SelectLangProps, SelectLangState> {
  state: SelectLangState = { langs: [] };

  componentDidMount() {
    fetchLangs()
      .then(response => {
        this.setState({ langs: response.data });
      })
      .catch(e => {
        console.error("error fetch user langs", e);
      });
  }

  render() {
    return (
      <SysDomaineAndLabel
        id="changeLanguage"
        sysDomaineChoices={this.state.langs}
        value={this.props.selectedLang}
        label={<Trans i18nKey="commun_language">langue</Trans>}
        onChange={this.onChange}
      />
    );
  }

  onChange = (e: SyntheticEvent<any>) => {
    const val = convertValue(e);
    const lang = this.state.langs.find(l => l.value === val);

    if (lang) {
      updateSelectedLang(lang.baseValue)
        .then(() => {
          window.location.reload();
        })
        .catch(erreur => {
          console.log(erreur);
        });
    }
  };
}

export default SelectLang;
