import immer from "immer";
import Action from "../Action";

import { SET_DIRTY, SET_CURRENT_GALAXY_PROPERTIES_ID } from "constant/adminGalaxy";

// State redux du dashboard
export interface AdminGalaxyState {
  isDirty: boolean;
  currentGalaxyPropertiesId: number | null;
  // galaxyProperties: Pojo;
}

// type alias pour l'action dans le reducer de dashboard
type DashboardAction = Action<any>;

const initialState = { isDirty: false, currentGalaxyPropertiesId: null };

export default function reducer(
  state: AdminGalaxyState = initialState,
  action: DashboardAction
): AdminGalaxyState {
  switch (action.type) {
    case SET_DIRTY:
      return setDirty(state, action);
    case SET_CURRENT_GALAXY_PROPERTIES_ID:
      return immer(state, draft => {
        draft.currentGalaxyPropertiesId = action.payload.id;
      });
    default:
      return state;
  }
}

/**
 * Permet de traiter le changement de focus
 * @param state state dashboard
 * @param action action redux
 */
function setDirty(state: AdminGalaxyState, action: DashboardAction): AdminGalaxyState {
  const isDirty: boolean = action.payload.isDirty;
  return immer(state, draft => {
    draft.isDirty = isDirty;
  });
}
