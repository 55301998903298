import React, { SyntheticEvent, FC, useState, useCallback } from "react";
import Modal from "composants/Modal/Modal";
import { ComponentState } from "types/Component";

import { Trans, useTranslation } from "react-i18next";
import produce from "immer";

interface BreakRowDialogProps {
  sjmoCode: string;
  columns: ComponentState[];
  initialBreakRow: string[];
  onValidate(breakRows: string[]): void;
  onClose(): void;
}

export function useBreakRowColumns() {
  const [breakRowColumns, setBreakRowColumns] = useState<string[]>([]);
  const [isBreakRowDialogOpen, setBreakRowDialogOpen] = useState(false);

  const toggle = useCallback(() => setBreakRowDialogOpen(val => !val), []);

  const setColumns = useCallback((col: string[]) => setBreakRowColumns(col), []);

  return {
    columns: breakRowColumns,
    setColumns,
    isOpen: isBreakRowDialogOpen,
    toggle
  };
}

const BreakRowDialog: FC<BreakRowDialogProps> = props => {
  const { t } = useTranslation();
  const [breakRow, setBreakRow] = useState<string[]>(props.initialBreakRow || []);
  // breakRow: this.props.initialBreakRow || []

  function onChange(e: SyntheticEvent<HTMLSelectElement>) {
    const newState = produce(breakRow, draft => {
      draft.push(e.currentTarget.value);
    });

    setBreakRow(newState);
  }

  function onChangeExisting(e: SyntheticEvent<HTMLSelectElement>) {
    const oldValue = e.currentTarget.name;
    const value = e.currentTarget.value;

    const index = breakRow.indexOf(oldValue);
    if (index !== -1) {
      if (value === "") {
        onDelete(oldValue);
      } else {
        const newState = produce(breakRow, draft => {
          draft[index] = value;
        });
        setBreakRow(newState);
      }
    }
  }

  function onDelete(col: string) {
    const newState = produce(breakRow, draft => {
      const index = draft.indexOf(col);
      if (index !== -1) {
        draft.splice(index, 1);
      }
    });
    setBreakRow(newState);
  }

  function onValidate() {
    props.onValidate(breakRow);
  }

  function clear() {
    setBreakRow([]);
  }

  const optionsExisting = props.columns
    .filter(column => column.typeCompo !== "OEL" && column.compoVisible)
    .map(column => {
      return (
        <option key={column.column} value={column.column}>
          {column.label}
        </option>
      );
    });

  const optionsHiddenColumns = props.columns
    .filter(col => col.typeCompo !== "OEL" && col.compoVisible === false)
    .map(column => {
      return (
        <option key={column.column} value={column.column}>
          {column.label}
        </option>
      );
    });

  const optionsNewVisible = props.columns
    .filter(
      column =>
        column.typeCompo !== "OEL" &&
        column.compoVisible === true &&
        breakRow.indexOf(column.column) === -1
    )

    .map(column => {
      return (
        <option key={column.column} value={column.column}>
          {column.label}
        </option>
      );
    });

  const optionsNewHidden = props.columns
    .filter(
      column =>
        column.typeCompo !== "OEL" &&
        column.compoVisible === false &&
        breakRow.indexOf(column.column) === -1
    )
    .map(column => {
      return (
        <option key={column.column} value={column.column}>
          {column.label}
        </option>
      );
    });

  return (
    <Modal
      title={
        <>
          <Trans i18nKey="commun_ajouter_une_rupture">Ajouter une rupture</Trans>
          <span className="is-pulled-right">
            <button className="button is-text is-small" onClick={clear}>
              <Trans i18nKey="commun_vider" />
            </button>
          </span>
        </>
      }
      onClose={props.onClose}
      onValidate={onValidate}
      width="30rem"
      hideCancel
    >
      {breakRow.map(col => {
        return (
          <div key={col}>
            <div className="select mb-5">
              <select name={col} value={col} onChange={onChangeExisting}>
                <option value="" />

                {optionsExisting.length > 0 && (
                  <optgroup label={t("commun_visible")}>{optionsExisting}</optgroup>
                )}
                {optionsHiddenColumns.length > 0 && (
                  <optgroup label={t("commun_cache")}>{optionsHiddenColumns}</optgroup>
                )}
              </select>
            </div>
          </div>
        );
      })}
      <hr style={{ backgroundColor: "hsl(0, 0%, 90%)", width: "40%", margin: "1.25rem auto" }} />
      <div className="select">
        <select value="" onChange={onChange}>
          <option value="" />
          {optionsNewVisible.length > 0 && (
            <optgroup label={t("commun_visible")}>{optionsNewVisible}</optgroup>
          )}
          {optionsNewHidden.length > 0 && (
            <optgroup label={t("commun_cache")}>{optionsNewHidden}</optgroup>
          )}
        </select>
      </div>
    </Modal>
  );
};

export default BreakRowDialog;
