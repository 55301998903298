// creatorDefinition
export const ADD_NEW_CREATOR_ENTITY = "ADD_NEW_CREATOR_ENTITY";
export const FETCH_CREATOR_DEFINITION = "FETCH_CREATOR_DEFINITION";
export const FETCH_FOCUS_CREATOR = "FETCH_FOCUS_CREATOR";
export const FETCH_FOCUS_CREATOR_SUCCESS = "FETCH_FOCUS_CREATOR_SUCCESS";
export const FETCH_FOCUS_CREATOR_GROUP = "FETCH_FOCUS_CREATOR_GROUP";
export const FETCH_FOCUS_CREATOR_GROUP_SUCCESS = "FETCH_FOCUS_CREATOR_GROUP_SUCCESS";
export const FOCUS_CHANGE_CREATOR = "FOCUS_CHANGE_CREATOR";
export const FETCH_CREATOR_GROUP_AND_CHANGE_FOCUS = "FETCH_CREATOR_GROUP_AND_CHANGE_FOCUS";
export const UNSELECT_FOCUS = "UNSELECT_FOCUS";
export const CHANGE_CREATOR_NEW_ENTITY_VALUE = "CHANGE_CREATOR_NEW_ENTITY_VALUE";
export const SAVE_CREATOR_ENTITY = "SAVE_CREATOR_ENTITY";
export const SAVE_CREATOR_ENTITY_SUCCESS = "SAVE_CREATOR_ENTITY_SUCCESS";
export const INIT_CREATOR_FROM_CONTEXT = "INIT_CREATOR_FROM_CONTEXT";
export const CLEAN_CREATOR = "CLEAN_CREATOR";

export const ON_CLOSE_CREATOR_CALLBACK = Symbol.for("ON_CLOSE_CREATOR_CALLBACK");
