import React, { Component, FC, useState, useEffect } from "react";
import history from "customHistory";
import {
  SATELLITES,
  COMMENTAIRES,
  EXTENSIONS,
  DOCUMENTS,
  CONFIGURATEURS,
  VALIDATIONS,
  MAILS,
  ARTICULATIONS,
  SATELLITES_KEYS
} from "constant/satellite";
import { Link } from "react-router-dom";
import { t } from "utils/i18n";
import { getAllCount } from "api";

import { openSatellite } from "utils/navigation.utils";
import { ReducerState } from "reducers";
import { useSelector } from "react-redux";
import { AllSatelliteState, OneSatelliteState } from "reducers/modules/satellites";
import { isEqual } from "lodash-es";

export interface SatelliteAllCount {
  [COMMENTAIRES]?: number;
  [EXTENSIONS]?: number;
  [DOCUMENTS]?: number;
  [CONFIGURATEURS]?: number;
  [ARTICULATIONS]?: number;
  [VALIDATIONS]?: number;
  [MAILS]?: number;
}

interface SatelliteMenuProps {
  tableName: string;
  sjmoCode: string;
  isActive: boolean;
  query?: string;
  columns?: string[];
  contextId?: string;
  currentTab?: string;
  ctrlkey: string;
  onClick(): void;
  onMouseLeave?(): void;
}

function selectSatellites(state: ReducerState) {
  const visibilities: Record<string, boolean> = {};

  for (let key of SATELLITES_KEYS) {
    const currentSat: OneSatelliteState = state.satellites[key];
    visibilities[key] = currentSat.visible;
  }
  return visibilities;
}

const SatelliteMenu: FC<SatelliteMenuProps> = props => {
  const [counts, setCounts] = useState<SatelliteAllCount>({});

  const satelliteVisibilities = useSelector(selectSatellites, (a, b) => isEqual(a, b));

  useEffect(() => {
    if (props.contextId && props.isActive) {
      getAllCount(props.tableName, props.contextId).then(response => {
        setCounts(response.data);
      });
    }
  }, [props.tableName, props.contextId, props.currentTab, props.isActive]);

  return (
    <div onMouseLeave={props.onMouseLeave}>
      {/* Dans le cas où il y a un tab affiché on ne propose pas de menu pour naviguer vers lui même */}
      {SATELLITES_KEYS.filter(
        satellliteName =>
          satellliteName !== props.currentTab && satelliteVisibilities[satellliteName] === true
      ).map(satelliteName => {
        const title = t(SATELLITES[satelliteName].title);

        const params = openSatellite({
          tableName: props.tableName,
          sjmoCode: props.sjmoCode,
          query: props.query,
          columns: props.columns,
          satelliteName,
          contextId: props.contextId,
          ctrlKey: props.ctrlkey
        });

        return (
          <div className="dropdown-item" key={satelliteName}>
            <Link
              onClick={e => {
                e.stopPropagation();
                props.onClick();
              }}
              className="is-fullwidth is-text has-text-link satellite-menu-item"
              to={{ search: params }}
            >
              <span>{title}</span>
              {props.contextId && <span className="tag">{counts[satelliteName]}</span>}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(SatelliteMenu);
