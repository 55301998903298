import React, {
  FC,
  useEffect,
  useRef,
  useReducer,
  useState,
  MouseEvent,
  useCallback,
  SyntheticEvent,
  useContext
} from "react";
import LoaderContainer from "composants/LoaderContainer";
import FocusBar from "composants/focus/FocusBar";
import { getMiniExpertFocus, getMiniExpertGroups, findOne } from "api";
import { FocusState } from "types/Focus";
import { ActionTypeData } from "reducers/Action";
import { Button } from "composants/button";
import { track } from "tracking";
import { Fa } from "composants/Icon";
import { useTranslation } from "react-i18next";
import { ComponentGroupState } from "types/ComponentGroup";
import classNames from "classnames";
import { DataInteractionContext } from "hooks/useInteractions";
import GroupComponent from "composants/group/GroupComponent";
import { showContextMenu } from "actions/contextMenu";
import { getDimensionFromEvent, validateForm } from "utils/component.utils";
import { addMessage } from "actions/messages";
import { saveOrUpdateEntity } from "actions";
import { useDispatch } from "react-redux";
import { Pojo } from "types/Galaxy";
import {
  GalaxieListenerCallbackContext,
  useGalaxieCallbackListener
} from "containers/galaxy/GalaxieContextListener";
import formMachine, {
  isFormLoadingState,
  FormMachineContext,
  FormMachineEvents,
  FormMachineStates
} from "machine/FormMachine";
import { getCompos, convertValue } from "utils/entities.utils";
import { useMachine } from "@xstate/react";
import { State, Interpreter } from "xstate";
import { GalaxieContext } from "containers/galaxy/Galaxie";

interface MiniExpertProps {
  sjmoCode: string;
  tableName: string;
  currentMainEntityId: string;
}

type MiniExpertDefinitionState = {
  focuses: FocusState[];
  groups: Record<string, ComponentGroupState[] | undefined>;
  selected: string | null;
  activeGroup: number;
};

type MiniExpertDefinitionAction =
  | ActionTypeData<"INIT", FocusState[]>
  | ActionTypeData<"INIT_CACHE", MiniExpertDefinitionState>
  | ActionTypeData<"INIT_GROUPS", { focusId: string; groups: ComponentGroupState[] }>
  | ActionTypeData<"CHANGE_SELECTED", string>
  | ActionTypeData<"CHANGE_ACTIVE_GROUP", number>;

function reducerMiniExpertDefiniton(
  state: MiniExpertDefinitionState = { focuses: [], groups: {}, selected: null, activeGroup: 0 },
  action: MiniExpertDefinitionAction
): MiniExpertDefinitionState {
  switch (action.type) {
    case "INIT_CACHE":
      return action.payload;
    case "INIT": {
      const prefered = action.payload.filter(el => el.privilegie);
      return {
        focuses: action.payload,
        groups: {},
        selected:
          prefered.length > 0
            ? prefered[0].focusId
            : action.payload.length > 0
            ? action.payload[0].focusId
            : null,
        activeGroup: 0
      };
    }
    case "INIT_GROUPS": {
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.focusId]: action.payload.groups
        },
        activeGroup: 0
      };
    }

    case "CHANGE_SELECTED": {
      return {
        ...state,
        selected: action.payload
      };
    }

    case "CHANGE_ACTIVE_GROUP": {
      return {
        ...state,
        activeGroup: action.payload
      };
    }

    default:
      return state;
  }
}

const MiniExpert: FC<MiniExpertProps> = props => {
  const [t] = useTranslation();

  const [definition, dispatch] = useReducer(reducerMiniExpertDefiniton, {
    focuses: [],
    groups: {},
    selected: null,
    activeGroup: 0
  });

  const { refreshListenerAndCallback } = useContext(GalaxieContext);
  const [state, send] = useMachine(formMachine, {
    actions: {
      saveCallback: () => refreshListenerAndCallback && refreshListenerAndCallback()
    }
  });

  const loadEntity = useCallback(() => {
    if (props.currentMainEntityId) {
      findOne({ tableName: props.tableName, id: props.currentMainEntityId }).then(
        res => send({ type: "LOAD_ENTITY", sjmoCode: props.sjmoCode, entity: res.data }),
        () =>
          console.error(`cannot load "${props.tableName}" with id "${props.currentMainEntityId}"`)
      );
    } else {
      send({ type: "LOAD_ENTITY", sjmoCode: props.sjmoCode, entity: null });
    }
  }, [props.currentMainEntityId, props.sjmoCode, props.tableName, send]);
  useGalaxieCallbackListener(loadEntity);

  useEffect(() => {
    if (
      state.context.entity == null ||
      state.context.entity.tableName !== props.tableName ||
      state.context.entity.id !== props.currentMainEntityId
    ) {
      loadEntity();
    }
  }, [loadEntity, props.currentMainEntityId, props.tableName, state.context.entity]);

  useEffect(() => {
    getMiniExpertFocus(props.sjmoCode, props.tableName).then(res =>
      dispatch({ type: "INIT", payload: res.data })
    );
  }, [props.sjmoCode, props.tableName]);

  useEffect(() => {
    const focusId = definition.selected;
    if (focusId != null) {
      getMiniExpertGroups(props.sjmoCode, focusId).then(
        res =>
          dispatch({
            type: "INIT_GROUPS",
            payload: { focusId, groups: res.data }
          }),
        () => {
          console.error(`cannot fetch groups for ${focusId}`);
        }
      );
    }
  }, [props.sjmoCode, definition.selected]);

  function onFocusChange(id: string) {
    dispatch({ type: "CHANGE_SELECTED", payload: id });
  }

  const isLoading = isFormLoadingState(state);

  let activeGroup: ComponentGroupState | null = null;
  if (definition.selected)
    activeGroup = definition.groups[definition.selected]?.[definition.activeGroup] ?? null;

  return (
    <div>
      <FocusBar
        focuses={definition.focuses}
        selectedFocus={definition.selected ?? undefined}
        onFocusChange={onFocusChange}
        personnalisable={false}
      >
        <div className="tabs">
          <ul>
            <li>
              <Button
                className="is-inverted is-rounded is-link is-small"
                disabled={state.matches("saving")}
                style={{ marginRight: 5 }}
                onClick={() => {
                  send({ type: "SAVE", sjmoCode: props.sjmoCode });
                  track("miniexpert::save");
                }}
                title={t("commun_valider")}
              >
                <span className="icon">
                  {state.matches("saving") ? <Fa icon="spinner-third" spin /> : <Fa icon="save" />}
                </span>
                <span>{t("commun_valider")}</span>
              </Button>
            </li>
            {definition.selected &&
              definition.groups[definition.selected]?.map((group, i) => {
                return (
                  <li
                    key={group.groupLabel}
                    className={classNames({ "is-active": i === definition.activeGroup })}
                  >
                    <a
                      onClick={() => {
                        dispatch({ type: "CHANGE_ACTIVE_GROUP", payload: i });
                        track("miniexpert::tabs::changed");
                      }}
                    >
                      {group.groupLabel}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
      </FocusBar>
      <LoaderContainer loading={isLoading} className="columns">
        {activeGroup && (
          <MiniExpertContent
            group={activeGroup}
            sjmoCode={props.sjmoCode}
            tableName={props.tableName}
            state={state}
            send={send}
          />
        )}
      </LoaderContainer>
    </div>
  );
};

const MiniExpertContent: FC<{
  group: ComponentGroupState;
  sjmoCode: string;
  tableName: string;
  state: State<FormMachineContext, FormMachineEvents, FormMachineStates>;
  send: Interpreter<FormMachineContext, FormMachineStates, FormMachineEvents>["send"];
}> = props => {
  const dispatch = useDispatch();

  function onBlur(event: SyntheticEvent<any>) {
    const field: string = event.currentTarget.name;

    const compos = getCompos([props.group]);
    let currentCompo = compos.find(compo => compo.column === field);

    props.send({ type: "BLUR", field, sjmoCode: props.sjmoCode, wvi: currentCompo?.wvi ?? false });
  }

  function changeValue(field: string, value: any) {
    const compos = getCompos([props.group]);
    let currentCompo = compos.find(compo => compo.column === field);

    props.send({
      type: "CHANGE",
      field,
      sjmoCode: props.sjmoCode,
      compo: currentCompo?.typeCompo ?? "I",
      value,
      wvi: currentCompo?.wvi ?? false
    });
  }

  function onChange(e: SyntheticEvent<any>) {
    const field: string = e.currentTarget.name;
    const value = convertValue(e);

    changeValue(field, value);
  }

  function onEditorChange(field: string | undefined, value: string) {
    if (!field) return;
    changeValue(field, value);
  }

  function onItemChange(selectedItem: Pojo | null, field: string) {
    const fieldValue = selectedItem ? selectedItem.id : null;
    changeValue(field, fieldValue);
  }

  const contextMenu = (field: string) => (event: MouseEvent<any>) => {
    if (event.ctrlKey) {
      return;
    }

    event.preventDefault();
    const dimension = getDimensionFromEvent(event);

    const entity = props.state.context.entity;
    if (entity) {
      dispatch(
        showContextMenu(
          dimension.x,
          dimension.y,
          props.sjmoCode,
          props.tableName,
          field,
          entity.id,
          entity[field],
          null, // genericEntity uniquement pour les listes génériques
          "miniexp"
        )
      );
    }
  };

  return (
    <DataInteractionContext.Provider value={props.state.context.entity}>
      <div className={`column is-${props.group.groupSize}`}>
        <GroupComponent
          group={props.group}
          onChange={onChange}
          onValueChange={onEditorChange}
          onItemChange={onItemChange}
          onBlur={onBlur}
          contextMenu={contextMenu}
          readonly={props.state.context.entity === null}
          entity={props.state.context.entity ?? {}}
          groupSize={props.group.groupSize}
          wviState={props.state.context.result}
          sjmoCode={props.sjmoCode}
          tableName={props.tableName}
        />
      </div>
    </DataInteractionContext.Provider>
  );
};

export default MiniExpert;
