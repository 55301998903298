import React, { FC, useCallback, useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import history from "customHistory";
import { t } from "utils/i18n";
import { ReducerState } from "reducers";
import { AllSatelliteState, OneSatelliteState } from "reducers/modules/satellites";

import {
  SATELLITES,
  COMMENTAIRES,
  EXTENSIONS,
  DOCUMENTS,
  CONFIGURATEURS,
  ARTICULATIONS,
  VALIDATIONS,
  MAILS,
  SATELLITES_KEYS
} from "constant/satellite";
import Modal from "composants/Modal/Modal";
import { Row, Col } from "composants/Layout";
import FilterableList, { SEARCH_FIELD_SIZE } from "composants/FilterableList/FilterableList";
import classNames from "classnames";

import { Pojo } from "types/Galaxy";
import { Line } from "types/Component";
import {
  fetchArticulationCount,
  fetchCommentaireCount,
  fetchConfigurateurCount,
  fetchDocumentCount,
  fetchExtensionCount,
  fetchMailCount,
  fetchValidationCount
} from "actions/satellites";

import { addMessage } from "actions/messages";
import { getAllCount, findAll } from "api";

import IndicateurBarSattellite from "composants/satellite/IndicateurBarSatellite";
import { openSatellite, closeSatellite } from "utils/navigation.utils";
import { SatelliteAllCount } from "composants/satellite/SatelliteMenu";
import { Message } from "types/Message";

import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

const SATELLITES_COUNT_ACTION = {
  [COMMENTAIRES]: fetchCommentaireCount,
  [EXTENSIONS]: fetchExtensionCount,
  [DOCUMENTS]: fetchDocumentCount,
  [CONFIGURATEURS]: fetchConfigurateurCount,
  [ARTICULATIONS]: fetchArticulationCount,
  [VALIDATIONS]: fetchValidationCount,
  [MAILS]: fetchMailCount
};

export interface SatelliteLine extends Line {
  options: any;
}

export interface TabSatelliteProps {
  tableName: string;
  height: number | string;
  contextId?: string;
  sjmoCode: string;
  count: number;
  countAction(tableName: string, id?: string): void;
  addMessage(message: Message): void;
  shouldRefreshGalaxie(shouldRefresh: boolean): void;
}

export interface SatelliteContextLabel {
  tableLabel: string;
  oldPk: string[];
}

interface SatellitesDatasProps {
  currentTab: string;
  tableName: string;
  sjmoCode: string;
  initContextId?: string;
  // Propriété nécéssaire pour l'ouverture depuis un datatable
  ctrlKey?: string;
  query?: string;
  columns?: string[];
}

interface SatellitesDatasReduxState {
  satellites: AllSatelliteState;
  // interactions: InteractionReducerParameter | undefined;
}

function selectSatellitesData(state: ReducerState): SatellitesDatasReduxState {
  return {
    satellites: state.satellites
  };
}

const SatellitesData: FC<SatellitesDatasProps> = props => {
  const currentSatellite = SATELLITES[props.currentTab];

  const [satellitesCounts, setSatellitesCounts] = useState<SatelliteAllCount>({
    [EXTENSIONS]: 0,
    [COMMENTAIRES]: 0,
    [DOCUMENTS]: 0,
    [CONFIGURATEURS]: 0,
    [ARTICULATIONS]: 0,
    [VALIDATIONS]: 0,
    [MAILS]: 0
  });

  const reduxState = useSelector(selectSatellitesData);
  const dispatch = useDispatch();

  const shouldRefreshGalaxie = useRef<boolean>(false);

  useEffect(() => {
    if (props.initContextId && props.ctrlKey) {
      updateAllCounts(props.tableName, props.initContextId);
    }
  }, [props.ctrlKey, props.initContextId, props.tableName]);

  useEffect(() => {
    // on a besoin du initContextId pour être sûr que
    // l'on ouvre les satellites à partir d'une entité
    // sélectionné (entité principale)
    // s'il n'y pas d'entité principale, on laisse les valeurs par défaut.
    if (props.initContextId && !props.ctrlKey) {
      let counts = {};
      for (let sat of SATELLITES_KEYS) {
        counts[sat] = reduxState.satellites[sat].count;
      }
      setSatellitesCounts(counts);
    }
  }, [props.ctrlKey, props.initContextId, reduxState.satellites]);

  const [pojos, setPojos] = useState<Pojo[]>([]);
  useEffect(() => {
    let isValid = true;
    if (props.query && props.tableName) {
      findAll({
        tableName: props.tableName,
        filter: props.query,
        first: 0,
        size: false // false === désactivation de la pagination
      })
        .then(res => {
          isValid && setPojos(res.data.data);
        })
        .catch(e => {
          console.error(
            "error during fetch of the data in satellites data for tableName",
            props.tableName
          );
          console.error(e);
        });
    }
    return () => {
      isValid = false;
    };
  }, [props.query, props.tableName]);

  const lines: Line[] = useMemo(() => {
    let pojosData: Line[] = [];
    if (props.columns) {
      for (let pojo of pojos) {
        // infos: [entity[first3Cols[0]], entity[first3Cols[1]], entity[first3Cols[2]]],
        //         options: { id: entity.id }
        pojosData.push({
          infos: [pojo[props.columns[0]], pojo[props.columns[1]], pojo[props.columns[2]]],
          options: { id: pojo.id }
        } as any);
      }
    }
    return pojosData;
  }, [pojos, props.columns]);

  function onClose() {
    const params = closeSatellite();
    // si on a un ctrlkey
    // on lance un event de reload de satellite
    if (props.ctrlKey) {
      const event = new CustomEvent(props.ctrlKey + "--satellite");
      window.dispatchEvent(event);
    } else if (shouldRefreshGalaxie.current) {
      const event = new CustomEvent(props.sjmoCode + "--galaxie-refresh");
      window.dispatchEvent(event);
    }
    history.push({ search: params.toString() });
  }

  function customOpenSatellite(satelliteName: string, contextId?: string) {
    return openSatellite({
      tableName: props.tableName,
      sjmoCode: props.sjmoCode,
      query: props.query,
      columns: props.columns,
      satelliteName,
      contextId,
      ctrlKey: props.ctrlKey
    });
  }

  function addMessageFn(message: Message) {
    dispatch(addMessage(message));
  }

  const satellitesCountAction = useCallback(
    (satelliteName: string, tableName: string, id: string) => {
      if (props.ctrlKey) {
        return;
      }
      const fn = SATELLITES_COUNT_ACTION[satelliteName];
      // on vérifie mais satelliteName n'est pas censé pouvoir être autre chose que
      // les valeurs prédéfinies
      fn && dispatch(fn(tableName, id));
    },
    [dispatch, props.ctrlKey]
  );

  function updateAllCounts(tableName: string, initContextId?: string) {
    if (initContextId) {
      getAllCount(tableName, initContextId).then(response => {
        setSatellitesCounts(response.data);
      });
    }
  }

  const countAction = useCallback(
    (tableName: string, id?: string) => {
      if (props.ctrlKey) {
        updateAllCounts(tableName, id);
      } else if (id) {
        satellitesCountAction(props.currentTab, tableName, id);
      }
    },
    [props.ctrlKey, props.currentTab, satellitesCountAction]
  );

  function setShouldRefreshGalaxie(shouldRefresh: boolean) {
    shouldRefreshGalaxie.current = shouldRefresh;
  }

  function buildTitle() {
    const selectedTitle = t(currentSatellite.title);

    let counts = {};
    SATELLITES_KEYS.forEach(sat => {
      const currentSat: OneSatelliteState = reduxState.satellites[sat];
      counts[sat] = { count: satellitesCounts[sat], datas: [], visible: currentSat.visible };
    });
    const context = {
      tableName: props.tableName,
      id: props.initContextId,
      sjmoCode: props.sjmoCode
    };
    const satellites = { ...counts, context: context };

    return (
      <Row>
        <Col span={2}>
          <span>{selectedTitle}</span>
        </Col>
        <Col span={10}>
          <div className="is-pulled-right">
            <IndicateurBarSattellite
              openSatelliteParams={customOpenSatellite}
              satellites={satellites as AllSatelliteState}
              className="has-text-grey-darker"
            />
          </div>
        </Col>
      </Row>
    );
  }

  function buildComponent() {
    // let first3vals: SatelliteLine[] = [];
    // TODO: on va séparer le composant en DEUX
    // parce que l'on a besoin de fetch dans le cadre où l'on a une query
    // l'autre cas sera juste : affiche le container pour la currentTab

    const height = `calc(70vh - ${SEARCH_FIELD_SIZE}px)`;
    const SelectedCompo = currentSatellite.compo;

    if (props.initContextId && props.initContextId.split("~").length > 4) {
      return (
        <div>
          <Trans i18nKey="commun_donne_sat_indisponible">
            Les données satellites sont indisponible ce type de données
          </Trans>
        </div>
      );
    }

    if (props.columns && props.columns.length > 0) {
      return (
        <Row>
          <Col span={3}>
            <FilterableList
              lines={lines}
              buildChildrenList={renderChildrenList}
              listHeight={height}
            />
          </Col>
          <Col span={9}>
            <SelectedCompo
              tableName={props.tableName}
              contextId={props.initContextId}
              height="calc(70vh - 90px)"
              sjmoCode={props.sjmoCode}
              countAction={countAction}
              count={satellitesCounts[props.currentTab]}
              addMessage={addMessageFn}
              shouldRefreshGalaxie={setShouldRefreshGalaxie}
            />
          </Col>
        </Row>
      );
    } else {
      return (
        <SelectedCompo
          tableName={props.tableName}
          contextId={props.initContextId}
          height="calc(70vh - 90px)"
          sjmoCode={props.sjmoCode}
          countAction={countAction}
          count={satellitesCounts[props.currentTab]}
          shouldRefreshGalaxie={setShouldRefreshGalaxie}
        />
      );
    }
  }

  function renderChildrenList(lignes: SatelliteLine[]): JSX.Element {
    const infos = lignes.map(ligne => {
      const params = new URLSearchParams(history.location.search);
      params.set("satelliteContextId", ligne.options.id);
      return (
        <Link
          key={ligne.options.id}
          to={{
            search: params.toString(),
            state: {
              origin: history.location.pathname
            }
          }}
          className={classNames(
            "panel-block",
            "satellite-context-line",
            ligne.options.id === props.initContextId ? "satellite-active-context" : ""
          )}
        >
          <div>
            {ligne.infos.map((info, index) => {
              return <p key={`${ligne.options.id}_${index}`}>{info}</p>;
            })}
          </div>
        </Link>
      );
    });
    return <nav className="panel">{infos}</nav>;
  }

  return (
    <Modal show onClose={onClose} title={buildTitle()} minWidth="90vw" minHeight="70vh" hideFooter>
      {buildComponent()}
    </Modal>
  );
};

export default SatellitesData;
