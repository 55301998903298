import React, { Component } from "react";
import { Pojo } from "types/Galaxy";

interface KanbanDatatableProps {
  header: string[];
  totalHT: number | string;
  totalTTC: number | string;
  total: number | string;
  renderHeader(key: string, i?: number): JSX.Element | JSX.Element[] | string | undefined;
  // children(key: string, i?: number): JSX.Element | JSX.Element[] | string | undefined;
}
export class KanbanDatatable extends Component<KanbanDatatableProps> {
  render() {
    return (
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>{this.props.header.map(this.props.renderHeader)}</tr>
        </thead>
        <tfoot className="table-footer-totaux">
          <tr>
            <th colSpan={this.props.header.length - 2} />
            <th>HT</th>
            <th className="text-right">{this.props.totalHT}</th>
          </tr>
          <tr>
            <th colSpan={this.props.header.length - 2} />
            <th>TTC</th>
            <th className="text-right">{this.props.totalTTC}</th>
          </tr>
          <tr>
            <th colSpan={this.props.header.length - 2} />
            <th>TOTAL</th>
            <th className="text-right">{this.props.total}</th>
          </tr>
        </tfoot>
        <tbody>{this.props.children}</tbody>
      </table>
    );
  }
}

export default KanbanDatatable;
