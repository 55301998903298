import {
  INIT_ADMIN_GALAXY,
  SAVE_ALL_ADMIN,
  SET_DIRTY,
  CREATE_NEW_GALAXY_FOCUS,
  CREATE_NEW_PANEL,
  SET_CURRENT_GALAXY_PROPERTIES_ID,
  CREATE_NEW_GALAXY,
  CREATE_OR_UPDATE_FOCUS_GALAXY,
  DELETE_FOCUS_GALAXY,
  DELETE_PANEL,
  GET_ASSOCIATION_FOCUS,
  REFRESH_GALAXY_FOCUS_LIST
} from "constant/adminGalaxy";

import { Dispatch } from "redux";
import { PanelState } from "types/Dashboard";

/**
 * Permet d'initialiser l'administration de galaxie en fonction du code module
 * @param sjmoCode code module
 */
export function initGalaxyInAdministration(sjmoCode: string) {
  return {
    type: INIT_ADMIN_GALAXY,
    payload: sjmoCode
  };
}

export function saveAllAdmin(sjmoCode: string) {
  return {
    type: SAVE_ALL_ADMIN,
    payload: {
      sjmoCode
    }
  };
}

export function setDirty(isDirty: boolean) {
  return {
    type: SET_DIRTY,
    payload: {
      isDirty
    }
  };
}

export function createNewGalaxyFocus() {
  return {
    type: CREATE_NEW_GALAXY_FOCUS,
    payload: {}
  };
}

export function createNewPanel(
  sjmoCode: string,
  title: string,
  code: string,
  type: string,
  focusId: string
): any {
  return (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({
      type: CREATE_NEW_PANEL,
      payload: { sjmoCode, title, code, type, focusId }
    });

    return Promise.resolve();
  };
}

export function deletePanel(
  sjmoCode: string,
  tableName: string,
  panel: PanelState,
  callback?: () => void
): any {
  return (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({
      type: DELETE_PANEL,
      payload: { sjmoCode, tableName, panel, callback }
    });

    return Promise.resolve();
  };
}

export function setCurrentGalaxyPropertiesId(id: number) {
  return {
    type: SET_CURRENT_GALAXY_PROPERTIES_ID,
    payload: { id }
  };
}

export function onCreateNewGalaxy(
  galaxyTitle: string,
  newSjmoCode: string,
  newSjmoIcon: string,
  sjgaType: string,
  syjTablesId: string | null
): any {
  return (dispatch: Dispatch<any>): Promise<void> => {
    return new Promise(resolve => {
      dispatch({
        type: CREATE_NEW_GALAXY,
        payload: { galaxyTitle, newSjmoCode, newSjmoIcon, sjgaType, syjTablesId, callback: resolve }
      });
    });
  };
}

export function onCreateOrUpdateGalaxyFocus(params: {
  currentSjmoCode: string;
  title: string;
  privilegie: boolean;
  code: string;
  resourceKey: string;
  syjModuleId: string;
  sysMenuGroupeId: string;
  personnelId: string;
  callback: () => void;
}) {
  return { type: CREATE_OR_UPDATE_FOCUS_GALAXY, payload: params };
}

export function deleteSelectedFocus(focusId: string, sjmoCode: string) {
  return { type: DELETE_FOCUS_GALAXY, payload: { focusId, sjmoCode } };
}

export function getAssociationsFocus(sjmoCode: string, focusId: string) {
  return { type: GET_ASSOCIATION_FOCUS, payload: { focusId, sjmoCode } };
}

export function refreshFocusList(sjmoCode: string) {
  return { type: REFRESH_GALAXY_FOCUS_LIST, payload: sjmoCode };
}
