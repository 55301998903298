import React, { CSSProperties, SFC } from "react";

export interface RowBreakRendererProps {
  key: string;
  rowIndex: number;
  style?: CSSProperties;
  breakRowLabel: (index: number) => string;
}
export function rowBreakRenderer({ key, rowIndex, style, breakRowLabel }: RowBreakRendererProps) {
  return (
    <div key={key} style={style}>
      <div className="has-text-weight-semibold ml-7">{breakRowLabel(rowIndex)}</div>
    </div>
  );
}
export interface RowBreakSubtotalRendererProps {
  key: string;
  rowIndex: number;
  style?: CSSProperties;
  breakSubtotalLabel: (index: number) => string;
}
export function rowBreakSubtotalRenderer({
  key,
  rowIndex,
  style,
  breakSubtotalLabel
}: RowBreakSubtotalRendererProps) {
  return (
    <div key={key} style={style}>
      <div className="has-text-weight-semibold is-pulled-right mr-5">
        {breakSubtotalLabel(rowIndex)}
      </div>
    </div>
  );
}
