import React from "react";
import Gravatar from "react-gravatar";
import { ReducerState } from "reducers";
import { connect } from "react-redux";

import "./Avatar.css";
import { useTranslation } from "react-i18next";

interface AvatarProps {
  hash: string;
  userID?: string;
}

const AvatarInternal: React.FC<AvatarProps> = ({ hash, userID }) => {
  const [t] = useTranslation();

  return (
    <figure className="image is-32x32" title={t("commun_parametre_utilisateur")}>
      {hash ? (
        <div className="avatar">
          <Gravatar md5={hash} size={32} default="identicon" className="is-rounded" />
          <div className="avatar-user">{userID}</div>
        </div>
      ) : (
        <img className="is-rounded" src="https://bulma.io/images/placeholders/32x32.png" />
      )}
    </figure>
  );
};

function mapStateToProps(state: ReducerState) {
  return {
    hash: state.userSettings.hash,
    userID: state.userSettings.id
  };
}

export const Avatar = connect<AvatarProps>(mapStateToProps)(AvatarInternal);
