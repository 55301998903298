import React, { Component, SFC } from "react";
import classNames from "classnames";

import {
  SizeProps,
  ColorProps,
  getSizeClasses,
  getColorsClasses,
  excludeFromProps
} from "../common";
import { IconName } from "@fortawesome/pro-solid-svg-icons";

interface ButtonPropsInternal extends SizeProps, ColorProps {
  outlined?: boolean;
}

export type ButtonProps = ButtonPropsInternal & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    // innerRef,
    // icon,
    children,
    outlined,
    className,
    // other
    ...otherProps
  } = props;

  const sizeClass = getSizeClasses(props.size ? props.size : "");
  const colorClasses = getColorsClasses(props.color ? props.color : "");

  const parentClass = classNames("button", className, sizeClass, colorClasses, {
    "is-outlined": outlined
  });

  return (
    <button ref={ref} className={parentClass} {...excludeFromProps(otherProps)}>
      {children}
    </button>
  );
});

export const ButtonNoStyle = React.forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  return (
    <span
      ref={ref}
      {...props}
      style={{ pointerEvents: "auto", ...props.style }}
      onKeyDown={e => {
        let event = e || window.event;
        if ((event.key === "Enter" || event.key === " ") && props.onClick) {
          event.preventDefault();
          // contrôle la touche espace
          // event.target.dispatchEvent(new MouseEvent("click"));
          props.onClick && props.onClick(e as any);
        }

        props.onKeyDown && props.onKeyDown(e as any);
      }}
    />
  );
});

ButtonNoStyle.defaultProps = {
  tabIndex: 0
};
