export const INIT_ADMIN_DATATABLE = "INIT_ADMIN_DATATABLE";
export const INIT_ADMIN_DATATABLE_SUCCESS = "INIT_ADMIN_DATATABLE_SUCCESS";
export const CLEAR_ADMIN_DATATABLE = "CLEAR_ADMIN_DATATABLE";
export const INIT_DATATABLE_MODE_ADMIN = "INIT_DATATABLE_MODE_ADMIN";
export const ADMIN_DATATABLE_SAVE_NEW = "ADMIN_DATATABLE_SAVE_NEW";
export const ADMIN_DATATABLE_SAVE_NEW_SUCCESS = "ADMIN_DATATABLE_SAVE_NEW_SUCCESS";
export const ADMIN_DATATABLE_CLEAR_SAVE_STATE = "ADMIN_DATATABLE_CLEAR_SAVE_STATE";
export const ADMIN_DATATABLE_SAVE_SAVE_PICKLIST = "ADMIN_DATATABLE_SAVE_SAVE_PICKLIST";
export const ADMIN_DATATABLE_CLEAR_CTRLKEYS = "ADMIN_DATATABLE_CLEAR_CTRLKEYS";
export const SAVE_ADMIN_FOCUS_DATATABLE = "SAVE_ADMIN_FOCUS_DATATABLE";
export const ADMIN_DATATABLE_ADD_ONE_CTRLKEY = "ADMIN_DATATABLE_ADD_ONE_CTRLKEY";
export const SAVE_ADMIN_FILTER_BAR = "SAVE_ADMIN_FILTER_BAR";
export const DELETE_ADMIN_FILTER_BAR = "DELETE_ADMIN_FILTER_BAR";
export const ADMIN_DATATABLE_DELETE_FOCUS = "ADMIN_DATATABLE_DELETE_FOCUS";
