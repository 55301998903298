export const RESET_PROCESSUS = "RESET_PROCESSUS";
export const INIT_ADVANCED_PROCESS = "INIT_ADVANCED_PROCESS";
export const ADD_PROCESSUS_DEFINTION = "ADD_PROCESSUS_DEFINTION";
export const ADD_PROCESSUS_DEFINTION_SUCCESS = "ADD_PROCESSUS_DEFINTION_SUCCESS";
export const CHANGE_PROCESSUS_NEW_ENTITY_VALUE = "CHANGE_PROCESSUS_NEW_ENTITY_VALUE";
export const LAUNCH_ADVANCED_PROCESS = "LAUNCH_ADVANCED_PROCESS";
export const LAUNCH_ADVANCED_PROCESS_SUCCESS = "LAUNCH_ADVANCED_PROCESS_SUCCESS";
export const ADD_NEW_ENTITY = "ADD_NEW_ENTITY";
export const INCREMENT_INDEX = "INCREMENT_INDEX";
export const LAUNCH_PROCESS = "LAUNCH_PROCESS";
export const ON_APPLY_TO_ALL_CHANGE = "ON_APPLY_TO_ALL_CHANGE";
export const LAUNCH_NAVIGATION = "LAUNCH_NAVIGATION";
export const SET_PROCESSUS_CONTEXT = "SET_PROCESSUS_CONTEXT";
export const SET_PROCESSUS_ID = "SET_PROCESSUS_ID";
export const SET_PROCESSUS_MODULE = "SET_PROCESSUS_MODULE";
export const SET_PROCESSUS_TABLE_NAME = "SET_PROCESSUS_TABLE_NAME";
export const SET_PROCESSUS_FOR_ALL = "SET_PROCESSUS_FOR_ALL";
export const SET_PROCESSUS_CONTEXT_IDS = "SET_PROCESSUS_CONTEXT_IDS";
export const SET_PROCESSUS_URL_CPLT = "SET_PROCESSUS_URL_CPLT";
export const PREPARE_PROCESSUS_CONTEXT = "PREPARE_PROCESSUS_CONTEXT";

export const LAUNCH_PROCESS_FROM_GALAXY_HEADER = "LAUNCH_PROCESS_FROM_GALAXY_HEADER";
export const LAUNCH_PROCESS_FROM_GENERIC_LIST = "LAUNCH_PROCESS_FROM_GENERIC_LIST";
export const RESET_PACKAGE = "RESET_PACKAGE";
export const START_EDITION_IN_PROGRESS = "START_EDITION_IN_PROGRESS";

export const DISPLAY_EDITION_DOCUMENT = "DISPLAY_EDITION_DOCUMENT";
export const HIDE_EDITION_DOCUMENT = "HIDE_EDITION_DOCUMENT";

export const SET_EDITION_SUCCESS = "SET_EDITION_SUCCESS";
export const SET_EDITION_INDEX = "SET_EDITION_INDEX";
export const CLEAR_PROCESSUS_REDUCER = "CLEAR_PROCESSUS_REDUCER";
// Lancement d'une édition avec calcul de l'existence de paramètres avancés
export const LAUNCH_EDITION = "LAUNCH_EDITION";
// action de lancement directe de l'édition sans phase préalable de calcul des paramètres avancés
export const EXECUTE_EDITION = "EXECUTE_EDITION";
export const EXECUTE_AVANCED_EDITION = "EXECUTE_AVANCED_EDITION";
export const SET_MODE_EDITION = "SET_MODE_EDITION";
export const SET_PROCESS_IN_PROGRESS = "SET_PROCESS_IN_PROGRESS";
