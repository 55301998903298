import React, { FC } from "react";
import { Switch } from "@axin-org/comet";

import classNames from "classnames";

import { ComposantAllProps, getSizeClasses, getIntent } from "../common";

// Remplacer par un extends de HTMLInputElement
interface OnlyProps extends Omit<ComposantAllProps, "onChange"> {
  disabled?: boolean;
  value: string | boolean;
  onValueChange?(field: string, value: any): void;
}

interface CheckboxProps {
  label: string;
}

const Only: FC<OnlyProps> = props => {
  const {
    id,
    name,
    value,
    disabled,
    readOnly,
    onValueChange,
    onContextMenu,
    wviState,
    size,
    className,
    tooltip,
    type,
    ...restProps
  } = props;

  // Checked attend une valeur en boolean, cette transformation devrait se faire coté serveur normalement
  const checked = value ? (typeof value === "string" ? "O" === value : value) : false;

  const finalDisabled = readOnly || disabled;

  // La classe définissant la couleur dans un input est définie par le retour du wvi
  // const colorClasses = getColorsClasses(wviState ? wviState : "");
  const sizeClasses = getSizeClasses(size ? size : "");
  const classes = classNames(className, sizeClasses);

  return (
    <>
      <Switch
        {...restProps}
        id={id}
        name={name}
        intent={getIntent(wviState)}
        className={classes}
        disabled={finalDisabled}
        checked={checked}
        data-value={checked}
        onCheckedChange={val => onValueChange?.(name ?? "<unknown>", val)}
        onContextMenu={onContextMenu}
      />
      <label className={classes} htmlFor={id} title={tooltip} onContextMenu={onContextMenu} />
    </>
  );
};

export type CheckboxAllProps = CheckboxProps & OnlyProps;
const Checkbox: FC<CheckboxAllProps> & Partial<{ Only: any }> = ({
  disabled,
  label,
  ...otherProps
}) => {
  return (
    <div className="checkbox" {...{ disabled: disabled }}>
      <Only {...otherProps} disabled={disabled} />
    </div>
  );
};

Checkbox.Only = Only;

export default Checkbox;
