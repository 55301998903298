import React, { SFC, HTMLProps } from "react";
import classNames from "classnames";

export const Row: SFC<HTMLProps<HTMLDivElement>> = ({ children, className, ...restProps }) => (
  <div className={classNames("columns", className)} {...restProps}>
    {children}
  </div>
);

export const Col: SFC<{ span?: number; offset?: number } & HTMLProps<HTMLDivElement>> = ({
  children,
  span,
  offset,
  className,
  ...restProps
}) => (
  <div
    className={classNames("column", className, {
      [`is-${span}`]: span !== undefined,
      [`is-offset-${offset}`]: offset !== undefined
    })}
    {...restProps}
  >
    {children}
  </div>
);
