import { AxiosPromise } from "axios";
import { api, apiAdn, getUIContext } from "./common";
import { GalaxyInformation, StargateMenu, AppGalaxy, AppGalaxyInfo } from "types/Galaxy";
import { MenuGalaxy } from "types/Menu";
import { ComponentState } from "types/Component";

export function getAppMostUsed(): AxiosPromise<AppGalaxyInfo[]> {
  const params = getUIContext();
  return apiAdn.get(`/app/most-used?${params}`);
}

export function getAppState(): AxiosPromise<AppGalaxy> {
  const params = getUIContext();
  return apiAdn.get(`/app?${params}`);
}

export function toggleAppFavoris(
  code: string,
  focusCode: string | null
): AxiosPromise<AppGalaxyInfo[]> {
  const params = getUIContext({ sjmoCode: code });
  focusCode && params.set("galaxyFocusCode", focusCode);
  return apiAdn.post(`/app/favoris/toggle/${code}?${params}`);
}

/**
 * Information nécessaire à l'utilisation de la galaxie
 * - tableName
 * - mainGenSearch
 * - sjmoCode
 * @param sjmoCode code module
 */
export function getGalaxyInformation(sjmoCode: string): AxiosPromise<GalaxyInformation> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/galaxy/${sjmoCode}/info?${params}`);
}

export function getGalaxyMainGensearch(sjmoCode: string): AxiosPromise<ComponentState> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/galaxy/${sjmoCode}/main-gen-search?${params}`);
}

export function getStargateMenu(sjmoCode: string): AxiosPromise<StargateMenu[]> {
  const params = getUIContext({ sjmoCode });
  return api.get(`/stargate/menu?${params}`);
}

/**
 * Créer un trou de ver entre deux galaxies
 * @param from
 * @param to
 * @param tableName
 * @param entityId
 */
export function useStargateGco(
  from: string,
  to: string,
  tableName: string,
  entityId: string
): AxiosPromise<string> {
  const params = getUIContext({ sjmoCode: from });
  params.append("from", from);
  params.append("to", to);
  params.append("tableName", tableName);
  params.append("entityId", entityId.toString());
  return api.get(`/stargate/gco?${params}`);
}

/**
 * Permet de toggle le menu depuis les favoris
 * @param menuId id du menu
 */
export function toggleMenuToFavoris(sjmoCode: string): AxiosPromise<MenuGalaxy[]> {
  return apiAdn.post(`/menu/favoris/toggle/${sjmoCode}`);
}

export function addMenuToFavoris(sjmoCode: string): AxiosPromise<MenuGalaxy[]> {
  return apiAdn.post(`/menu/favoris/add/${sjmoCode}`);
}

export function reorderFavorisMenu(favoris: MenuGalaxy[]): AxiosPromise<MenuGalaxy[]> {
  return apiAdn.post(`/menu/favoris/reorder`, favoris);
}

export function deleteFavorisMenu(code: string): AxiosPromise<MenuGalaxy[]> {
  return apiAdn.delete(`/menu/favoris/${code}`);
}
