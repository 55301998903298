import { AxiosPromise } from "axios";

import { apiAdn, getUIContext } from "./common";
import { InteractionDefinitionState } from "reducers/modules/InteractionReducer";

// Fonction qui récupère la liste des interactions d'une galaxie
export function fetchInteractionsGalaxy(
  sjmoCode: string
): AxiosPromise<InteractionDefinitionState[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/interaction/definition?${params}`);
}

// Fonction qui récupère la liste des interactions définies de façon globale
export function fetchInteractionsGlobale(): AxiosPromise<InteractionDefinitionState[]> {
  const params = getUIContext();
  return apiAdn.get(`/interaction/definition/global?${params}`);
}

// Fonction qui récupère la liste des interaction définies pour une table
export function fetchInteractionsByTable(
  tableName: string
): AxiosPromise<InteractionDefinitionState[]> {
  const params = getUIContext();
  params.append("tableName", tableName);
  return apiAdn.get(`/interaction/definition/table?${params}`);
}

export function deleteInteraction(
  sjmoCode: string,
  definitionId: number | string
): AxiosPromise<void> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.delete(`/interaction/definition/${definitionId}?${params}`);
}
