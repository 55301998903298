import toaster from "composants/toaster/toaster";
import { uuidv4 } from "./uuid.utils";
import { t } from "./i18n";
import { NotificationGroup } from "types/Notification";

export function copyToClipboard(toCopy: string) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(toCopy);
    toaster.notify({
      intent: "INFO",
      group: NotificationGroup.DEFAULT,
      title: t("commun_copie_dans_presse_papier"),
      priority: "NORMAL",
      id: uuidv4(),
      createdAt: new Date().toISOString()
    });
  } else {
    toaster.notify({
      intent: "INFO",
      group: NotificationGroup.DEFAULT,
      title: "voici l'id du panel, vous pouvez le copier.",
      message: toCopy,
      priority: "CRITICAL",
      id: uuidv4(),
      createdAt: new Date().toISOString()
    });
  }
}
