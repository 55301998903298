import React from "react";
import { format } from "date-fns";
import { ViewDef } from "@fullcalendar/core";
import { Stack } from "@axin-org/comet";
import { SchedulerDefinition } from "../Scheduler";

export function agendaTaskTemplate(
  info: {
    isMirror: boolean;
    isStart: boolean;
    isEnd: boolean;
    event: any;
    el: HTMLElement;
    view: ViewDef;
  },
  definition: SchedulerDefinition
): any {
  return (
    <Stack style={{ paddingBottom: "8" }}>
      {info.view.type === "dayGridMonth" && (
        <div className="pl-8" style={{ color: "white" }}>
          {`${format(info.event.start as Date, "HH:mm")} - ${format(
            info.event.end as Date,
            "HH:mm"
          )}`}
        </div>
      )}

      <div
        style={{ position: "relative", left: "1em", color: "white" }}
        dangerouslySetInnerHTML={{ __html: info.event.title }}
      />
    </Stack>
  );
}
