// galaxie
export const INIT_GALAXIE = "INIT_GALAXIE";
export const INIT_GALAXIE_LOADING = "INIT_GALAXIE_LOADING";
export const INIT_GALAXIE_SUCCESS = "INIT_GALAXIE_SUCCESS";
export const INIT_GALAXIE_ERROR = "INIT_GALAXIE_ERROR";

export const CLOSE_GALAXIE = "CLOSE_GALAXIE";
export const CLOSE_GALAXIE_SUCCESS = "CLOSE_GALAXIE_SUCCESS";

export const SHOULD_NOT_UPDATE_GALAXY = "SHOULD_NOT_UPDATE_GALAXY";

export const CLOSING_DIRTY_GALAXY = "DIRTY_GALAXY";
export const CANCEL_CLOSING_DIRTY_GALAXY = "CANCEL_CLOSING_DIRTY_GALAXY";
export const FORCE_CLOSE_DIRTY_GALAXY = "FORCE_CLOSE_DIRTY_GALAXY";
export const SAVE_CLOSE_GALAXY = "SAVE_CLOSE_GALAXY";

export const SET_GALAXY_ACTIVE = "SET_GALAXY_ACTIVE";
export const RESET_GALAXY = "RESET_GALAXY";

export const DELETE_MAIN_ENTITY = "DELETE_MAIN_ENTITY";
