import { apiAdn, getUIContext } from "./common";
import { AxiosPromise } from "axios";
import { FocusState } from "types/Focus";
import { ComponentState } from "react";
import { Pojo } from "types/Galaxy";

export function getDatatableFocusModeAdmin(
  sjmoCode: string,
  tableName: string,
  ctrlKey: string
): AxiosPromise<FocusState[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/admin/datatable/focus?${params}&tableName=${tableName}&ctrlKey=${ctrlKey}`);
}

export function getCtrlKeys(sjmoCode: string, tableName: string): AxiosPromise<FocusState[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/admin/datatable/ctrlKeys?${params}&tableName=${tableName}`);
}

export function adminDatatableSaveNew(props: {
  sjmoCode: string;
  tableName: string;
  ctrlKey?: string;
  title: string;
  syjModuleId?: string;
  sysMenuGroupeId?: string;
  personnelId?: string;
  privilegie?: boolean;
}): AxiosPromise<FocusState> {
  const params = getUIContext({ sjmoCode: props.sjmoCode });
  return apiAdn.post(`/admin/datatable/focus?${params}`, props);
}

export function getFilterbarForm(
  sjmoCode: string,
  tableName: string
): AxiosPromise<ComponentState[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/admin/datatable/filter-form?${params}&tableName=${tableName}`);
}

export function getAdminFilterbar(
  sjmoCode: string,
  tableName: string,
  ctrlKey: string
): AxiosPromise<Pojo[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(
    `/admin/datatable/filter-bar?${params}&tableName=${tableName}&ctrlKey=${ctrlKey}`
  );
}

export function saveAdminFilterBar(sjmoCode: string, filter: Pojo): AxiosPromise<Pojo> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.post(`admin/datatable/filter-bar?${params}`, filter);
}
export function deleteDatatableFocus(sjmoCode: string, focusId: string): AxiosPromise<number> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.delete(`admin/datatable/focus?${params}&focusId=${focusId}`);
}

export function updateAdminFocusOptions(
  sjmoCode: string,
  obj: any,
  privilege: boolean
): AxiosPromise<string> {
  const params = getUIContext({ sjmoCode });
  params.set("privilege", privilege.toString());
  return apiAdn.put(`admin/datatable/focus/options?${params}`, obj);
}

export function getClauseMandatory(sjmoCode: string, tableId: string, ctrlKey: string) {
  const params = getUIContext({ sjmoCode });
  params.set("tableId", tableId);
  params.set("ctrlKey", ctrlKey);
  return apiAdn.get(`admin/datatable/get-clause-mandatory?${params}`);
}

export function updateColumns(sjmoCode: string, table: Pojo) {
  const params = getUIContext({ sjmoCode });
  return apiAdn.post(`admin/datatable/update-columns?${params}`, table);
}

export function deleteClauseMandatory(sjmoCode: string, clauseId: string) {
  const params = getUIContext({ sjmoCode });
  params.set("clauseId", clauseId);
  return apiAdn.delete(`admin/datatable/delete-clause-mandatory?${params}`);
}
