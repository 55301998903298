// mini-expert
export const INIT_MINI_EXPERT = "INIT_MINI_EXPERT";
export const FETCH_FOCUS_MINI_EXPERT = "FETCH_FOCUS_MINI_EXPERT";
export const FETCH_FOCUS_MINI_EXPERT_SUCCESS = "FETCH_FOCUS_MINI_EXPERT_SUCCESS";
export const FOCUS_CHANGE_MINI_EXPERT = "FOCUS_CHANGE_MINI_EXPERT";
export const FETCH_MINI_EXPERT_GROUP_AND_CHANGE_FOCUS = "FETCH_MINI_EXPERT_GROUP_AND_CHANGE_FOCUS";
export const FETCH_FOCUS_MINI_EXPERT_GROUP = "FETCH_FOCUS_MINI_EXPERT_GROUP";
export const FETCH_FOCUS_MINI_EXPERT_GROUP_SUCCESS = "FETCH_FOCUS_MINI_EXPERT_GROUP_SUCCESS";
export const SHOULD_UPDATE_MINI_EXPERT = "SHOULD_UPDATE_MINI_EXPERT";

export const ON_CLOSE_MINIEXPERT_CALLBACK = Symbol.for("ON_CLOSE_MINIEXPERT_CALLBACK");
