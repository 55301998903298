import { ReducerState } from "reducers";
import { Association } from "types/admin";
import { t } from "utils/i18n";
import { Pojo } from "types/Galaxy";
import { get } from "lodash-es";
import { findMap, mapToList, mapToUnorderedList } from "utils/entities.utils";
import { AffectationType } from "enum";

export const selectGlobalAssociation = (
  state: ReducerState,
  sjmoCode: string,
  focusId: string,
  tableName: string,
  columnName: string,
  privilegie: string
): Association => {
  let globalAssociation: Association = {
    type: AffectationType.GLOBAL,
    contextId: "global",
    privilegie: false,
    label: t("commun_global")
  } as Association;

  // Association global
  const assGlobals = get(state, ["entities", sjmoCode, tableName], undefined);

  // Récupération de l'association existante
  if (assGlobals && focusId) {
    const existGlobal: Pojo | null = findMap(
      assGlobals,
      pojo => pojo[columnName].toString() === focusId
    );
    // Enrichissement de l'association avec l'existant
    if (existGlobal) {
      globalAssociation = {
        ...existGlobal,
        type: AffectationType.GLOBAL,
        privilegie: existGlobal[privilegie],
        creatorFocusId: focusId,
        label: t("commun_global")
      } as Association;
    }
  }
  return globalAssociation;
};

export const selectRoleAssociation = (
  state: ReducerState,
  sjmoCode: string,
  focusId: string,
  tableName: string,
  columnName: string,
  privilegie: string
): Association[] => {
  let roleAssociations: Association[] = [];

  if (focusId) {
    // Récupéaration de la liste des associations par rôle existante
    const assRoles: Record<string, Pojo> = get(state, ["entities", sjmoCode, tableName]);
    if (assRoles) {
      roleAssociations = mapToList(assRoles)
        .filter((pojo: Pojo) => pojo[columnName].toString() === focusId)
        .map((pojo: Pojo) => {
          return {
            ...pojo,
            type: AffectationType.ROLE,
            privilegie: pojo[privilegie],
            creatorFocusId: focusId,
            contextId: pojo.sysMenuGroupeId,
            label: pojo.label
          } as Association;
        });
    }

    // Completion de la liste avec la liste de tous les rôle non selectionné
    const allRoles: Record<string, Pojo> = get(state, ["entities", sjmoCode, "sysMenuGroupe"]);
    if (allRoles) {
      let remaining = mapToUnorderedList(allRoles).filter((pojo: Pojo) => {
        const index = roleAssociations.findIndex(ass => ass.contextId === pojo.id);
        return index === -1;
      });
      remaining.forEach(pojo => {
        roleAssociations.push({
          id: pojo.id,
          type: AffectationType.ROLE,
          privilegie: false,
          contextId: pojo.id,
          label: pojo.label
        } as Association);
      });
    }
  }

  return roleAssociations;
};

export const selectUserAssociation = (
  state: ReducerState,
  sjmoCode: string,
  focusId: string,
  tableName: string,
  columnName: string,
  privilegie: string
): Association[] => {
  let userAssociations: Association[] = [];

  // Récupération de la liste des liens existants
  const assUser: Record<string, Pojo> = get(state, ["entities", sjmoCode, tableName]);
  if (assUser && focusId) {
    userAssociations = mapToList(assUser)
      .filter((pojo: Pojo) => pojo[columnName].toString() === focusId)
      .map((pojo: Pojo) => {
        return {
          ...pojo,
          type: AffectationType.USER,
          privilegie: pojo[privilegie],
          creatorFocusId: focusId,
          contextId: pojo.personnelId,
          label: pojo.label
        } as Association;
      });
  }

  // Complétion de la liste avec tout les users
  const allUser: Record<string, Pojo> = get(state, ["entities", sjmoCode, "personnel"]);
  if (allUser) {
    let remaining = mapToUnorderedList(allUser).filter((pojo: Pojo) => {
      const index = userAssociations.findIndex(ass => ass.contextId === pojo.id);
      return index === -1;
    });
    remaining.forEach(pojo => {
      userAssociations.push({
        id: pojo.id,
        type: AffectationType.USER,
        privilegie: false,
        contextId: pojo.id,
        label: pojo.label
      } as Association);
    });
  }
  return userAssociations;
};
