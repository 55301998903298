import {
  VALUE_CHANGE,
  ADD_ENTITY,
  GET_AND_ADD_ENTITY,
  FIND_ONE_SUCCESS,
  ADD_ALL_ENTITIES,
  REMOVE_ENTITIES
} from "constant/entities";
import immer from "immer";
import Action from "reducers/Action";
import { Pojo } from "types/Galaxy";
import { UPDATE_ITEMS_AUTOCOMPLETE } from "constant/autocomplete.constant";
import { preparePojo } from "utils/entities.utils";
import { isEqual, get } from "lodash-es";

// State redux du mini-expert
export interface EntitesState {
  [sjmoCode: string]: { [tableName: string]: { [key: string]: Pojo } };
}

// type alias pour l'action dans le reducer de dashboard
type EntitesAction = Action<any>;

const initialState = {};

export default function(state: EntitesState = initialState, action: EntitesAction): EntitesState {
  // il faut migrer sur immer
  switch (action.type) {
    case FIND_ONE_SUCCESS: {
      action.payload.reset = true;
      return addEntity(state, action);
    }
    case VALUE_CHANGE:
      return valueChange(state, action);
    case ADD_ENTITY:
      return addEntity(state, action);
    case GET_AND_ADD_ENTITY:
      return getAndAddEntity(state, action);

    case UPDATE_ITEMS_AUTOCOMPLETE:
    case ADD_ALL_ENTITIES:
      return addEntities(state, action);

    case REMOVE_ENTITIES:
      return removeEntities(state, action);

    default:
      return state;
  }
}

function addEntity(
  state: EntitesState,
  action: Action<{
    sjmoCode: string;
    value: Pojo;
    key: string | number;
    tableName: string;
    reset: boolean;
  }>
): EntitesState {
  const { sjmoCode, value, tableName, key, reset } = action.payload;
  return immer(state, draft => {
    if (!draft[sjmoCode]) {
      draft[sjmoCode] = {};
    }
    changeOneEntity(state, draft, value, sjmoCode, tableName, reset);
  });
}

function addEntities(
  state: EntitesState,
  action: Action<{ sjmoCode: string; tableName: string; pojos: Pojo[]; reset: boolean }>
): EntitesState {
  const { sjmoCode, tableName, pojos, reset } = action.payload;
  return immer(state, draft => {
    if (!draft[sjmoCode]) {
      draft[sjmoCode] = {};
    }

    for (let pojo of pojos) {
      changeOneEntity(state, draft, pojo, sjmoCode, tableName, reset);
    }
  });
}

function changeOneEntity(
  state: EntitesState,
  draft: EntitesState,
  newValue: Pojo,
  sjmoCode: string,
  tableName: string,
  reset: boolean
) {
  // on récupère l'entité courante + les jointures (dans le cas où elles sont présentes)
  const pojoByTableNames = preparePojo(newValue);
  const tableNames = Object.keys(pojoByTableNames);

  // Si l'entity existe déjà dans le reducer
  // Et que la nouvelle entity n'a pas remplie la propriété _style
  // On set la propriété _style dans la nouvelle valeur
  for (let currentTableName of tableNames) {
    const ids = Object.keys(pojoByTableNames[currentTableName]);
    for (let currentId of ids) {
      const oldPojo: Pojo | undefined = get(state, [sjmoCode, currentTableName, currentId]);
      const newPojo: Pojo = get(pojoByTableNames, [currentTableName, currentId]);

      const style = oldPojo ? oldPojo._style : {};

      if (!newPojo._style || isEqual({}, newPojo._style)) {
        pojoByTableNames[currentTableName][currentId] = {
          ...pojoByTableNames[currentTableName][currentId],
          _style: style
        };
      }
    }
  }

  // on boucle par entité
  for (let pojoTableName of tableNames) {
    // si la tableName n'existe pas, il faut le rajouter avant d'ajouter l'entité.
    if (!draft[sjmoCode][pojoTableName]) {
      draft[sjmoCode][pojoTableName] = {};
    }

    const ids = Object.keys(pojoByTableNames[pojoTableName]);
    for (let currentId of ids) {
      // on vérifie que l'entité n'est pas déjà présente
      const oldPojo = draft[sjmoCode][pojoTableName][currentId];

      // dans le cas où elle est présente, on ne remplace que si modifie = false ou que l'on est sur un reset.
      if ((reset && oldPojo && oldPojo.tableName === tableName) || !oldPojo || !oldPojo.modifie) {
        draft[sjmoCode][pojoTableName][currentId] = {
          ...oldPojo,
          ...pojoByTableNames[pojoTableName][currentId]
        };

        // on utilise le `modifie` de l'entité que l'on passe en cas de reset
        if (reset) {
          draft[sjmoCode][pojoTableName][currentId].modifie =
            pojoByTableNames[pojoTableName][currentId].modifie;
        }
      }
    }
  }
}

function valueChange(state: EntitesState, action: EntitesAction): EntitesState {
  const sjmoCode = action.payload.sjmoCode;
  const key = action.payload.key;
  const tableName = action.payload.tableName;
  const value = action.payload.value;
  const ctrlkey = action.payload.ctrlkey;

  return immer(state, draft => {
    const workingKey = key;
    const entity = draft[sjmoCode][tableName][workingKey];
    entity[ctrlkey] = value;
    // à partir de maintenant, l'entité est considéré comme modifié
    entity.modifie = true;
  });
}

function getAndAddEntity(state: EntitesState, action: EntitesAction): EntitesState {
  const sjmoCode = action.payload.sjmoCode;
  const entity = action.payload.value;
  const key = action.payload.key;
  const tableName = action.payload.tableName;
  return immer(state, draft => {
    if (!draft[sjmoCode]) {
      draft[sjmoCode] = {};
      draft[sjmoCode][tableName] = {};
    }

    if (!draft[sjmoCode][tableName]) {
      draft[sjmoCode][tableName] = {};
    }

    const workingKey = key;
    draft[sjmoCode][tableName][workingKey] = entity;
  });
}

function removeEntities(
  state: EntitesState,
  action: Action<{ sjmoCode: string; tableName: string; ids: number[] }>
): EntitesState {
  const { sjmoCode, tableName, ids } = action.payload;

  return immer(state, draft => {
    const current = get(draft, [sjmoCode, tableName], undefined);
    if (current) {
      for (let id of ids) {
        delete current[id];
      }
    }
  });
}
