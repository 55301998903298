import React, { FC, useState, useEffect } from "react";
import Loader from "./Loader";
import { fetchOneTranslation } from "api";
import { LOADER_TIME_TRIGGER } from "customGlobal";
import { withRaf } from "./RafComponent";

export function useTraduction(i18nKey: string) {
  const [traduction, setTraduction] = useState<string>("");
  const [state, setState] = useState<"INITIAL" | "LOADING" | "ERROR" | "OK">("INITIAL");

  useEffect(() => {
    let timer = setTimeout(() => {
      setState("LOADING");
    }, LOADER_TIME_TRIGGER() / 2);

    fetchOneTranslation(i18nKey)
      .then(res => {
        setTraduction(res.data);
        setState("OK");
      })
      .catch(() => {
        setTraduction("");
        setState("ERROR");
      })
      .then(() => {
        clearTimeout(timer);
      });

    return () => clearTimeout(timer);
  }, [i18nKey]);

  return [traduction, state];
}

const GetTraductionInternal: FC<{ i18nKey: string }> = ({ i18nKey }) => {
  const [traduction, state] = useTraduction(i18nKey);

  switch (state) {
    case "INITIAL":
      return null;
    case "ERROR":
      return <div>error during the fetch of traduction for {i18nKey}</div>;
    case "LOADING":
      return <Loader />;
    case "OK":
      return <>{traduction}</>;
    default:
      return null;
  }
};

export const GetTraduction = withRaf(
  React.memo(
    GetTraductionInternal,
    (prevProps, nextProps) => prevProps.i18nKey === nextProps.i18nKey
  )
);
