import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, WithTranslation, Trans } from "react-i18next";
import get from "lodash-es/get";

import { State } from "xstate";
import { Action, ActionObject } from "xstate/lib/types";

import { arrayMove } from "react-sortable-hoc";
import { RSQLCriteria, RSQLFilterExpression, Operators } from "rsql-criteria-typescript";
import Scrollbars from "react-custom-scrollbars";
import memoize from "memoizee";
import produce from "immer";

import { ReducerState } from "reducers";
import { addSelectionFromLov, AddSelectionFromLovParams } from "actions/lov.action";
import { updateRowDatatable } from "actions/datatable.action";
import { addEntity } from "actions/actionEntities";

import { selectAnyEntityById } from "selectors";
import { selectContextAsDetail } from "selectors/context.selector";

import Modal from "../Modal/Modal";
import { GenericDatatable } from "../datatable";
import { DatatableSort } from "../datatable/table/datatableBehavior";
import { ToolbarButtonOverride } from "../datatable/Toolbar";
import { Panier, PanierItem } from "./LovComponent";
import { lovMachine } from "./machine";

import { Pojo } from "types/Galaxy";
import { GenericListComponent, LovMapping, FilterBarDefinition } from "types/Component";

import { getLovDefinition, getLovColumn, getLovData, getLovDefaultColumn } from "api/lov";
import { find, preRecord } from "api";

import { listToMap } from "utils/entities.utils";
import { mapToRSQL, initRsqlCriteria } from "utils/query.utils";

import "./Lov.css";
import { LOADER_TIME_TRIGGER } from "customGlobal";
import { FilterBar } from "types/Search";
import { Fa } from "composants/Icon";
import { getDatatableKey } from "containers/datatable/Datatable";
import { format } from "date-fns";
import { track } from "tracking";

const LOV_DATATABLE_BUTTON_VISISIBLITY: ToolbarButtonOverride = {
  add: false,
  save: false,
  delete: false,
  focus: false,
  processus: false,
  satellite: false,
  exportExcel: false,
  exportPdf: false,
  refresh: true,
};

interface LovProps {
  // path pour la validation
  sjmoCode: string;
  targetTableName: string;
  targetCtrlKey: string;
  targetId?: string;
  lovType: "DT" | "GS";
  contextTableName?: string;
  contextId?: string | null;
  // pour charger la lov
  column: string;
  tableName: string;
  syjLovId: string | null;
  onClose(): void;
}

interface LovPropsRedux {
  interactionOrig: Record<string, any>;
  targetEntity: Pojo | null;
  targetEntityOrigin: "CREATOR" | "DATATABLE" | "ENTITIES";
}

interface LovPropsReduxFn {
  addSelectionFromLov(params: AddSelectionFromLovParams): Promise<void>;
  updateRowDatatable(sjmoCode: string, ctrlKey: string, pojo: Pojo): void;
  addEntity(
    sjmoCode: string,
    tableName: string,
    key: string, // id
    value: any,
    reset?: boolean
  ): Promise<void>;
}

interface LovState {
  entityToClone: Pojo | null;
  preRecordFailed: boolean;
  currentMachine: State<any, any>;
  lovId: string | null;
  mappings: LovMapping[];
  filters: FilterBarDefinition[];
  filterBar: FilterBar;
  selectedRows: number[];
  columns: GenericListComponent[];
  entities: Record<string, Pojo | "LOADING_POJO">;
  totalRecords: number;
  selectedEntities: Pojo[];
  loading: boolean;
}

type LovAllProps = LovProps & LovPropsRedux & LovPropsReduxFn & WithTranslation;
type ActionStateLov = { type: string; [key: string]: any };

class Lov extends Component<LovAllProps, LovState> {
  state: LovState = {
    entityToClone: null,
    preRecordFailed: false,
    currentMachine: lovMachine.initialState,
    lovId: null,
    mappings: [],
    filters: [],
    selectedRows: [],
    columns: [],
    entities: {},
    totalRecords: 0,
    selectedEntities: [],
    loading: false,
    filterBar: {
      filterBarId: null,
      startDate: null,
      endDate: null,
    },
  };

  removeRow = memoize((index: number) => () => {
    const newState = produce(this.state, (draft) => {
      draft.selectedEntities.splice(index, 1);
    });
    this.setState(newState, this.refreshDataFromTable);
  });

  getColumnNameId = memoize((lovId: string | null) => {
    if (!lovId) {
      return "id";
    }

    let filtered = this.state.columns.filter((col) => col.isId);
    return filtered.length > 0 ? filtered[0].column : "";
  }, {});

  private gridRef = React.createRef<GenericDatatable>();
  private isValidating = false;

  get selectionUnique() {
    return this.props.lovType === "GS";
  }

  get isLovColumn() {
    return this.state.lovId !== null;
  }

  componentDidMount() {
    this.transition({ type: "START" });
    if (this.props.lovType === "DT") {
      preRecord({
        sjmoCode: this.props.sjmoCode,
        tableName: this.props.targetTableName,
        context: this.props.interactionOrig,
      })
        .then((res) =>
          this.setState({
            entityToClone: res.data,
          })
        )
        .catch(() => {
          console.error("error pre-record of table orig during lov initialization");
          this.setState({
            entityToClone: null,
            preRecordFailed: true,
          });
        });
    }
  }

  componentDidUpdate(prevProps: LovProps, prevState: LovState) {
    if (
      prevState.columns !== this.state.columns ||
      prevState.columns.length !== this.state.columns.length
    ) {
      this.getColumnNameId.clear();
    }
  }

  fetchData = (
    first = 0,
    size = 10,
    filter: RSQLCriteria = initRsqlCriteria(),
    reset: boolean = false
  ) => {
    const columnName = this.getColumnNameId(this.state.lovId);

    let notIn: string[] = [];
    for (let entity of this.state.selectedEntities) {
      if (entity) {
        notIn.push(entity[columnName]);
      }
    }
    if (notIn.length > 0) {
      filter.filters.and(new RSQLFilterExpression(columnName, Operators.NotIn, notIn));
    }

    const { contextTableName, contextId } = this.props;

    let contextParameter =
      contextTableName && contextId
        ? `&contextTableName=${contextTableName}&contextId=${contextId}`
        : "";

    let moduleParameter = `&sjmoCode=${this.props.sjmoCode}`;

    let filterBarParameter = "";
    if (this.state.filterBar) {
      if (this.state.filterBar.filterBarId) {
        filterBarParameter = `&filterBarId=${this.state.filterBar.filterBarId}`;
      }

      if (this.state.filterBar.startDate) {
        filterBarParameter += `&filterBarStart=${encodeURIComponent(
          this.state.filterBar.startDate
        )}`;
      }

      if (this.state.filterBar.endDate) {
        filterBarParameter += `&filterBarEnd=${encodeURIComponent(this.state.filterBar.endDate)}`;
      }
    }
    if (this.state.lovId) {
      this.fetchLovData(
        first,
        size,
        filter.build() + filterBarParameter + contextParameter + moduleParameter,
        reset
      );
    } else {
      this.fetchDataDefault(
        first,
        size,
        filter.build() + contextParameter + moduleParameter,
        reset
      );
    }
  };

  fetchDataDefault = (first: number, size: number, filter: string, reset: boolean) => {
    find(this.props.tableName, filter, first, size).then((res) => {
      const entities = reset
        ? listToMap(res.data.data, first)
        : { ...this.state.entities, ...listToMap(res.data.data, first) };

      this.transition({
        type: "DATA_SUCCESS",
        entities,
        totalRecords: res.data.meta.totalRecords,
      });
    });
  };

  fetchLovData = (first: number, size: number, filter: string, reset: boolean) => {
    if (this.state.lovId) {
      getLovData(this.state.lovId, first, size, filter, this.context).then((res) => {
        const entities = reset
          ? listToMap(res.data.data, first)
          : { ...this.state.entities, ...listToMap(res.data.data, first) };

        this.transition({
          type: "DATA_SUCCESS",
          entities,
          totalRecords: res.data.meta.totalRecords,
        });
      });
    }
  };

  command(action: ActionObject<any, any>, event: ActionStateLov): Record<string, any> | void {
    switch (action.type) {
      case "fetchDefinition":
        getLovDefinition(
          this.props.sjmoCode,
          this.props.column,
          this.props.tableName,
          this.props.syjLovId
        )
          .then((res) => {
            this.transition({ type: "DEFINITION_SUCCESS", lovDefinition: res.data });
          })
          .catch(() => console.error("error during fetch of lov definition"));
        break;
      case "setLovDefinition":
        return {
          lovId: event.lovDefinition.id,
          mappings: event.lovDefinition.mappings,
          filters: event.lovDefinition.filters,
        };

      case "fetchColumns":
        if (event.lovDefinition.id) {
          getLovColumn(event.lovDefinition.id)
            .then((res) =>
              this.transition({
                type: "COLUMNS_SUCCESS",
                columns: res.data.map((col) => ({ ...col, compoVisible: true })),
              })
            )
            .catch(() => console.error("error during feth of lov columns"));
        } else if (this.props.tableName) {
          getLovDefaultColumn(this.props.tableName)
            .then((res) =>
              this.transition({
                type: "COLUMNS_SUCCESS",
                columns: res.data.map((col) => ({ ...col, compoVisible: true })),
              })
            )
            .catch(() => console.error("error during feth of default lov columns"));
        }
        break;
      case "setColumns":
        return { columns: event.columns };
      case "fetchLovData":
      case "fetchData":
        this.fetchData(event.first, event.size, event.filter, event.reset);
        break;
      case "setData":
        return { entities: event.entities, totalRecords: event.totalRecords };
      default:
        // nothing to do
        break;
    }
  }

  transition = (eventType: ActionStateLov, callback?: () => void) => {
    const { currentMachine } = this.state;
    const nextMachine = lovMachine.transition(currentMachine, eventType.type);

    const nextState = nextMachine.actions.reduce(
      (state: any, action: any) => this.command(action, eventType) || state,
      undefined
    );

    this.setState(
      {
        currentMachine: nextMachine.value,
        ...nextState,
      },
      () => callback && callback()
    );
  };

  refreshDataFromTable = () => this.gridRef.current && this.gridRef.current.refresh();

  onReorderEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.setState({
      selectedEntities: arrayMove(this.state.selectedEntities, oldIndex, newIndex),
    });
  };

  onRowSelect = (rowIndex: number | "ALL") => {
    let newSelectedRows: number[] = [];
    if (this.selectionUnique && rowIndex === "ALL") {
      newSelectedRows = [0];
    } else if (rowIndex === "ALL") {
      // on sélectionne tous les ids
      for (let i = 0, index = this.state.totalRecords; i < index; i++) {
        newSelectedRows.push(i);
      }
    } else if (this.selectionUnique && this.state.selectedRows.indexOf(rowIndex) === -1) {
      newSelectedRows = [rowIndex];
    } else if (this.state.selectedRows.indexOf(rowIndex) === -1) {
      newSelectedRows = this.state.selectedRows.concat(rowIndex);
    }

    this.setState(
      {
        selectedRows: newSelectedRows,
      },
      () => this.gridRef.current && this.gridRef.current.recomputeHeader()
    );
  };

  onRowUnselect = (rowIndex: number | "ALL") => {
    let newSelectedRows: number[] = [];
    if (rowIndex !== "ALL") {
      newSelectedRows = this.state.selectedRows.filter((row) => row !== rowIndex);
    }

    this.setState(
      {
        selectedRows: newSelectedRows,
      },
      () => this.gridRef.current && this.gridRef.current.recomputeHeader()
    );
  };

  clearSelections = () => {
    this.setState(
      {
        selectedEntities: [],
      },
      this.refreshDataFromTable
    );
  };

  validateSelections = () => {
    console.log("validate marche");
    if (this.state.preRecordFailed) {
      return;
    }

    if (this.isValidating) {
      return;
    }
    this.isValidating = true;
    const timer = setTimeout(() => {
      this.setState({ loading: true });
    }, LOADER_TIME_TRIGGER());

    const dispatchToEntity = (pojo: Pojo) => {
      const { sjmoCode, targetTableName, targetId, targetCtrlKey } = this.props;

      let eventName: string | null = null;
      /*
      #1250
      le créator n'utilise plus redux
      if (this.props.targetEntityOrigin === "CREATOR") {
        this.props.addNewEntityCreator(pojo);
      } else */
      if (this.props.targetEntityOrigin === "DATATABLE") {
        let dtKey = getDatatableKey(sjmoCode, targetCtrlKey, targetTableName);
        eventName = `${dtKey}--lov`;
      } else if (this.props.targetEntityOrigin === "ENTITIES") {
        this.props.addEntity(sjmoCode, targetTableName, targetId as string, pojo, true);
      }

      if (eventName != null) {
        let customEvent = new CustomEvent<{ pojo: Pojo }>(eventName, { detail: { pojo: pojo } });
        window.dispatchEvent(customEvent);
      }
    };

    this.props
      .addSelectionFromLov({
        entityToClone:
          this.props.lovType === "GS" ? this.props.targetEntity : this.state.entityToClone,
        sjmoCode: this.props.sjmoCode,
        tableOrig: this.props.targetTableName,
        target: this.props.targetCtrlKey,
        isDatatable: !this.selectionUnique,
        mappings: this.state.mappings || [
          { columnTarget: this.props.column, columnOrig: "id", wvi: true },
        ],
        selectionToAdd: this.state.selectedEntities,
        // on ne remplace pas les uuids lorsque l'on est sur une GS car on modifie seulement l'entité d'origine
        replaceUuid: this.props.lovType === "GS" ? false : true,
        dispatchToEntity,
      })
      .then(() => {
        this.isValidating = false;
        clearTimeout(timer);
        this.setState({
          loading: false,
        });
      })
      .then(() => {
        track("lov::confirmed");
        this.props.onClose();
      })
      .catch(() => {
        this.isValidating = false;
        clearTimeout(timer);
        this.setState({
          loading: false,
        });
      });
  };

  loadMoreData = ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
    return new Promise<void>((resolve) => {
      this.transition(
        { type: "SEARCH_DATA", first: startIndex, size: stopIndex - startIndex },
        resolve
      );
    });
  };

  updateSearch = (
    filter?: Record<string, any>,
    sort?: Record<string, DatatableSort>
  ): Promise<void> => {
    return new Promise<void>((resolve) => {
      const rsql = mapToRSQL(filter, sort);
      this.transition({ type: "SEARCH_DATA", filter: rsql, reset: true }, () => resolve());
    }).then(() => {
      this.gridRef.current && this.gridRef.current.recomputeGrids();
    });
  };

  addToPanier = () => {
    if (this.selectionUnique && this.state.selectedEntities.length >= 1) {
      return;
    }

    // Pick<T, Exclude<keyof T, K>>
    const newState = produce(this.state, (draft) => {
      for (let row of draft.selectedRows) {
        let curr = draft.entities[row];
        if (curr && curr !== "LOADING_POJO") {
          draft.selectedEntities.push(curr);
        }
      }

      draft.selectedRows = [];
    });

    this.setState(newState, this.refreshDataFromTable);
  };

  changeFilterBar = (name: string, value: any) => {
    this.setState(
      {
        filterBar: {
          ...this.state.filterBar,
          [name]: value,
        },
      },
      this.refreshDataFromTable
    );
  };

  render() {
    return (
      <Modal
        show
        title={
          <Trans
            // ns="lov"
            i18nKey={this.selectionUnique ? "lov:lov_title_unique" : "lov:lov_title_multiple"}
            values={{ name: this.props.tableName }}
          >
            Sélection : {{ name: this.props.tableName }}
          </Trans>
        }
        onClose={() => {
          this.props.onClose();
          track("lov::cancel");
        }}
        minHeight="90vh"
        minWidth="90vw"
      >
        {/* TODO: faire en sorte que la table calcule la hauteur automatiquement en fonction du parent */}
        <button
          className="button is-link is-rounded button-add"
          style={{ marginBottom: "1em" }}
          onClick={this.addToPanier}
          disabled={
            this.state.preRecordFailed ||
            (this.selectionUnique && this.state.selectedEntities.length > 0)
          }
        >
          <span className="icon">
            <Fa icon="plus" fixedWidth />
          </span>
          <span>
            <Trans i18nKey="commun_ajouter">Ajouter</Trans>
          </span>
        </button>
        <div className="columns">
          <div className="column is-8">
            <div
              style={{
                height: 600,
                borderRadius: "var(--datatable-radius)",
                boxShadow: "hsl(0,0%,89%) 0 0 15px 1px",
              }}
            >
              <GenericDatatable
                ref={this.gridRef}
                toolbarButtonVisibility={LOV_DATATABLE_BUTTON_VISISIBLITY}
                sjmoCode="LOV_GENERIC_TABLE"
                tableName="listeGenerique"
                tableCtrlKey="listeGeneriqueLov"
                selectionActive
                selectedRows={this.state.selectedRows}
                onRowSelect={this.onRowSelect}
                onRowUnselect={this.onRowUnselect}
                filterBar={this.state.filterBar}
                onChangeFilterBar={this.changeFilterBar}
                loadMoreData={this.loadMoreData}
                updateSearch={this.updateSearch}
                entities={this.state.entities}
                originalColumns={this.state.columns}
                columnWidthName="width"
                totalRecords={this.state.totalRecords}
                satellites={[]}
                showActionColumn={false}
                flagDirtyEntities={false}
                filterVisible
                filters={this.state.filters}
              />
            </div>
          </div>
          <div className="column is-4">
            <Panier
              loading={this.state.loading}
              axis="y"
              lockAxis="y"
              useDragHandle
              onSortEnd={this.onReorderEnd}
              onClear={this.clearSelections}
              onValidate={this.validateSelections}
              disabled={this.state.preRecordFailed}
            >
              <Scrollbars autoHide className="lov-scroll-panier">
                {this.state.selectedEntities.map((entity, index) => (
                  <PanierItem
                    key={index}
                    index={index}
                    title={
                      entity
                        ? get(entity, "label", entity[this.getColumnNameId(this.state.lovId)])
                        : ""
                    }
                    removeRow={this.removeRow(index)}
                  />
                ))}
              </Scrollbars>
            </Panier>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ReducerState, ownProps: LovAllProps): LovPropsRedux => {
  const datatableInteractions = selectContextAsDetail(state.interactions, {
    sjmoCode: ownProps.sjmoCode,
    tableName: ownProps.targetTableName,
    ctrlKey: ownProps.targetCtrlKey,
  });

  let entity = selectAnyEntityById(state, {
    sjmoCode: ownProps.sjmoCode,
    tableName: ownProps.targetTableName,
    ctrlKey: ownProps.targetCtrlKey,
    id: ownProps.targetId || "",
  });

  return {
    interactionOrig: datatableInteractions,
    targetEntity: entity ? entity.pojo : null,
    targetEntityOrigin: entity ? entity.from : "ENTITIES",
  };
};

export default withTranslation(["commun", "lov"])(
  connect<LovPropsRedux, LovPropsReduxFn>(mapStateToProps, {
    addSelectionFromLov,
    updateRowDatatable,

    addEntity,
  })(Lov)
);
