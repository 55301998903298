import { call, put, takeLatest, select, fork, delay, putResolve } from "redux-saga/effects";
import { Task } from "redux-saga";
import history from "customHistory";
import { AxiosResponse, AxiosError } from "axios";

import { ReducerState } from "reducers";
import { DashboardFocusState } from "reducers/modules/dashboard/DashboardReducer";

import { FocusState } from "types/Focus";
import { Menu as MenuExpert } from "types/Menu";
import { DashboardLayout, PanelState } from "types/Dashboard";

import Action from "reducers/Action";

import {
  getDashboardFocus,
  getDashboardPanels,
  saveFocusPersonalise,
  razFocusPersonnalise
} from "api/dashboard";

import { getExpertMenu } from "api/expert";
import { changeFocus, changeFocusAndPanels } from "actions/dashboard";

import {
  INIT_GALAXIE,
  CLOSE_GALAXIE,
  CLOSE_GALAXIE_SUCCESS,
  CLOSING_DIRTY_GALAXY,
  FORCE_CLOSE_DIRTY_GALAXY,
  SAVE_CLOSE_GALAXY,
  DELETE_MAIN_ENTITY
} from "constant/galaxy";
import {
  FETCH_FOCUS,
  FETCH_FOCUS_SUCCESS,
  FETCH_FOCUS_PANELS,
  FETCH_FOCUS_PANELS_SUCCESS,
  FETCH_FOCUS_PANELS_AND_CHANGE_FOCUS,
  SAVE_FOCUS_PERSO,
  RAZ_FOCUS_PERSO,
  CHANGE_FOCUS_FROM_URL
} from "constant/dashboard";

import { FETCH_EXPERT_MENU_SUCCESS, FETCH_EXPERT_MENU } from "constant/expert";
import {
  initGalaxySuccess,
  initGalaxyError,
  initGalaxieLoading,
  shouldNotUpdateGalaxy
} from "actions/galaxy.action";
import { removeEntities } from "actions/actionEntities";
import { INIT_INTERACTION_SUCCESS } from "constant/context";
import { fetchInteractionsGalaxy } from "api/interaction";
import {
  selectIsGalaxyFetched,
  selectActiveGalaxies,
  selectDirtyGalaxyEntities,
  selectGalaxyInformation
} from "selectors";
import { LOADER_GALAXY_TIME_TRIGGER } from "customGlobal";
import { saveAllDirtyGalaxyEntities } from "./entities.saga";
import { STOP_GALAXY_LOADER } from "constant/loader";
import { InteractionDefinitionState } from "reducers/modules/InteractionReducer";
import { deleteMany } from "api";
import { Message } from "types/Message";
import { t } from "utils/i18n";
import { addMessage } from "actions/messages";
import { updateContext } from "actions/satellites";
import { clearCacheModule } from "containers/datatable/Datatable";
import { deleteInteractiveReportCacheByModule } from "containers/datatable/hook/useInteractiveReport";
import { GalaxyInformation } from "types/Galaxy";
import { getProcessusDefinitionByCompositeId } from "api/processus";
import { setDefaultEditionDefinition } from "actions/galaxies.action";

/**
 * Permet de récupérer les focus de galaxies
 * @param action action redux
 */
function* fetchDashboardFocus(action: any) {
  const response: AxiosResponse<FocusState[]> = yield call(getDashboardFocus, action.payload);

  yield put({
    type: FETCH_FOCUS_SUCCESS,
    payload: { focuses: response.data, sjmoCode: action.payload }
  });
}

/**
 * Permet de récupérer les panels associés à un focus de galaxie.
 * @param action action redux
 */
function* fetchDashboardPanel(action: Action<{ sjmoCode: string; focusId: string }>) {
  try {
    const response: AxiosResponse<DashboardLayout> = yield call(
      getDashboardPanels,
      action.payload.focusId,
      action.payload.sjmoCode
    );

    yield put({
      type: FETCH_FOCUS_PANELS_SUCCESS,
      payload: {
        panels: response.data,
        focusId: action.payload.focusId,
        sjmoCode: action.payload.sjmoCode
      }
    });
  } catch {
    console.error("error during fetchDashboardPanel");
  }
}

/**
 * Permet d'initialiser une galaxie en fonction de l'action INIT_GALAXIE
 * @param action action redux
 */
function* watchInitGalaxy(action: Action<{ sjmoCode: string; focusFromURL: string | undefined }>) {
  const { sjmoCode, focusFromURL } = action.payload;
  try {
    const isGalaxyInitialized = yield select(selectIsGalaxyFetched, sjmoCode);
    console.log("init galaxie", isGalaxyInitialized);
    // si jamais la galaxie a déjà été initialisé,
    // pas besoin de refaire un appel
    if (isGalaxyInitialized) {
      return;
    }

    const task: Task = yield fork(showLoadingGalaxie, sjmoCode);

    const subAction = { payload: sjmoCode };

    const galaxyInfo: GalaxyInformation | undefined = yield select(
      selectGalaxyInformation,
      sjmoCode
    );
    if (galaxyInfo && galaxyInfo.defaultEditionProcessus) {
      const res = yield call(
        getProcessusDefinitionByCompositeId,
        sjmoCode,
        galaxyInfo.defaultEditionProcessus
      );

      if (res.data) {
        yield put(setDefaultEditionDefinition(sjmoCode, res.data));
      }
    }

    // on lance la récupération des focus de la galaxie
    yield call(fetchDashboardFocus, subAction);

    // on récupére le focus privilégié
    let focus: DashboardFocusState | null = null;
    if (focusFromURL) {
      focus = yield select((state: ReducerState) => {
        const def = state.dashboard.dashboardDefinitions[sjmoCode];
        const f = def.filter(el => el.focusCode === focusFromURL);
        return f.length > 0 ? f[0] : null;
      });
    } else {
      focus = yield select((state: ReducerState) => {
        const def = state.dashboard.dashboardDefinitions[sjmoCode];
        return def && def.filter(el => el.privilegie)[0];
      });
    }

    // si on a un focus
    if (focus) {
      // on lance la récupération des panels associés
      yield call(fetchDashboardPanel, {
        payload: { sjmoCode, focusId: focus.focusId }
      } as any);

      // on sélectionne le focus
      yield put(changeFocus(sjmoCode, focus.focusId));
    }

    // chargement du menu de l'expert
    yield call(fetchExpertMenu, {
      payload: { sjmoCode }
    } as any);

    // chargement des interractions des composants.
    yield call(fetchInteractionsDefinition, {
      payload: { sjmoCode }
    } as any);

    if (task.isRunning()) {
      task.cancel();
    } else {
      // si le démarrage du loading est inférieur à 1s, on laisse le loader encore jusqu'à
      // 1s pour éviter de faire un flash à l'utilisateur
      const timeToDelay = 1000 - (performance.now() - task.result());
      if (timeToDelay > 0) {
        yield delay(timeToDelay);
      }
    }

    yield put(initGalaxySuccess(sjmoCode));
  } catch {
    yield put(initGalaxyError(sjmoCode));
    console.error("watchInitGalaxy failed");
  }
}

function* watchSelectFocusFromURL(
  action: Action<{ sjmoCode: string; galaxyFocus: string | undefined }>
) {
  const { sjmoCode, galaxyFocus } = action.payload;

  if (galaxyFocus === undefined) {
    return;
  }
  // on récupére le focus privilégié
  let focus: DashboardFocusState | null = yield select((state: ReducerState) => {
    const def = state.dashboard.dashboardDefinitions[sjmoCode];
    const f = def.filter(el => el.focusCode === galaxyFocus);
    return f.length > 0 ? f[0] : null;
  });

  // si on a un focus
  if (focus) {
    // on lance la récupération des panels associés
    yield call(fetchDashboardPanel, {
      payload: { sjmoCode, focusId: focus.focusId }
    } as any);

    // on sélectionne le focus
    yield put(changeFocus(sjmoCode, focus.focusId));
  }
}

function* showLoadingGalaxie(sjmoCode: string) {
  yield delay(LOADER_GALAXY_TIME_TRIGGER());
  yield put(initGalaxieLoading(sjmoCode));
  return performance.now();
}

function* changeFocusesAndPanels(sjmoCode: string, focusId: string, focuses: FocusState[]) {
  const focus = focuses.filter(el => el.focusId === focusId)[0];

  const responsePanels: AxiosResponse<DashboardLayout> = yield call(
    getDashboardPanels,
    focus.focusId,
    sjmoCode
  );

  if (focus) {
    yield put(changeFocusAndPanels(sjmoCode, focusId, focuses, responsePanels.data as any));
    // on sélectionne le focus
    yield put(changeFocus(sjmoCode, focus.focusId));
  }
}
/**
 * Permet de récupérer les panels d'un focus et de basculer sur ce focus
 * @param action action redux
 */
function* fetchDashboardPanelAndChangeFocusSaga(
  action: Action<{ sjmoCode: string; focusId: string }>
) {
  const { sjmoCode, focusId } = action.payload;

  // on récupére le focus sélectionné
  const focuses: DashboardFocusState[] = yield select((state: ReducerState) => {
    const def = state.dashboard.dashboardDefinitions[sjmoCode];
    return def;
  });

  yield call(changeFocusesAndPanels, sjmoCode, focusId, focuses);
}

/**
 * Sauvegarde un focus perso de galaxie
 * @param action action redux
 */
function* saveFocusPerso(
  action: Action<{ sjmoCode: string; focusId: string; panels: PanelState[] }>
) {
  const { sjmoCode, focusId, panels } = action.payload;

  try {
    // on demande une sauvegarde
    yield call(saveFocusPersonalise, sjmoCode, focusId, panels);

    // On navigue uniquement lorsque l'on créer un focus perso ie lorsqu'on est en mode super user
    if (sessionStorage.getItem("superUser") !== "true") {
      // Sinon on navigue vers le nouveau focus

      // on lance la récupération des focus de la galaxie
      const focusResponse: AxiosResponse<FocusState[]> = yield call(getDashboardFocus, sjmoCode);
      const focus = focusResponse.data.filter(el => el.privilegie)[0];

      // si on a un focus privilegie, on lance la récupération des panels & on change le focus courant
      if (focus) {
        yield call(changeFocusesAndPanels, sjmoCode, focus.focusId, focusResponse.data);
      }
    }
  } catch {
    // rien a faire en cas d'erreur sur la sauvegarde perso
    // on ne veut pas reset le focus de l'utilisateur
    // donc a laisse juste notre erreur s'afficher en notification globale
  }
}

/**
 * Suppression d'un focus perso de galaxie
 * @param action action redux
 */
function* razFocusPerso(action: Action<{ sjmoCode: string; focusId: string }>) {
  const { sjmoCode, focusId } = action.payload;

  try {
    yield call(razFocusPersonnalise, sjmoCode, focusId);

    const focusResponse: AxiosResponse<FocusState[]> = yield call(getDashboardFocus, sjmoCode);
    const focus = focusResponse.data.filter(el => el.privilegie)[0];

    yield call(changeFocusesAndPanels, sjmoCode, focus.focusId, focusResponse.data);
  } catch (e) {
    // si jamais on ne peut pas supprimer le focus perso, il n'y a rien à faire
    // sachant que axios catch et affiche les erreurs directement
    console.error("error during razFocusPerso", e);
  }
}

/**
 * Permet de récupérer les panels associés à un focus de galaxie.
 * @param action action redux
 */
function* fetchExpertMenu(action: Action<{ sjmoCode: string }>) {
  try {
    const response: AxiosResponse<MenuExpert[]> = yield call(
      getExpertMenu,
      action.payload.sjmoCode
    );

    yield put({
      type: FETCH_EXPERT_MENU_SUCCESS,
      payload: {
        menu: response.data,
        sjmoCode: action.payload.sjmoCode
      }
    });
  } catch (e) {
    console.error("error during fetchExpertMenu", e);
  }
}

/**
 * Permet de récupérer les interactions d'une galaxie
 * @param action action redux
 */
function* fetchInteractionsDefinition(action: Action<{ sjmoCode: string }>) {
  const response: AxiosResponse<InteractionDefinitionState[]> = yield call(
    fetchInteractionsGalaxy,
    action.payload.sjmoCode
  );
  yield put({
    type: INIT_INTERACTION_SUCCESS,
    payload: {
      definition: response.data,
      type: action.payload.sjmoCode
    }
  });
}

/**
 * Sauvegarde les entités dirty d'une galaxie et clos cette galaxie.
 * @param action
 */
function* saveCloseNavigate(action: Action<{ toClose: string; active: string }>) {
  try {
    yield call(saveAllDirtyGalaxyEntities, action.payload.toClose);

    yield call(forceCloseNavigate, action.payload.toClose, action.payload.active);
  } catch (e) {
    console.error("Erreur lors de la sauvegarde", e);
    yield put({
      type: STOP_GALAXY_LOADER,
      payload: {
        sjmoCode: action.payload
      }
    });
  }
}

function* getURLOnClose(toClose: string) {
  const activeGalaxies: { sjmoCode: string; link: string }[] = yield select(selectActiveGalaxies);
  // Ouverture l'onglet précédent par défaut, si il existe
  // Sinon on tente de naviguer vers l'onglet suivant
  const indexToClose = activeGalaxies.findIndex(current => current.sjmoCode === toClose);
  const indexToNavigateOnClose = indexToClose > 0 ? indexToClose - 1 : indexToClose + 1;
  // Si l'index est valide on navigue vers le code correspondant
  // Sinon c'est qu'il y avait un seul onglet
  // On retourne donc sur le home
  const urlToNavigate =
    activeGalaxies.length > indexToNavigateOnClose
      ? activeGalaxies[indexToNavigateOnClose].link
      : "";

  return { urlToNavigate, activeGalaxies, indexToNavigateOnClose };
}

function* forceCloseNavigateAction(action: Action<{ toClose: string; active: string }>) {
  yield call(forceCloseNavigate, action.payload.toClose, action.payload.active);
}
/**
 * Force la fermeture d'une galaxie, sans sauvegarder les entité dirty.
 * @param action
 */
function* forceCloseNavigate(toClose: string, active: string) {
  const { urlToNavigate, activeGalaxies, indexToNavigateOnClose } = yield call(
    getURLOnClose,
    toClose
  );

  yield putResolve(
    shouldNotUpdateGalaxy(
      activeGalaxies[indexToNavigateOnClose]
        ? activeGalaxies[indexToNavigateOnClose].sjmoCode
        : "HOME"
    )
  );

  clearCacheModule(toClose);
  deleteInteractiveReportCacheByModule(toClose);
  yield put({ type: CLOSE_GALAXIE_SUCCESS, payload: toClose });

  if (active === toClose) {
    yield call(history.push as any, urlToNavigate);
  }
}

/**
 * Tente de fermer une galaxie, le process est intérrompu si la galaxie possède des entité en dirty.
 * Dans ce cas un flag est ajouté dans le reducer galaxies/active[sjmoCode].
 * @param action
 */
function* closeGalaxy(action: Action<{ toClose: string; active: string; isDirty: boolean }>) {
  const { toClose, active, isDirty } = action.payload;
  const dirtyEntities = yield select(selectDirtyGalaxyEntities, { sjmoCode: toClose });

  if (dirtyEntities.length > 0 || isDirty === true) {
    yield put({ type: CLOSING_DIRTY_GALAXY, payload: toClose });
  } else {
    yield call(forceCloseNavigate, toClose, active);
  }

  clearCacheModule(toClose);
  deleteInteractiveReportCacheByModule(toClose);
}

/**
 * Tente de suprimer l'entité principale en base.
 * En cas de succès :
 *  - on suprimer l'entité dans redux
 *  - on met à jour l'id de la galaxy active à null
 *  - on navigue vers la galaxy vide
 *
 * @param {Action<{ sjmoCode: string; tableName: string; id: string }>} action
 * @returns
 */
function* deleteMainEntity(
  action: Action<{ sjmoCode: string; tableName: string; id: string; callback?: () => void }>
) {
  const { sjmoCode, tableName, id } = action.payload;

  try {
    yield call(deleteMany, tableName, [id], sjmoCode);
    yield put(removeEntities(sjmoCode, tableName, [id]));
    yield put(updateContext({ tableName, sjmoCode }));
    yield call(history.push as any, `/page/${sjmoCode}`);

    if (action.payload.callback) {
      yield call(action.payload.callback);
    }
  } catch (e) {
    const er = e as AxiosError;
    if (!er.response) {
      return;
    }

    const message: Message = {
      code: er.response.data.code,
      message: t(er.response.data.message),
      type: er.response.data.type,
      target: er.response.data.target
    };
    yield put(addMessage(message));

    if (action.payload.callback) {
      yield call(action.payload.callback);
    }
  }
}

// export d'une liste qui est utilisé dans l'index des saga
export default [
  takeLatest(INIT_GALAXIE, watchInitGalaxy),
  takeLatest(FETCH_FOCUS, fetchDashboardFocus),
  takeLatest(FETCH_FOCUS_PANELS, fetchDashboardPanel),
  takeLatest(FETCH_FOCUS_PANELS_AND_CHANGE_FOCUS, fetchDashboardPanelAndChangeFocusSaga),
  takeLatest(SAVE_FOCUS_PERSO, saveFocusPerso),
  takeLatest(RAZ_FOCUS_PERSO, razFocusPerso),
  takeLatest(FETCH_EXPERT_MENU, fetchExpertMenu),
  takeLatest(CLOSE_GALAXIE, closeGalaxy),
  takeLatest(FORCE_CLOSE_DIRTY_GALAXY, forceCloseNavigateAction),
  takeLatest(SAVE_CLOSE_GALAXY, saveCloseNavigate),
  takeLatest(DELETE_MAIN_ENTITY, deleteMainEntity),
  takeLatest(CHANGE_FOCUS_FROM_URL, watchSelectFocusFromURL)
];
