export const INIT_DATATABLE = "INIT_DATATABLE";
export const CHANGE_DATATABLE_FOCUS = "CHANGE_DATATABLE_FOCUS";
export const CHANGE_DATATABLE_FOCUS_SUCCESS = "CHANGE_DATATABLE_FOCUS_SUCCESS";

export const FETCH_DATATABLE_FOCUS_SUCCESS = "FETCH_DATATABLE_FOCUS_SUCCESS";
export const FETCH_DATATABLE_FOCUS_ERROR = "FETCH_DATATABLE_FOCUS_ERROR";
export const SAVE_FOCUS_PERSO_DATATABLE = "SAVE_FOCUS_PERSO_DATATABLE";
export const RAZ_FOCUS_PERSO_DATATABLE = "RAZ_FOCUS_PERSO_DATATABLE";

export const FETCH_DATATABLE_COLUMN_SUCCESS = "FETCH_DATATABLE_COLUMN_SUCCESS";
export const FETCH_DATATABLE_COLUMN_ERROR = "FETCH_DATATABLE_COLUMN_ERROR";

export const FETCH_DATATABLE_DATA = "FETCH_DATATABLE_DATA";
export const FETCH_DATATABLE_DATA_SUCCESS = "FETCH_DATATABLE_DATA_SUCCESS";
export const FETCH_DATATABLE_DATA_ERROR = "FETCH_DATATABLE_DATA_ERROR";
export const CLEAR_DATATABLE_DATA = "CLEAR_DATATABLE_DATA";
export const SAVE_DATATABLE_ENTITY = "SAVE_DATATABLE_ENTITY";

export const SET_DATATABLE_PRE_RECORD_CACHE = "SET_DATATABLE_PRE_RECORD_CACHE";

export const ADD_ROW_DATATABLE = "ADD_ROW_DATATABLE";
export const ADD_ROW_DATATABLE_SUCCESS = "ADD_ROW_DATATABLE_SUCCESS";
export const ADD_MULTIPLE_ROW_DATATABLE_SUCCESS = "ADD_MULTIPLE_ROW_DATATABLE_SUCCESS";
export const UPDATE_ROW_DATATABLE = "UPDATE_ROW_DATATABLE";
export const RESET_NEW_ENTTIES_DATATABLE = "RESET_NEW_ENTTIES_DATATABLE";
export const CHANGE_ROW_DATATABLE_NEW_ENTITY = "CHANGE_ROW_DATATABLE_NEW_ENTITY";

export const REMOVE_ENTITIES_DATATABLE = "REMOVE_ENTITIES_DATATABLE";
export const REMOVE_NEW_ENTITIES_DATATABLE = "REMOVE_NEW_ENTITIES_DATATABLE";

export const UPDATE_FILTER_DATATABLE = "UPDATE_FILTER_DATATABLE";
export const UPDATE_SEARCH_DATATABLE = "UPDATE_SEARCH_DATATABLE";
export const UPDATE_SELECTED_ROWS_DATATABLE = "UPDATE_SELECTED_ROWS_DATATABLE";

export const DATATABLE_TEMPORARY_LOADING = "DATATABLE_TEMPORARY_LOADING";

export const WVI_DATATABLE = "WVI_DATATABLE";
export const ADD_WVI_DATATABLE = "ADD_WVI_DATATABLE";

export const SHOULD_UPDATE_DATATABLE = "SHOULD_UPDATE_DATATABLE";

export const EXPORT_DT_XLS = "EXPORT_DT_XLS";
export const EXPORT_DT_PDF = "EXPORT_DT_PDF";

export const UPDATE_DATATABLE_SORT = "UPDATE_DATATABLE_SORT";
export const CLEAR_DATATABLE_REDUX_STATE = "CLEAR_DATATABLE_REDUX_STATE";
export const INIT_BASE_DATATABLE = "INIT_BASE_DATATABLE";
export const DATATABLE_ADD_ONE_FOCUS = "DATATABLE_ADD_ONE_FOCUS";
export const DATATABLE_UPDATE_FILTERS = "DATATABLE_UPDATE_FILTERS";
export const DATATABLE_REMOVE_FILTERS = "DATATABLE_REMOVE_FILTERS";
export const DATATABLE_REMOVE_FOCUS = "DATATABLE_REMOVE_FOCUS";
export const LOCAL_UPDATE_DATATABLE_FOCUS = "LOCAL_UPDATE_DATATABLE_FOCUS";

export const DATATABLE_UPDATE_FILTER_BAR = "DATATABLE_UPDATE_FILTER_BAR";

export const DATATABLE_CHANGE_BREAK_COLUMNS = "DATATABLE_CHANGE_BREAK_COLUMNS";
export const DATATABLE_SATTELLITE_EXIST = "DATATABLE_SATTELLITE_EXIST";
export const DATATABLE_SATTELLITE_EXIST_SUCCESS = "DATATABLE_SATTELLITE_EXIST_SUCCESS";
