import React, { SFC, Component } from "react";
import { connect } from "react-redux";
import { matchPath, withRouter, RouteComponentProps, NavLink } from "react-router-dom";
import { ReducerState } from "reducers";
import { Trans } from "react-i18next";

import { Button } from "../button";
import {
  closeGalaxy,
  shouldNotUpdateGalaxy,
  forceCloseDirtyGalaxy,
  saveAndCloseGalaxy,
  cancelClosingDirtyGalaxy
} from "actions/galaxy.action";
import { selectActiveGalaxies } from "selectors";
import { findMap } from "utils/entities.utils";
import { Portal } from "../Portal";

import "./Menu.css";
import { getPositionOverlay } from "utils/component.utils";
import { t } from "utils/i18n";
import { AppGalaxyInfo } from "types/Galaxy";
import { Fa } from "composants/Icon";
import { useDirtyGalaxy } from "layout/Dirty";

class Confirm extends Component<{
  sjmoCode: string;
  active: string;
  x: number;
  y: number;
  cancel: (sjmoCode: string) => void;
  saveAndNavigate: (toClose: string, active: string) => void;
  forceCloseDirty: (toClose: string, active: string) => void;
}> {
  componentDidMount() {
    document.addEventListener("click", this.onCloseEventListener);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onCloseEventListener);
  }

  onCloseEventListener = (e: MouseEvent) => {
    const el = e.target && (e.target as HTMLElement).closest("#confirmClosingGalaxyBox");
    if (!el) {
      this.props.cancel(this.props.sjmoCode);
    }
  };

  render() {
    return (
      <Portal>
        <div
          id="confirmClosingGalaxyBox"
          className="box"
          style={{
            position: "absolute",
            left: this.props.x - 100,
            top: this.props.y + 10,
            zIndex: 40,
            width: 300
          }}
        >
          <p className="is-size-7 pb-7">
            <Trans i18nKey="commun_fermer_galaxy_dirty" />
          </p>
          <nav className="level">
            <div className="level-item">
              <Button
                className="button is-small is-success"
                onClick={() => {
                  this.props.saveAndNavigate(this.props.sjmoCode, this.props.active);
                }}
              >
                {t("commun_sauvegarder")}
              </Button>
            </div>
            <div className="level-item">
              <Button
                className="button is-small is-danger"
                onClick={() => {
                  this.props.forceCloseDirty(this.props.sjmoCode, this.props.active);
                }}
              >
                {t("commun_fermer")}
              </Button>
            </div>
            <div className="level-item">
              <Button
                className="button is-small"
                onClick={() => {
                  this.props.cancel(this.props.sjmoCode);
                }}
              >
                {t("commun_annuler")}
              </Button>
            </div>
          </nav>
        </div>
      </Portal>
    );
  }
}

interface ActiveTabGalaxyReduxProps {
  activeGalaxies: { sjmoCode: string; link: string }[];
  galaxies: Record<string, AppGalaxyInfo>;
  isClosingDirtyGalaxy: { sjmoCode: string; link: string } | undefined;
}

interface ActiveTabGalaxyFn {
  close(toClose: string, active: string, isDirty: boolean): void;
  shouldNotUpdate(code: string): void;
  cancelClosingDirty(code: string): void;
  saveAndClose(toClose: string, active: string): void;
  forceCloseDirty(toClose: string, active: string): void;
}

type ActiveTabGalaxyAllProps = ActiveTabGalaxyReduxProps &
  ActiveTabGalaxyFn &
  RouteComponentProps<any>;

let dimension: { x: number; y: number } | null = null;

const ActiveTabGalaxy: SFC<ActiveTabGalaxyAllProps> = ({
  activeGalaxies,
  location,
  galaxies,
  close,
  isClosingDirtyGalaxy,
  shouldNotUpdate,
  cancelClosingDirty,
  saveAndClose,
  forceCloseDirty
}) => {
  const { getDirty } = useDirtyGalaxy();
  const match = matchPath<{ code: string }>(location.pathname, {
    path: "/page/:code"
  });
  return (
    <>
      <div className="galaxy-tab-container">
        <ul style={{ borderColor: "transparent" }}>
          <li className={location.pathname === "/" ? "galaxy-tab is-active" : "galaxy-tab"}>
            <div>
              <NavLink to="/" onClick={() => shouldNotUpdate("HOME")}>
                {t("commun_accueil")}
              </NavLink>
            </div>
          </li>
          {activeGalaxies.map(active => {
            const currentGalaxy = findMap(galaxies, galaxy => galaxy.code === active.sjmoCode);

            if (currentGalaxy === null || !currentGalaxy.link) {
              return null;
            } else {
              const { sjmoCode } = active;

              // Test avec includes car match.url ne renvois pas la partie mainEntityId de l'url courante
              const isActive: boolean = match && active.link.includes(match.url) ? true : false;

              return (
                <li key={sjmoCode} className={isActive ? "galaxy-tab is-active" : "galaxy-tab"}>
                  <div>
                    <NavLink to={active.link} onClick={() => shouldNotUpdate(active.sjmoCode)}>
                      {currentGalaxy ? currentGalaxy.label : undefined}
                    </NavLink>
                    <a
                      id={active.sjmoCode + "_close_button"}
                      className="has-text-danger"
                      onClick={e => {
                        dimension = getPositionOverlay(
                          e.currentTarget.getBoundingClientRect() as DOMRect
                        );
                        close(sjmoCode, match ? match.params.code : sjmoCode, getDirty(sjmoCode));
                      }}
                    >
                      <span className="icon is-small">
                        <Fa icon="times" transform="down-2" />
                      </span>
                    </a>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      </div>
      {isClosingDirtyGalaxy && (
        <Confirm
          sjmoCode={isClosingDirtyGalaxy.sjmoCode}
          x={dimension ? dimension.x : 0}
          y={dimension ? dimension.y : 0}
          cancel={cancelClosingDirty}
          forceCloseDirty={forceCloseDirty}
          saveAndNavigate={saveAndClose}
          active={match ? match.params.code : isClosingDirtyGalaxy.sjmoCode}
        />
      )}
    </>
  );
};

function mapStateToProps(state: ReducerState) {
  const activeGalaxies = selectActiveGalaxies(state);
  const isClosingDirtyGalaxy = activeGalaxies.find(current => current.closingDirty === true);
  return {
    activeGalaxies,
    galaxies: state.app.galaxies,
    isClosingDirtyGalaxy
  };
}

export default withRouter(
  connect<ActiveTabGalaxyReduxProps, ActiveTabGalaxyFn>(mapStateToProps, {
    close: closeGalaxy,
    shouldNotUpdate: shouldNotUpdateGalaxy,
    cancelClosingDirty: cancelClosingDirtyGalaxy,
    saveAndClose: saveAndCloseGalaxy,
    forceCloseDirty: forceCloseDirtyGalaxy
  })(ActiveTabGalaxy)
);
