import { all } from "redux-saga/effects";

// on importe ici les autres saga
import dashboardSaga from "saga/galaxy.saga";
import entitiesSaga from "saga/entities.saga";
import expertSaga from "saga/expert.saga";
import satellitesSaga from "./satellites.saga";
import datatableSaga from "./datatable.saga";
import processusSaga from "./processus.saga";
import treeSaga from "./tree.saga";
import interactionsSaga from "./interactions.saga";
import messageSaga from "./message.saga";
import adminGalaxySaga from "./adminGalaxy.saga";
import emailSaga from "saga/email.saga";
import adminMiniExpertSaga from "./adminMiniExpert.saga";
import adminCreatorSaga from "./adminCreator.saga";
import adminDatatableSaga from "./adminDatatable.saga";
import draggableGroupListSaga from "./draggableGroupList.saga";

// Un saga pour les gouverner tous
export default function* rootSaga() {
  // todo
  // pas besoin de plusieurs all, un seul suffit
  yield all([
    ...dashboardSaga,
    ...entitiesSaga,
    ...expertSaga,
    ...satellitesSaga,
    ...datatableSaga,
    ...processusSaga,
    ...treeSaga,
    ...interactionsSaga,
    ...messageSaga,
    ...adminGalaxySaga,
    ...emailSaga,
    ...adminMiniExpertSaga,
    ...adminCreatorSaga,
    ...adminDatatableSaga,
    ...draggableGroupListSaga
  ]);
}
