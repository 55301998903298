import { useEffect, useState } from "react";
import throttle from "lodash-es/throttle";

const defaultEvents = ["mousemove", "mousedown", "resize", "keydown", "touchstart", "wheel"];
const oneMinute = 60e3;

const broadcast = new BroadcastChannel("idle");

export default function useIdle(
  ms: number = oneMinute,
  initialState: boolean = false,
  events: string[] = defaultEvents
): boolean {
  const [state, setState] = useState<boolean>(initialState);

  useEffect(() => {
    function onMessage(ev: MessageEvent) {
      setState(ev.data as boolean);
    }

    broadcast.addEventListener("message", onMessage);

    return () => {
      broadcast.removeEventListener("message", onMessage);
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    let timeout: any;

    const set = (newState: boolean) => {
      if (mounted) {
        setState(newState);
        if (!document.hidden) {
          broadcast.postMessage(newState);
        }
      }
    };

    const onEvent = throttle(() => {
      if (state) {
        set(false);
      }

      clearTimeout(timeout);
      timeout = setTimeout(() => set(true), ms);
    }, 100);

    const onVisibility = () => {
      if (!document.hidden) {
        onEvent();
      }
    };

    for (let i = 0; i < events.length; i++) {
      window.addEventListener(events[i], onEvent, { passive: true });
    }
    document.addEventListener("visibilitychange", onVisibility, { passive: true });

    timeout = setTimeout(() => set(true), ms);

    return () => {
      mounted = false;

      for (let i = 0; i < events.length; i++) {
        window.removeEventListener(events[i], onEvent);
      }
      document.removeEventListener("visibilitychange", onVisibility);
    };
  }, [ms, events, state]);

  return state;
}
