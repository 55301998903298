/**
 * Gestion des actions de contextualisation au sein d'un galaxie
 */

import { CONTEXTUALIZE } from "constant/context";
import { Pojo } from "types/Galaxy";

export function contextualize(
  sjmoCode: string,
  tableName: string | null,
  ctrlkey: string,
  entity: Pojo | null
) {
  return {
    type: CONTEXTUALIZE,
    payload: { sjmoCode, tableName, ctrlkey, entity },
    meta: { delayed: true }
  };
}
