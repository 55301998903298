import Action from "reducers/Action";
import { AxiosResponse, AxiosPromise } from "axios";
import { all, takeEvery, select, call, put, takeLatest, putResolve } from "redux-saga/effects";

import {
  INIT_DATATABLE,
  CHANGE_DATATABLE_FOCUS,
  FETCH_DATATABLE_COLUMN_ERROR,
  FETCH_DATATABLE_FOCUS_ERROR,
  FETCH_DATATABLE_DATA,
  DATATABLE_TEMPORARY_LOADING,
  FETCH_DATATABLE_DATA_ERROR,
  UPDATE_SEARCH_DATATABLE,
  SAVE_DATATABLE_ENTITY,
  ADD_ROW_DATATABLE,
  WVI_DATATABLE,
  REMOVE_ENTITIES_DATATABLE,
  SAVE_FOCUS_PERSO_DATATABLE,
  RAZ_FOCUS_PERSO_DATATABLE,
  EXPORT_DT_PDF,
  EXPORT_DT_XLS,
  DATATABLE_UPDATE_FILTER_BAR,
  DATATABLE_SATTELLITE_EXIST
} from "constant/datatable.constant";

import {
  getFocus,
  getColumn,
  saveFocusPersoDatatable,
  razFocusPersoDatatable,
  callExportPdf,
  callExportXls
} from "api/datatable";
import { createMany, preRecord, whenValidateItem, deleteMany, findAll } from "api/entities";

import { FocusState } from "types/Focus";
import { PagedResource, FilterBar } from "types/Search";
import { ComponentState } from "types/Component";
import { Pojo, WhenValidateItemResult } from "types/Galaxy";

import {
  updateDatatableColumns,
  updateDatatableFocus,
  callApi,
  fetchDatatableDataSuccess,
  addRowDatatableSuccess,
  resetNewEntitiesDatatable,
  addWviDatatable,
  updateRowDatatable,
  removeNewEntitiesDatatable,
  ActionSaveFocusPersoDatatable,
  ActionRazFocusPersoDatatable,
  updateSelectedRows,
  clearDatatableData,
  setDatatablePreRecordCache,
  changeFocusSuccess,
  datatableSattelliteExistSuccess
} from "actions/datatable.action";
import { addAllEntities, addEntity, removeEntities } from "actions";

import {
  selectDatatableFilter,
  selectDatatableTotalRecords,
  selectEntitiesFromTable,
  selectCurrentOelColumns,
  selectKeysBySjmoCode,
  selectCurrentDefinition,
  selectDatatableDefaultSort,
  selectDatatableFilterBar
} from "selectors/datatable.selectors";
import { InteractionReducerParameter } from "reducers/modules/InteractionReducer";
import { addMessage } from "actions/messages";
import { RESET_GALAXY } from "constant/galaxy";
import { URL_DOWNLOAD } from "customGlobal";
import { DatatableState } from "reducers/modules/DatatableReducer";
import { existList } from "api";

export type ActionInitDatatable = Action<{ sjmoCode: string; tableName: string; ctrlKey: string }>;
type ActionChangeFocusDatatable = Action<{
  sjmoCode: string;
  ctrlKey: string;
  selectedFocus: string;
}>;

type ActionFetchDatatableData = Action<{
  sjmoCode: string;
  tableName: string;
  ctrlKey: string;
  startIndex: number;
  size: number;
  reset: boolean;
  loading: boolean;
  includeOel: string[];
  includeStyle: boolean;
  callback?: {
    resolve: () => void;
    reject: () => void;
  };
}>;

function* callGetColumn(action: ActionChangeFocusDatatable) {
  const { sjmoCode, ctrlKey, selectedFocus } = action.payload;
  yield put(changeFocusSuccess(sjmoCode, ctrlKey, selectedFocus));
  yield call(getColumns, sjmoCode, ctrlKey, selectedFocus);
}

function* getColumns(sjmoCode: string, ctrlKey: string, selectedFocus: string) {
  try {
    const response: AxiosResponse<ComponentState[]> = yield call(
      getColumn,
      sjmoCode,
      selectedFocus
    );
    yield putResolve(updateDatatableColumns(sjmoCode, ctrlKey, response.data));
  } catch {
    yield putResolve({ type: FETCH_DATATABLE_COLUMN_ERROR });
  }
}

function* callGetFocus(action: ActionInitDatatable) {
  const { sjmoCode, tableName, ctrlKey } = action.payload;

  try {
    const response: AxiosResponse<FocusState[]> = yield call(
      getFocus,
      sjmoCode,
      tableName,
      ctrlKey
    );

    // on enregistre la liste des focus
    yield putResolve(updateDatatableFocus(sjmoCode, ctrlKey, response.data));
    if (response.data.length > 0) {
      yield call(getColumns, sjmoCode, ctrlKey, response.data[0].focusId);
    }
  } catch {
    yield put({ type: FETCH_DATATABLE_FOCUS_ERROR });
  }
}

function* initDatatable(action: ActionInitDatatable) {
  const { sjmoCode, ctrlKey, tableName } = action.payload;
  yield call(callGetFocus, action);

  const oelColumns = yield select(selectCurrentOelColumns, { sjmoCode, ctrlKey });

  yield put(
    callApi({
      sjmoCode,
      tableName,
      ctrlKey,
      startIndex: 0,
      reset: true,
      includeOel: oelColumns,
      includeStyle: true
    })
  );
}

function* fetchDatatableData(action: ActionFetchDatatableData) {
  try {
    const {
      sjmoCode,
      tableName,
      ctrlKey,
      startIndex,
      size,
      reset,
      loading,
      includeOel,
      includeStyle
    } = action.payload;

    const selectProperties = { sjmoCode, ctrlKey };
    const filter: string = yield select(selectDatatableFilter, selectProperties);

    const filterBar: FilterBar = yield select(selectDatatableFilterBar, selectProperties);

    const totalRecords = yield select(selectDatatableTotalRecords, selectProperties);
    const defaultSort = yield select(selectDatatableDefaultSort, selectProperties);
    const shouldAddSort = !filter.includes("order=");

    if (reset) {
      // on vide les data de la datatable
      // - entities
      // - newEntities
      // - rowSelected
      // - wviState
      yield put(clearDatatableData(sjmoCode, ctrlKey));
    } else if (loading) {
      let loadingEntities = {};
      for (let i = startIndex; i < totalRecords && i < startIndex + size; i++) {
        loadingEntities[i] = "LOADING_POJO";
      }

      yield putResolve({
        type: DATATABLE_TEMPORARY_LOADING,
        payload: { sjmoCode, ctrlKey, loading: loadingEntities }
      });
    }

    const response: AxiosResponse<PagedResource<Pojo>> = yield call(findAll, {
      sjmoCode,
      tableName,
      filter: shouldAddSort ? `${filter}&order=${encodeURIComponent(defaultSort)}` : filter,
      first: startIndex,
      size,
      includeJoinParent: true, // on inclus les jointures
      includeOels: includeOel,
      includeStyle,
      contextKey: ctrlKey,
      filterBar
    });

    yield put(addAllEntities(sjmoCode, tableName, response.data.data, reset));
    yield put(fetchDatatableDataSuccess(sjmoCode, ctrlKey, startIndex, response.data, reset));

    action.payload.callback && action.payload.callback.resolve();

    // On regarde pour chaque enregistrement si il exite une donnée satellite
    // Cette opération se fait après le callback afin de ne pas retarder le rafraichissement d'informations plus importantes
    const ids = response.data.data.map(pojo => pojo.id);
    if (ids.length > 0) {
      const satelliteExistResponse = yield call(existList, { tableName, ids });
      yield put(datatableSattelliteExistSuccess(sjmoCode, ctrlKey, satelliteExistResponse.data));
    }
  } catch {
    const { sjmoCode, ctrlKey } = action.payload;
    yield put({
      type: FETCH_DATATABLE_DATA_ERROR,
      payload: {
        sjmoCode,
        ctrlKey
      }
    });

    action.payload.callback && action.payload.callback.reject();
  }
}

function* updateAllBySjmoCode(action: Action<{ sjmoCode: string }>) {
  const ctrlKeys = yield select(selectKeysBySjmoCode, { sjmoCode: action.payload.sjmoCode });

  for (let ctrlKey of ctrlKeys) {
    const oelColumns = yield select(selectCurrentOelColumns, {
      sjmoCode: action.payload.sjmoCode,
      ctrlKey
    });

    const def = yield select(selectCurrentDefinition, {
      sjmoCode: action.payload.sjmoCode,
      ctrlKey
    });

    yield put(
      callApi({
        sjmoCode: action.payload.sjmoCode,
        tableName: def.tableName,
        ctrlKey,
        startIndex: 0,
        reset: true,
        includeOel: oelColumns,
        includeStyle: true
      })
    );
  }
}

function* updateSearch(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    ctrlKey: string;
    callback: {
      resolve: () => void;
      reject: () => void;
    };
  }>
) {
  const { sjmoCode, tableName, ctrlKey, callback } = action.payload;

  const oelColumns = yield select(selectCurrentOelColumns, { sjmoCode, ctrlKey });
  yield put(
    callApi({
      sjmoCode,
      tableName,
      ctrlKey,
      startIndex: 0,
      reset: true,
      includeOel: oelColumns,
      includeStyle: true,
      callback
    })
  );
}

function* saveEntities(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    ctrlKey: string;
    entities: Pojo[];
    callback?: () => void;
  }>
) {
  const { sjmoCode, tableName, ctrlKey, entities } = action.payload;

  try {
    if (entities.length > 0) {
      const response: AxiosResponse<Pojo[]> = yield call(createMany, tableName, entities, sjmoCode);
      // TODO : ici on doit supprimer également les data qui était présente dans le store "datatable.newEntities"
      // mais on doit faire attention à ce que les data que l'on supprime soit seulement
      // les datas qui ont été sauvegardé

      const savedEntities = response.data;
      const oelColumns = yield select(selectCurrentOelColumns, { sjmoCode, ctrlKey });

      yield all([
        put(resetNewEntitiesDatatable(sjmoCode, ctrlKey)),
        put(addAllEntities(sjmoCode, tableName, savedEntities, true)),
        put(
          callApi({
            sjmoCode,
            tableName,
            ctrlKey,
            startIndex: 0,
            reset: true,
            loading: false,
            includeOel: oelColumns,
            includeStyle: true
          })
        )
      ]);
    }
  } catch {
    // TOTO : checker l'erreur et afficher un message
    console.error("erreur pour la fonction : saveEntities");
  }

  if (action.payload.callback) {
    yield call(action.payload.callback);
  }
}

function* addNewEntityDatatable(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    ctrlKey: string;
    position: "START" | "END";
    interactions?: InteractionReducerParameter;
    callback?: () => void;
  }>
) {
  const { sjmoCode, tableName, ctrlKey, position, interactions, callback } = action.payload;
  try {
    yield fetchPreRecordEntity(sjmoCode, ctrlKey, tableName, interactions);
    yield put(addRowDatatableSuccess(sjmoCode, ctrlKey, position));

    callback && callback();
  } catch {
    console.error("erreur pour la fonction : addNewEntityDatatable");
  }
}

type WhenValidateItemAction = Action<{
  sjmoCode: string;
  tableName: string;
  ctrlKey: string;
  field: string;
  pojo: Pojo;
  callback: () => void;
}>;

function* whenValidateItemDatatable(action: WhenValidateItemAction) {
  try {
    const { sjmoCode, tableName, ctrlKey, field, pojo } = action.payload;

    if (!pojo) {
      return;
    }

    // on vérifie si l'entité fait partie des entités déjà sauvegardées
    const entitiesFromTable = yield select(selectEntitiesFromTable, { sjmoCode, tableName });
    const isNewEntity = !entitiesFromTable || !entitiesFromTable[pojo.id];

    // Récupération des colonnes transientes affichées
    const oelColumns = yield select(selectCurrentOelColumns, { sjmoCode, ctrlKey });

    let result: WhenValidateItemResult<Pojo>;
    try {
      const response: AxiosResponse<WhenValidateItemResult<Pojo>> = yield call(
        whenValidateItem,
        sjmoCode,
        tableName,
        field,
        pojo,
        oelColumns
      );
      result = response.data;
    } catch (reason) {
      result = reason.response.data;
    }

    try {
      // si c'est une nouvelle entité, on l'ajoute directement dans le reducer de datatable
      if (isNewEntity) {
        yield putResolve(updateRowDatatable(sjmoCode, ctrlKey, result.entity));
      } else {
        // sinon dans le cache
        yield putResolve(
          addEntity(
            sjmoCode,
            tableName,
            result.entity.id,
            { ...result.entity, modifie: true },
            true
          )
        );
      }

      // on ajoute le wvi à la table et le message en global si nécessaire
      yield put(
        addWviDatatable(
          sjmoCode,
          ctrlKey,
          result.entity.id || result.entity.uuid,
          field,
          result.message
        )
      );

      // s'il y a un message, on l'ajoute
      if (result.message.message) {
        yield put(addMessage(result.message));
      }
    } catch {
      // on catch ici dans le cas où le result n'est pas celui attendu
      // par exemple, l'api qui est down, l'entité n'est pas retourné, etc
      console.error("erreur pour la fonction : whenValidateItemDatatable");
    }
  } catch (e) {
    console.error("erreur dans la fonction du wvi", e);
  }

  action.payload.callback();
}

type ActionRemoveEntities = Action<{
  sjmoCode: string;
  tableName: string;
  ctrlKey: string;
  toBeRemove: (string | number)[];
  callback?: () => void;
}>;

function* removeEntitiesDatatable(action: ActionRemoveEntities) {
  const { sjmoCode, tableName, ctrlKey, toBeRemove } = action.payload;
  try {
    const entities: Record<string, Pojo> = yield select(selectEntitiesFromTable, {
      sjmoCode,
      tableName
    });

    const remoteDelete: number[] = [];
    const localDelete: string[] = [];
    let currentId: number | string;
    for (let i = 0; i < toBeRemove.length; i++) {
      currentId = toBeRemove[i];
      if (!entities[currentId]) {
        localDelete.push(currentId as string);
      } else {
        remoteDelete.push(currentId as number);
      }
    }

    if (localDelete.length > 0) {
      yield put(removeNewEntitiesDatatable(sjmoCode, ctrlKey, localDelete));
    }

    if (remoteDelete.length > 0) {
      yield call(deleteMany, tableName, remoteDelete, sjmoCode);
      yield all([
        call(updateSearch, { payload: { sjmoCode, tableName, ctrlKey } } as any),
        putResolve(removeEntities(sjmoCode, tableName, remoteDelete))
      ]);
    }

    yield put(updateSelectedRows(sjmoCode, ctrlKey, []));

    if (action.payload.callback) {
      yield call(action.payload.callback);
    }
  } catch {
    // nothing to do
    console.error("erreur pour la fonction : removeEntitiesDatatable");
    if (action.payload.callback) {
      yield call(action.payload.callback);
    }
  }
}

/**
 * Permet de gérer les sauvegardes de focus perso de datatable
 */
function* saveFocusPerso(action: ActionSaveFocusPersoDatatable) {
  const { sjmoCode, tableName, columns, ctrlKey, datatableId } = action.payload;

  const { breakColumns }: DatatableState = yield select(selectCurrentDefinition, {
    sjmoCode,
    ctrlKey
  });

  try {
    // on lance la sauvegarde de notre focus
    yield call(
      saveFocusPersoDatatable,
      datatableId,
      tableName,
      ctrlKey,
      sjmoCode,
      columns,
      breakColumns
    );

    // Si le mode super user n'est pas activé alors c'est que l'on est en train de créer une perso
    if (sessionStorage.getItem("superUser") !== "true") {
      // on récupère la liste des focus et on re-sélectionne le focus privilegié
      yield call(callGetFocus, {
        payload: {
          sjmoCode,
          tableName,
          ctrlKey
        }
      } as any);
    }
  } catch {
    // en cas d'erreur, un erreur globale va s'afficher
    // et donc on n'a rien à faire ici (on souhaite laisser la perso de l'utilisateur tel quel)
    console.error("erreur pour la fonction : saveFocusPerso");
  }
}

function* deleteFocusPersoDatatable(action: ActionRazFocusPersoDatatable) {
  const { sjmoCode, ctrlKey, tableName, datatableId } = action.payload;
  try {
    // on supprime le focus
    yield call(razFocusPersoDatatable, datatableId, sjmoCode);

    // on recharge le focus
    yield call(callGetFocus, {
      payload: {
        sjmoCode,
        tableName,
        ctrlKey
      }
    } as any);
  } catch {
    console.error("erreur pour la fonction : deleteFocusPersoDatatable");
  }
}

function* exportPdf(action: Action<{ sjmoCode: string; ctrlKey: string }>) {
  const { sjmoCode, ctrlKey } = action.payload;
  yield call(callExport, callExportPdf, sjmoCode, ctrlKey);
}

function* exportXls(action: Action<{ sjmoCode: string; ctrlKey: string }>) {
  const { sjmoCode, ctrlKey } = action.payload;
  yield call(callExport, callExportXls, sjmoCode, ctrlKey);
}

function* callExport(
  callExportApi: (
    sjmoCode: string,
    tableName: string,
    filter: string,
    columns: ComponentState[]
  ) => AxiosPromise<string>,
  sjmoCode: string,
  ctrlKey: string
) {
  try {
    const definition = yield select(selectCurrentDefinition, { sjmoCode, ctrlKey });

    const pathResponse = yield call(
      callExportApi,
      sjmoCode,
      definition.tableName,
      definition.filter,
      definition.columns
    );

    let fakeLink = document.createElement("a");
    fakeLink.href = `${URL_DOWNLOAD()}${encodeURI(pathResponse.data)}`;
    fakeLink.click();
  } catch {
    console.error("export KO");
  }
}

function* fetchPreRecordEntity(
  sjmoCode: string,
  ctrlKey: string,
  tableName: string,
  interactions?: InteractionReducerParameter
) {
  const shouldCallPreRecord = true; // yield select(selectDatatableShouldUpdateCache, { sjmoCode, ctrlKey });

  if (shouldCallPreRecord) {
    // Récupération des colonnes transientes affichées
    const oelColumns = yield select(selectCurrentOelColumns, { sjmoCode, ctrlKey });

    // pas de try / catch car c'est le parent qui doit faire le catch ici
    const response: AxiosResponse<Pojo> = yield call(preRecord, {
      sjmoCode,
      tableName,
      context: interactions,
      includeOels: oelColumns
    });

    yield putResolve(setDatatablePreRecordCache(sjmoCode, ctrlKey, response.data));
  }
}

function* watchChangeFilterBar(action: Action<{ sjmoCode: string; ctrlKey: string }>) {
  const { sjmoCode, ctrlKey } = action.payload;

  const definition: DatatableState = yield select(selectCurrentDefinition, { sjmoCode, ctrlKey });
  const oelColumns = yield select(selectCurrentOelColumns, { sjmoCode, ctrlKey });

  yield put(
    callApi({
      sjmoCode,
      tableName: definition.tableName || "",
      ctrlKey,
      startIndex: 0,
      reset: true,
      includeOel: oelColumns,
      includeStyle: true
    })
  );
}

function* watchDatatableSattelliteExist(
  action: Action<{ sjmoCode: string; ctrlKey: string; tableName: string; ids: string[] }>
) {
  const { sjmoCode, ctrlKey, tableName, ids } = action.payload;
  if (ids.length > 0) {
    const satelliteExistResponse = yield call(existList, { tableName, ids });
    yield put(datatableSattelliteExistSuccess(sjmoCode, ctrlKey, satelliteExistResponse.data));
  }
}

export default [
  takeEvery(DATATABLE_UPDATE_FILTER_BAR, watchChangeFilterBar),
  takeEvery(INIT_DATATABLE, initDatatable),
  takeEvery(CHANGE_DATATABLE_FOCUS, callGetColumn),
  takeEvery(FETCH_DATATABLE_DATA, fetchDatatableData),
  takeLatest(UPDATE_SEARCH_DATATABLE, updateSearch),
  takeLatest(SAVE_DATATABLE_ENTITY, saveEntities),
  takeEvery(ADD_ROW_DATATABLE, addNewEntityDatatable),
  takeEvery(WVI_DATATABLE, whenValidateItemDatatable),
  takeLatest(REMOVE_ENTITIES_DATATABLE, removeEntitiesDatatable),
  takeEvery(SAVE_FOCUS_PERSO_DATATABLE, saveFocusPerso),
  takeEvery(RAZ_FOCUS_PERSO_DATATABLE, deleteFocusPersoDatatable),
  takeLatest(RESET_GALAXY, updateAllBySjmoCode),
  takeLatest(EXPORT_DT_PDF, exportPdf),
  takeLatest(EXPORT_DT_XLS, exportXls),
  takeLatest(DATATABLE_SATTELLITE_EXIST, watchDatatableSattelliteExist)
];
