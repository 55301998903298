import { AxiosPromise } from "axios";

import { apiAdn, getUIContext } from "./common";
import { FocusState } from "types/Focus";
import { DashboardLayout, PanelState } from "types/Dashboard";

/**
 * Fonction qui récupère la liste des focus d'une galaxie
 */
export function getDashboardFocus(sjmoCode: string): AxiosPromise<FocusState[]> {
  return apiAdn.get(`/dashboard/${sjmoCode}/focus`);
}

/**
 * Fonction qui récupère la liste des panels d'un focus de dashboard
 *
 * @param id id du focus
 * @param sjmoCode code du module
 */
export function getDashboardPanels(id: string, sjmoCode: string): AxiosPromise<DashboardLayout[]> {
  const uiContext = getUIContext({ sjmoCode });
  return apiAdn.get(`/dashboard/${id}/panels?${uiContext}`);
}

/**
 * Permet de faire la sauvegarde du focus perso
 * @param sjmoCode code du module
 * @param focusId id du focus de galaxie
 * @param panels les panels à sauvegarder
 */
export function saveFocusPersonalise(
  sjmoCode: string,
  focusId: string,
  panels: PanelState[]
): AxiosPromise<DashboardLayout[]> {
  const body = { sjmoCode, focusId, panels };
  const uiContext = getUIContext({ sjmoCode });

  if (sessionStorage.getItem("superUser") === "true") {
    return apiAdn.post(`/dashboard/focus?${uiContext}`, body);
  }

  return apiAdn.post(`/dashboard/focus/perso?${uiContext}`, body);
}

/**
 * Permet de supprimer un focus perso
 * @param sjmoCode code du module
 * @param focusId id du focus perso
 */
export function razFocusPersonnalise(
  sjmoCode: string,
  focusId: string
): AxiosPromise<DashboardLayout[]> {
  const uiContext = getUIContext({ sjmoCode });
  return apiAdn.delete(`/dashboard/focus/perso?${uiContext}`, { data: { focusId } });
}
