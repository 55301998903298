import React, { useState, useContext, CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { Trans } from "react-i18next";
import classNames from "classnames";
import { ProcessusDefinition, ProcessusDefinitionNature } from "types/Processus";

import { getDimensionFromEvent } from "utils/component.utils";

import ConfirmationModal from "./ConfirmationModal";
import { saveAllGalaxyEntities } from "actions/actionEntities";

import customHistory from "customHistory";
// import { calculateNavigation } from "api/processus";
import { ProcessusContext } from "./ProcessusProvider";
import { Fa } from "composants/Icon";
import { track } from "tracking";
import {
  useRegisterProcessus,
  useInfoProcessus,
  useStableProcessusId
} from "features/processus/processusManager";
import { ButtonLink, Columns, Column } from "@axin-org/comet";
import { Pojo } from "types/Galaxy";
import { uuidv4 } from "utils/uuid.utils";
import DelayProcessModal from "./DelayProcessModal";
import { useMemo } from "react";
import { useProcessus } from "./useProcessus";

function ProcessusLoading(props: { id: string }) {
  const [processusState] = useInfoProcessus(props.id);

  return !processusState.matches("done") &&
    !processusState.matches("wait") &&
    !processusState.matches("error") ? (
    <span className="icon">
      <Fa icon="spinner" spin />
    </span>
  ) : null;
}

export interface ProcessusLinkProps {
  definition: ProcessusDefinition;
  canDelayProcess?: Boolean;
  className?: string;
  style?: CSSProperties;
}

export function openAdvanced(
  module: string,
  processID: string,
  navigationUrl: string,
  type: ProcessusDefinitionNature,
  selected: Pojo[] | undefined,
  delay: string | null,
  label?: string,
  editionType?: string,
  refresh?: () => void
) {
  const params = new URLSearchParams();
  params.set("sjmoCode", module);
  params.set("process", processID);
  params.set("navigationUrl", navigationUrl);
  params.set("type", type);
  if (delay) {
    params.set("delay", delay);
    params.set("forAll", "true");
  }
  if (label) {
    params.set("processLabel", label);
  }
  if (editionType) {
    params.set("editionType", editionType);
  }

  const uuid = uuidv4();
  (window as any)[uuid] = () => refresh?.();

  customHistory.push({
    search: params.toString(),
    state: { selected, refresh: uuid }
  });
}

export function ProcessusLink({
  definition,
  children,
  canDelayProcess,
  ...restProps
}: React.ComponentProps<"a"> & { disabled?: boolean } & ProcessusLinkProps) {
  const processus = useProcessus(definition);

  return (
    <>
      {processus.modal.confirm.show && processus.modal.position && (
        <ConfirmationModal
          id={definition.id}
          launchProcess={processus.launch}
          save={processus.save}
          close={() => {
            processus.modal.confirm.set(false);
            track("processus::modal::cancel");
          }}
          top={processus.modal.position.x}
          left={processus.modal.position.y}
        />
      )}

      <a
        {...restProps}
        // {...(buttonProps as any)}
        onClick={processus.button.onClick}
        // ref={containerRef}
        className={classNames("processmenu-hover-show-action", restProps.className)}
        style={restProps.style}
      >
        <Columns gap={0}>
          <Column width="full">
            {children ?? definition.label}
            {processus.hasProcessusStateSpawned && (
              <ProcessusLoading id={processus.stableProcessusId} />
            )}
            {definition.type === "edition" && definition.apercu && definition.rapide && (
              <span className="processmenu-action">
                <div className="field has-addons">
                  <div className="control">
                    <button
                      className="button is-small is-primary"
                      data-edition-type="apercu"
                      disabled={!definition.apercu}
                    >
                      <Trans i18nKey="commun_apercu" />
                    </button>
                  </div>
                  <div className="control">
                    <button
                      className="button is-small is-info"
                      data-edition-type="rapide"
                      disabled={!definition.rapide}
                    >
                      <Trans i18nKey="commun_rapide" />
                    </button>
                  </div>
                </div>
              </span>
            )}
          </Column>
          <Column className="flex justify-end">
            {definition.type !== "navigation" && definition.isDifferable && canDelayProcess && (
              <ButtonLink
                onClick={() => processus.modal.calendar.set(true)}
                className="p-0 right-0"
              >
                <Fa icon="clock" />
              </ButtonLink>
            )}
          </Column>
        </Columns>
      </a>

      {processus.modal.calendar.show && (
        <DelayProcessModal
          sjmoCode={processus.context.sjmoCode}
          compositeID={definition.id}
          type={definition.type}
          editionType={processus.editionDefault}
          selected={processus.context.selected ?? []}
          advanced={
            (definition.type === "traitement" && definition.isAdvanced) ||
            (definition.type === "edition" && definition.isAdvanced)
          }
          label={definition.label}
          onClose={() => processus.modal.calendar.set(false)}
          callback={processus.context.onAfterSaveProcess}
        />
      )}
    </>
  );
}

export default ProcessusLink;
