import React, { FC } from "react";
import { useState } from "react";
import { convertValue } from "utils/entities.utils";
import Modal from "composants/Modal/Modal";
import { useRegisterProcessus, useStableProcessusId } from "features/processus/processusManager";
import { useCallback } from "react";
import { Pojo } from "types/Galaxy";
import { ProcessusDefinitionNature } from "types/Processus";
import Input from "composants/input/Input";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { openAdvanced } from "./ProcessusLink";

interface DelayProcessModalProps {
  sjmoCode: string;
  compositeID: string;
  selected: Pojo[];
  type: ProcessusDefinitionNature;
  editionType: "rapide" | "apercu" | undefined;
  advanced: boolean;
  label: string;
  onClose(): void;
  callback?: () => void;
}

const DelayProcessModal: FC<DelayProcessModalProps> = props => {
  const [t] = useTranslation();
  const [date, setDate] = useState<string>();
  const [
    managerState,
    { register: registerProcessus, reset: resetProcessus }
  ] = useRegisterProcessus();
  const stableID = useStableProcessusId(props.compositeID, props.selected, undefined);

  const machineId = managerState.context.info[stableID];
  const currentProcessusMachine = managerState.context.processus[machineId ?? ""];
  const hasProcessusStateSpawned =
    currentProcessusMachine !== undefined &&
    !currentProcessusMachine.getSnapshot().matches("error") &&
    !currentProcessusMachine.getSnapshot().matches("done");

  useEffect(() => {
    if (hasProcessusStateSpawned) {
      props.onClose();
    }
  }, [hasProcessusStateSpawned, props]);

  const launchProcess = useCallback(() => {
    if (date) {
      if (currentProcessusMachine !== undefined) {
        const snapshot = currentProcessusMachine.getSnapshot();

        if (snapshot.matches("error")) {
          resetProcessus(props.compositeID, props.selected);
        } else if (!snapshot.matches("done")) {
          return;
        }
      }

      if (
        (props.type === "traitement" && props.advanced) ||
        (props.type === "edition" && props.advanced)
      ) {
        openAdvanced(
          props.sjmoCode,
          props.compositeID,
          "",
          props.type,
          props.selected,
          date,
          props.label,
          props.editionType,
          props.callback
        );
        props.onClose();
        return;
      }

      registerProcessus(
        {
          module: props.sjmoCode,
          compositeID: props.compositeID,
          type: props.type,
          selected: props.selected ?? [],
          executeAt: date,
          editionType: props.editionType,
          label: props.label
        },
        props.callback
      );
    }
  }, [
    currentProcessusMachine,
    date,
    props.advanced,
    props.callback,
    props.compositeID,
    props.editionType,
    props.label,
    props.selected,
    props.sjmoCode,
    props.type,
    registerProcessus,
    resetProcessus
  ]);

  return (
    <Modal
      title={t("commun_choisissez_heure_lancement")}
      onClose={props.onClose}
      onValidate={launchProcess}
    >
      <Input type="time" value={date} onChange={e => setDate(convertValue(e))} id="pickAnHour" />
    </Modal>
  );
};

export default DelayProcessModal;
