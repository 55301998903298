import { api, apiAdn, getUIContext } from "api/common";
import { AxiosPromise } from "axios";
import { GenericListComponent, LovDefinition, LovParam } from "types/Component";
import { PagedResource } from "types/Search";

/**
 * Permet de récupérer l'id de la lov. Si jamais la valeur de retour est null,
 * on a aucune LOV paramétré.
 *
 * @param sjmoCode sjmoCode code du module
 * @param column column colonne d'origine (par rapport au clic droit)
 * @param table table table d'origine (par rapport au clic droit / double clic)
 */
export function getLovDefinition(
  sjmoCode: string,
  column: string,
  tableName: string,
  syjLovId: string | null
): AxiosPromise<LovDefinition> {
  const params = getUIContext({ sjmoCode, column });
  params.append("table", tableName);
  params.append("column", column);

  if (syjLovId) {
    params.append("syjLovId", syjLovId);
  }
  return apiAdn.get(`/lov/definition?${params}`);
}

/**
 * Permet de récupérer la liste des lovs paramétrées pour une datatable.
 * C'est le seul composant à pouvoir faire de l'ajout multiple.
 *
 * @param sjmoCode code du module
 * @param datatableId id de focus de table
 * @param datatableTableName table name de la table
 * @returns retourne la liste des lov disponibles
 */
export function getListLov(
  sjmoCode: string,
  datatableId: string,
  datatableTableName: string
): AxiosPromise<LovParam[]> {
  const params = getUIContext({ sjmoCode });
  params.set("datatableId", datatableId);
  params.set("tableName", datatableTableName);
  return apiAdn.get(`/lov/list?${params}`);
}

/**
 * Permet de récupérer les colonnes de la lov à lancer
 * @param lovId id de la lov
 */
export function getLovColumn(lovId: number): AxiosPromise<GenericListComponent[]> {
  return apiAdn.get(`/lov/${lovId}/columns`);
}

/**
 * Permet de récupérer les définitions en fonction du nom de la colonne
 *
 * @export
 * @param table nom de la table
 * @returns {AxiosPromise<GenericListComponent[]>}
 */
export function getLovDefaultColumn(table: string): AxiosPromise<GenericListComponent[]> {
  return apiAdn.get(`/lov/${table}/default-columns`);
}

/**
 * Permet de récupérer les data de la LOV
 * @param lovId id de la lov
 * @param first début de pagination
 * @param size nombre d'éléments à ramener
 * @param filter filter additionnel
 * @param context contextualisation de la requête de la LOV. (le plus souvent à partir de la mainEntity)
 */
export function getLovData(
  lovId: string,
  first: number = 0,
  size: number = 10,
  filter: string,
  context: string
): AxiosPromise<PagedResource<Record<string, any>>> {
  const contextMatrix = context && context.length > 0 ? `&${context}` : "";
  return api.get(`/lov/${lovId}/data?first=${first}&size=${size}&${filter}&${contextMatrix}`);
}
