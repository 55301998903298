import { Layout } from "react-grid-layout";
import { ThunkAction } from "redux-thunk";

import {
  LAYOUT_CHANGE,
  FOCUS_CHANGE,
  CLOSE_PANEL,
  ADD_PANEL_TO_FOCUS,
  FETCH_FOCUS,
  FETCH_FOCUS_PANELS,
  FETCH_FOCUS_PANELS_AND_CHANGE_FOCUS,
  SAVE_FOCUS_PERSO,
  RAZ_FOCUS_PERSO,
  CHANGE_FOCUS_AND_PANELS,
  CHANGE_FOCUS_FROM_URL,
  ADD_MULTIPLE_PANEL_TO_FOCUS,
  CLOSE_MULTIPLE_PANEL
} from "constant/dashboard";

import { PanelState } from "types/Dashboard";
import { FocusState } from "types/Focus";
import { ReducerState } from "reducers";

import {
  selectListComponentParents,
  selectListComponentChildren
} from "selectors/context.selector";

export function changeFocus(sjmoCode: string, selected: string) {
  return {
    type: FOCUS_CHANGE,
    payload: { selected, sjmoCode }
  };
}

export function closePanel(
  sjmoCode: string,
  sjpaId: string
): ThunkAction<void, ReducerState, unknown, any> {
  return (dispatch, getState) => {
    const state = getState();

    const selectedDashboard = state.dashboard.selected[sjmoCode];
    const dashboardDefinition = state.dashboard.dashboardDefinitions[sjmoCode].find(
      d => d.focusId === selectedDashboard
    );
    const panel = dashboardDefinition?.panels.find(p => p.panelId === sjpaId);

    if (panel && panel.panelCtrlKey) {
      const children = selectListComponentChildren(state, sjmoCode, panel.panelCtrlKey);
      const additionalPanels =
        dashboardDefinition?.panels
          .filter(p => children.includes(p.panelCtrlKey ?? ""))
          .map(p => p.panelId) ?? [];

      dispatch({
        type: CLOSE_MULTIPLE_PANEL,
        payload: { sjmoCode, panelsId: [sjpaId, ...additionalPanels] }
      });
    } else {
      dispatch({
        type: CLOSE_PANEL,
        payload: { sjmoCode, sjpaId }
      });
    }
  };
}

export function addPanelToFocus(
  sjmoCode: string,
  sjpaId: string
): ThunkAction<void, ReducerState, unknown, any> {
  return (dispatch, getState) => {
    const state = getState();

    const selectedDashboard = state.dashboard.selected[sjmoCode];
    const dashboardDefinition = state.dashboard.dashboardDefinitions[sjmoCode].find(
      d => d.focusId === selectedDashboard
    );
    const panel = dashboardDefinition?.panels.find(p => p.panelId === sjpaId);

    if (panel && panel.panelCtrlKey) {
      const parents = selectListComponentParents(state, sjmoCode, panel.panelCtrlKey);
      const additionalPanels =
        dashboardDefinition?.panels
          .filter(p => parents.includes(p.panelCtrlKey ?? ""))
          .map(p => p.panelId) ?? [];

      dispatch({
        type: ADD_MULTIPLE_PANEL_TO_FOCUS,
        payload: { sjmoCode, panelsId: [sjpaId, ...additionalPanels] }
      });
    } else {
      dispatch({
        type: ADD_PANEL_TO_FOCUS,
        payload: { sjmoCode, sjpaId }
      });
    }
  };
}

export function fetchFocus(sjmoCode: string) {
  return {
    type: FETCH_FOCUS,
    payload: sjmoCode
  };
}

export function fetchPanels(sjmoCode: string, focusId: string) {
  return {
    type: FETCH_FOCUS_PANELS,
    payload: { sjmoCode, focusId }
  };
}

export function layoutChange(sjmoCode: string, layouts: Layout[]) {
  return {
    type: LAYOUT_CHANGE,
    payload: { sjmoCode, layouts }
  };
}

export function fetchDashboardPanelAndChangeFocus(sjmoCode: string, focusId: string) {
  return {
    type: FETCH_FOCUS_PANELS_AND_CHANGE_FOCUS,
    payload: { sjmoCode, focusId }
  };
}

export function changeFocusFromURL(sjmoCode: string, galaxyFocus: string | undefined) {
  return {
    type: CHANGE_FOCUS_FROM_URL,
    payload: {
      sjmoCode,
      galaxyFocus
    }
  };
}

export function saveFocusPerso(sjmoCode: string, focusId: string, panels: PanelState[]) {
  return {
    type: SAVE_FOCUS_PERSO,
    payload: { sjmoCode, focusId, panels }
  };
}

export function changeFocusAndPanels(
  sjmoCode: string,
  focusId: string,
  focuses: FocusState[],
  panels: PanelState[]
) {
  return {
    type: CHANGE_FOCUS_AND_PANELS,
    payload: {
      focuses,
      panels,
      sjmoCode,
      focusId
    }
  };
}

export function razFocusPerso(sjmoCode: string, focusId: string) {
  return {
    type: RAZ_FOCUS_PERSO,
    payload: { sjmoCode, focusId }
  };
}
