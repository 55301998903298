import React, { Component, ComponentState, SyntheticEvent } from "react";
import Modal from "composants/Modal/Modal";
import { t } from "utils/i18n";
import { ADMIN_GALAXIE } from "./AdminGalaxy";
import { getColumnDefinition } from "api/column";
import { BlockComponent } from "composants/group/GroupComponent";
import { convertValue, createManyEntity } from "utils/entities.utils";
import { Pojo } from "types/Galaxy";
import { Message } from "types/Message";
import { findAll } from "api";
import { getNewCtrlKey } from "api/adminGalaxy";
import { ComponentReadOnly } from "types/Component";

function mockUpFonction() {
  return () => {
    return;
  };
}

interface ModalPanelTableProps {
  idPanel: string;
  addMessage: (message: Message) => void;
  sjmoCode: string | null;
  onClose(): void;
}

interface ModalPanelTableState {
  entity: Pojo;
  compos: ComponentState[];
}

class ModalPanelTable extends Component<ModalPanelTableProps, ModalPanelTableState> {
  state: ModalPanelTableState = { compos: [], entity: {} as Pojo };

  componentDidMount() {
    this.setState({ entity: { syjPanelId: this.props.idPanel } as any });

    getColumnDefinition(ADMIN_GALAXIE, "syjPanelTable", ["syjTablesId, sjptCtrlKey"]).then(
      response => {
        const compos = response.data;
        let index = compos.findIndex(compo => compo.column === "sjptCtrlKey");
        if (index != -1) {
          compos[index].disabled = ComponentReadOnly.READ_ONLY;
        }
        this.setState({
          compos
        });
      }
    );

    findAll({
      sjmoCode: ADMIN_GALAXIE,
      tableName: "syjPanelTable",
      size: 1,
      filter: "q=syjPanelId.id==" + this.props.idPanel
    }).then(response => {
      if (response.data.meta.totalRecords > 0) {
        this.setState({
          entity: {
            ...response.data.data[0]
          }
        });
      }
    });
  }

  renderForm = () => {
    return (
      <BlockComponent
        sjmoCode={ADMIN_GALAXIE}
        entity={this.state.entity}
        compos={this.state.compos}
        wviState={{}}
        contextMenu={mockUpFonction}
        onBlur={mockUpFonction}
        onChange={this.onChange}
        onValueChange={this.onValueChange}
        onItemChange={this.onItemChange}
      />
    );
  };

  render() {
    return (
      <Modal
        onClose={this.props.onClose}
        onValidate={this.onSave}
        title={t("commun_associer_panel_table")}
      >
        {this.state.compos.length === 2 && this.renderForm()}
      </Modal>
    );
  }

  onChange = (e: SyntheticEvent<any>) => {
    const field: string = e.currentTarget.name;
    const val = convertValue(e);
    this.setState({ entity: { ...this.state.entity, [field]: val } });
  };

  onValueChange = (field: string | undefined, value: any) => {
    if (!field) return;
    this.setState({ entity: { ...this.state.entity, [field]: value } });
  };

  onItemChange = (selectedItem: Pojo, field?: string) => {
    if (field) {
      this.setState({
        entity: {
          ...this.state.entity,
          [field]: selectedItem ? selectedItem.id : undefined
        }
      });
      /*Construction de la clé à partir de la table choisie*/
      if (this.props.sjmoCode && selectedItem) {
        getNewCtrlKey(selectedItem.sjtaName, this.props.sjmoCode).then(response => {
          this.setState({
            entity: {
              ...this.state.entity,
              sjptCtrlKey: response.data
            }
          });
        });
      }
    }
  };

  onSave = () => {
    if (this.state.entity.syjTablesId && this.state.entity.sjptCtrlKey) {
      createManyEntity(
        "syjPanelTable",
        [this.state.entity as Pojo],
        ADMIN_GALAXIE,
        this.props.onClose,
        this.props.addMessage
      );
    } else {
      const message: Message = {
        type: "DANGER",
        message: t("commun_champs_obligatoire_non_rempli"),
        target: "GLOBAL"
      };
      this.props.addMessage(message);
    }
  };
}

export default ModalPanelTable;
