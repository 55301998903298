import { AppGalaxyInfo, AppGalaxy } from "types/Galaxy";

import { ActionTypeData } from "reducers/Action";

import {
  INIT_GALAXIE,
  INIT_GALAXIE_LOADING,
  INIT_GALAXIE_SUCCESS,
  INIT_GALAXIE_ERROR
} from "constant/galaxy";
import { ProcessusDefinition } from "types/Processus";
import produce from "immer";

export interface AppState {
  galaxies: Record<string, AppGalaxyInfo>;
  mostUsed: AppGalaxyInfo[];
  favoris: AppGalaxyInfo[];
}

const initial: AppState = {
  galaxies: {},
  mostUsed: [],
  favoris: []
};

const INIT_APP_STATE_MOST_USED = "INIT_APP_STATE_MOST_USED";
type InitMostUsedAction = ActionTypeData<typeof INIT_APP_STATE_MOST_USED, AppGalaxyInfo[]>;

const INIT_APP_STATE = "INIT_APP_STATE";
type InitAction = ActionTypeData<typeof INIT_APP_STATE, AppGalaxy>;

const UPDATE_FAVORIS = "UPDATE_FAVORIS";
type UpdateFavoris = ActionTypeData<typeof UPDATE_FAVORIS, AppGalaxyInfo[]>;

const SET_DEFAULT_EDITION_DEFINITION = "SET_DEFAULT_EDITION_DEFINITION";
type SetDefaultEditionDefinition = ActionTypeData<
  typeof SET_DEFAULT_EDITION_DEFINITION,
  { sjmoCode: string; definition: ProcessusDefinition }
>;

export const APP_REDUCER_ACTIONS_TYPE = {
  INIT_APP_STATE_MOST_USED,
  INIT_APP_STATE,
  UPDATE_FAVORIS,
  SET_DEFAULT_EDITION_DEFINITION
};

type AllActions = InitMostUsedAction | InitAction | UpdateFavoris | SetDefaultEditionDefinition;

export default function reducer(state: AppState = initial, action: AllActions): AppState {
  switch (action.type) {
    case INIT_APP_STATE_MOST_USED:
      const mostUsed: Record<string, AppGalaxyInfo> = {};
      for (let galaxy of action.payload) {
        mostUsed[galaxy.code] = galaxy;
      }
      return {
        ...state,
        galaxies: { ...state.galaxies, ...mostUsed },
        mostUsed: action.payload
      };
    case INIT_APP_STATE:
      let galaxies: Record<string, AppGalaxyInfo> = {};
      for (let galaxy of action.payload.galaxies) {
        galaxies[galaxy.code] = galaxy;
      }

      return {
        ...state,
        galaxies,
        favoris: action.payload.favoris
      };

    case UPDATE_FAVORIS:
      return {
        ...state,
        favoris: action.payload
      };

    case SET_DEFAULT_EDITION_DEFINITION:
      return produce(state, draft => {
        if (state.galaxies[action.payload.sjmoCode]) {
          draft.galaxies[action.payload.sjmoCode].informations.defaultEditionProcessusDefinition =
            action.payload.definition;
        }
      });

    default:
      return state;
  }
}

export type GalaxyStateValue = "INIT" | "FETCHING" | "OK" | "ERROR";
export type GalaxyState = Record<string, GalaxyStateValue>;

type InitGalaxy = ActionTypeData<typeof INIT_GALAXIE, { sjmoCode: string }>;
type InitGalaxySuccess = ActionTypeData<typeof INIT_GALAXIE_SUCCESS, string>;
type InitGalaxyError = ActionTypeData<typeof INIT_GALAXIE_ERROR, string>;

type InitGalaxyLoader = ActionTypeData<typeof INIT_GALAXIE_LOADING, string>;

type GalaxyStateAllActions = InitGalaxy | InitGalaxySuccess | InitGalaxyError | InitGalaxyLoader;

export function reducerGalaxyState(
  state: GalaxyState = {},
  action: GalaxyStateAllActions
): GalaxyState {
  switch (action.type) {
    case INIT_GALAXIE:
      return {
        ...state,
        [action.payload.sjmoCode]: "INIT"
      };

    case INIT_GALAXIE_LOADING:
      return {
        ...state,
        [action.payload]: "FETCHING"
      };

    case INIT_GALAXIE_SUCCESS:
      return {
        ...state,
        [action.payload]: "OK"
      };

    case INIT_GALAXIE_ERROR:
      return {
        ...state,
        [action.payload]: "ERROR"
      };

    default:
      return state;
  }
}
