import React, { SyntheticEvent, FC, useState, useMemo } from "react";
import { convertValue } from "utils/entities.utils";
import Scrollbars from "react-custom-scrollbars";

import "./filterableList.css";
import { Line } from "types/Component";
import { useTranslation } from "react-i18next";

import "./filterableList.css";
// Différence de taille entre le composant et la liste seule sans le composant de recherche
export const SEARCH_FIELD_SIZE = 50;

interface FilterableListProps {
  lines: Line[];
  listHeight?: number | string;
  buildChildrenList(lignes: Line[]): JSX.Element;
}

const FilterableList: FC<FilterableListProps> = props => {
  const [t] = useTranslation();
  const [query, setQuery] = useState("");

  const filteredLines = useMemo(() => {
    if (query === null || query.length <= 0) {
      return props.lines;
    }

    return props.lines.filter(line => {
      let exist = false;

      if (query && line.infos) {
        for (let info of line.infos) {
          if (
            info !== undefined &&
            info !== null &&
            info
              .toString()
              .toUpperCase()
              .includes(query.toUpperCase())
          ) {
            exist = true;
            break;
          }
        }
      }
      return exist;
    });
  }, [props.lines, query]);

  return (
    <>
      <input
        value={query}
        onChange={(e: SyntheticEvent<any>) => {
          const val = convertValue(e);
          setQuery(val);
        }}
        className="input search_field"
        placeholder={t("commun_recherche")}
        autoComplete="off"
      />
      <Scrollbars autoHide style={{ height: props.listHeight }}>
        {props.buildChildrenList(filteredLines)}
      </Scrollbars>
    </>
  );
};

export default FilterableList;
