import immer from "immer";
import Action from "reducers/Action";
import { ProcessusDefinition } from "types/Processus";

import { FETCH_CONTEXT_MENU_SUCCESS, HIDE_CONTEXT_MENU } from "constant/contextMenu";
import { Pojo } from "types/Galaxy";
import { SET_PROCESSUS_CONTEXT } from "constant/processus";

// State d'un focus d'expert
export interface ContextMenuState {
  show: boolean;
  definitionOk: boolean;
  contextOk: boolean;
  definition: ProcessusDefinition[];
  xpos: number;
  ypos: number;
  sjmoCode: string | null;
  origineTableName: string;
  origineTableId: string;
  columnName: string | null;
  genericEntity: Pojo | null;
  forceNewTab: boolean;
}

// type alias pour l'action dans le reducer de dashboard
type ContextMenuAction = Action<any>;

const initialState = {
  show: false,
  definitionOk: false,
  contextOk: false,
  definition: [],
  xpos: 0,
  ypos: 0,
  sjmoCode: null,
  contextId: null,
  columnName: null,
  origineTableName: "",
  origineTableId: "",
  genericEntity: null,
  forceNewTab: false
};

export default function reducer(
  state: ContextMenuState = initialState,
  action: ContextMenuAction
): ContextMenuState {
  switch (action.type) {
    case FETCH_CONTEXT_MENU_SUCCESS:
      return showContextMenu(state, action);
    case HIDE_CONTEXT_MENU:
      return hideContextMenu(state, action);
    case SET_PROCESSUS_CONTEXT:
      return setContextOk(state, action);
    default:
      return state;
  }
}

/**
 * On affiche le menu que si on a la définition et le context qui sont à jour
 * @param state
 * @param action
 */
function showContextMenu(state: ContextMenuState, action: ContextMenuAction): ContextMenuState {
  // on récupère la valeurs des actions
  const xpos = action.payload.xpos;
  const ypos = action.payload.ypos;
  const sjmoCode = action.payload.sjmoCode;
  const columnName = action.payload.columnName;
  const definition = action.payload.definition;
  const origineTableName = action.payload.origineTableName;
  const origineTableId = action.payload.origineTableId;
  const genericEntity = action.payload.genericEntity;
  const forceNewTab = action.payload.forceNewTab;
  return immer(state, draft => {
    draft.show = state.contextOk;
    draft.definitionOk = true;
    draft.xpos = xpos;
    draft.ypos = ypos;
    draft.sjmoCode = sjmoCode;
    draft.columnName = columnName;
    draft.definition = definition;
    draft.origineTableName = origineTableName;
    draft.origineTableId = origineTableId;
    draft.genericEntity = genericEntity;
    draft.forceNewTab = forceNewTab;
  });
}

function hideContextMenu(state: ContextMenuState, action: ContextMenuAction): ContextMenuState {
  return initialState;
}

/**
 * On affiche le menu que si on a la définition et le context qui sont à jour
 * @param state
 * @param action
 */
function setContextOk(state: ContextMenuState, action: ContextMenuAction): ContextMenuState {
  return immer(state, draft => {
    draft.contextOk = true;
    draft.show = state.definitionOk;
  });
}
