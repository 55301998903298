import set from "lodash-es/set";
import { uuidv4 } from "./uuid.utils";

type VisitorType<T> = Array<T> | T;
type VisitorCallback<T> = (current: T, parent?: T, props?: any) => void | VisitorType<T>;
type VistorCallbackChildren<T> = (current: T, props?: any) => void | Array<T>;

export function visitor<T>(
  current: VisitorType<T>,
  visitFn: VisitorCallback<T>,
  childrenFn: VistorCallbackChildren<T>,
  parent?: T,
  props?: any
): void {
  // sans current, on ne fait rien.
  if (!current) {
    return;
  }

  // la valeur initiale est un tableau, on boucle directement dessus.
  if (current instanceof Array) {
    const count = current.length;
    for (let i = 0; i < count; i++) {
      visitor(current[i], visitFn, childrenFn, parent, props);
    }
    return;
  }

  // on execute la fonction de visite
  visitFn(current, parent, props);

  // condition pour lancer visitor sur un paramètre de l'objet courant.
  const children = childrenFn(current, props);
  if (children) {
    const count = children.length;
    for (let i = 0; i < count; i++) {
      visitor(children[i], visitFn, childrenFn, current, props);
    }
  }
}

export type DataWithOrderToTree<T> = Pick<T, keyof T> & {
  id: string;
  parent: string;
  expanded?: boolean;
};

export type Tree<T> = Pick<T, keyof T> & {
  key: string;
  id: string;
  children: Tree<T>[];
  expanded: boolean;
};

export function dataWithOrderToTree<T = object>(
  rootKey: string | null,
  param: DataWithOrderToTree<T>[],
  generateID: () => string = uuidv4
): Tree<T>[] {
  let tree: Tree<T>[] = [];

  let data: DataWithOrderToTree<T>;

  let nextIndex: number;
  for (let index = 0, size = param.length; index < size; index++) {
    data = param[index];
    nextIndex = index + 1;

    if (data.parent === rootKey) {
      let subTree: Tree<T>[] = [];
      if (nextIndex < size && data.id === param[nextIndex].parent) {
        // on calcule l'intervalle du children
        let subStopIndex = nextIndex;
        let loopRootPath = [data.id];
        while (
          subStopIndex < size &&
          loopRootPath.findIndex(k => k === param[subStopIndex].parent) !== -1
        ) {
          if (
            subStopIndex + 1 < size &&
            param[subStopIndex + 1].parent === param[subStopIndex].id
          ) {
            loopRootPath.push(param[subStopIndex].id);
          }
          subStopIndex++;
        }

        subTree = dataWithOrderToTree(data.id, param.slice(nextIndex, subStopIndex), generateID);
      }

      tree.push({
        ...(data as any),
        children: subTree,
        expanded: data.expanded ? data.expanded : false
      });
    }
  }

  return tree;
}
