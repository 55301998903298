import SockJS from "sockjs-client";
import Stomp, { Client } from "webstomp-client";

import { URL_WEBSOCKET } from "customGlobal";

import auth from "auth";

// FERMEZ LES YEUX --------------------------------------------------------------------------------

const AbstractXHRObject = require("sockjs-client/lib/transport/browser/abstract-xhr");

const _start = AbstractXHRObject.prototype._start;

AbstractXHRObject.prototype._start = function(method: any, url: any, payload: any, opts: any) {
  if (!opts) {
    opts = { noCredentials: true };
  }
  return _start.call(this, method, url, payload, opts);
};

// C'EST BON C'EST SAFE ---------------------------------------------------------------------------

type ConnectionFrame = {
  body: string;
  command: string;
  headers: {
    "heart-beat": string;
    "user-name": string;
    version: string;
  };
};

const urlWebSocket = URL_WEBSOCKET();

export function getSocket() {
  if (urlWebSocket) {
    return new SockJS(`${urlWebSocket}?access_token=${auth.token}`);
  } else {
    return null;
  }
}

function setupStomp(socket: WebSocket): Promise<Client | undefined> {
  // const csrf = await fetch("/ws-adn/csrf");
  // console.log(csrf);
  if (!urlWebSocket) {
    console.warn("cannot start a websocket without an URL");
    return Promise.resolve(undefined);
  }

  const accessToken = auth.token;

  const stompClient = Stomp.over(socket, {
    debug: false
  });

  const headers: any = {
    // "X-Csrf-Token": csrf,
    Authorization: "Bearer " + accessToken,
    "X-Authorization": accessToken
  };

  return new Promise(resolve => {
    stompClient.connect(
      headers,
      () => {
        resolve(stompClient);
      },
      () => {
        console.error("error stomp connection");
      }
    );
  });
}

export default setupStomp;
